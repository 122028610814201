/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import LoadingAnimation from '../../../../components/LoadingAnimaton';
import Button from '../../../../components/Button';
import PPGResultsCard from '../PPGResultsCard';
import PPGsResultsHeader from '../PPGsResultsHeader';
import getClient from '../../../../services/client/elasticsearch';
import FetchingAnimation from '../../../../components/FetchingAnimation';
import ErrorAnimation from '../../../../components/ErrorAnimation';
import { useRankingElastic } from '../../../../hooks/rankSearch';
import useQueryUrl from '../../../../hooks/queryUrl';

import * as S from './styles';

interface PPGsResultsTableProps {
  premium: boolean;
  registered: boolean;
  searchRank?: string;
  setSearchTerm?: any;
  searchTerm?: string;
  sortSearch?: any;
  setSortSearch?: any;
  pageSize?: number;
  isRanking?: boolean;
}

const PPGsResultsTable: React.FC<PPGsResultsTableProps> = props => {
  // TODO: create useQuery hook
  const history = useHistory();
  const query = useQueryUrl();
  const [forcePage, setForcePage] = useState<number>(null);
  const [ppgList, setPpgList] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageCount = Math.ceil(totalPages / props.pageSize);

  const defaultSearch = {
    index: 'ppgs',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      sort: [props.sortSearch],
    },
  };

  const customSearch = {
    index: 'ppgs',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        match: {
          university: props.searchTerm,
        },
      },
      sort: [props.sortSearch],
    },
  };

  const exactMatchSearch = {
    index: 'ppgs',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        query_string: {
          fields: ['university'],
          query: props.searchTerm,
        },
      },
    },
  };

  const {
    data: ppgs,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['ppgs', pageNumber, props.searchTerm, props.sortSearch, props.pageSize],
    async () => {
      const response = await getClient().search(
        props.searchTerm !== '' && !props.searchTerm.includes('"')
          ? customSearch
          : props.searchTerm !== '' && props.searchTerm.includes('"')
          ? exactMatchSearch
          : defaultSearch,
      );
      // eslint-disable-next-line no-shadow
      const data = await response.hits.hits;
      setPpgList(data);
      const totalData: any = await response.hits.total;
      setTotalPages(totalData.value);
      return data;
    },
    {
      initialData: [],
    },
  );

  const currentSearch = query.get('termo') || '';
  useEffect(() => {
    if (currentSearch !== '') {
      props.setSearchTerm(currentSearch);
    }
  }, []);

  useMemo(() => {
    if (props.searchTerm) {
      query.set('termo', props.searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [props.searchTerm]);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    setTimeout(() => {
      if (currentPage) {
        setPageNumber(currentPageNumber - 1);
        setForcePage(currentPageNumber - 1);
        query.set('page', currentPageNumber.toString());
        window.history.replaceState(
          {},
          '',
          `${location.pathname}?${query.toString()}`,
        );
      }
    }, 100);
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const displayResults = ppgList.map((ppg: any, index) => {
    return (
      <PPGResultsCard
        rank={index + 1 + pageNumber * props.pageSize}
        name={ppg?._source?.name}
        university={ppg?._source?.university}
        capesGrade={ppg?._source?.capesGrade}
        valuationArea={ppg?._source?.valuationArea}
        indexes={ppg?._source?.indexes}
        uniqId={ppg?._source?.uniqId}
        premium={props.premium}
        registered={props.registered}
        isRanking={props.sortSearch?.[Object.keys(props.sortSearch).toString()]}
      />
    );
  });

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            {props.searchTerm !== '' ? (
              <h3>
                Foram encontrados <span>{totalPages.toLocaleString()}</span>{' '}
                resultados
              </h3>
            ) : (
              <h3>
                <span>{totalPages.toLocaleString()}</span> PPGS foram avaliados
                pela plataforma
              </h3>
            )}
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <PPGsResultsHeader
            isRanking={
              props.sortSearch?.[Object.keys(props.sortSearch).toString()]
            }
          />
          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            <ErrorAnimation />
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        {props.premium ? (
          <>
            <S.PaginationContainer>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                forcePage={forcePage}
                onPageChange={changePage}
                containerClassName="pagination-buttons"
                previousLinkClassName="pagination-previous-buttons"
                nextLinkClassName="pagination-next-buttons"
                disabledClassName="pagination-disabled"
                activeClassName="pagination-active"
                onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
              />
            </S.PaginationContainer>
          </>
        ) : (
          <>
            <S.Block>{}</S.Block>
            <S.ReadMore>
              <Button>Cadastre-se e veja mais resultados</Button>
            </S.ReadMore>
          </>
        )}
      </S.Container>
    </>
  );
};

export default PPGsResultsTable;
