import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import CtaBanner from '../../components/CtaBanner';
import SearchBar from '../../components/SearchBar';
import AdvancedFilters from '../../components/AdvancedFilters';
import { usePermission } from '../../hooks/permission';
import AuthorsResultsTable from './components/AuthorsResultsTable';
import Section from '../../components/Section';
import * as S from './styles';

const AuthorsResultsScreen: React.FC = () => {
  const { user, signed } = useAuth();
  const { Permissions } = usePermission();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchRank, setSearchRank] = useState('');
  const [userPremium, setUserPremium] = useState(false);
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
  const [isRanking, setIsRanking] = useState(false);
  const [pageSize, setPageSize] = useState<any>(5);
  const [sortSearch, setSortSearch] = useState({});

  useEffect(() => {
    if (!signed || !user?.subscriptions?.enabled) {
      setUserPremium(false);
    } else {
      setUserPremium(true);
    }
  }, [user]);

  useEffect(() => {
    if (searchTerm !== '' || searchRank !== '') {
      setOpenAdvancedFilter(true);
    }
  }, [searchTerm, searchRank]);

  useEffect(() => {
    setSearchRank('');
  }, [sortSearch]);

  const permission = Permissions('Search_Author');

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <Section />

          <SearchBar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            setPageSize={setPageSize}
            permission={permission}
            isRanking={isRanking}
            setIsRanking={setIsRanking}
            setSearchRank={setSearchRank}
            barType="author"
          />

          <AdvancedFilters
            author
            setPageSize={setPageSize}
            isOpen={openAdvancedFilter}
            setSortSearch={setSortSearch}
            isRanking={isRanking}
            sortSearch={sortSearch}
          />
        </S.IntroWrapper>
        <AuthorsResultsTable
          registered
          isRanking={isRanking}
          pageSize={pageSize}
          premium={userPremium}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sortSearch={sortSearch}
          searchRank={searchRank}
        />
      </S.Container>
      <CtaBanner isNews />
    </>
  );
};

export default AuthorsResultsScreen;
