/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { BsCheckCircle } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CtaBanner from '../../components/CtaBanner';
import { ReactComponent as SearchIcon } from '../../assets/search-right.svg';
import PopUpFindName from '../../components/PopUpFindName';
import WelcomePopUp from '../../components/WelcomePopUp';
import { researcherList, authorList } from './fakeData';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { validadeCpf } from '../../utils/cpferify';
import LoadingScreen from '../../components/LoadingScreen';
import Button from '../../components/Button';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface RegisterProps {
  description?: string;
}

interface ResearcherFormInputs {
  name: string;
  CPF: number;
  birthDate: number | Date;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
  terms: boolean;
}

interface AuthorFormInputs {
  name: string;
  CPF: number;
  birthDate: number | Date;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
  nameInCitations: string;
  lattes: string;
  personalWebsite: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  twitter: string;
  terms: boolean;
}

const RegisterPage = ({
  description = 'Assine a Plataforma Caciq e tenha acesso ao que há de mais moderno em termos de logística de pesquisa científica na atualidade, podendo cadastrar-se como pesquisador ou como autor',
}: RegisterProps) => {
  const [registerType, setRegisterType] = useState(false);
  const history = useHistory();
  const { user } = useAuth();
  const { type } = useParams() as any;

  useEffect(() => {
    if (type === 'autor') {
      setRegisterType(true);
    } else return null;
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.MainContainer>
        <S.Container>
          <div className="register-page-header">
            <h2>
              Cadastro de <span>assinante</span>
            </h2>
            <p>{description}</p>
          </div>

          <S.ButtonSection>
            <div
              className={
                !registerType
                  ? 'button-active button-container'
                  : 'button-container'
              }
            >
              <button type="button" onClick={() => setRegisterType(false)}>
                <h3>Pesquisador</h3>
              </button>

              <ul>
                {researcherList.map(e => (
                  <li
                    className={
                      e.hasRight !== false ? null : 'unavaliable-feature'
                    }
                  >
                    {e.hasRight !== false ? (
                      <BsCheckCircle />
                    ) : (
                      <IoMdCloseCircleOutline />
                    )}
                    <span>{e.title}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={
                registerType
                  ? 'button-active button-container'
                  : 'button-container'
              }
            >
              <button type="button" onClick={() => setRegisterType(true)}>
                <h3>Autor</h3>
              </button>

              <ul>
                {authorList.map(e => (
                  <li
                    className={
                      e.hasRight !== false ? null : 'unavaliable-feature'
                    }
                  >
                    {e.hasRight !== false ? (
                      <BsCheckCircle />
                    ) : (
                      <IoMdCloseCircleOutline />
                    )}
                    <span>{e.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </S.ButtonSection>

          {!registerType ? (
            <ResearcherTab history={history} user={user} />
          ) : (
            <AuthorTab history={history} user={user} />
          )}
        </S.Container>
      </S.MainContainer>
      <CtaBanner />
    </>
  );
};

const researcherSchema = Yup.object().shape({
  name: Yup.string()
    .max(30, 'O Nome não pode exceder 30 caracteres')
    .required('O Nome é obrigatório'),
  CPF: Yup.string()
    .required('O CPF é obrigatório')
    .test('validade CPF', 'CPF inválido', validadeCpf),
  birthDate: Yup.string().required('A data de aniversário é obrigatória'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O E-mail é obrigatório'),
  emailConfirmation: Yup.mixed().oneOf(
    [Yup.ref('email')],
    'Os E-mails não coincidem',
  ),
  password: Yup.string().required('A senha é obrigatória'),
  passwordConfirmation: Yup.mixed().oneOf(
    [Yup.ref('password')],
    'As senhas não coincidem',
  ),
  terms: Yup.boolean().oneOf(
    [true],
    'Aceite os termos de privacidade para concluir o cadastro',
  ),
});

const ResearcherTab = ({ history, user }) => {
  const { signIn, signed, loading } = useAuth();
  const [cpf, setCPF] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [eula, setEula] = useState(false);
  const { emitError } = useError();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResearcherFormInputs>({
    resolver: yupResolver(researcherSchema),
  });
  const onSubmit: SubmitHandler<ResearcherFormInputs> = async data => {
    try {
      const parsedData = {
        name: data.name,
        email: data.email,
        password: data.password,
        CPF: data.CPF,
        birthDate: data.birthDate,
        accountType: 'researcher',
        socials: [],
      };

      if (signed) {
        history.push('/usuario/pagamento');
      } else {
        setIsLoading(true);
        const postSingUp = await api.post('/signup', parsedData);

        setTimeout(() => {
          signIn(data.email, data.password, true);
          setIsLoading(false);
          history.push('/usuario/confirmar-email');
        }, 500);
      }
    } catch (error: any) {
      setIsLoading(false);
      emitError(error, 'Falha ao cadastrar, tente novamente!');
    }
  };

  const handleCpfFilter = e => {
    let filtered = e.target.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 20) return;
    filtered = filtered.replace(/\D/g, '');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    filtered = filtered.replace(/(-\d{2})\d+?$/, '$1');

    setCPF(filtered);
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      {isAuthenticated ? (
        <WelcomePopUp
          isOpen={isAuthenticated}
          setPopUpState={setIsAuthenticated}
        />
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="form-itens">
          <li>
            <label htmlFor="name">Nome Completo</label>
            <input
              id="name"
              type="text"
              placeholder="Nome Completo"
              {...register('name')}
              defaultValue={user?.name || ''}
            />
            <p> {errors.name?.message}</p>
          </li>
          <div className="row-input">
            <li>
              <label htmlFor="CPF">CPF</label>
              <input
                id="CPF"
                value={cpf}
                type="text"
                placeholder="000.000.000-00"
                {...register('CPF')}
                onChange={e => handleCpfFilter(e)}
              />
              <p> {errors.CPF?.message}</p>
            </li>
            <li>
              <label htmlFor="birthDate">Nascimento</label>
              <input
                id="birthDate"
                type="date"
                placeholder="dd/mm/aaaa"
                {...register('birthDate')}
              />
              <p> {errors.birthDate?.message}</p>
            </li>
          </div>
          <li>
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="email"
              placeholder="email@email.com.br"
              {...register('email')}
              defaultValue={user?.email || ''}
            />
            <p> {errors.email?.message}</p>
          </li>
          <li>
            <label htmlFor="emailConfirmation">Confirmar E-mail</label>
            <input
              id="emailConfirmation"
              type="email"
              placeholder="email@email.com.br"
              {...register('emailConfirmation')}
              defaultValue={user?.email || ''}
            />
            <p> {errors.emailConfirmation?.message}</p>
          </li>
          <li>
            <label htmlFor="password">Senha</label>
            <input id="password" type="password" {...register('password')} />
            <p> {errors.password?.message}</p>
          </li>
          <li>
            <label htmlFor="passwordConfirmation">Confirmar Senha</label>
            <input
              id="passwordConfirmation"
              type="password"
              {...register('passwordConfirmation')}
            />
            <p> {errors.passwordConfirmation?.message}</p>
          </li>
        </ul>

        <div className="terms-container">
          <input
            type="checkbox"
            id="terms"
            {...register('terms')}
            onChange={value => setEula(value.target.checked)}
          />
          <label htmlFor="terms">
            Li e concordo com a
            <a
              href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Privacidade_Finalizada.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Política de privacidade
            </a>{' '}
            e com o{' '}
            <a
              href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Termos_Uso_Finalizada.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Termo de Proteção de Dados{' '}
            </a>
            <p>{errors.terms?.message}</p>
          </label>
          <p>{errors.terms?.message}</p>
        </div>

        <div className="buttons-section">
          <button
            type="submit"
            disabled={!eula}
            title={!eula && 'Você não aceitou os termos de privacidade'}
          >
            Finalizar
          </button>
        </div>
      </form>
    </>
  );
};

const authorSchema = Yup.object().shape({
  name: Yup.string()
    .max(30, 'O Nome não pode exceder 30 caracteres')
    .required('O Nome é obrigatório'),
  CPF: Yup.string().required('O CPF é obrigatório'),
  birthDate: Yup.string().required('A data de nascimento é obrigatória'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O E-mail é obrigatório'),
  emailConfirmation: Yup.mixed().oneOf(
    [Yup.ref('email')],
    'Os E-mails não coincidem',
  ),
  password: Yup.string().required('A senha é obrigatória'),
  passwordConfirmation: Yup.mixed().oneOf(
    [Yup.ref('password')],
    'As senhas não coincidem',
  ),
  terms: Yup.boolean().oneOf(
    [true],
    'Aceite os termos de privacidade para concluir o cadastro',
  ),
});

const AuthorTab = ({ history, user }) => {
  const [searchName, setSearchName] = useState(false);
  const [newValue, setNewValue] = useState<any>('');
  const [registerLoading, setRegisterLoading] = useState(false);
  const { emitError } = useError();

  const [cpf, setCPF] = useState(null);
  const { signIn, signed } = useAuth();
  const [eula, setEula] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AuthorFormInputs>({
    resolver: yupResolver(authorSchema),
  });

  const onAuthorSubmit: SubmitHandler<AuthorFormInputs> = async data => {
    try {
      delete data.emailConfirmation;
      delete data.passwordConfirmation;

      const social = [];

      if (data.lattes)
        social.push({
          name: 'lattes',
          url: data.lattes,
          public: false,
        });
      if (data.personalWebsite)
        social.push({
          name: 'personalWebsite',
          url: data.personalWebsite,
          public: false,
        });
      if (data.nameInCitations)
        social.push({
          name: 'nameInCitations',
          url: data.nameInCitations,
          public: false,
        });
      if (data.linkedin)
        social.push({ name: 'linkedin', url: data.linkedin, public: false });
      if (data.facebook)
        social.push({ name: 'facebook', url: data.facebook, public: false });
      if (data.instagram)
        social.push({ name: 'instagram', url: data.instagram, public: false });
      if (data.twitter)
        social.push({ name: 'twitter', url: data.twitter, public: false });

      const fullData = {
        CPF: data.CPF,
        birthDate: data.birthDate,
        email: data.email,
        name: data.name,
        password: data.password,
        accountType: 'author',
        socials: social,
      };

      setRegisterLoading(true);

      if (signed) {
        history.push('/usuario/pagamento');
      } else {
        const post = await api.post('/signup', fullData);
        toast.success('Conta criada! Redirecionando ...');

        signIn(data.email, data.password, true);
        // const syncOrcid = await api.post('/signup', fullData);
        setRegisterLoading(false);

        history.push('/usuario/pagamento');
      }
    } catch (error: any) {
      setRegisterLoading(false);
      emitError(error, 'Ocorreu um erro ao cadastrar, tente novamente!');
    }
  };
  useEffect(() => {
    setValue('name', newValue.label);
  }, [newValue]);

  const handleCpfFilter = e => {
    let filtered = e.target.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 20) return;
    filtered = filtered.replace(/\D/g, '');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    filtered = filtered.replace(/(-\d{2})\d+?$/, '$1');

    setCPF(filtered);
  };

  return (
    <>
      {searchName ? (
        <PopUpFindName
          isOpen={searchName}
          userType="authors"
          setPopUpState={setSearchName}
          handleNewValue={setNewValue}
        />
      ) : null}

      {registerLoading ? <LoadingScreen /> : null}

      <form onSubmit={handleSubmit(onAuthorSubmit)}>
        <ul className="form-itens">
          <li>
            <label htmlFor="name">Nome Completo</label>
            <input
              id="name"
              type="text"
              placeholder="Nome Completo"
              {...register('name')}
              defaultValue={user?.name || ''}
            />
            <p> {errors.name?.message}</p>
          </li>

          <div className="row-input">
            <li>
              <label htmlFor="CPF">CPF</label>
              <input
                id="CPF"
                type="text"
                placeholder="000.000.000-00"
                {...register('CPF')}
                value={cpf}
                defaultValue={user?.CPF || ''}
                onChange={e => handleCpfFilter(e)}
              />
              <p> {errors.CPF?.message}</p>
            </li>
            <li>
              <label htmlFor="birthDate">Nascimento</label>
              <input
                id="birthDate"
                type="date"
                {...register('birthDate')}
                defaultValue={user?.birthDate || ''}
              />
              <p> {errors.birthDate?.message}</p>
            </li>
          </div>
          <li>
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="email"
              placeholder="email@email.com.br"
              {...register('email')}
              defaultValue={user?.email || ''}
            />
            <p> {errors.email?.message}</p>
          </li>
          <li>
            <label htmlFor="emailConfirmation">Confirmar E-mail</label>
            <input
              id="emailConfirmation"
              type="email"
              placeholder="email@email.com.br"
              {...register('emailConfirmation')}
            />
            <p> {errors.emailConfirmation?.message}</p>
          </li>
          <li>
            <label htmlFor="password">Senha</label>
            <input id="password" type="password" {...register('password')} />
            <p> {errors.password?.message}</p>
          </li>
          <li>
            <label htmlFor="passwordConfirmation">Confirmar Senha</label>
            <input
              id="passwordConfirmation"
              type="password"
              {...register('passwordConfirmation')}
            />
            <p> {errors.passwordConfirmation?.message}</p>
          </li>
          <li>
            <label htmlFor="nameInCitations">
              Nome em Citações Bibliográficas
            </label>
            <input
              id="nameInCitations"
              type="text"
              placeholder="SOBRENOME, Nome"
              {...register('nameInCitations')}
            />
          </li>
          <li>
            <label htmlFor="lattes">Lattes</label>
            <input id="lattes" placeholder="Lattes" {...register('lattes')} />
          </li>
          <li>
            <label htmlFor="personalSite">Site Pessoal</label>
            <input
              id="personalSite"
              placeholder="www.sitepessoal.com.br"
              {...register('personalWebsite')}
            />
          </li>
          <li>
            <label htmlFor="linkedin">Linkedin</label>
            <input
              id="linkedin"
              placeholder="www.linkedin.com/seuperfil"
              {...register('linkedin')}
            />
          </li>
          <li>
            <label htmlFor="facebook">Facebook</label>
            <input
              id="facebook"
              placeholder="www.facebook.com/seuperfil"
              {...register('facebook')}
            />
          </li>
          <li>
            <label htmlFor="instagram">Instagram</label>
            <input
              id="instagram"
              placeholder="www.instagram.com/seuperfil"
              {...register('instagram')}
            />
          </li>
          <li>
            <label htmlFor="twitter">Twitter</label>
            <input
              id="twitter"
              placeholder="www.twitter.com/seuperfil"
              {...register('twitter')}
            />
          </li>
        </ul>

        <div className="terms-container">
          <input
            type="checkbox"
            id="terms"
            {...register('terms')}
            onChange={value => setEula(value.target.checked)}
          />
          <label htmlFor="terms">
            Li e concordo com os
            <a href="https://google.com">Termos de Privacidade </a>
          </label>
          <p>{errors.terms?.message}</p>
        </div>

        <div className="buttons-section">
          <button
            type="submit"
            disabled={!eula}
            title={!eula && 'Você não aceitou os termos de privacidade'}
          >
            Finalizar
          </button>
        </div>
        <button
          type="button"
          className="search-button"
          onClick={() => setSearchName(!searchName)}
        >
          <SearchIcon />
        </button>
      </form>
    </>
  );
};

export default RegisterPage;
