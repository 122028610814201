/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import * as S from './styles';

type bannerProps = {
  title?: string;
  bodyText?: string;
  buttonText?: string;
  isNews?: boolean;
  buttonHref?: string;
};

const CtaBanner = ({
  title = 'Descubra os melhores textos, revistas, autores e programas de pós-graduação na sua temática de estudo.',
  bodyText = 'Amplie e qualifique sua pesquisa para o escritório, ou então, seu artigo, seu TCC, sua dissertação, sua tese ou seu trabalho de pós-doutorado.',
  buttonText = 'Conheça nossa metodologia',
  isNews = false,
  buttonHref = 'https://caciq.com.br/metodologia-indices-caciq/',
}: bannerProps) => {
  return (
    <>
      <S.CtaContainer backgroundProp={isNews}>
        <S.CtaWrapper>
          <h2>{title}</h2>
          <p>{bodyText}</p>
          <S.StyledButton
            href={buttonHref}
            target="_blank"
            backgroundProp={isNews}
          >
            {buttonText}
          </S.StyledButton>
        </S.CtaWrapper>
      </S.CtaContainer>
    </>
  );
};

export default CtaBanner;
