import { css } from 'styled-components';

interface MediaQueryParams {
  minWidth: number;
  maxWidth: number;
}

export default {
  mobile: style => props =>
    css`
      @media screen and (max-width: 767px) {
        ${style}
      }
    `,

  tablet: style => props =>
    css`
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        ${style}
      }
    `,

  custom: (style, customParams: MediaQueryParams) => props =>
    css`
      @media screen and (min-width: ${customParams.minWidth}px) and (max-width: ${customParams.maxWidth}px) {
        ${style}
      } ;
    `,
};
