/* eslint-disable consistent-return */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import * as S from './styles';

export interface ContentTagProps {
  large?: boolean;
  title?: string;
  href?: string;
  hrefBlank?: string;
  onClick?: any;
}

const ContentTag: React.FC<ContentTagProps> = ({
  children,
  large,
  title,
  href,
  hrefBlank,
  onClick = false,
}) => {
  const history = useHistory();

  function handleAction() {
    if (onClick && !href) onClick(`"${title}"`);
    if (href) history.push(href);
    if (hrefBlank) window.open(hrefBlank, '_blank');
  }

  return (
    <>
      <S.StyledtTag large={large} title={title}>
        <button type="button" onClick={handleAction}>
          {children}
        </button>
      </S.StyledtTag>
    </>
  );
};

export default ContentTag;
