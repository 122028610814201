/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from 'react-query';
import RelatedContent from '../../components/RelatedContent';
import CaciqIndex from '../../components/CaciqIndex';
import FetchingAnimation from '../../components/FetchingAnimation';
import * as S from './styles';
import NumberOfCitations from '../../components/NumberOfCitations';
import { ReactComponent as AvatarImg } from '../../assets/avatar.svg';
import VerifyProfileCard from '../../components/VerifyProfileCard';
import { IJournal } from '../../models/journal';
import { useJournalDetails } from '../../services/hooks/useJournalDetails';
import ErrorAnimation from '../../components/ErrorAnimation';
import LoadingAnimation from '../../components/LoadingAnimaton';
import { api } from '../../services/api';
import { usePermission } from '../../hooks/permission';

import getClient from '../../services/client/elasticsearch';

export interface JournalDetailsProps extends IJournal {
  publishedArticles?: number;
  avatar?: string;
  responsibleEditor?: string;
  personalSite?: string;
  twitter?: string;
  instagram?: string;
  linkedin?: string;
  facebook?: string;
}

const JournalDetails: React.FC<JournalDetailsProps> = props => {
  const [userJournal, setUserJournal] = useState<any>([]);
  const { Permissions } = usePermission();

  const [currentJournal, setCurrentJournal] = useState<any>([]);
  const { details } = useParams() as any;

  const {
    data: journal,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['journals', details],
    async () => {
      const response = await getClient().search({
        index: 'journals',
        size: 5,
        body: {
          query: {
            bool: {
              should: [
                {
                  match: {
                    name: details,
                  },
                },
                {
                  match: {
                    uniqId: details,
                  },
                },
              ],
            },
          },
        },
      });
      const data: any = await response.hits.hits;

      if (data[0]?._source?.userId) {
        const author = await api.post('/profile/public', {
          ids: [data[0]?._source?.userId],
        });
        setUserJournal(author?.data[0]);
      }
      return data;
    },
    {
      initialData: [],
    },
  );

  const editorResponsible: any = userJournal.filter(
    e => e.name === 'editorResponsible' && e.public,
  );
  const lattes: any = userJournal?.socials?.filter(e => e.name === 'lattes');
  const personalWebsite: any = userJournal?.socials?.filter(
    e =>
      (e.name === 'personalWebsite' || e.name === 'personalSite') && e.public,
  );
  const instagram: any = userJournal?.socials?.filter(
    e => e.name === 'instagram' && e.public,
  );
  const facebook: any = userJournal?.socials?.filter(
    e => e.name === 'facebook' && e.public,
  );
  const twitter: any = userJournal?.socials?.filter(
    e => e.name === 'twitter' && e.public,
  );
  const linkedin: any = userJournal?.socials?.filter(
    e => e.name === 'linkedin' && e.public,
  );

  useEffect(() => {
    if (!journal) return null;

    return setCurrentJournal(journal[0]?._source);
  }, [journal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ICRPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');
  const HALLPermission = Permissions('Result_Scholar_H4_Index');
  const I10Permission = Permissions('Result_Scholar_Index');
  const QualisPermission = Permissions('Result_Qualis_Index');
  const SJRPermission = Permissions('Result_Scimago_Index');

  const ICH4 = currentJournal?.indexes?.['IC-H4-R'];
  const ICR = currentJournal?.indexes?.['IC-R'];
  const HALL = currentJournal?.indexes?.hAll;
  const SJR = currentJournal?.indexes?.sjr;
  const H5 = currentJournal?.indexes?.h5;
  const I10All = currentJournal?.indexes?.i10All;
  const I10 = currentJournal?.indexes?.i105y;
  const Qualis = currentJournal?.stratum;

  return (
    <>
      <S.Container>
        {isLoading ? (
          <LoadingAnimation />
        ) : error ? (
          <ErrorAnimation />
        ) : (
          <>
            <S.JournalWrapper>
              <S.ImageWrapper>
                {currentJournal?.avatar ? (
                  <img src={currentJournal?.avatar} alt="" />
                ) : (
                  <div className="perfil-photo">
                    <AvatarImg />
                  </div>
                )}
              </S.ImageWrapper>

              <S.JournalInfoWrapper>
                <p>{currentJournal?.issn}</p>

                <div className="NameWrapper">
                  <h2>{currentJournal?.name}</h2>
                  {!isLoading && isFetching && <FetchingAnimation />}
                </div>
                <div className="IndexWrapper">
                  {ICR && ICRPermission ? (
                    <h5>
                      Caciq-r <strong>{Number(ICR)}</strong>
                    </h5>
                  ) : (
                    ''
                  )}
                  {ICH4 && ICH4Permission ? (
                    <h5>
                      Caciq-h4R <strong>{Number(ICH4)}</strong>
                    </h5>
                  ) : (
                    ''
                  )}

                  {HALL && HALLPermission ? (
                    <h5>
                      Web-h4 <strong>{Number(HALL)}</strong>
                    </h5>
                  ) : (
                    ''
                  )}

                  {SJR && SJRPermission ? (
                    <p>
                      sjr <strong>{Number(SJR)}</strong>
                    </p>
                  ) : (
                    ''
                  )}

                  {Qualis && QualisPermission ? (
                    <h5>
                      Qualis <strong>{Qualis}</strong>
                    </h5>
                  ) : (
                    ''
                  )}
                </div>

                <div className="OccupationArea">
                  <h6>Área de avaliação</h6>
                  <p>{currentJournal?.valuationArea?.map(e => e)}</p>
                </div>
              </S.JournalInfoWrapper>
            </S.JournalWrapper>

            <S.DetailsWrapper>
              <S.MoreDetailsContent>
                <NumberOfCitations
                  // eslint-disable-next-line no-octal
                  numberInfo={
                    currentJournal?.articlesCount?.toLocaleString() || 0
                  }
                  textInfo="Textos publicados no período"
                />
              </S.MoreDetailsContent>

              <S.DetailsCardContent>
                <VerifyProfileCard
                  cardType="journal"
                  isVerifyed={currentJournal?.userId}
                  responsibleEditor={
                    editorResponsible?.length > 0
                      ? editorResponsible[0]?.url
                      : null
                  }
                  lattes={lattes?.length > 0 ? lattes[0]?.url : null}
                  personalSite={
                    personalWebsite?.length > 0 ? personalWebsite[0]?.url : null
                  }
                  twitter={twitter?.length > 0 && twitter[0]?.url}
                  facebook={facebook?.length > 0 && facebook[0]?.url}
                  instagram={instagram?.length > 0 && instagram[0]?.url}
                  linkedin={linkedin?.length > 0 && linkedin[0]?.url}
                />
              </S.DetailsCardContent>
            </S.DetailsWrapper>
          </>
        )}
      </S.Container>

      <S.RelatedWrapper>
        {journal.length > 1 && <h3>Veja mais periódicos relacionados</h3>}
        {isLoading ? (
          <LoadingAnimation />
        ) : error ? (
          'Erro ao carregar. Tente novamente'
        ) : (
          <S.ContainerCards>
            {journal?.slice(1, 4).map((item: any) => (
              <RelatedContent
                type="journal"
                item="Titulo"
                properties="Áreas de avaliação"
                valuationAreas={item?._source?.valuationArea}
                href={`/revista/detalhe/${item?._source?.uniqId}`}
                text={item?._source?.name}
                caciqIndex={
                  <CaciqIndex
                    type="journal"
                    name="IC-R"
                    value={item?._source?.indexes?.['IC-R'] || 0}
                  />
                }
              />
            ))}
          </S.ContainerCards>
        )}
      </S.RelatedWrapper>
    </>
  );
};

export default JournalDetails;
