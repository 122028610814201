/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import RelatedContent from '../../components/RelatedContent';
import CaciqIndex from '../../components/CaciqIndex';
import * as S from './styles';
import ContentTag from '../../components/ContentTag';
import DetailsCard from '../../components/DetailsCard';
import Button from '../../components/Button';
import NumberOfCitations from '../../components/NumberOfCitations';
import VerifyAuthor, { IAuthor } from './components/VerifyAuthor';
import LoadingAnimation from '../../components/LoadingAnimaton';
import FetchingAnimation from '../../components/FetchingAnimation';
import getClient from '../../services/client/elasticsearch';

export interface ArticleDetailsProps {
  journal_id?: string;
  uniqId?: string;
  title?: string;
  abstract?: string;
  authors?: IAuthor[];
  publicationYear?: number;
  linkPDF?: string[];
  keywords?: string[];
  DOI?: string;
  sources?: string[];
  total_refs_citation?: number;
  total_references?: number;
  references?: IReference[];
  publicationSite?: string;
  indexes?: {
    name?: string;
    value?: string;
  }[];
  citationName?: string;
}

interface IReference {
  title?: string;
  DOI?: string;
  author?: string;
  publicationYear?: number;
  journalTitle?: string;
}

const ArticleDetails: React.FC = () => {
  const [currentArticle, setCurrentArticle] = useState<any>([]);
  const { details } = useParams() as any;
  const [authorList, setAuhtorList] = useState(5);

  const {
    data: article,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['article', details],
    async () => {
      const response = await getClient().search({
        index: 'articles',
        size: 5,
        body: {
          query: {
            bool: {
              should: [
                {
                  match: {
                    name: details,
                  },
                },
                {
                  match: {
                    uniqId: details,
                  },
                },
                {
                  match: {
                    id: details,
                  },
                },
              ],
            },
          },
        },
      });
      const data = await response.hits.hits;
      return data;
    },
    {
      initialData: [],
    },
  );

  useEffect(() => {
    if (!article) return null;
    return setCurrentArticle(article[0]?._source);
  }, [article]);

  const postTags = useMemo(() => {
    if (currentArticle?.keywords?.length) {
      return currentArticle.keywords.map(keyword => [
        keyword || 'Não cadastrado',
      ]);
    }
    return [];
  }, [article]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const seeMore = () => {
    if (authorList === 5) setAuhtorList(-1);
    else setAuhtorList(5);
  };

  const hasAuthors = currentArticle?.authorsRel?.filter(
    filter => filter.orcid || filter.ORCID,
  );

  return (
    <>
      <S.Container>
        {isLoading ? (
          <LoadingAnimation />
        ) : error ? (
          'Erro ao carregar. Tente novamente'
        ) : (
          <S.Container>
            <S.TitleWrapper>
              <p>
                Publicado em {currentArticle?.journalRel?.name}{' '}
                {!isLoading && isFetching && <FetchingAnimation />}
              </p>
              <h2>{currentArticle?.title}</h2>
              <S.TagWrapper>
                {currentArticle?.authorsRel
                  ?.slice(0, authorList)
                  .map(author => (
                    <ContentTag
                      href={`/autor/detalhe/${author.uniqId}`}
                      title={author.name}
                      large
                    >
                      {author.name}
                    </ContentTag>
                  ))}

                {currentArticle?.authorsRel?.length >= 5 && (
                  <div className="see-more-author-container">
                    <Button link size="small" onClick={seeMore}>
                      {authorList === 5 ? 'Ver mais' : 'Ver menos'} (
                      {currentArticle?.authorsRel?.length})
                    </Button>
                  </div>
                )}
              </S.TagWrapper>
              <h3>
                Caciq<span>-t</span>{' '}
                {currentArticle?.indexes?.['IC-T'] ? (
                  <strong>{Number(currentArticle?.indexes?.['IC-T'])}</strong>
                ) : (
                  'Não cadastrado'
                )}
              </h3>
            </S.TitleWrapper>

            <S.DetailsWrapper>
              <S.DetailsCardContent>
                {postTags.length > 0 && (
                  <DetailsCard type="citedKeywords" big tags={postTags} />
                )}

                <DetailsCard
                  type="citedArticles"
                  big
                  href="https://dx.doi.org/"
                  tags={currentArticle?.references?.map(e => [
                    e?.title,
                    e?.DOI,
                  ])}
                />
              </S.DetailsCardContent>

              <S.MoreDetailsContent>
                {currentArticle?.attached_file && (
                  <Button>Download do texto</Button>
                )}

                {currentArticle?.publication_site && (
                  <Button ghost>Visitar site da publicação</Button>
                )}

                {/* <span>
                  Et has minim elitr intellegat. Mea aeterno eleifend antiopam
                  ad.
                </span> */}
                {currentArticle?.total_refs_citation?.length && (
                  <NumberOfCitations
                    numberInfo={
                      currentArticle?.total_refs_citation?.length || 0
                    }
                    textInfo="Citações em outros textos"
                  />
                )}

                <VerifyAuthor
                  isAuthor={hasAuthors?.length > 0}
                  authors={hasAuthors}
                />
              </S.MoreDetailsContent>
            </S.DetailsWrapper>
          </S.Container>
        )}
      </S.Container>

      <S.RelatedWrapper>
        {article.length > 1 && <h3>Veja mais textos relacionados</h3>}

        {isLoading ? (
          <LoadingAnimation />
        ) : error ? (
          'Erro ao carregar. Tente novamente'
        ) : (
          <S.ContainerCards>
            {article.slice(1, 4).map((item: any) => (
              <RelatedContent
                type="article"
                item="Título"
                properties="Autores"
                href={`/textos/detalhe/${item?._source?.uniqId}`}
                modalHref="/autor/detalhe"
                tags={item._source?.authorsRel?.map(author => ({
                  title: author.name,
                  uniqId: author.uniqId,
                  href: `/autor/detalhe/${author.uniqId}`,
                }))}
                text={item._source.title || item._source.DOI}
                caciqIndex={
                  <CaciqIndex
                    type="article"
                    name="iC-T"
                    value={item?._source?.indexes?.['IC-T']}
                  />
                }
              />
            ))}
          </S.ContainerCards>
        )}
      </S.RelatedWrapper>
    </>
  );
};

export default ArticleDetails;
