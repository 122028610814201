/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import ContentTag from '../../../../components/ContentTag';
import ModalContent from '../../../../components/ModalContent';

interface CollectionProps {
  collection?: any;
}

const Collection = ({ collection = ['-'] }: CollectionProps) => {
  const [isAuthorsModalOpen, setIsAuthorsModalOpen] = useState(false);
  function handleOpenAuthorsModal() {
    setIsAuthorsModalOpen(true);
  }

  function handleCloseAuthorsModal() {
    setIsAuthorsModalOpen(false);
  }

  return (
    <>
      <ModalContent
        modalType="tag"
        isOpen={isAuthorsModalOpen}
        onRequestClose={handleCloseAuthorsModal}
        tags={collection}
        title="Palavras Chave"
      />

      {collection?.slice(0, 3)?.map(e => (
        <>
          <ContentTag
            href={`/${e?.name ? 'artigos' : 'ppg'}/detalhe/${e?.uniqId}`}
          >
            {e || e?.name || e?.title || '-'}
          </ContentTag>
        </>
      ))}
      {collection.length > 3 && (
        <button
          type="button"
          className="see-more-keyWord"
          onClick={handleOpenAuthorsModal}
        >
          Ver mais
        </button>
      )}
    </>
  );
};

export default Collection;
