/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ArticlesResultsScreen from '../screens/ArticlesResultsScreen';
import ArticleDetails from '../screens/ArticleDetails';
import JournalDetails from '../screens/JournalDetails';
import AuthorsResultsScreen from '../screens/AuthorsResultsScreen';
import AuthorDetails from '../screens/AuthorDetails';
import IndexesResultsScreen from '../screens/IndexesResultsScreen';
import PPGsResultsScreen from '../screens/PPGsResultsScreen';
import PPGDetails from '../screens/PPGDetails';
import JournalsResultsScreen from '../screens/JournalsResultsScreen';
import RegisterPage from '../screens/RegisterPage';
import RegisterPeriodicosPPG from '../screens/RegisterPeriodicos&PPG';
import MonthlyRegistration from '../screens/MonthlyRegister&Year';
import ChoosePlans from '../screens/ChoosePlans';
import ChangePass from '../screens/ChangePass';
import PerfilFree from '../screens/FreeProfile';
import LoginPage from '../screens/LoginPage';
import FreeRegister from '../screens/FreeRegister';
import ResearcherProfile from '../screens/ResearcherProfile';
import AuthorProfile from '../screens/AuthorProfile';
import PeriodicProfile from '../screens/PeriodicProfile';
import PPGProfile from '../screens/PPGProfile';
import ForgotPassword from '../screens/ForgotPassword';
import RecoverPassword from '../screens/RecoverPassword';
import ConfirmEmail from '../screens/ConfirmEmail';
import OrcidLogin from '../screens/OrcidLogin';
import OrcidSync from '../screens/OrcidSync';
import AccountConfiguration from '../screens/AccountConfiguration';
import { useAuth } from '../hooks/auth';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

const RoutesWrapper: React.FC = ({ children }) => (
  <>
    <NavBar />
    <section className="app-main-container">
      {children}
      <Footer />
    </section>
  </>
);

const UserProfile = (): JSX.Element => {
  const { user } = useAuth();

  const basicRole = user?.plan?.basicRole;
  const accountType = user?.accountType;
  const subscriptions = user?.subscriptions;

  if (accountType === 'researcher' && !subscriptions) return <PerfilFree />;
  if (accountType === 'researcher') return <ResearcherProfile />;
  if (accountType === 'author') return <AuthorProfile />;
  if (accountType === 'journal') return <PeriodicProfile />;
  if (accountType === 'ppg') return <PPGProfile />;
  if (basicRole === 'CaciqAdmin') return <PPGProfile />;

  return LoginPage();
};

const UserFreeRoute = ({ emailConfirmation = false }) => (
  <Switch>
    <Route path="/textos" exact component={ArticlesResultsScreen} />
    <Route path="/texto/detalhe/:details" exact component={ArticleDetails} />
    <Route path="/indices" component={IndexesResultsScreen} />
    <Route path="/usuario/confirmar-email" exact component={ConfirmEmail} />
    <Route path="/perfil/orcid" component={OrcidSync} />
    <Route path="/perfil/orcid/:code" exact component={OrcidSync} />
    <Route path="/perfil/:usuario" exact component={UserProfile} />
    <Route path="/mudarsenha/:usuario" component={ChangePass} />

    <Route
      path="/perfil/conta/:usuario"
      exact
      component={AccountConfiguration}
    />

    <Route
      path="/usuario/registro-premium-2"
      component={RegisterPeriodicosPPG}
    />
    <Route
      path="/usuario/registro-premium/:type"
      exact
      component={RegisterPage}
    />
    <Route path="/usuario/pagamento" component={MonthlyRegistration} />
    <Route path="/usuario/planos" component={ChoosePlans} />
    {!emailConfirmation ? (
      <Redirect to="/usuario/confirmar-email" />
    ) : (
      <Redirect to="/textos" />
    )}
  </Switch>
);

const PayingUserRoute = ({ emailConfirmation = false }) => (
  <Switch>
    <Route path="/usuario/confirmar-email" exact component={ConfirmEmail} />
    <Route path="/perfil/orcid" component={OrcidSync} />
    <Route path="/perfil/orcid/:code" exact component={OrcidSync} />
    <Route path="/perfil/:usuario" exact component={UserProfile} />
    <Route path="/mudarsenha/:usuario" component={ChangePass} />
    <Route
      path="/perfil/conta/:usuario"
      exact
      component={AccountConfiguration}
    />
    <Route
      path="/usuario/registro-premium-2/"
      component={RegisterPeriodicosPPG}
    />
    <Route
      path="/usuario/registro-premium/:type"
      exact
      component={RegisterPage}
    />
    <Route path="/usuario/pagamento" exact component={MonthlyRegistration} />
    <Route path="/usuario/planos" exact component={ChoosePlans} />
    <Route path="/textos" exact component={ArticlesResultsScreen} />
    <Route path="/texto/detalhe/:details" exact component={ArticleDetails} />
    <Route path="/ppg" exact component={PPGsResultsScreen} />
    <Route path="/ppg/detalhe/:details" exact component={PPGDetails} />
    <Route path="/autores" exact component={AuthorsResultsScreen} />
    <Route path="/autor/detalhe/:details" exact component={AuthorDetails} />
    <Route path="/revistas" exact component={JournalsResultsScreen} />
    <Route path="/revista/detalhe/:details" exact component={JournalDetails} />
    <Route path="/indices" component={IndexesResultsScreen} />

    {!emailConfirmation ? (
      <Redirect to="/usuario/confirmar-email" />
    ) : (
      <Redirect to="/textos" />
    )}
  </Switch>
);

const CofirmEmailRoute = () => (
  <Switch>
    <Route path="/usuario/confirmar-email" exact component={ConfirmEmail} />
    <Redirect to="/usuario/confirmar-email" />
  </Switch>
);

const AuthRoute = () => (
  <Switch>
    <Route path="/textos" exact component={ArticlesResultsScreen} />
    <Route path="/usuario/planos" exact component={ChoosePlans} />
    <Route path="/usuario/registrar" exact component={FreeRegister} />
    <Route
      path="/usuario/registro-premium-2"
      exact
      component={RegisterPeriodicosPPG}
    />
    <Route
      path="/usuario/esqueci-minha-senha"
      exact
      component={ForgotPassword}
    />
    <Route path="/usuario/recuperar-senha" exact component={RecoverPassword} />
    <Route path="/usuario/registro-premium" exact component={RegisterPage} />
    <Route path="/usuario/pagamento" exact component={MonthlyRegistration} />
    <Route path="/indices" component={IndexesResultsScreen} />
    <Route path="/login/orcid" exact component={OrcidLogin} />
    <Route path="/login/orcid/:code" exact component={OrcidLogin} />
    <Route path="/login" exact component={LoginPage} />
    <Redirect to="/textos" />
  </Switch>
);

export default function Routes() {
  const { user, signed } = useAuth();

  const isEmailVerified = user?.isEmailVerified;
  const isPremium = user?.subscriptions?.enabled;

  if (signed) {
    if (!isEmailVerified) {
      return (
        <RoutesWrapper>
          <CofirmEmailRoute />
        </RoutesWrapper>
      );
    }
    if (isPremium) {
      return (
        <RoutesWrapper>
          <PayingUserRoute emailConfirmation={isEmailVerified} />
        </RoutesWrapper>
      );
    }

    return (
      <RoutesWrapper>
        <UserFreeRoute emailConfirmation={isEmailVerified} />
      </RoutesWrapper>
    );
  }

  return (
    <RoutesWrapper>
      <AuthRoute />
    </RoutesWrapper>
  );
}
