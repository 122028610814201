/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { HiOutlineEmojiSad } from 'react-icons/hi';
import { Container } from './styles';

export const ErrorAnimation = () => {
  function reload() {
    window.location.reload();
  }
  return (
    <Container>
      <HiOutlineEmojiSad /> <h3>Erro ao Carregar</h3>
      <button type="button" onClick={reload}>
        Tentar novamente
      </button>
    </Container>
  );
};

export default ErrorAnimation;
