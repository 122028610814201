/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Select from 'react-select';
import yearFilter from './yearFilter';
import * as S from './styles';

interface SelectProps {
  label?: string;
  option?: any;
  date?: boolean;
  year?: boolean;
  customFunction?: any;
  defaultValue?: any;
}

const selectOptions = [
  {
    value: 'default1',
    label: 'default',
  },

  {
    value: 'default2',
    label: 'default',
  },

  {
    value: 'default3',
    label: 'default',
  },
];

const dateSelector = [
  {
    value: [],
    label: 'Todos',
  },
  {
    value: [2017, 2018, 2019, 2020],
    label: '2017 - 2020',
  },
];

const yearSelector = yearFilter.map(year => {
  return {
    value: year === 'Todos' ? [] : [year],
    label: year.toString(),
  };
});

const SelectInput = ({
  label = '',
  option = selectOptions,
  date = false,
  year = false,
  customFunction = false,
  defaultValue,
}: SelectProps) => {
  const handleButton = e => {
    if (customFunction) {
      customFunction(e.value);
    } else return null;
  };

  return (
    <S.StyledForm dateProp={date || year}>
      <label htmlFor="searchbar">{label}</label>
      <Select
        options={date ? dateSelector : year ? yearSelector : option}
        onChange={e => handleButton(e)}
        defaultValue={
          date ? dateSelector[0] : year ? yearSelector[0] : defaultValue
        }
      />
    </S.StyledForm>
  );
};

export default SelectInput;
