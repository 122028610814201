import React, { useState, useMemo } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as ArcticleIcon } from '../../assets/arcticleIcon.svg';
import { ReactComponent as AuthorIcon } from '../../assets/author-icon.svg';
import { ReactComponent as InedxIcon } from '../../assets/indexIcon.svg';
import { ReactComponent as JournalIcon } from '../../assets/journalIcon.svg';
import { ReactComponent as PpgIcon } from '../../assets/ppgIcon.svg';
import { ButtonSection, Nav, ContainerRow, Container } from './styles';
import { useAuth } from '../../hooks/auth';
import PopUp from '../PopUp';
import Line from '../../assets/line-btn.png';

const Section = () => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const { signed, user } = useAuth();
  const history = useHistory();

  const sectionItems = [
    {
      id: 0,
      title: 'Textos',
      href: '/textos',
      src: <ArcticleIcon />,
    },
    {
      id: 1,
      title: 'Autores',
      href: '/autores',
      src: <AuthorIcon />,
      disabled: !signed || !user?.subscriptions?.enabled,
    },
    {
      id: 2,
      title: 'Revistas',
      href: '/revistas',
      src: <JournalIcon />,
      disabled: !signed || !user?.subscriptions?.enabled,
    },
    {
      id: 3,
      title: 'Programas de Pós-graduação',
      href: '/ppg',
      src: <PpgIcon />,
      disabled: !signed || !user?.subscriptions?.enabled,
    },
    {
      id: 4,
      title: 'Índices',
      href: '/indices',
      src: <InedxIcon />,
    },
  ];

  const registerUser = () => {
    history.push('/usuario/planos');
  };

  return (
    <Container>
      {openPopUp ? (
        <PopUp
          title={
            signed
              ? 'Torne-se Premium e ganhe 14 dias grátis para ver esse conteúdo.'
              : 'Cadastre-se e ganhe 14 dias grátis para ver esse conteúdo.'
          }
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          alert
          isOpen={openPopUp}
          setIsOpen={setOpenPopUp}
          buttonDefaultText={signed ? 'Torne-se Premium' : 'Cadastre-se'}
          buttonSecondaryText="Voltar a navegação"
          principalButtonFunction={registerUser}
        />
      ) : null}

      <h2>Plataforma Digital de Logística Científica</h2>
      <h5>Faça sua pesquisa no campo abaixo utilizando termos de interesse</h5>

      <Nav>
        <ul>
          {sectionItems.map(itens => (
            <li key={itens.id}>
              <ButtonSection disbledProp={itens.disabled}>
                {itens.disabled ? (
                  <button
                    type="button"
                    onClick={() => setOpenPopUp(!openPopUp)}
                  >
                    <ContainerRow>
                      {itens.src}
                      <span>{itens.title}</span>
                    </ContainerRow>
                  </button>
                ) : (
                  <NavLink to={itens.href} activeClassName="active-link">
                    <ContainerRow>
                      {itens.src}
                      <span>{itens.title}</span>
                    </ContainerRow>
                    <img src={Line} alt="" className="divLine" />
                  </NavLink>
                )}
              </ButtonSection>
            </li>
          ))}
        </ul>
      </Nav>
    </Container>
  );
};

export default Section;
