import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    color: ${({ theme }) => theme.colors.grey2};
    margin-bottom: 14px;
    letter-spacing: 2px;
    font-size: 2.5rem;
  }

  h5 {
    width: 100%;
    max-width: 656px;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.grey2};
    font-weight: normal;
    letter-spacing: 1px;
    text-align: center;
  }
`;

export const Nav = styled.nav`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${mq.custom(
    css`
      padding: 0;
      width: 100vw;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ul {
    display: flex;
    flex-direction: row;

    ${mq.custom(
      css`
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
      `,
      { minWidth: 0, maxWidth: 1090 },
    )}

    li {
      margin: 0 10px;

      ${mq.custom(
        css`
          margin: 0;
        `,
        { minWidth: 0, maxWidth: 1023 },
      )}
    }
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface ButtonProps {
  disbledProp: boolean;
}

export const ButtonSection = styled.div<ButtonProps>`
  a {
    height: 72px;
    display: block;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 28px;
    text-decoration: none;
    color: #636e6f;
    transition: background 0.5s, color 0.5s, height 0.5s;

    ${mq.custom(
      css`
        height: 50px;
        padding: 10px 18px;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}

    &:hover {
      background: #6d216c;
      color: #fff;
      height: 78px;

      .divLine {
        display: block;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .divLine {
      display: none;
      margin-top: 6px;
      text-align: center;
    }

    svg {
      margin-right: 12px;

      ${mq.custom(
        css`
          width: 16px;
          height: 16px;
        `,
        { minWidth: 0, maxWidth: 1023 },
      )}
    }
  }

  .active-link {
    background: #6d216c;
    color: #ffffff;
    height: 78px;

    ${mq.custom(
      css`
        height: 56px;
        padding: 10px 18px;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}

    .divLine {
      display: block;
    }

    svg {
      path {
        fill: white;
      }
    }
  }

  button {
    height: 72px;
    display: block;
    background: #fff;
    border: 0;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 28px;
    text-decoration: none;
    color: #dadee5;
    transition: background 0.5s, color 0.5s, height 0.5s;

    ${mq.custom(
      css`
        height: 50px;
        padding: 10px 18px;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}

    &:hover {
      background: #fff;
      color: #dadee5;
    }

    svg {
      margin-right: 12px;

      ${mq.custom(
        css`
          width: 16px;
          height: 16px;
        `,
        { minWidth: 0, maxWidth: 1023 },
      )}

      path {
        fill: #dadee5;
      }
    }
  }
`;
