/* eslint-disable camelcase */
import React, { ReactNode, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import closeImg from '../../../../assets/close.svg';
import PremiumIcon from '../../../../assets/premium.svg';
import Button from '../../../../components/Button';
import ContentTag from '../../../../components/ContentTag';
import ModalContent from '../../../../components/ModalContent';
import { IArticle } from '../../../../models/article';
import { usePermission } from '../../../../hooks/permission';

import * as S from './styles';

export interface ArticleResultCardProps extends IArticle {
  citedArticles: string[] | any;
  downloadableArticle?: boolean;
  premium?: boolean;
  registered?: boolean;
  attached_file?: string;
  setSearchTerm?: any;
  publicationSite?: string;
  rank?: number;
  isRanking?: boolean;
}

const ArticleResultCard: React.FC<ArticleResultCardProps> = props => {
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
  const { Permissions } = usePermission();

  const history = useHistory();

  function handleOpenModal() {
    setIsReadMoreOpen(true);
  }

  function handleCloseModal() {
    setIsReadMoreOpen(false);
  }

  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false);

  function handleOpenKeywordsModal() {
    setIsKeywordsModalOpen(true);
  }

  function handleCloseKeywordsModal() {
    setIsKeywordsModalOpen(false);
  }

  const [isArticlesModalOpen, setIsArticlesModalOpen] = useState(false);

  function handleOpenArticlesModal() {
    setIsArticlesModalOpen(true);
  }

  function handleCloseArticlesModal() {
    setIsArticlesModalOpen(false);
  }

  const [isAuthorsModalOpen, setIsAuthorsModalOpen] = useState(false);

  function handleOpenAuthorsModal() {
    setIsAuthorsModalOpen(true);
  }

  function handleCloseAuthorsModal() {
    setIsAuthorsModalOpen(false);
  }

  const permission = Permissions('Result_Caciq_Index');
  const internalResultPermission = Permissions('Result_Internal_Result');

  const registerUser = () => {
    history.push('/usuario/planos');
  };

  return (
    <>
      <S.Container>
        <S.Content>
          {props.isRanking && (
            <S.RankWrapper>
              <strong>{props.rank}°</strong>
            </S.RankWrapper>
          )}
          <S.TitleWrapper>
            <p>{props.title}</p>
          </S.TitleWrapper>

          <S.TagWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {props.authors.map(
                (author, index) =>
                  index < 3 && (
                    <ContentTag
                      title={author.name}
                      href={`/autor/detalhe/${author.uniqId}`}
                    >
                      {author.name}
                    </ContentTag>
                  ),
              )}
              <ModalContent
                modalType="tag"
                isOpen={isAuthorsModalOpen}
                onRequestClose={handleCloseAuthorsModal}
                tags={props.authors.map(tag => ({
                  title: tag.name,
                  href: `/autor/detalhe/${tag.uniqId}`,
                }))}
                title="Autores"
              />
            </div>
            {props?.authors?.length > 3 ? (
              <Button link onClick={handleOpenAuthorsModal}>
                Ver mais
              </Button>
            ) : null}
          </S.TagWrapper>

          {props.premium ? (
            <>
              {
                // Palavra-chave desativado a pedido do Dr. Emerson
                // definido na reunião presencial dia 17/10/2022
                //
                /* <S.TagWrapper>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  {props?.keywords?.length > 0 ? (
                    props?.keywords?.map(
                      (keyWord, index) =>
                        index < 3 && (
                          <ContentTag
                            title={keyWord}
                            onClick={props.setSearchTerm}
                          >
                            {keyWord}
                          </ContentTag>
                        ),
                    )
                  ) : (
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  )}
                  {
                    // Palavra-chave desativado a pedido do Dr. Emerson
                    // definido na reunião presencial dia 17/10/2022
                    //
                    // <ModalContent
                    //   modalType="tag"
                    //   isOpen={isKeywordsModalOpen}
                    //   handleCustomSearch={props.setSearchTerm}
                    //   onRequestClose={handleCloseKeywordsModal}
                    //   tags={props.keywords || ['-']}
                    //   title="Palavras-chave"
                    // />
                  }
                </div>

                {props?.keywords?.length > 3 ? (
                  <Button link onClick={handleOpenKeywordsModal}>
                    Ver mais
                  </Button>
                ) : null}
              </S.TagWrapper> */
              }

              <S.TagWrapper>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  {props?.citedArticles ? (
                    <p>{Number(props?.citedArticles).toLocaleString()}</p>
                  ) : (
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  )}
                </div>
              </S.TagWrapper>
            </>
          ) : (
            <S.PremiumWrapper>
              <Button
                size="small"
                color="blueMidtone"
                shadow
                onClick={registerUser}
              >
                <img src={PremiumIcon} alt="" />
                <p>Seja Premium e tenha acesso completo</p>
              </Button>
            </S.PremiumWrapper>
          )}

          <S.IndexWrapper>
            {props?.indexes !== undefined && permission ? (
              <p>
                Caciq<span>-t </span>
                <strong>
                  {Number(props?.indexes) >= 1
                    ? Number(props?.indexes)
                    : Number(props?.indexes).toFixed(3)}
                </strong>
              </p>
            ) : (
              <div
                className="not-found"
                title="Não encontrado nas maiores plataformas do Brasil"
              />
            )}
          </S.IndexWrapper>

          <S.ButtonWrapper>
            {props.premium && props.registered ? (
              // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
              <>
                <Button
                  shadow
                  href={`/texto/detalhe/${props.uniqId}`}
                  disabled={!internalResultPermission}
                >
                  Saiba mais
                </Button>
                {props.publicationSite && (
                  <div style={{ marginTop: 8 }}>
                    <a
                      href={`https://dx.doi.org/${props.publicationSite}`}
                      className="publication-site-btn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Site da publicação
                    </a>
                  </div>
                )}

                {props.attached_file ? (
                  <div style={{ marginTop: 8 }}>
                    <Button ghost size="small" href={props.attached_file}>
                      Download do artigo
                    </Button>
                  </div>
                ) : null}
              </>
            ) : props.registered ? (
              /* Popup assinatura */
              <Button shadow onClick={handleOpenModal}>
                Saiba mais
              </Button>
            ) : (
              /* Popup registro */
              <Button shadow onClick={handleOpenModal}>
                Saiba mais
              </Button>
            )}
          </S.ButtonWrapper>
        </S.Content>
      </S.Container>
      <S.Divider />

      {props.registered === false ? (
        <ModalContent
          modalType="alert"
          alertContent={{
            icon: 'alert',
            title: 'Cadastre-se e ganhe 14 dias grátis para ver esse conteúdo.',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonDefaultText: 'Cadastre-se',
            buttonSecondaryText: 'Voltar a navegação',
            onClickDefault: registerUser,
            onClickGhost: handleCloseModal,
          }}
          isOpen={isReadMoreOpen}
          onRequestClose={handleCloseModal}
        />
      ) : (
        <ModalContent
          modalType="alert"
          alertContent={{
            icon: 'alert',
            title: 'Ops! Você precisa ser assinante para ver este conteúdo.',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonDefaultText: 'Ver planos de assinatura',
            buttonSecondaryText: 'Voltar a navegação',
            onClickDefault: registerUser,
            onClickGhost: handleCloseModal,
          }}
          isOpen={isReadMoreOpen}
          onRequestClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default ArticleResultCard;
