import styled, { css } from 'styled-components';
import { theme } from '../../styles/global';
import mq from '../../styles/mq';

export const MainContainer = styled.section`
  padding-top: 200px;

  .author-data {
    .section-title {
      max-width: 530px;
      margin: 56px auto 32px auto;

      h3 {
        margin-bottom: 12px;
      }
      h3,
      h6 {
        text-align: center;
        color: ${theme.colors.grey2};

        a {
          color: ${theme.colors.purpleDefault};
        }
      }
    }
  }
`;

export const PerfilSection = styled.section`
  width: 100%;
  max-width: 926px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;

  ${mq.custom(
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  .picture-container {
    width: 266px;
    height: 266px;
    border-radius: 50%;
    border: solid white 16px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25);

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .perfil-container {
    flex: 1;
    padding-left: 15px;
  }

  .user-information {
    h2 {
      color: ${theme.colors.grey1};
    }

    p {
      color: ${theme.colors.grey2};
    }
  }

  .edit-column {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    button {
      background: none;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.grey2};

      svg {
        margin-left: 13px;
      }
    }

    &::before {
      content: '';
      height: 1.5px;
      background: ${theme.colors.purpleDefault};
      right: 0px;
      left: -30px;
      bottom: 0px;
      position: absolute;
      z-index: 1;
    }
  }
`;

interface PerfilProps {
  inputProps: boolean;
}

export const UserDetails = styled.section<PerfilProps>`
  display: flex;
  gap: 22px;
  margin: 28px 0;

  form {
    vertical-align: top;

    .form-buttons {
      display: flex;
      gap: 20px;
      justify-content: center;

      button {
        &:nth-child(1) {
          display: ${props => (!props.inputProps ? 'none' : '')};
        }
      }
    }

    .two-row-input {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 22px;

      div {
        margin-top: 0px !important;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      li {
        flex-basis: 700px;
        flex-grow: 1;
        label {
          display: block;
          font-family: 'Mulish', sans-serif;
          font-size: 12px;
          line-height: 120%;
          letter-spacing: 1px;
          color: #17a1a1;
        }

        input {
          width: 100%;
          height: 45px;
          margin-top: 12px;
          border: 1.5px solid #dadee5;
          border-radius: 10px;
          padding: 16px 4px 16px 12px;
          color: #636e6f;
          font-family: 'Work Sans', sans-serif;
          font-weight: normal;
          font-size: 16px;
          outline: none;

          &::placeholder {
            color: #a9b3c4;
          }

          &:focus {
            border: 1.5px solid #17a1a1;
          }
        }
      }
    }
  }

  .user-informations-editable {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 22px;

    label {
      pointer-events: ${props => (!props.inputProps ? 'none' : '')};
    }

    input {
      ${props => {
        if (!props.inputProps) {
          return css`
            background: none;
            border-color: theme.colors.grey6;
            border-radius: 0px;
            border-width: 0px 0px 1px 0px;
            border-style: solid;
            pointer-events: none;
          `;
        }
        return null;
      }}
    }

    div {
      flex-basis: 700px;

      & + div {
        margin-top: 21px;
      }
    }
  }

  .switchers-column {
    width: 38px;
    display: ${props => (props.inputProps ? 'none' : '')};

    li + li {
      margin-top: 20px;
    }
    .can-appear {
      height: 70px;
      display: flex;
      align-items: center;
    }

    .cannot-appear {
      height: 70px;
    }
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
