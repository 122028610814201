/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PremiumIcon from '../../../../../assets/premium.svg';
import Button from '../../../../../components/Button';
import ModalContent from '../../../../../components/ModalContent';
import { IAuthor } from '../../../../../models/author';
import { usePermission } from '../../../../../hooks/permission';
import * as S from './styles';

export interface AuthorIndexResultsCardProps extends IAuthor {
  premium?: boolean;
  registered?: boolean;
  rank?: number;
  isRanking?: boolean;
  sortSearch?: any;
  currentSort?: string;
}

const AuthorIndexResultsCard: React.FC<AuthorIndexResultsCardProps> = props => {
  const [orderSearch, setOrderSearch] = useState('ic-a');
  const history = useHistory();
  const { Permissions } = usePermission();

  const RedirectTo = () => {
    history.push('/usuario/planos');
  };

  useEffect(() => {
    if (
      props.sortSearch.length > 0 &&
      props.sortSearch !== null &&
      props.sortSearch !== undefined
    ) {
      const actualRank = Object?.keys(props?.sortSearch[0]);
      const cropRank = actualRank
        ?.filter(e => e !== 'total_refs_citation')
        .toString()
        .split('indexes.');

      setOrderSearch(cropRank[1]);
    }
  }, [props.sortSearch]);

  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

  function handleOpenModal() {
    setIsReadMoreOpen(true);
  }

  function handleCloseModal() {
    setIsReadMoreOpen(false);
  }
  const internalResultPermission = Permissions('Result_Internal_Result');

  const ICAPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');
  const HALLPermission = Permissions('Result_Scholar_H4_Index');
  const I10Permission = Permissions('Result_Scholar_Index');
  const ICA = props?.indexes?.['IC-A'];
  const ICH4 = props?.indexes?.['IC-H4-A'];
  const HALL = props?.indexes?.hAll;
  const I10All = props?.indexes?.i10All;
  const ICARank = props?.indexes?.['IC-A-RANK'];
  const ICH4Rank = props?.indexes?.['IC-H4-A-RANK'];
  const HALLRank = props?.indexes?.['hAll-RANK'];
  const I10AllRank = props?.indexes?.['i10All-RANK'];

  return (
    <>
      <S.Container>
        <S.Content>
          {props.isRanking && (
            <S.RankWrapper>
              <strong>{props.rank}°</strong>
            </S.RankWrapper>
          )}
          <S.TitleWrapper>
            <p>{props.name}</p>
          </S.TitleWrapper>

          {props.currentSort?.includes?.('IC-A') || props.currentSort === '' ? (
            <>
              {ICA != null && ICAPermission ? (
                <S.IndexWrapper>
                  <p>
                    <strong>
                      {Number(ICA) >= 1 ? Number(ICA) : Number(ICA).toFixed(3)}
                    </strong>
                    {ICARank ? (
                      <span>
                        ranque:{' '}
                        {orderSearch.includes('iC-A') ? props.rank : ICARank}
                      </span>
                    ) : (
                      ''
                    )}
                  </p>
                </S.IndexWrapper>
              ) : (
                <S.IndexWrapper>
                  <p>
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  </p>
                </S.IndexWrapper>
              )}
            </>
          ) : null}
          {props.currentSort?.includes?.('IC-H4-A') ||
          props.currentSort === '' ? (
            <>
              {ICH4 != null && ICH4Permission ? (
                <S.IndexWrapper>
                  <p>
                    <strong>
                      {Number(ICH4) >= 1
                        ? Number(ICH4)
                        : Number(ICH4).toFixed(3)}
                    </strong>
                    {ICH4Rank ? (
                      <span>
                        {' '}
                        ranque:
                        {orderSearch.includes('IC-H4-A')
                          ? props.rank
                          : ICH4Rank}
                      </span>
                    ) : (
                      ''
                    )}
                  </p>
                </S.IndexWrapper>
              ) : (
                <S.IndexWrapper>
                  <p>
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  </p>
                </S.IndexWrapper>
              )}
            </>
          ) : null}

          {props.currentSort?.includes?.('hAll') || props.currentSort === '' ? (
            <>
              {HALL != null && HALLPermission ? (
                <S.IndexWrapper>
                  <p>
                    <strong>
                      {Number(HALL) >= 1
                        ? Number(HALL)
                        : Number(HALL).toFixed(3)}
                    </strong>
                    {HALLRank ? (
                      <span>
                        ranque:
                        {orderSearch.includes('hAll') ? props.rank : HALLRank}
                      </span>
                    ) : (
                      ''
                    )}
                  </p>
                </S.IndexWrapper>
              ) : (
                <S.IndexWrapper>
                  <p>
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  </p>
                </S.IndexWrapper>
              )}
            </>
          ) : null}

          {props.currentSort?.includes?.('i10All') ||
          props.currentSort === '' ? (
            <>
              {I10All != null && I10Permission ? (
                <S.IndexWrapper>
                  <p>
                    <strong>
                      {Number(I10All) >= 1
                        ? Number(I10All)
                        : Number(I10All).toFixed(3)}
                    </strong>
                    {I10AllRank ? (
                      <span>
                        ranque:
                        {orderSearch.includes('i10All')
                          ? props.rank
                          : I10AllRank}
                      </span>
                    ) : (
                      ''
                    )}
                  </p>
                </S.IndexWrapper>
              ) : (
                <S.IndexWrapper>
                  <p>
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  </p>
                </S.IndexWrapper>
              )}
            </>
          ) : null}

          {!props.premium ? (
            <S.PremiumWrapper>
              <Button
                size="small"
                color="blueMidtone"
                shadow
                href="/usuario/planos"
              >
                <img src={PremiumIcon} alt="" />
                <p>Seja Premium e tenha acesso completo</p>
              </Button>
            </S.PremiumWrapper>
          ) : null}

          <S.ButtonWrapper>
            {
              // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
            }
            {props.premium && props.registered ? (
              <>
                <Button
                  shadow
                  href={`/autor/detalhe/${props.name}`}
                  disabled={!internalResultPermission}
                >
                  Saiba mais
                </Button>
              </>
            ) : props.registered ? (
              /* Popup assinatura */
              <Button shadow onClick={handleOpenModal}>
                Saiba mais
              </Button>
            ) : (
              /* Popup registro */
              <Button shadow onClick={handleOpenModal}>
                Saiba mais
              </Button>
            )}
          </S.ButtonWrapper>
        </S.Content>
      </S.Container>
      <S.Divider />

      {props.registered === false ? (
        <ModalContent
          modalType="alert"
          alertContent={{
            icon: 'alert',
            title: 'Cadastre-se e ganhe 14 dias grátis para ver esse conteúdo.',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonDefaultText: 'Cadastre-se',
            buttonSecondaryText: 'Voltar a navegação',
            onClickDefault: RedirectTo,
            onClickGhost: handleCloseModal,
          }}
          isOpen={isReadMoreOpen}
          onRequestClose={handleCloseModal}
        />
      ) : (
        <ModalContent
          modalType="alert"
          alertContent={{
            icon: 'alert',
            title: 'Ops! Você precisa ser assinante para ver este conteúdo.',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonDefaultText: 'Ver planos de assinatura',
            buttonSecondaryText: 'Voltar a navegação',
            onClickDefault: RedirectTo,

            onClickGhost: handleCloseModal,
          }}
          isOpen={isReadMoreOpen}
          onRequestClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default AuthorIndexResultsCard;
