/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import Button from '../../../../../components/Button';
import useQueryUrl from '../../../../../hooks/queryUrl';
import IndexesHeaders from '../../IndexesHeaders';
import JournalIndexResultsCard from '../JournalIndexResultsCard';
import * as S from './styles';
import LoadingAnimation from '../../../../../components/LoadingAnimaton';
import FetchingAnimation from '../../../../../components/FetchingAnimation';
import ErrorAnimation from '../../../../../components/ErrorAnimation';
import { useRankingElastic } from '../../../../../hooks/rankSearch';
import getClient from '../../../../../services/client/elasticsearch';

interface JournalsIndexesTableProps {
  premium: boolean;
  registered: boolean;
  searchTerm?: string;
  sortSearch?: any;
  searchRank?: string;
  setSortSearch?: any;
  searchMethod?: any;
  setSearchMethod?: any;
  pageSize?: number;
  isRanking?: boolean;
  dateSearch?: any;
  currentSort?: string;
}

const JournalsIndexesTable: React.FC<JournalsIndexesTableProps> = props => {
  const history = useHistory();
  const query = useQueryUrl();
  const [journalList, setJournalList] = useState([]);
  const [forcePage, setForcePage] = useState<number>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(totalPages / props.pageSize);

  const defaultSearch = {
    index: 'journals',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    _source: ['name', 'issn', 'valuationArea', 'indexes', 'stratum', 'uniqId'],

    body: {
      sort: props.sortSearch,
    },
  };

  const customSearch = {
    index: 'journals',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    _source: ['name', 'issn', 'valuationArea', 'indexes', 'stratum', 'uniqId'],

    body: {
      query: {
        bool:
          props.dateSearch && props.searchTerm
            ? {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match: {
                            name: props.searchTerm,
                          },
                        },

                        {
                          match: {
                            issn: props.searchTerm,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: props.dateSearch?.map(year => ({
                        match: { 'articlesRel.publicationYear': year },
                      })),
                    },
                  },
                ],
              }
            : {
                should: [
                  {
                    bool: {
                      should: [
                        {
                          match: {
                            name: props.searchTerm,
                          },
                        },
                        {
                          match: {
                            issn: props.searchTerm,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: props.dateSearch?.map(year => ({
                        match: { 'articlesRel.publicationYear': year },
                      })),
                    },
                  },
                ],
              },
      },
      sort: props.sortSearch,
    },
  };

  const exactMatchSearch = {
    index: 'journals',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    _source: ['name', 'issn', 'valuationArea', 'indexes', 'stratum', 'uniqId'],

    body: {
      query: {
        query_string: {
          fields: ['name'],
          query: props.searchTerm,
        },
      },
    },
  };

  const {
    data: journals,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    [
      'journals',
      pageNumber,
      props.searchTerm,
      props.sortSearch,
      props.pageSize,
      props.dateSearch,
    ],
    async () => {
      const response = await getClient().search(
        (props.searchTerm !== '' || props.dateSearch.length > 0) &&
          !props.searchTerm.includes('"')
          ? customSearch
          : (props.searchTerm !== '' || props.dateSearch.length > 0) &&
            props.searchTerm.includes('"')
          ? exactMatchSearch
          : defaultSearch,
      );
      const data = await response.hits.hits;
      setJournalList(data);
      const totalData: any = await response.hits.total;
      setTotalPages(totalData.value);

      return data;
    },
    {
      initialData: [],
    },
  );

  const { data: rank } = useRankingElastic('articles', props.searchRank);

  useEffect(() => {
    const actualRank = Object.keys(props.sortSearch);
    const cropRank = actualRank
      ?.filter(e => e !== 'total_refs_citation')
      .toString()
      .split('indexes.');
    const userSearch: any =
      rank.users[0]?._source?.indexes?.[`${cropRank[1]}-RANK`];

    const pageToGo = Math.ceil(userSearch / props.pageSize);
    if (props.isRanking && props.searchRank !== '' && !isNaN(pageToGo)) {
      if (pageToGo < 99999) {
        setPageNumber(pageToGo - 1);
        setForcePage(pageToGo - 1);
        history.push(`/indices?type=periodicos&page=${pageToGo}`);
      } else {
        toast.warning('Resultado não encontrado');
      }
    } else return null;
  }, [rank]);

  useEffect(() => {
    setPageNumber(0);
  }, [props.searchTerm]);

  useMemo(() => {
    if (props.searchTerm) {
      query.set('termo', props.searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [props.searchTerm]);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    if (currentPage) {
      setPageNumber(currentPageNumber - 1);
      setForcePage(currentPageNumber - 1);
      query.set('page', currentPageNumber.toString());
      window.history.replaceState(
        {},
        '',
        `${location.pathname}?${query.toString()}`,
      );
    }

    return null;
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const displayResults = journalList.map((journal: any, index) => {
    return (
      <JournalIndexResultsCard
        rank={index + 1 + pageNumber * props.pageSize}
        issn={journal?._source?.issn}
        name={journal?._source?.name}
        indexes={journal?._source?.indexes || []}
        stratum={journal?._source?.stratum}
        premium={props?.premium}
        registered={props?.registered}
        isRanking={props.sortSearch?.[Object.keys(props.sortSearch).toString()]}
        currentSort={props.currentSort}
      />
    );
  });

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            <h3>
              Foram encontrados <span>{totalPages.toLocaleString()}</span>{' '}
              resultados
            </h3>
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <IndexesHeaders
            type="journal"
            currentSort={props.currentSort}
            isRanking={
              props.sortSearch?.[Object.keys(props.sortSearch).toString()]
            }
          />
          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            <ErrorAnimation />
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        {props.premium ? (
          <>
            <S.PaginationContainer>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                forcePage={forcePage}
                onPageChange={changePage}
                containerClassName="pagination-buttons"
                previousLinkClassName="pagination-previous-buttons"
                nextLinkClassName="pagination-next-buttons"
                disabledClassName="pagination-disabled"
                activeClassName="pagination-active"
                onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
              />
            </S.PaginationContainer>
          </>
        ) : (
          <>
            <S.Block>{}</S.Block>
            <S.ReadMore>
              <Button href="/usuario/planos">
                Cadastre-se e veja mais resultados
              </Button>
            </S.ReadMore>
          </>
        )}
      </S.Container>
    </>
  );
};

export default JournalsIndexesTable;
