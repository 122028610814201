import styled, { css, keyframes } from 'styled-components';
import { theme } from '../../styles/global';

const loading = keyframes`

0% {

    transform:scale(1);
    opacity: 0.5;
    
}


50%{
    
    transform:scale(0.3);
    opacity: 0;
}


100%{
    transform:scale(1);
  opacity: 0.5;
}

`;

export const Loading = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  span {
    width: 10px;
    height: 10px;
    opacity: 0.5;
    border-radius: 50%;
    background: ${theme.colors.purpleDefault};
    animation: 1.35s ${loading} ease infinite;
  }
`;
