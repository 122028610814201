import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/global';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;
export const MainContainer = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;
export const PopUp = styled.div`
  width: 100%;
  max-width: 648px;
  min-height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  overflow: hidden;
  animation: 0.4s ${entranceAnim} ease;

  .popUp-body {
    h5 {
      margin-top: 12px;
    }

    h5,
    h3 {
      color: ${theme.colors.grey3};
    }
  }
  .buttons-section-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;

    button {
      max-width: 102px;
      padding: 12px 18px;
      border-radius: 6px;
      ${theme.fonts.mulish.p};
      font-weight: 700;
      transition: 0.3s;

      &:hover {
        background: ${theme.colors.purpleMidtone};
        color: white;
      }
    }

    .send-information-button {
      background: ${theme.colors.purpleDefault};
      color: white;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
      border: 0;
    }

    .disabled-btn {
      background: gray;
      pointer-events: none;
    }

    .cancel-button {
      background: none;
      border: solid 1px ${theme.colors.purpleDefault};
      color: ${theme.colors.purpleDefault};
    }
  }
`;

export const PDFSelector = styled.div`
  margin: 34px 0;
  display: flex;
  gap: 17px;

  .input-field {
    flex: 1;
    padding: 13px 22px;
    border: solid 1px ${theme.colors.grey4};
    border-radius: 10px;
    label {
      color: ${theme.colors.grey3};
    }

    span {
      ${theme.fonts.mulish.p};
      word-break: break-all;
    }
  }

  .label-btn {
    min-width: 200px;
    max-height: 42px;
    cursor: pointer;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${theme.colors.white};
    ${theme.fonts.workSans.p}
    background: ${theme.colors.blueMidtone};
    border-radius: 6px;

    div {
      color: ${theme.colors.white};
    }
    svg {
      width: 20px;
      path {
        stroke: ${theme.colors.white};
      }
    }
  }
`;
