import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 40px;

  .search-types {
    display: flex;
    align-items: center;

    #quadriennium,
    #year {
      width: 6px;
      opacity: 0;
      transition: 0.1s;

      &:checked {
        ~ .radio::before {
          background: ${({ theme }) => theme.colors.purpleDefault} !important;
        }

        ~ label,
        ~ .css-qc6sy-singleValue {
          color: ${({ theme }) => theme.colors.grey2} !important  ;
        }
      }
    }

    .radio {
      width: 18px;
      height: 18px;
      border: solid 1.5px #dadee5;
      border-radius: 99px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 99px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
        transition: 0.3s;
      }
    }

    label,
    .css-qc6sy-singleValue {
      ${({ theme }) => theme.fonts.workSans.p};
      color: ${({ theme }) => theme.colors.grey4};
      cursor: pointer;
    }
  }
`;
