import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import CtaBanner from '../../components/CtaBanner';
import Button from '../../components/Button';
import { ReactComponent as EditPen } from '../../assets/pen-icon.svg';
import { ReactComponent as PerfilPhoto } from '../../assets/avatar.svg';
import ChangeProfile from './components/changePerfil';
import UploadFile from '../../components/uploadPictureInput';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface FormInputs {
  name: string;
  CPF: number;
  avatar: string;
  birthDate: number;
  email: string;
}

const ResearcherProfile = () => {
  const [cnpj, setCNPJ] = useState(null);
  const [editPerfil, setEditPerfil] = useState(false);
  const { register, handleSubmit } = useForm<FormInputs>();
  const { user, updateProfile } = useAuth();
  const [newPic, setNewPic] = useState(user.avatar);
  const { emitError } = useError();

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      const fullData = {
        name: data.name,
        CPF: data.CPF,
        birthDate: data.birthDate,
        avatar: newPic || 'none',
        email: data.email,
      };
      const post = await api.put('/profile', {
        ...data,
        avatar: newPic || user.avatar,
      });

      toast.success('Perfil Atualizado!');
      updateProfile(fullData as any);
      window.location.reload();
    } catch (error: any) {
      emitError(error, 'Falha ao atualizar perfil!');
    }
  };

  const splitDate = user?.birthDate?.toString().split('T');

  const handleCnpjFilter = e => {
    let filtered = e.target.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 20) return;
    filtered = filtered.replace(/\D/g, '');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    filtered = filtered.replace(/(-\d{2})\d+?$/, '$1');

    setCNPJ(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.MainContainer>
      <S.PerfilSection>
        <div className="picture-container">
          {editPerfil ? (
            <UploadFile newFile={newPic} setNewFile={setNewPic} />
          ) : null}

          {newPic === null || newPic === '' || newPic === 'none' ? (
            <PerfilPhoto />
          ) : (
            <img src={newPic} alt="perfil picturer" />
          )}
        </div>

        <section className="perfil-container">
          <div className="user-information">
            <h2>{user.name}</h2>

            <div className="edit-column">
              <p>Premium: Pesquisador</p>

              <button type="button" onClick={() => setEditPerfil(!editPerfil)}>
                Editar perfil <EditPen />
              </button>
            </div>
          </div>
          <S.UserDetails inputProps={editPerfil}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ul className="user-informations-editable">
                <li>
                  <label htmlFor="name">Nome completo</label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    defaultValue={user.name}
                    {...register('name')}
                  />
                </li>

                <div className="two-row-input">
                  <li>
                    <label htmlFor="cep">CPF</label>
                    <input
                      name="cep"
                      type="text"
                      id="cep"
                      value={cnpj}
                      {...register('CPF')}
                      defaultValue={user.CPF}
                      onChange={e => handleCnpjFilter(e)}
                    />
                  </li>
                  <li>
                    <label htmlFor="dtns">Nascimento</label>
                    <input
                      name="dtns"
                      id="dtns"
                      type="date"
                      {...register('birthDate')}
                      defaultValue={splitDate[0]}
                    />
                  </li>
                </div>

                <li>
                  <label htmlFor="email">E-mail</label>
                  <input
                    name="name"
                    type="text"
                    id="email"
                    {...register('email')}
                    defaultValue={user.email}
                  />
                </li>
              </ul>

              {editPerfil ? (
                <div className="button-section">
                  <Button typeOf="submit">Salvar as alterações</Button>
                </div>
              ) : null}
            </form>
          </S.UserDetails>
        </section>
      </S.PerfilSection>
      <ChangeProfile />
      <CtaBanner />
    </S.MainContainer>
  );
};

export default ResearcherProfile;
