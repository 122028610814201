/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import PlanCard from '../../components/PlanCard';
import CtaBanner from '../../components/CtaBanner';
import * as S from './styles';

interface PlansProp {
  description?: string;
}

const ChoosePlans = ({
  description = 'Assine a Caciq, incremente seu perfil, e tenha acesso ao que há de mais moderno e inteligente em termos de pesquisa científica',
}: PlansProp) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.MainContainer>
        <S.Container>
          <h2>Planos de cadastro e Assinatura</h2>
          <p>{description}</p>

          <div className="plan-section">
            <PlanCard isPlansScreen />
          </div>
        </S.Container>
      </S.MainContainer>
      <CtaBanner />
    </>
  );
};

export default ChoosePlans;
