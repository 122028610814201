import React from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from '../../assets/logo-caciq.svg';

const loadingAnim = keyframes`

0% {
  opacity:0;
}

50%{
  opacity:1;
}

100%{
  opacity:0;

}

`;

const MainContainer = styled.section`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  img {
    width: 100%;
    max-width: 150px;
    animation: 1.5s ${loadingAnim} infinite;
  }
`;

export default function LoadingScreen() {
  return (
    <MainContainer>
      <img src={Logo} alt="" />
    </MainContainer>
  );
}
