import styled from 'styled-components';
import { theme } from '../../../../styles/global';

export const Container = styled.div`
  width: 100%;
  min-height: 205px;

  .author-data {
    .section-title {
      max-width: 530px;
      margin: 56px auto 32px auto;

      h3 {
        margin-bottom: 12px;
      }
      h3,
      h6 {
        text-align: center;
        color: ${theme.colors.grey2};

        a {
          color: ${theme.colors.purpleDefault};
        }
      }
    }
  }
`;

interface ContentProps {
  fixIndex?: number;
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  max-width: 1366px;
  min-height: 205px;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;

  ul {
    display: flex;
    gap: 20px;
    padding: 20px 0 20px 0;
    border-bottom: 1.5px solid #dadee5;

    li {
      width: calc(100% / 5);
      font: 13px Mulish, sans-serif;
      font-weight: 600;
      color: ${theme.colors.purpleDefault};
      display: flex;
      align-items: center;
      &:nth-child(4) {
        max-width: 80px;
      }
    }
  }

  .content-section {
    ul {
      position: relative;
      padding: 55px 0;
      z-index: 2;

      &:nth-child(${prop => prop.fixIndex + 1}) {
        z-index: 3;
      }

      &:hover::before {
        background: #f2f2f2;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -50vw;
        right: -50vw;
        transition: background-color 0.2s;
      }
      li {
        ${theme.fonts.workSans.p}
        font-weight: 600;
        color: ${theme.colors.grey2};
        z-index: 2;

        p {
          ${theme.fonts.workSans.p}
          font-weight: 600;
          color: ${theme.colors.blueDefault};
        }
      }

      .buttons-column {
        display: flex;
        gap: 12px;

        .see-page-button {
          padding: 8px 12px;
          border: 0;
          ${theme.fonts.mulish.h6}
          border-radius: 6px;
          color: ${theme.colors.white};
          background: ${theme.colors.purpleDefault};
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
          transition: 0.3s;

          &:hover {
            background: ${theme.colors.purpleDark};
          }
        }
      }
    }
  }
`;

export const UploadPDF = styled.div`
  .add-pdf-button {
    position: relative;
    padding: 8px 12px;
    border: 1px solid ${theme.colors.purpleDefault};
    ${theme.fonts.mulish.h6}
    border-radius: 6px;
    color: ${theme.colors.purpleDefault};
    background: transparent;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.purpleDark};
      background: ${theme.colors.purpleDark};
    }
  }

  .disabled-pdf-btn {
    background: gray !important;
    cursor: not-allowed !important;
  }
  input {
    position: absolute;
    visibility: hidden;
  }
`;
