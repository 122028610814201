/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { userFields } from '../../fakeData';
import Button from '../../../../components/Button';
import getClient from '../../../../services/client/elasticsearch';
import { api } from '../../../../services/api';
import { useError } from '../../../../hooks/errors';
import * as S from './styles';

interface AuthorProps {
  ppgs?: string[];
}

const AuthorPostgraduate = ({ ppgs = [] }: AuthorProps) => {
  const [unbind, setUnbind] = useState(false);
  const { emitError } = useError();

  function triggerUndind() {
    setUnbind(true);
  }
  function cancelUndind() {
    setUnbind(false);
  }

  async function unbindPPG(id) {
    try {
      await api.patch(`/attachUser/unsubFromPPG//${id}`);
      toast.success('PPG Desvinculado');
      window.location.reload();
    } catch (error: any) {
      emitError(error, 'Algo deu errado, tente novamente mais tarde!');
    }
  }

  const { data: ppgsList, isLoading } = useQuery(
    ['ppgs', ppgs],
    async () => {
      const response = await getClient().search({
        index: 'ppgs',
        size: 100,
        body: {
          query: {
            bool: {
              should: ppgs?.map(id => ({
                match: { id },
              })),
            },
          },
        },
      });

      const data = await response.hits.hits;

      return data;
    },
    {
      initialData: [],
    },
  );
  return (
    <>
      <S.Container>
        <section className="author-data">
          <div className="section-title">
            <h3>Programa de Pós-Graduação</h3>
            {/* <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              vulputate risus, sed a enim enim integer. Tempus tellus
              condimentum pellentesque ut vivamus odio tristique. Egestas id
              suspendisse sit felis eleifend diam semper.
              <a href="https://google.com">Saiba mais.</a>
            </h6> */}
          </div>
        </section>
        <S.Content>
          <ul>
            <li>CURSO</li>
            <li>UNIVERSIDADE</li>
            <li>Caciq-p</li>
            <li>Caciq-h4p</li>
            <li />
          </ul>

          <div className="content-section">
            <ul>
              {ppgsList.map((item: any) => (
                <>
                  <li> {item?._source?.name} </li>
                  <li> {item?._source?.university} </li>
                  <li>
                    <p>
                      {item?._source?.indexes?.['IC-P']
                        ? Number(item?._source?.indexes?.['IC-P'])
                        : '-'}
                    </p>
                  </li>
                  <li>
                    <p>
                      {item?._source?.indexes?.['IC-H4-P']
                        ? Number(item?._source?.indexes?.['IC-H4-P'])
                        : '-'}
                    </p>
                  </li>
                  <li>
                    <div className="buttons-column">
                      {!unbind && <Button>Saiba Mais</Button>}
                      {!unbind ? (
                        <Button ghost onClick={triggerUndind}>
                          Desvincular
                        </Button>
                      ) : (
                        <div className="confirmation-container">
                          <span>
                            Tem certeza que deseja contínuar essa ação?
                          </span>

                          <Button
                            link
                            onClick={() => unbindPPG(item?._source?.id)}
                          >
                            Sim, desvincular
                          </Button>
                          <Button link onClick={cancelUndind}>
                            Não, não desvincular
                          </Button>
                        </div>
                      )}
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </div>
        </S.Content>
      </S.Container>
    </>
  );
};

export default AuthorPostgraduate;
