/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ReactComponent as EditPen } from '../../assets/pen-icon.svg';
import Button from '../../components/Button';
import AuthorPostgraduate from './components/AuthorPostGraduate';
import AuthorArticles from './components/AuthorArticles';
import { ReactComponent as PerfilPhoto } from '../../assets/avatar.svg';
import { useAuth } from '../../hooks/auth';
import CtaBanner from '../../components/CtaBanner';
import SwitchComponent from '../../components/Switch';
import AnaliseIndex from '../../components/AnalisePerfil';
import { api } from '../../services/api';
import UploadFile from '../../components/uploadPictureInput';
import * as S from './styles';
import getClient from '../../services/client/elasticsearch';
import { usePermission } from '../../hooks/permission';
import { useError } from '../../hooks/errors';

interface AuthorProp {
  name?: string;
}

interface FormInputs {
  name: string;
  CPF: number;
  birthDate: number;
  email: string;
  quote: string;
  orcid: string;
  lattes: string;
  personalWebsite: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  twitter: string;
}

const AuthorProfile = ({ name = 'Mourice Junior' }: AuthorProp) => {
  const [editPerfil, setEditPerfil] = useState(false);
  const { user, updateProfile } = useAuth();
  const [newPic, setNewPic] = useState(user.avatar);

  const social = user?.socials;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: userElastic }: any = useQuery(
    ['user', user],
    async () => {
      const response = await getClient().search({
        index: 'authors',
        size: 5,
        body: {
          query: {
            match: { _id: user?.authorId },
          },
        },
      });

      return response.hits.hits;
    },
    {
      initialData: [],
    },
  );

  return (
    <S.MainContainer>
      <S.PerfilSection>
        <div className="picture-container">
          {editPerfil ? (
            <UploadFile newFile={newPic} setNewFile={setNewPic} />
          ) : null}

          {newPic === null || newPic === '' || newPic === 'none' ? (
            <PerfilPhoto />
          ) : (
            <img src={newPic} alt="perfil picturer" />
          )}
        </div>

        <section className="perfil-container">
          <div className="user-information">
            <h2>{user.name}</h2>

            <div className="edit-column">
              <p> Autor</p>

              <button type="button" onClick={() => setEditPerfil(!editPerfil)}>
                Editar perfil <EditPen />
              </button>
            </div>
          </div>

          <FormSection
            name={user.name}
            publicId={userElastic[0]?._source?.uniqId}
            perfilPhoto={newPic || user.avatar}
            inputProps={editPerfil}
            socialFields={social}
            user={user}
            updateProfile={updateProfile}
          />
        </section>
      </S.PerfilSection>

      {userElastic[0]?._source?.articlesRel?.length > 0 && (
        <AuthorArticles
          articles={userElastic[0]?._source?.articlesRel?.map(
            article => article._id,
          )}
        />
      )}
      {userElastic[0]?._source?.ppgsRel?.length > 0 && (
        <AuthorPostgraduate
          ppgs={userElastic[0]?._source?.ppgsRel?.map(id => id.id)}
        />
      )}
      {!user?.isVerified && <AnaliseIndex />}
      <CtaBanner />
    </S.MainContainer>
  );
};

const FormSection = ({
  name,
  inputProps,
  perfilPhoto = '',
  socialFields = [],
  user,
  updateProfile,
  publicId,
}) => {
  const { Permissions } = usePermission();

  const { register, handleSubmit } = useForm<FormInputs>();
  const [cnpj, setCNPJ] = useState(null);
  const { emitError } = useError();
  const syncPermission = Permissions('Sync_Profile_Account');

  const authorName: any = socialFields.filter(e => e.name === user.name);
  const authorEmail: any = socialFields.filter(e => e.name === user.email);
  const nameInCitations: any = socialFields.filter(
    e => e.name === 'nameInCitations',
  );
  const orcid: any = socialFields.filter(e => e.name === 'orcid');
  const lattes: any = socialFields.filter(e => e.name === 'lattes');
  const personalWebsite: any = socialFields.filter(
    e => e.name === 'personalWebsite' || e.name === 'personalSite',
  );
  const linkedin: any = socialFields.filter(e => e.name === 'linkedin');

  const facebook: any = socialFields.filter(e => e.name === 'facebook');
  const instagram: any = socialFields.filter(e => e.name === 'instagram');
  const twitter: any = socialFields.filter(e => e.name === 'twitter');

  const switchFields = [
    {
      name: 'name',
      url: user.name,
      public: authorName[0]?.public || false,
      canAppear: true,
    },
    {
      canAppear: false,
    },
    {
      name: 'email',
      url: user.name,
      public: authorEmail[0]?.public || false,
      canAppear: true,
    },
  ];

  const sortedFields = [
    nameInCitations.length > 0
      ? { ...nameInCitations[0] }
      : {
          id: true,
          name: 'nameInCitations',
          url: '',
          public: false,
        },
    orcid.length > 0
      ? { ...orcid[0] }
      : {
          id: true,
          name: 'orcid',
          url: '',
          public: false,
        },
    lattes.length > 0
      ? { ...lattes[0] }
      : {
          id: true,
          name: 'lattes',
          url: '',
          public: false,
        },
    personalWebsite.length > 0
      ? { ...personalWebsite[0] }
      : {
          id: true,
          name: 'personalWebsite',
          url: '',
          public: false,
        },
    linkedin.length > 0
      ? { ...linkedin[0] }
      : {
          id: true,
          name: 'linkedin',
          url: '',
          public: false,
        },
    facebook.length > 0
      ? { ...facebook[0] }
      : {
          id: true,
          name: 'facebook',
          url: '',
          public: false,
        },
    instagram.length > 0
      ? { ...instagram[0] }
      : {
          id: true,
          name: 'instagram',
          url: '',
          public: false,
        },
    twitter.length > 0
      ? { ...twitter[0] }
      : {
          id: true,
          name: 'twitter',
          url: '',
          public: false,
        },
  ];

  const switchData = [...switchFields, ...sortedFields];

  const splitDate = user?.birthDate?.toString().split('T');

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      const social = [];

      if (data.quote)
        social.push({
          name: 'nameInCitations',
          url: data.quote.toString(),
          public: nameInCitations[0]?.public || false,
        });
      if (data.orcid)
        social.push({
          name: 'orcid',
          url: data.orcid.toString(),
          public: orcid[0]?.public || false,
        });
      if (data.personalWebsite)
        social.push({
          name: 'personalWebsite',
          url: data.personalWebsite.toString(),
          public: personalWebsite[0]?.public || false,
        });
      if (data.lattes)
        social.push({
          name: 'lattes',
          url: data.lattes.toString(),
          public: lattes[0]?.public || false,
        });
      if (data.linkedin)
        social.push({
          name: 'linkedin',
          url: data.linkedin.toString(),
          public: linkedin[0]?.public || false,
        });
      if (data.facebook)
        social.push({
          name: 'facebook',
          url: data.facebook.toString(),
          public: facebook[0]?.public || false,
        });
      if (data.instagram)
        social.push({
          name: 'instagram',
          url: data.instagram.toString(),
          public: instagram[0]?.public || false,
        });
      if (data.twitter)
        social.push({
          name: 'twitter',
          url: data.twitter.toString(),
          public: twitter[0]?.public || false,
        });

      const fullData = {
        CPF: data.CPF,
        birthDate: data.birthDate,
        avatar: perfilPhoto || 'none',
        email: data.email,
        name: data.name,
      };
      toast.info('Salvando informações');
      const postProfile = await api.put('/profile', fullData);

      social.forEach(async item => {
        const post = await api.patch('/profile/social', item);
      });
      toast.success('Perfil Atualizado');

      updateProfile({ ...fullData, socials: social } as any);
      window.location.reload();
    } catch (error: any) {
      emitError(error, 'Erro ao atualizar perfil');
    }
  };

  const handleCnpjFilter = e => {
    let filtered = e.target.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 20) return;
    filtered = filtered.replace(/\D/g, '');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    filtered = filtered.replace(/(-\d{2})\d+?$/, '$1');

    setCNPJ(filtered);
  };

  return (
    <S.UserDetails inputProps={inputProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="user-informations-editable">
          <li>
            <label htmlFor="name">Nome completo</label>
            <input
              name="name"
              id="name"
              type="text"
              defaultValue={name}
              {...register('name')}
            />
          </li>

          <div className="two-row-input">
            <li>
              <label htmlFor="cep">CPF</label>
              <input
                name="cep"
                type="text"
                id="cep"
                placeholder="Nome Completo"
                value={cnpj}
                {...register('CPF')}
                onChange={e => handleCnpjFilter(e)}
                defaultValue={user.CPF}
              />
            </li>
            <li>
              <label htmlFor="dtns">Nascimento</label>
              <input
                name="dtns"
                id="dtns"
                type="date"
                {...register('birthDate')}
                defaultValue={splitDate[0]}
              />
            </li>
          </div>

          <li>
            <label htmlFor="email">E-mail</label>
            <input
              name="name"
              type="text"
              id="email"
              {...register('email')}
              defaultValue={user.email}
            />
          </li>
          <li>
            <label htmlFor="quote">Nome em citação bibliográfica</label>
            <input
              name="name"
              type="text"
              id="quote"
              {...register('quote')}
              defaultValue={nameInCitations[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="orcid">Orcid</label>
            <input
              name="name"
              type="text"
              id="orcid"
              {...register('orcid')}
              defaultValue={orcid[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="lattes">Lattes</label>
            <input
              name="name"
              type="text"
              id="lattes"
              {...register('lattes')}
              defaultValue={lattes[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="personalSite">Site Pessoal</label>
            <input
              name="name"
              type="text"
              id="personalSite"
              {...register('personalWebsite')}
              defaultValue={personalWebsite[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="linkedin">Linkedin</label>
            <input
              name="name"
              type="text"
              id="linkedin"
              {...register('linkedin')}
              defaultValue={linkedin[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="facebook">Facebook</label>
            <input
              name="name"
              type="text"
              id="facebook"
              {...register('facebook')}
              defaultValue={facebook[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="instagram">Instagram</label>
            <input
              name="name"
              type="text"
              id="instagram"
              {...register('instagram')}
              defaultValue={instagram[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="twitter">Twitter</label>
            <input
              name="name"
              type="text"
              id="twitter"
              {...register('twitter')}
              defaultValue={twitter[0]?.url}
            />
          </li>
        </ul>

        <div className="form-buttons">
          <Button typeOf="submit">Salvar as alterações</Button>
          <Button href={`/autor/detalhe/${publicId}`} ghost>
            Visualizar perfil público
          </Button>
          <div id="orcid" />
          <Button
            ghost
            disabled={!syncPermission}
            onClick={() => {
              window.open(
                'https://orcid.org/oauth/authorize?client_id=APP-2ZRPI8X6LA898U01&response_type=code&scope=/authenticate&redirect_uri=https://app.caciq.com.br/perfil/orcid',
              );
            }}
          >
            Sincronizar com ORCID
          </Button>
        </div>
      </form>
      <ul className="switchers-column">
        {switchData.map(item =>
          item.name || item.canAppear ? (
            <li className="can-appear">
              <SwitchComponent
                name={item.name}
                url={item?.url}
                check={item?.public}
              />
            </li>
          ) : (
            <li className="cannot-appear" />
          ),
        )}
      </ul>
    </S.UserDetails>
  );
};

export default AuthorProfile;
