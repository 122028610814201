import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './route';

const Routes: React.FC = () => {
  return (
    <Router>
      <PrivateRoute />
    </Router>
  );
};

export default Routes;
