/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as S from './styles';
import Section from '../Section';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import InnerSection from '../InnerSection';
import Button from '../Button';
import Logo from '../../assets/logo-caciq.svg';
import { notLoggedIn, LoggedIn } from './navItens';
import { usePermission } from '../../hooks/permission';
import { useAuth } from '../../hooks/auth';

const NavBar = () => {
  const { user, signed, signOut } = useAuth();
  const { Permissions } = usePermission();
  const location = useLocation();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [conditionalPath, setConditionalPath] = useState('user:');
  const [menuItens, setMenuItens] = useState(notLoggedIn);
  const [isInProfile, setIsInProfile] = useState(false);
  const profilePermission = Permissions('Profile');
  useEffect(() => {
    // Função para verificar se inclue
    const includes = (path: string) => location.pathname.includes(path);

    if (includes('perfil')) return setConditionalPath('login');
    if (includes('login')) return setConditionalPath('login');
    if (includes('usuario') || includes('detalhe'))
      return setConditionalPath('user:');

    setIsInProfile(false);
    return setConditionalPath('');
  }, [location.pathname]);

  useEffect(() => {
    if (signed) setMenuItens(LoggedIn);
    else {
      setMenuItens(notLoggedIn);
    }
  }, [signed]);

  useEffect(() => {
    const handleScrollAnim = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 100) {
        setHeaderHeight(-300);
      } else {
        setHeaderHeight(0);
      }
    };
    window.addEventListener('scroll', handleScrollAnim);

    return () => window.removeEventListener('scroll', handleScrollAnim);
  }, [headerHeight]);

  const logOut = () => {
    signOut();
  };

  return (
    <>
      <S.Container propHeight={headerHeight}>
        <S.NavBar propHeight={headerHeight}>
          <div>
            <Link to="/">
              <img className="logo" src={Logo} alt="" />
            </Link>
          </div>
          <div>
            <ul>
              {menuItens.map(itens => (
                <li key={itens.id}>
                  <Link to={itens.href}> {itens.title} </Link>
                </li>
              ))}
              {!signed ? (
                <li>
                  <Button href="/usuario/planos">Cadastrar</Button>
                </li>
              ) : signed && profilePermission ? (
                <Button
                  href={`/perfil/usuario:${user?.name}`}
                  disabled={isInProfile}
                >
                  Minha Conta
                  <ArrowRight style={{ fill: 'white', marginLeft: '4px' }} />
                </Button>
              ) : (
                <Button onClick={logOut}>
                  {' '}
                  Sair{' '}
                  <ArrowRight
                    style={{ fill: 'white', marginLeft: '4px' }}
                  />{' '}
                </Button>
              )}
            </ul>
          </div>
        </S.NavBar>
        {conditionalPath && <InnerSection />}
      </S.Container>
    </>
  );
};

export default NavBar;
