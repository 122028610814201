import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import closeImg from '../../../../../assets/close.svg';

import Button from '../../../../../components/Button';
import ModalContent from '../../../../../components/ModalContent';
import { IPPG } from '../../../../../models/ppg';
import { usePermission } from '../../../../../hooks/permission';
import * as S from './styles';

export interface PPGIndexResultsCardProps extends IPPG {
  premium?: boolean;
  registered?: boolean;
  rank?: number;
  isRanking?: boolean;
  currentSort?: string;
}

const PPGIndexResultsCard: React.FC<PPGIndexResultsCardProps> = props => {
  const history = useHistory();
  const { Permissions } = usePermission();

  const RedirectTo = () => {
    history.push('/usuario/planos');
  };

  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

  function handleOpenModal() {
    setIsReadMoreOpen(true);
  }

  function handleCloseModal() {
    setIsReadMoreOpen(false);
  }
  const internalResultPermission = Permissions('Result_Internal_Result');

  const ICPPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');

  const ICP = props?.indexes?.['IC-P'];
  const H4P = props?.indexes?.['IC-H4-P'];
  const ICPRank = props?.indexes?.['IC-P-RANK'];
  const H4PRank = props?.indexes?.['IC-H4-P-RANK'];

  return (
    <>
      <S.Container>
        <S.Content>
          {props.isRanking && (
            <S.RankWrapper>
              <strong>{props.rank}°</strong>
            </S.RankWrapper>
          )}
          <S.TitleWrapper>
            <p>{props.name}</p>
          </S.TitleWrapper>

          <S.TitleWrapper>
            <p>{props.university}</p>
          </S.TitleWrapper>

          {props.currentSort?.includes?.('IC-P') || props.currentSort === '' ? (
            <>
              {ICP && ICPPermission ? (
                <S.IndexWrapper>
                  <p>
                    <strong>
                      {Number(ICP) >= 1 ? Number(ICP) : Number(ICP).toFixed(3)}
                    </strong>
                    {ICPRank ? (
                      <span>
                        ranque:{' '}
                        {props.currentSort.includes('iC-P')
                          ? props.rank
                          : ICPRank}
                      </span>
                    ) : (
                      ''
                    )}
                  </p>
                </S.IndexWrapper>
              ) : (
                <S.IndexWrapper>
                  <p>
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  </p>
                </S.IndexWrapper>
              )}
            </>
          ) : null}

          {props.currentSort?.includes?.('IC-H4-P') ||
          props.currentSort === '' ? (
            <>
              {H4P && ICH4Permission ? (
                <S.IndexWrapper>
                  <p>
                    <strong>
                      {Number(H4P) >= 1 ? Number(H4P) : Number(H4P).toFixed(3)}
                    </strong>
                    {H4PRank ? (
                      <span>
                        ranque:{' '}
                        {props.currentSort.includes('iC-H4-P')
                          ? props.rank
                          : H4PRank}
                      </span>
                    ) : (
                      ''
                    )}
                  </p>
                </S.IndexWrapper>
              ) : (
                <S.IndexWrapper>
                  <p>
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  </p>
                </S.IndexWrapper>
              )}
            </>
          ) : null}

          <S.ButtonWrapper>
            {
              // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
            }
            {props.premium && props.registered ? (
              <>
                <Button
                  shadow
                  href={`/ppg/detalhe/${props.name}`}
                  disabled={!internalResultPermission}
                >
                  {' '}
                  Saiba mais
                </Button>
              </>
            ) : props.registered ? (
              /* Popup assinatura */
              <Button shadow onClick={handleOpenModal}>
                Saiba mais
              </Button>
            ) : (
              /* Popup registro */
              <Button shadow onClick={handleOpenModal}>
                Saiba mais
              </Button>
            )}
          </S.ButtonWrapper>
        </S.Content>
      </S.Container>
      <S.Divider />

      {props.registered === false ? (
        <ModalContent
          modalType="alert"
          alertContent={{
            icon: 'alert',
            title: 'Cadastre-se e ganhe 14 dias grátis para ver esse conteúdo.',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonDefaultText: 'Cadastre-se',
            buttonSecondaryText: 'Voltar a navegação',
            onClickDefault: RedirectTo,

            onClickGhost: handleCloseModal,
          }}
          isOpen={isReadMoreOpen}
          onRequestClose={handleCloseModal}
        />
      ) : (
        <ModalContent
          modalType="alert"
          alertContent={{
            icon: 'alert',
            title: 'Ops! Você precisa ser assinante para ver este conteúdo.',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            buttonDefaultText: 'Ver planos de assinatura',
            buttonSecondaryText: 'Voltar a navegação',
            onClickDefault: RedirectTo,

            onClickGhost: handleCloseModal,
          }}
          isOpen={isReadMoreOpen}
          onRequestClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default PPGIndexResultsCard;
