import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/Button';
import ModalContent from '../../../../../components/ModalContent';
import { usePermission } from '../../../../../hooks/permission';
import * as S from './styles';

export interface ArticleIndexResultsCardProps {
  title?: string;
  index?: any;
  uniqId?: string;
  premium?: boolean;
  registered?: boolean;
  rank?: number;
  isRanking?: boolean;
}

const ArticleIndexResultsCard: React.FC<ArticleIndexResultsCardProps> =
  props => {
    const { Permissions } = usePermission();

    const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
    const history = useHistory();

    const registerUser = () => {
      history.push('/usuario/planos');
    };
    function handleOpenModal() {
      setIsReadMoreOpen(true);
    }

    function handleCloseModal() {
      setIsReadMoreOpen(false);
    }

    const internalResultPermission = Permissions('Result_Internal_Result');

    const permission = Permissions('Result_Caciq_Index');

    return (
      <>
        <S.Container>
          <S.Content>
            {props.isRanking && (
              <S.RankWrapper>
                <strong>{props.rank}°</strong>
              </S.RankWrapper>
            )}

            <S.TitleWrapper>
              <p>{props.title}</p>
            </S.TitleWrapper>

            <S.IndexWrapper>
              {permission ? (
                <p>
                  <strong>
                    {props?.index?.['IC-T'] &&
                      (Number(props?.index?.['IC-T']) >= 1
                        ? Number(props?.index?.['IC-T'])
                        : Number(props?.index?.['IC-T']).toFixed(3))}
                  </strong>

                  {props?.index?.['IC-T-RANK'] ? (
                    <span> ranking: {props?.index?.['IC-T-RANK']}</span>
                  ) : (
                    ''
                  )}
                </p>
              ) : (
                <div
                  className="not-found"
                  title="Não encontrado nas maiores plataformas do Brasil"
                />
              )}
            </S.IndexWrapper>

            <S.ButtonWrapper>
              {
                // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
              }
              {props.premium && props.registered ? (
                <>
                  <Button
                    shadow
                    href={`/texto/detalhe/${props.uniqId}`}
                    disabled={!internalResultPermission}
                  >
                    Saiba mais
                  </Button>
                </>
              ) : props.registered ? (
                /* Popup assinatura */
                <Button shadow onClick={handleOpenModal}>
                  Saiba mais
                </Button>
              ) : (
                /* Popup registro */
                <Button shadow onClick={handleOpenModal}>
                  Saiba mais
                </Button>
              )}
            </S.ButtonWrapper>
          </S.Content>
        </S.Container>
        <S.Divider />

        {props.registered === false ? (
          <ModalContent
            modalType="alert"
            alertContent={{
              icon: 'alert',
              title:
                'Cadastre-se e ganhe 14 dias grátis para ver esse conteúdo.',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              buttonDefaultText: 'Cadastre-se',
              buttonSecondaryText: 'Voltar a navegação',
              onClickDefault: registerUser,

              onClickGhost: handleCloseModal,
            }}
            isOpen={isReadMoreOpen}
            onRequestClose={handleCloseModal}
          />
        ) : (
          <ModalContent
            modalType="alert"
            alertContent={{
              icon: 'alert',
              title: 'Ops! Você precisa ser assinante para ver este conteúdo.',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              buttonDefaultText: 'Ver planos de assinatura',
              buttonSecondaryText: 'Voltar a navegação',
              onClickDefault: registerUser,

              onClickGhost: handleCloseModal,
            }}
            isOpen={isReadMoreOpen}
            onRequestClose={handleCloseModal}
          />
        )}
      </>
    );
  };

export default ArticleIndexResultsCard;
