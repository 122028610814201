import React, { useState, useRef } from 'react';
import { Button } from './styles';

interface SelectorProp {
  items?: string[] | any;
  setNewValue: any;
  formName: any;
}

const Selector: React.FC<SelectorProp> = ({
  items = [],
  setNewValue = [],
  formName = [],
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const buttonRef = useRef(null);

  const [selectedValue, setSelectedValue] = useState<any>({});

  const handleNewValue = () => {
    setIsSelected(!isSelected);
    if (!isSelected) {
      setNewValue(old => [
        ...old,
        {
          label: items?._source?.name,
          value: items?._source?.userId,
        },
      ]);

      setSelectedValue({
        label: items?._source?.name,
        value: items?._source?.userId,
      });
    } else {
      const updateList = formName?.filter(
        filter => filter?.value !== selectedValue?.value,
      );
      setNewValue(updateList);
    }
  };

  return (
    <Button selectProps={isSelected} onClick={handleNewValue} ref={buttonRef}>
      <span />
      <p>{`${items?._source?.name} - ${items?._source?.userId}`}</p>
    </Button>
  );
};

export default Selector;
