import React from 'react';
import * as S from './styles';

import Button from '../Button';
import { useAuth } from '../../hooks/auth';
import { ReactComponent as Linkedin } from '../../assets/linkedin.svg';
import { ReactComponent as Facebook } from '../../assets/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/twitter.svg';

export interface VerifyProfileProps {
  cardType: 'article' | 'author' | 'journal' | 'ppg';
  isVerifyed: boolean;
  isPpgAuthor?: boolean;

  coordinator?: string;
  universitySite?: string;

  orcid?: string;
  responsibleEditor?: string;
  lattes?: string;
  personalSite?: string;

  linkedin?: string;
  instagram?: string;
  facebook?: string;
  twitter?: string;
}

const accountTypes = {
  article: 'Artigo',
  author: 'Autor',
  journal: 'Periódico',
  ppg: 'Programa de Pós Graduação',
};

const VerifyProfileCard: React.FC<VerifyProfileProps> = ({ ...props }) => {
  const { signed, user } = useAuth();

  return (
    <>
      {props.isVerifyed ? (
        <S.VeriFyedAuthorContainer {...props}>
          <section>
            <h6>
              {props.cardType === 'author'
                ? 'Orcid'
                : props.cardType === 'journal'
                ? 'Editor Reposável'
                : 'Coordenador do PPG'}
            </h6>
            <div>
              <p>
                {props.cardType === 'author'
                  ? props.orcid || (
                      <p style={{ color: '#A9B3C4' }}>Dado não cadastrado</p>
                    )
                  : props.cardType === 'journal'
                  ? props.responsibleEditor || (
                      <p style={{ color: '#A9B3C4' }}>Dado não cadastrado</p>
                    )
                  : props.coordinator || (
                      <p style={{ color: '#A9B3C4' }}>Dado não cadastrado</p>
                    )}
              </p>
            </div>

            <h6>
              {props.cardType === 'author'
                ? 'Lattes'
                : props.cardType === 'journal'
                ? 'Site Pessoal'
                : 'Site Universidade'}
            </h6>
            <div>
              <p>
                {props.cardType === 'author'
                  ? (
                      <a href={props.lattes} target="_blank" rel="noreferrer">
                        {' '}
                        {props.lattes || (
                          <p style={{ color: '#A9B3C4' }}>
                            Dado não cadastrado
                          </p>
                        )}
                      </a>
                    ) || <p style={{ color: '#A9B3C4' }}>Dado não cadastrado</p>
                  : props.cardType === 'journal'
                  ? props.personalSite || (
                      <p style={{ color: '#A9B3C4' }}>Dado não cadastrado</p>
                    )
                  : props.universitySite || (
                      <p style={{ color: '#A9B3C4' }}>Dado não cadastrado</p>
                    )}
              </p>
            </div>

            {props.cardType === 'author' ? (
              <>
                <h6>Site Pessoal</h6>
                <div>
                  <p>
                    {props.personalSite ? (
                      <a
                        href={props.personalSite}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        {props.personalSite}{' '}
                      </a>
                    ) : (
                      <p style={{ color: '#A9B3C4' }}>Dado não cadastrado</p>
                    )}
                  </p>
                </div>
              </>
            ) : (
              ''
            )}

            <S.SocialContainer {...props}>
              <div>
                {props.linkedin ? (
                  <a href={props.linkedin} target="_blank">
                    <Linkedin height={20} width={20} />
                  </a>
                ) : (
                  ''
                )}
                {props.facebook ? (
                  <a href={props.facebook} target="_blank">
                    <Facebook height={20} width={20} />
                  </a>
                ) : (
                  ''
                )}
                {props.instagram ? (
                  <a href={props.instagram} target="_blank">
                    <Instagram height={20} width={20} />
                  </a>
                ) : (
                  ''
                )}
                {props.twitter ? (
                  <a href={props.twitter} target="_blank">
                    <Twitter height={20} width={20} />
                  </a>
                ) : (
                  ''
                )}
              </div>
              {props.cardType === 'ppg' && props.isPpgAuthor ? (
                <Button ghost>Sou professor deste programa</Button>
              ) : (
                ''
              )}
            </S.SocialContainer>
          </section>
        </S.VeriFyedAuthorContainer>
      ) : (
        <S.UnverifyedContainer {...props}>
          <p>
            Você é este {accountTypes[props.cardType]}?
            <br />
            <span>
              Ative sua conta <br />
            </span>
            e mantenha seu perfil atualizado
          </p>
          <Button
            size="small"
            color="blueMidtone"
            href={
              accountTypes[props.cardType] === 'Autor' && signed
                ? `/perfil/usuario:${user.name}/#orcid`
                : accountTypes[props.cardType] === 'Periódico' ||
                  accountTypes[props.cardType] === 'Programa de Pós Graduação'
                ? '/usuario/registro-premium-2'
                : '/login/orcid'
            }
          >
            Eu sou esse {accountTypes[props.cardType]}!
          </Button>
        </S.UnverifyedContainer>
      )}
    </>
  );
};

export default VerifyProfileCard;
