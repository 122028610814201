/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import AnaliseIndex from '../../../../components/AnalisePerfil';
import Button from '../../../../components/Button';
import Collection from './Collection';
import getClient from '../../../../services/client/elasticsearch';
import PopUpFindName from '../PopUpFindName';
import ModalContent from '../../../../components/ModalContent';
import ContentTag from '../../../../components/ContentTag';
import * as S from './styles';

interface IndexProp {
  user?: any;
  userList?: any;
}

const PeridicIndex = ({ user = false, userList = [] }: IndexProp) => {
  const { data: authors, isLoading } = useQuery(
    ['articles', userList],
    async () => {
      const response = await getClient().search({
        index: 'authors',
        size: 100,
        body: {
          query: {
            bool: {
              should: userList?.map(id => ({
                match: { _id: id },
              })),
            },
          },
        },
      });

      const data = await response.hits.hits;
      console.log(data);
      const totalData: any = await response.hits.total;

      return data;
    },
    {
      initialData: [],
    },
  );

  return (
    <>
      <S.Container>
        {user.isVerified ? <Index userList={authors} /> : <AnaliseIndex />}
      </S.Container>
    </>
  );
};

const Index = ({ userList }) => {
  const [addTecher, setAddTeacher] = useState(false);
  const [modalList, setModalList] = useState([]);
  const [isAuthorsModalOpen, setIsAuthorsModalOpen] = useState(false);

  function handleOpenPopUp() {
    setAddTeacher(true);
  }

  function handleOpenAuthorsModal(list) {
    setIsAuthorsModalOpen(true);
    console.log(isAuthorsModalOpen);
    setModalList(list);
  }

  function handleCloseAuthorsModal() {
    setIsAuthorsModalOpen(false);
  }

  return (
    <>
      <ModalContent
        modalType="tag"
        isOpen={isAuthorsModalOpen}
        onRequestClose={handleCloseAuthorsModal}
        tags={modalList}
        title="Palavras Chave"
      />
      {addTecher && (
        <PopUpFindName
          userType="authors"
          isOpen={addTecher}
          setPopUpState={setAddTeacher}
        />
      )}
      <S.Content>
        <div className="section-title">
          <h3>Professores</h3>
        </div>
        <ul className="table-header">
          <li>NOME</li>
          <li>ARTIGOS PUBLICADOS</li>
          <li>PROGRAMAS DE PÓS GRADUAÇÃO</li>
          {
            // Palavra-chave desativado a pedido do Dr. Emerson
            // definido na reunião presencial dia 17/10/2022
            //
            // <li>PALAVRAS-CHAVE</li>
          }

          <li>ÍNDICES</li>
          <li>
            <button
              type="button"
              className="add-teacher"
              onClick={handleOpenPopUp}
            >
              Adicionar Professor +
            </button>
          </li>
        </ul>

        <div className="content-section">
          {userList?.map(item => (
            <ul className="main-list">
              <li> {item?._source?.name} </li>
              <li className="keyword-list">
                {item?._source?.articlesRel?.slice(0, 3)?.map(articles => (
                  <ContentTag href={`/textos/detalhe/${articles.uniqId}`}>
                    {articles.title}
                  </ContentTag>
                ))}

                {item?._source?.articlesRel?.length > 3 && (
                  <Button
                    size="small"
                    link
                    onClick={() =>
                      handleOpenAuthorsModal(item?._source?.articlesRel)
                    }
                  >
                    Ver mais
                  </Button>
                )}
              </li>
              <li className="keyword-list">
                {item?._source?.ppgsRel?.slice(0, 3)?.map(ppg => (
                  <ContentTag href={`/ppg/detalhe/${ppg.uniqId}`}>
                    {ppg.name}
                  </ContentTag>
                ))}

                {item?._source?.ppgsRel?.length > 3 && (
                  <Button
                    size="small"
                    link
                    onClick={() =>
                      handleOpenAuthorsModal(item?._source?.ppgsRel)
                    }
                  >
                    Ver mais
                  </Button>
                )}
              </li>
              <li className="keyword-list">
                <Collection collection={item?._source?.keywords} />
              </li>

              <li>
                <ul className="indexes">
                  {item?._source?.indexes?.['IC-A'] ? (
                    <li>
                      <span> IC-A</span>

                      <p> {Number(item?._source?.indexes?.['IC-A'])}</p>
                    </li>
                  ) : null}
                  {item?._source?.indexes?.['IC-H4-A'] ? (
                    <li>
                      <span> IC-H4-A</span>

                      <p> {Number(item?._source?.indexes?.['IC-H4-A'])}</p>
                    </li>
                  ) : null}
                  {item?._source?.indexes?.hAll ? (
                    <li>
                      <span> hAll</span>

                      <p> {Number(item?._source?.indexes?.hAll)}</p>
                    </li>
                  ) : null}

                  {item?._source?.indexes?.h5 ? (
                    <li>
                      <span> H5</span>

                      <p> {Number(item?._source?.indexes?.h5)}</p>
                    </li>
                  ) : null}
                  {item?._source?.indexes?.i10All ? (
                    <li>
                      <span> i10All</span>

                      <p> {Number(item?._source?.indexes?.i10All)}</p>
                    </li>
                  ) : null}
                  {item?._source?.indexes?.i105y ? (
                    <li>
                      <span> i10</span>

                      <p> {Number(item?._source?.indexes?.i105y)}</p>
                    </li>
                  ) : null}
                </ul>
              </li>

              <li>
                <Button href={`/autor/detalhe/${item?._source?.uniqId}`}>
                  Saiba Mais
                </Button>
              </li>
            </ul>
          ))}
        </div>
      </S.Content>
    </>
  );
};

export default PeridicIndex;
