/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { BsCheckCircle } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import CtaBanner from '../../components/CtaBanner';
import { ReactComponent as SearchIcon } from '../../assets/search-right.svg';
import { ReactComponent as UploadIcon } from '../../assets/upload-icon.svg';
import PopUpFindName from '../../components/PopUpFindName';
import PopUpAddFile from '../../components/PopUpAddFile';
import LoadingScreen from '../../components/LoadingScreen';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface RegistrationProp {
  description?: string;
}

interface PeriodicFormInputs {
  name: string;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
  editor: string;
  nameInCitations: string;
  issn: string;
  site: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  twitter: string;
  verification: string;
  terms: boolean;
}

interface PPGFormInputs {
  name: string;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
  coordinatorPPG: string;
  universityName: string;
  universitySite: string;
  valuationArea: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  twitter: string;
  verification: string;
  terms: boolean;
}

const periodicosList = [
  {
    title:
      'Pesquisar Autores, Periódicos, Programas de Pós Graduação e Índices',
  },
  {
    title:
      'Ver página de detalhes de Autores, Periódicos e Programas de Pós Graduação',
  },
  {
    title: 'Vincular professores',
    hasRight: false,
  },
];
const ppgList = [
  {
    title:
      'Pesquisar Autores, Periódicos, Programas de Pós Graduação e Índices',
  },
  {
    title:
      'Ver página de detalhes de Autores, Periódicos e Programas de Pós Graduação',
  },
  {
    title: 'Vincular professores',
    hasRight: true,
  },
];

const RegisterPeriodicosPPG = ({
  description = 'Assine a Plataforma Caciq e tenha acesso ao que há de mais moderno em termos de logística de pesquisa científica na atualidade, podendo cadastrar-se como periódico científico ou como Programa de Pós-graduação stricto sensu',
}: RegistrationProp) => {
  const [registerType, setRegisterType] = useState(false);

  const [isLoading, setIsloading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}

      <S.MainContainer>
        <S.Container>
          <div className="form-header">
            <h2>
              Cadastro de{' '}
              <span>Periódicos e de Programas de Pós-graduação</span>
            </h2>
            <p>{description}</p>
          </div>
          <S.ButtonSection>
            <div
              className={
                !registerType
                  ? 'button-active button-container'
                  : 'button-container'
              }
            >
              <button type="button" onClick={() => setRegisterType(false)}>
                <h3>Periódico</h3>
              </button>

              <ul>
                {periodicosList.map(e => (
                  <li
                    className={
                      e.hasRight !== false ? null : 'unavaliable-feature'
                    }
                  >
                    {e.hasRight !== false ? (
                      <BsCheckCircle />
                    ) : (
                      <IoMdCloseCircleOutline />
                    )}
                    <span>{e.title}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={
                registerType
                  ? 'button-active button-container'
                  : 'button-container'
              }
            >
              <button type="button" onClick={() => setRegisterType(true)}>
                <h3>PPG</h3>
              </button>

              <ul>
                {ppgList.map(e => (
                  <li
                    className={
                      e.hasRight !== false ? null : 'unavaliable-feature'
                    }
                  >
                    {e.hasRight !== false ? (
                      <BsCheckCircle />
                    ) : (
                      <IoMdCloseCircleOutline />
                    )}
                    <span>{e.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </S.ButtonSection>

          {!registerType ? (
            <PeriodicTab history={history} setIsloading={setIsloading} />
          ) : (
            <PPGTab history={history} setIsloading={setIsloading} />
          )}
        </S.Container>
      </S.MainContainer>
      <CtaBanner />
    </>
  );
};

const periodicSchema = Yup.object().shape({
  name: Yup.string().required('O Nome é obrigatório'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O E-mail é obrigatório'),
  emailConfirmation: Yup.mixed().oneOf(
    [Yup.ref('email')],
    'Os E-mails não coincidem',
  ),
  editor: Yup.string().required('O Editor é obrigatório'),
  issn: Yup.string()
    .required('O ISSN é obrigatório')
    .min(8, 'Mínimo de 8 caracteres'),
  password: Yup.string().required('A senha é obrigatória'),
  passwordConfirmation: Yup.mixed().oneOf(
    [Yup.ref('password')],
    'As senhas não coincidem',
  ),
  terms: Yup.boolean().oneOf(
    [true],
    'Aceite os termos de privacidade para concluir o cadastro',
  ),
});

const PeriodicTab = ({ history, setIsloading }) => {
  const [searchName, setSearchName] = useState(false);
  const [pdfFile, setPdfFile] = useState<File>();
  const [pdfFileName, setPdfFileName] = useState<File>();
  const [newValue, setNewValue] = useState<any>();
  const { signIn, signed, user, updateProfile } = useAuth();
  const [eula, setEula] = useState(false);
  const { emitError } = useError();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PeriodicFormInputs>({
    resolver: yupResolver(periodicSchema),
  });

  useEffect(() => {
    if (newValue?.label) {
      setValue('name', newValue?.label);
    }
  }, [newValue]);

  const onSubmit: SubmitHandler<PeriodicFormInputs> = async data => {
    try {
      const social = [];

      if (data.editor)
        social.push({
          name: 'editorResponsible',
          url: data.editor,
          public: false,
        });
      if (data.nameInCitations)
        social.push({
          name: 'nameInCitations',
          url: data.nameInCitations,
          public: false,
        });
      if (data.issn)
        social.push({
          name: 'issn',
          url: data.issn,
          public: false,
        });
      if (data.site)
        social.push({ name: 'periodicWebsite', url: data.site, public: false });
      if (data.linkedin)
        social.push({ name: 'linkedin', url: data.linkedin, public: false });
      if (data.facebook)
        social.push({ name: 'facebook', url: data.facebook, public: false });
      if (data.instagram)
        social.push({ name: 'instagram', url: data.instagram, public: false });
      if (data.twitter)
        social.push({ name: 'twitter', url: data.twitter, public: false });

      const fullData = {
        email: data.email,
        CPF: '0000000000',
        birthDate: '00000000',
        name: data.name,
        password: data.password,
        accountType: 'journal',
        socials: social,
      };

      if (signed) {
        setIsloading(true);
        history.push('/usuario/confirmar-email');
      } else {
        setIsloading(true);
        const postProfile = await api.post('/signup', fullData);

        setTimeout(async () => {
          const currentUser = await signIn(data.email, data.password, true);

          social.forEach(async item => {
            const post = await api.patch('/profile/social', item);
          });

          updateProfile({ ...fullData, socials: social } as any);

          const formData = new FormData();
          formData.append('file', pdfFile);

          const sendPdf = await api.post(
            `upload/verification/${newValue?.value}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );

          setIsloading(false);
        }, 500);
      }
    } catch (error: any) {
      setIsloading(false);
      emitError(error, 'Ocorreu um erro ao cadastrar, tente novamente!');
    }
  };

  return (
    <>
      {searchName ? (
        <PopUpFindName
          isOpen={searchName}
          userType="journals"
          setPopUpState={setSearchName}
          handleNewValue={setNewValue}
        />
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="form-itens">
          <li>
            <label htmlFor="name">Nome do Periódico</label>
            <input
              id="name"
              type="text"
              placeholder="Nome do Periódico"
              {...register('name')}
              defaultValue={user?.name || ''}
              disabled
            />
            <p> {errors.name?.message}</p>
          </li>

          <li>
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="email"
              placeholder="email@email.com.br"
              {...register('email')}
              defaultValue={user?.email || ''}
            />
            <p> {errors.email?.message}</p>
          </li>
          <li>
            <label htmlFor="emailConfirmation">Confirmar E-mail</label>
            <input
              id="emailConfirmation"
              type="email"
              placeholder="email@email.com.br"
              {...register('emailConfirmation')}
              defaultValue={user?.email || ''}
            />
            <p> {errors.emailConfirmation?.message}</p>
          </li>
          <li>
            <label htmlFor="password">Senha</label>
            <input id="password" type="password" {...register('password')} />
            <p> {errors.password?.message}</p>
          </li>
          <li>
            <label htmlFor="passwordConfirmation">Confirmar Senha</label>
            <input
              id="passwordConfirmation"
              type="password"
              {...register('passwordConfirmation')}
            />
            <p> {errors.passwordConfirmation?.message}</p>
          </li>
          <li>
            <label htmlFor="editor">Editor responsável</label>
            <input
              id="editor"
              type="text"
              placeholder="Nome do Editor Responsável"
              {...register('editor')}
            />
            <p> {errors.editor?.message}</p>
          </li>
          <li>
            <label htmlFor="nameInCitations">Nome em citações</label>
            <input
              id="nameInCitations"
              type="text"
              placeholder="Nome Sobrenome"
              {...register('nameInCitations')}
            />
            <p> {errors.editor?.message}</p>
          </li>
          <li>
            <label htmlFor="issn">ISSN</label>
            <input
              id="issn"
              type="text"
              placeholder="0000-0000"
              {...register('issn')}
            />
            <p> {errors.issn?.message}</p>
          </li>
          <li>
            <label htmlFor="site">Site Periódico</label>
            <input
              id="site"
              type="text"
              placeholder="www.site.com.br"
              {...register('site')}
            />
          </li>
          <li>
            <label htmlFor="linkedin">Linkedin</label>
            <input
              id="linkedin"
              type="text"
              placeholder="www.linkedin.com/seuperfil"
              {...register('linkedin')}
            />
          </li>
          <li>
            <label htmlFor="facebook">Facebook</label>
            <input
              id="facebook"
              type="text"
              placeholder="www.facebook.com/seuperfil"
              {...register('facebook')}
            />
          </li>
          <li>
            <label htmlFor="instagram">Instagram</label>
            <input
              id="instagram"
              type="text"
              placeholder="www.instagram.com/seuperfil"
              {...register('instagram')}
            />
          </li>
          <li>
            <label htmlFor="twitter">Twitter</label>
            <input
              id="twitter"
              type="text"
              placeholder="www.twitter.com/seuperfil"
              {...register('twitter')}
            />
          </li>
        </ul>

        <button
          type="button"
          className="search-button"
          onClick={() => setSearchName(!searchName)}
        >
          <SearchIcon />
        </button>
        <div className="send-verification">
          <h5>Comprovante de verificação</h5>

          <h6>
            Para a validação do perfil é necessário o envio de um comprovante em
            PDF da titularidade como Editor-Chefe da revista e de sua vinculação
            institucional (em caso de perfil Periódico) ou da sua designação
            para a função de Coordenação (em caso de perfil PPG). É importante o
            uso do e-mail oficial do periódico, da instituição ou do editor. A
            Caciq entrará em contato em caso de dúvidas ou esclarecimentos.{' '}
          </h6>

          <div className="file-column">
            <label htmlFor="file">
              {!pdfFileName?.name && <UploadIcon />}
              <p>
                {pdfFileName?.name ? pdfFileName?.name : 'Escolher arquivo'}
              </p>
            </label>

            <input
              type="file"
              name="file"
              id="file"
              {...register('verification')}
              onChange={file => {
                setPdfFile(file.target.files[0]);
                setPdfFileName(file.target.files[0]);
              }}
            />
          </div>

          {/* <p> {errors.verification?.message}</p> */}
        </div>
        <div className="terms-container">
          <input
            type="checkbox"
            id="terms"
            {...register('terms')}
            onChange={value => setEula(value.target.checked)}
          />
          <label htmlFor="terms">
            Li e concordo com a
            <a
              href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Privacidade_Finalizada.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Política de privacidade
            </a>{' '}
            e com o{' '}
            <a
              href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Termos_Uso_Finalizada.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Termo de Proteção de Dados{' '}
            </a>
            <p>{errors.terms?.message}</p>
          </label>
          <p> {errors.terms?.message}</p>
        </div>

        <div className="buttons-section">
          <button
            type="submit"
            disabled={!eula}
            title={!eula && 'Você não aceitou os termos de privacidade'}
          >
            Finalizar
          </button>
        </div>
      </form>
    </>
  );
};

const ppgSchema = Yup.object().shape({
  name: Yup.string().required('O Nome é obrigatório'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O E-mail é obrigatório'),
  emailConfirmation: Yup.mixed().oneOf(
    [Yup.ref('email')],
    'Os E-mails não coincidem',
  ),
  password: Yup.string().required('A senha é obrigatória'),
  passwordConfirmation: Yup.mixed().oneOf(
    [Yup.ref('password')],
    'As senhas não coincidem',
  ),

  terms: Yup.boolean().oneOf(
    [true],
    'Aceite os termos de privacidade para concluir o cadastro',
  ),
});

const PPGTab = ({ history, setIsloading }) => {
  const [searchName, setSearchName] = useState(false);
  const { signed, signIn, user } = useAuth();
  const [pdfFile, setPdfFile] = useState<File>();
  const [pdfFileName, setPdfFileName] = useState<File>();
  const [newValue, setNewValue] = useState<any>('');
  const [eula, setEula] = useState(false);
  const { emitError } = useError();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PPGFormInputs>({
    resolver: yupResolver(ppgSchema),
  });
  const onSubmit: SubmitHandler<PPGFormInputs> = async data => {
    try {
      const social = [];

      if (data.coordinatorPPG)
        social.push({
          name: 'coordinatorPPG',
          url: data.coordinatorPPG,
          public: false,
        });
      if (data.universityName)
        social.push({
          name: 'universityName',
          url: data.universityName,
          public: false,
        });
      if (data.universitySite)
        social.push({
          name: 'universitySite',
          url: data.universitySite,
          public: false,
        });
      if (data.linkedin)
        social.push({ name: 'linkedin', url: data.linkedin, public: false });
      if (data.facebook)
        social.push({ name: 'facebook', url: data.facebook, public: false });
      if (data.instagram)
        social.push({ name: 'instagram', url: data.instagram, public: false });
      if (data.twitter)
        social.push({ name: 'twitter', url: data.twitter, public: false });

      const fullData = {
        email: data.email,
        CPF: '0000000000',
        birthDate: '00000000',
        name: data.name,
        password: data.password,
        accountType: 'ppg',
        socials: social,
      };

      if (signed) {
        setIsloading(true);
        history.push('/usuario/confirmar-email');
      } else {
        setIsloading(true);
        const post = await api.post('/signup', fullData);

        setTimeout(async () => {
          const currentUser = await signIn(data.email, data.password, true);

          const formData = new FormData();
          formData.append('file', pdfFile);

          const sendPdf = await api.post(
            `upload/verification/${newValue?.value}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );

          setIsloading(false);
        }, 500);
      }
    } catch (error: any) {
      setIsloading(false);
      emitError(error, 'Ocorreu um erro ao cadastrar, tente novamente!');
    }
  };

  useEffect(() => {
    if (newValue?.label) {
      setValue('name', newValue?.label);
    }
  }, [newValue]);

  return (
    <>
      {searchName ? (
        <PopUpFindName
          isOpen={searchName}
          userType="ppgs"
          setPopUpState={setSearchName}
          handleNewValue={setNewValue}
        />
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="form-itens">
          <li>
            <label htmlFor="name">Programa de pós-graduação </label>
            <input
              id="name"
              type="text"
              placeholder="Nome do Programa"
              disabled
              {...register('name')}
              defaultValue={user?.name || ''}
            />
            <p> {errors.name?.message}</p>
          </li>

          <li>
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="email"
              placeholder="email@email.com.br"
              {...register('email')}
              defaultValue={user?.email || ''}
            />
            <p> {errors.email?.message}</p>
          </li>
          <li>
            <label htmlFor="emailConfirmation">Confirmar E-mail</label>
            <input
              id="emailConfirmation"
              type="email"
              placeholder="email@email.com.br"
              {...register('emailConfirmation')}
              defaultValue={user?.email || ''}
            />
            <p> {errors.emailConfirmation?.message}</p>
          </li>
          <li>
            <label htmlFor="password">Senha</label>
            <input id="password" type="password" {...register('password')} />
            <p> {errors.password?.message}</p>
          </li>
          <li>
            <label htmlFor="passwordConfirmation">Confirmar Senha</label>
            <input
              id="passwordConfirmation"
              type="password"
              {...register('passwordConfirmation')}
            />
            <p> {errors.passwordConfirmation?.message}</p>
          </li>
          <li>
            <label htmlFor="editor">Coordenador do PPG</label>
            <input
              id="editor"
              type="text"
              placeholder="Nome do Coordenador"
              {...register('coordinatorPPG')}
            />
          </li>
          <li>
            <label htmlFor="valuationArea">Área de avaliação</label>
            <input
              id="valuationArea"
              type="text"
              placeholder="Área de avaliação"
              {...register('valuationArea')}
            />
          </li>

          <li>
            <label htmlFor="site">Nome da Universidade</label>
            <input
              id="site"
              type="text"
              placeholder="Nome da Universidade"
              {...register('universityName')}
            />
          </li>
          <li>
            <label htmlFor="site">Site da Universidade</label>
            <input
              id="site"
              type="text"
              placeholder="www.universidade.com.br"
              {...register('universitySite')}
            />
          </li>
          <li>
            <label htmlFor="linkedin">Linkedin</label>
            <input
              id="linkedin"
              type="text"
              placeholder="www.linkedin.com/seuperfil"
              {...register('linkedin')}
            />
          </li>
          <li>
            <label htmlFor="facebook">Facebook</label>
            <input
              id="facebook"
              type="text"
              placeholder="www.facebook.com/seuperfil"
              {...register('facebook')}
            />
          </li>
          <li>
            <label htmlFor="instagram">Instagram</label>
            <input
              id="instagram"
              type="text"
              placeholder="www.instagram.com/seuperfil"
              {...register('instagram')}
            />
          </li>
          <li>
            <label htmlFor="twitter">Twitter</label>
            <input
              id="twitter"
              type="text"
              placeholder="www.twitter.com/seuperfil"
              {...register('twitter')}
            />
          </li>
        </ul>

        <button
          type="button"
          className="search-button"
          onClick={() => setSearchName(!searchName)}
        >
          <SearchIcon />
        </button>
        <div className="send-verification">
          <h5>Comprovante de verificação</h5>

          <h6>
            Para a validação do perfil é necessário o envio de um comprovante em
            PDF da titularidade como Editor-Chefe da revista e de sua vinculação
            institucional (em caso de perfil Periódico) ou da sua designação
            para a função de Coordenação (em caso de perfil PPG). É importante o
            uso do e-mail oficial do periódico, da instituição ou do editor. A
            Caciq entrará em contato em caso de dúvidas ou esclarecimentos.{' '}
          </h6>

          <div className="file-column">
            <label htmlFor="file">
              {!pdfFileName?.name && <UploadIcon />}
              <p>
                {pdfFileName?.name ? pdfFileName?.name : 'Escolher arquivo'}
              </p>
            </label>
            <input
              type="file"
              name="file"
              id="file"
              {...register('verification')}
              onChange={file => {
                setPdfFile(file.target.files[0]);
                setPdfFileName(file.target.files[0]);
              }}
            />
          </div>
          {/* <p> {errors.verification?.message}</p> */}
        </div>
        <div className="terms-container">
          <input
            type="checkbox"
            id="terms"
            {...register('terms')}
            onChange={value => setEula(value.target.checked)}
          />
          <label htmlFor="terms">
            Li e concordo com os
            <a href="https://google.com">Termos de Privacidade </a>
          </label>
          <p> {errors.terms?.message}</p>
        </div>

        <div className="buttons-section">
          <button
            type="submit"
            disabled={!eula}
            title={!eula && 'Você não aceitou os termos de privacidade'}
          >
            Finalizar
          </button>
        </div>
      </form>
    </>
  );
};

export default RegisterPeriodicosPPG;
