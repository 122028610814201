import React from 'react';
import * as S from './styles';

interface NumberOfCitationsProps {
  numberInfo?: number;
  capesNumberInfo?: number | string;
  textInfo: string;
}

const NumberOfCitations: React.FC<NumberOfCitationsProps> = props => {
  return (
    <>
      <S.CitationsInfo>
        <strong>
          {props.numberInfo ? props.numberInfo : props.capesNumberInfo}
        </strong>
        <p>{props.textInfo}</p>
      </S.CitationsInfo>
    </>
  );
};

export default NumberOfCitations;
