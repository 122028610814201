/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../services/api';
import { Social } from '../models/user';

export interface SocialContextData {
  social: Social[];
  getSocial(): Promise<void>;
  isLoading: boolean;
}
export const SocialContext = createContext<SocialContextData>(
  {} as SocialContextData,
);
export const SocialProvider: React.FC = ({ children }: any) => {
  const [social, setSocial] = useState<Social[] | undefined>();
  const [loading, setLoading] = useState(false);

  const getSocial = async () => {
    try {
      setLoading(true);

      const response = await api.get('/profile/social/');
      setSocial(response.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <SocialContext.Provider
      value={{
        isLoading: loading,
        social,
        getSocial,
      }}
    >
      {children}
    </SocialContext.Provider>
  );
};

export function useSocial(): SocialContextData {
  const context = useContext(SocialContext);
  return context;
}
