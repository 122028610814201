/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Button from '../../../../../components/Button';
import IndexesHeaders from '../../IndexesHeaders';
import AuthorIndexResultsCard from '../AuthorIndexResultsCard';
import LoadingAnimation from '../../../../../components/LoadingAnimaton';
import FetchingAnimation from '../../../../../components/FetchingAnimation';
import ErrorAnimation from '../../../../../components/ErrorAnimation';
import getClient from '../../../../../services/client/elasticsearch';
import useQueryUrl from '../../../../../hooks/queryUrl';
import { useRankingElastic } from '../../../../../hooks/rankSearch';
import * as S from './styles';

interface AuthorsIndexesTableProps {
  premium: boolean;
  registered: boolean;
  searchRank?: string;
  searchTerm?: string;
  setSearchTerm?: any;
  sortSearch?: any;
  setSortSearch?: any;
  setAuthorSearchMethod?: any;
  authorSearchMethod?: any;
  pageSize?: number;
  isRanking?: boolean;
  currentSort?: string;
}

const AuthorsIndexesTable: React.FC<AuthorsIndexesTableProps> = props => {
  const history = useHistory();
  const query = useQueryUrl();
  const [authorList, setAuthorList] = useState([]);
  const [forcePage, setForcePage] = useState<number>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const pageCount = Math.ceil(totalPages / props.pageSize);

  const defaultSearch = {
    index: 'authors',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      sort: [props.sortSearch],
    },
  };

  const exactMatchSearch = {
    index: 'authors',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        query_string: {
          fields: ['name'],
          query: `"${props.searchTerm}"`,
        },
      },
    },
  };

  const {
    data: authors,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    [
      pageNumber,
      props.searchTerm,
      props.pageSize,
      props.sortSearch,
      props.authorSearchMethod,
    ],
    async () => {
      const response = await getClient().search(
        props.searchTerm !== '' ? exactMatchSearch : defaultSearch,
      );
      // eslint-disable-next-line no-shadow
      const count = await getClient().count({ index: 'authors' });

      const data = await response.hits.hits;
      const totalData: any = await response.hits.total;

      if (props.searchTerm !== '') {
        setTotalResults(totalData.value);
      } else {
        setTotalResults(count.count);
      }
      setAuthorList(data);
      setTotalPages(totalData.value);
      return data;
    },
    {
      initialData: [],
    },
  );

  const { data: rank } = useRankingElastic('authors', props.searchRank);

  useMemo(() => {
    const actualRank = Object.keys(props.sortSearch);
    const cropRank = actualRank
      ?.filter(e => e !== 'total_refs_citation')
      .toString()
      .split('indexes.');
    const userSearch: any =
      rank.users[0]?._source?.indexes?.[`${cropRank[1]}-RANK`];
    const pageToGo = Math.ceil(userSearch / props.pageSize);
    if (props.isRanking && props.searchRank !== '' && !isNaN(pageToGo)) {
      if (pageToGo < 99999) {
        setPageNumber(pageToGo - 1);
        setForcePage(pageToGo - 1);
        history.push(`/indices?type=autores&page=${pageToGo}`);
      } else {
        toast.warning('Resultado não encontrado');
      }
    } else return null;
  }, [props.searchRank]);

  const currentSearch = query.get('termo') || '';
  useEffect(() => {
    if (currentSearch !== '') {
      props.setSearchTerm(currentSearch);
    }
  }, []);

  useMemo(() => {
    if (props.searchTerm) {
      query.set('termo', props.searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [props.searchTerm]);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    if (currentPage) {
      setPageNumber(currentPageNumber - 1);
      setForcePage(currentPageNumber - 1);
      query.set('page', currentPageNumber.toString());
      window.history.replaceState(
        {},
        '',
        `${location.pathname}?${query.toString()}`,
      );
    }

    return null;
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const displayResults = authorList.map((author: any, index) => {
    return (
      <AuthorIndexResultsCard
        rank={index + 1 + pageNumber * props.pageSize}
        name={author?._source?.name}
        indexes={author?._source?.indexes}
        premium={props?.premium}
        registered={props?.registered}
        isRanking={props.sortSearch?.[Object.keys(props.sortSearch).toString()]}
        sortSearch={props.sortSearch}
        currentSort={props.currentSort}
      />
    );
  });

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            <h3>
              Foram encontrados <span>{totalResults.toLocaleString()}</span>{' '}
              resultados
            </h3>
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <IndexesHeaders
            type="author"
            currentSort={props.currentSort}
            isRanking={
              props.sortSearch?.[Object.keys(props.sortSearch).toString()]
            }
          />
          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            <ErrorAnimation />
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        {props.premium ? (
          <>
            <S.PaginationContainer>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                forcePage={forcePage}
                onPageChange={changePage}
                containerClassName="pagination-buttons"
                previousLinkClassName="pagination-previous-buttons"
                nextLinkClassName="pagination-next-buttons"
                disabledClassName="pagination-disabled"
                activeClassName="pagination-active"
                onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
              />
            </S.PaginationContainer>
          </>
        ) : (
          <>
            <S.Block>{}</S.Block>
            <S.ReadMore>
              <Button href="/usuario/planos">
                Cadastre-se e veja mais resultados
              </Button>
            </S.ReadMore>
          </>
        )}
      </S.Container>
    </>
  );
};

export default AuthorsIndexesTable;
