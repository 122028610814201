/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CtaBanner from '../../components/CtaBanner';
import SearchBar from '../../components/SearchBar';
import SelectInput from '../../components/Select';
import ArticlesIndexesTable from './components/Articles/ArticlesIndexesTable';
import AuthorsIndexesTable from './components/Authors/AuthorsIndexesTable';
import JournalsIndexesTable from './components/Journals/JournalsIndexesTable';
import PPGsIndexesTable from './components/PPGs/PPGsIndexesTable';
import useQueryUrl from '../../hooks/queryUrl';
import { usePermission } from '../../hooks/permission';
import AdvancedFilters from '../../components/AdvancedFilters';
import { useAuth } from '../../hooks/auth';
import YearFilter from '../../components/yearFilter';
import Section from '../../components/Section';
import * as S from './styles';

const selectOptionsSigned = [
  {
    value: 'artigos',
    label: 'Textos',
  },
  {
    value: 'autores',
    label: 'Autores',
  },
  {
    value: 'periodicos',
    label: 'Revistas',
  },
  {
    value: 'ppg',
    label: 'PPGs',
  },
];

const IndexesResultsScreen = () => {
  const history = useHistory();
  const query = useQueryUrl();
  const { user, signed } = useAuth();
  const { Permissions } = usePermission();
  const [indexFilter, setIndexFilter] = useState('artigos');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchRank, setSearchRank] = useState('');
  const [currentSort, setCurrentSort] = useState('');
  const [dateTerm, setDateTerm] = useState([]);
  const [userPremium, setUserPremium] = useState(false);
  const [searchMethod, setSearchMethod] = useState('');
  const [sortSearch, setSortSearch] = useState<any>({
    'indexes.IC-T.raw': { order: 'desc' },
  });
  const [pageSize, setPageSize] = useState<any>(5);
  const [isRanking, setIsRanking] = useState(false);

  function handleFilter(e) {
    setIndexFilter(e);
  }

  useEffect(() => {
    if (!signed || !user?.subscriptions?.enabled) {
      setUserPremium(false);
    } else {
      setUserPremium(true);
    }
  }, [user]);

  useEffect(() => {
    const currentPage = query.get('type');
    const currentPageN = query.get('page');
    const term = query.get('term');

    const currentPageNumber = parseInt(currentPageN);
    if (currentPage) {
      setIndexFilter(currentPage);

      query.set('page', (currentPageNumber || 1).toString());
      query.set('term', term);
      window.history.replaceState(
        {},
        '',
        `${location.pathname}?${query.toString()}`,
      );
    }
  }, []);

  const currentSearch = query.get('termo') || '';
  useEffect(() => {
    if (currentSearch !== '') {
      setSearchTerm(currentSearch);
    }
  }, []);

  useEffect(() => {
    if (indexFilter === 'artigos') {
      setSortSearch({
        'indexes.IC-T.raw': { order: 'desc' },
      });
    }

    if (indexFilter === 'autores') {
      setSortSearch({
        'indexes.IC-A.raw': { order: 'desc' },
      });
    }

    if (indexFilter === 'periodicos') {
      setSortSearch({
        'indexes.IC-R.raw': { order: 'desc' },
      });
    }

    if (indexFilter === 'ppg') {
      setSortSearch({
        'indexes.IC-P': { order: 'desc' },
      });
    }
  }, [indexFilter]);

  useEffect(() => {
    setSearchRank('');
  }, [sortSearch]);

  useMemo(() => {
    if (sortSearch?.[Object.keys(sortSearch).toString()]) {
      const actualRank = Object.keys(sortSearch);
      const cropRank = actualRank
        ?.filter(e => e !== 'total_refs_citation')
        .toString()
        .split('indexes.');
      setCurrentSort(cropRank[1]);
    } else setCurrentSort('');
  }, [sortSearch]);

  const permission = Permissions('Search_Index');

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <Section />

          {(indexFilter === 'artigos' || indexFilter === 'periodicos') && (
            <S.FilterWrapper>
              <YearFilter setNewDate={setDateTerm} />
            </S.FilterWrapper>
          )}

          <S.SearchContainer>
            <SelectInput
              label="Índice para"
              defaultValue={selectOptionsSigned[0]}
              customFunction={handleFilter}
              option={selectOptionsSigned}
            />
            <SearchBar
              barType={
                indexFilter === 'artigos'
                  ? 'article'
                  : indexFilter === 'autores'
                  ? 'author'
                  : indexFilter === 'periodicos'
                  ? 'journal'
                  : 'ppg'
              }
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              permission={permission}
              isRanking={currentSort !== ''}
              setPageSize={setPageSize}
              setIsRanking={setIsRanking}
              setSearchRank={setSearchRank}
            />
          </S.SearchContainer>
          <AdvancedFilters
            indexPage
            article={indexFilter === 'artigos'}
            author={indexFilter === 'autores'}
            journal={indexFilter === 'periodicos'}
            ppgs={indexFilter === 'ppg'}
            setSortSearch={setSortSearch}
            sortSearch={sortSearch}
            isOpen
            isRanking
            setPageSize={setPageSize}
          />
        </S.IntroWrapper>
        {indexFilter === 'artigos' ? (
          <ArticlesIndexesTable
            premium={userPremium}
            registered={signed}
            searchTerm={searchTerm}
            dateSearch={dateTerm}
            sortSearch={sortSearch}
            setSortSearch={setSortSearch}
            pageSize={pageSize}
            searchRank={searchRank}
            isRanking={isRanking}
          />
        ) : indexFilter === 'autores' ? (
          <AuthorsIndexesTable
            premium={userPremium}
            registered={signed}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setAuthorSearchMethod={setSearchMethod}
            sortSearch={sortSearch}
            setSortSearch={setSortSearch}
            authorSearchMethod={searchMethod}
            searchRank={searchRank}
            pageSize={pageSize}
            isRanking={isRanking}
            currentSort={currentSort}
          />
        ) : indexFilter === 'periodicos' ? (
          <JournalsIndexesTable
            premium={userPremium}
            dateSearch={dateTerm}
            registered={signed}
            searchTerm={searchTerm}
            sortSearch={sortSearch}
            setSortSearch={setSortSearch}
            setSearchMethod={setSearchMethod}
            searchMethod={searchMethod}
            searchRank={searchRank}
            pageSize={pageSize}
            isRanking={isRanking}
            currentSort={currentSort}
          />
        ) : indexFilter === 'ppg' ? (
          <PPGsIndexesTable
            premium={userPremium}
            registered={signed}
            searchTerm={searchTerm}
            sortSearch={sortSearch}
            setSortSearch={setSortSearch}
            setSearchMethod={setSearchMethod}
            searchMethod={searchMethod}
            pageSize={pageSize}
            isRanking={isRanking}
            currentSort={currentSort}
          />
        ) : null}
      </S.Container>
      <CtaBanner isNews />
    </>
  );
};

export default IndexesResultsScreen;
