import styled, { keyframes } from 'styled-components';


const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;

export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 00, 0, 0.2);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPopUp = styled.div`
  width: 100%;
  max-width: 408px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  animation: 0.4s ${entranceAnim} ease;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const Title = styled.h5`
  font-size: 20px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const Text = styled.p`
  font-size: 16px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const Link = styled.a`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purpleDefault};
  text-decoration: underline;
  font-weight: bold;
  margin-top: 18px;
`;

export const LinkWrapper = styled.div`
  margin-top: 18px;
`;
