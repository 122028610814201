export const notLoggedIn = [
  {
    id: 0,
    title: 'Início',
    href: '/articles',
  },
  {
    id: 0,
    title: 'Login',
    href: '/login',
  },
];
export const LoggedIn = [
  {
    id: 0,
    title: 'Início',
    href: '/articles',
  },
];

export default {};
