/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Button from '../../../../../components/Button';
import IndexesHeaders from '../../IndexesHeaders';
import PPGIndexResultsCard from '../PPGIndexResultsCard';
import * as S from './styles';
import FetchingAnimation from '../../../../../components/FetchingAnimation';
import getClient from '../../../../../services/client/elasticsearch';
import useQueryUrl from '../../../../../hooks/queryUrl';
import { useRankingElastic } from '../../../../../hooks/rankSearch';
import LoadingAnimation from '../../../../../components/LoadingAnimaton';

interface PPGsIndexesTableProps {
  premium: boolean;
  registered: boolean;
  searchTerm?: string;
  searchRank?: string;
  sortSearch?: any;
  setSortSearch?: any;
  searchMethod?: any;
  setSearchMethod?: any;
  pageSize?: number;
  isRanking?: boolean;
  currentSort?: string;
}

const PPGsIndexesTable: React.FC<PPGsIndexesTableProps> = props => {
  const history = useHistory();
  const query = useQueryUrl();
  const [forcePage, setForcePage] = useState<number>(null);
  const [ppgList, setPpgList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const defaultSearch = {
    index: 'ppgs',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      sort: props.sortSearch,
    },
  };

  const customSearch = {
    index: 'ppgs',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        match: {
          university: props.searchTerm,
        },
      },
      sort: props.sortSearch,
    },
  };

  const exactMatchSearch = {
    index: 'ppgs',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        query_string: {
          fields: ['university'],
          query: props.searchTerm,
        },
      },
    },
  };

  const {
    data: ppgs,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['ppgs', pageNumber, props.searchTerm, props.sortSearch, props.pageSize],
    async () => {
      const response = await getClient().search(
        props.searchTerm !== '' && !props.searchTerm.includes('"')
          ? customSearch
          : props.searchTerm !== '' && props.searchTerm.includes('"')
          ? exactMatchSearch
          : defaultSearch,
      );
      // eslint-disable-next-line no-shadow
      const data = await response.hits.hits;
      setPpgList(data);

      const totalData: any = await response.hits.total;
      setTotalPages(totalData.value);
      return data;
    },
    {
      initialData: [],
    },
  );

  const { data: rank } = useRankingElastic('articles', props.searchRank);

  useEffect(() => {
    const actualRank = Object.keys(props.sortSearch);
    const cropRank = actualRank
      ?.filter(e => e !== 'total_refs_citation')
      .toString()
      .split('indexes.');
    const userSearch: any =
      rank.users[0]?._source?.indexes?.[`${cropRank[1]}-RANK`];

    const pageToGo = Math.ceil(userSearch / props.pageSize);
    if (props.isRanking && props.searchRank !== '' && !isNaN(pageToGo)) {
      if (pageToGo < 99999) {
        setPageNumber(pageToGo - 1);
        setForcePage(pageToGo - 1);
        history.push(`/indices?type=ppg&page=${pageToGo}`);
      } else {
        toast.warning('Resultado não encontrado');
      }
    } else return null;
  }, [rank]);

  const pageCount = Math.ceil(totalPages / props.pageSize);

  useMemo(() => {
    if (props.searchTerm) {
      query.set('termo', props.searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [props.searchTerm]);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    if (currentPage) {
      setPageNumber(currentPageNumber - 1);
      setForcePage(currentPageNumber - 1);
      query.set('page', currentPageNumber.toString());
      window.history.replaceState(
        {},
        '',
        `${location.pathname}?${query.toString()}`,
      );
    }

    return null;
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const displayResults = ppgList.map((ppg: any, index) => {
    return (
      <PPGIndexResultsCard
        rank={index + 1 + pageNumber * props.pageSize}
        name={ppg?._source?.name}
        university={ppg?._source?.university}
        indexes={ppg?._source?.indexes}
        premium={props?.premium}
        registered={props?.registered}
        isRanking={props.sortSearch?.[Object.keys(props.sortSearch).toString()]}
        currentSort={props.currentSort}
      />
    );
  });

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            <h3>
              Foram encontrados <span>{totalPages}</span> resultados
            </h3>
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <IndexesHeaders
            type="ppg"
            currentSort={props.currentSort}
            isRanking={
              props.sortSearch?.[Object.keys(props.sortSearch).toString()]
            }
          />
          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            'Erro ao carregar. Tente novamente'
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        {props.premium ? (
          <>
            <S.PaginationContainer>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                forcePage={forcePage}
                onPageChange={changePage}
                containerClassName="pagination-buttons"
                previousLinkClassName="pagination-previous-buttons"
                nextLinkClassName="pagination-next-buttons"
                disabledClassName="pagination-disabled"
                activeClassName="pagination-active"
                onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
              />
            </S.PaginationContainer>
          </>
        ) : (
          <>
            <S.Block>{}</S.Block>
            <S.ReadMore>
              <Button href="/usuario/planos">
                Cadastre-se e veja mais resultados
              </Button>
            </S.ReadMore>
          </>
        )}
      </S.Container>
    </>
  );
};

export default PPGsIndexesTable;
