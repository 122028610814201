/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { IoMdAlert } from 'react-icons/io';
import { toast } from 'react-toastify';
import { ReactComponent as EditPen } from '../../assets/pen-icon.svg';
import PlanCard from '../../components/PlanCard';
import UploadFile from '../../components/uploadPictureInput';
import CtaBanner from '../../components/CtaBanner';
import { ReactComponent as PerfilPhoto } from '../../assets/avatar.svg';
import { fakeData } from './fakeData';
import * as S from './styles';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import { useError } from '../../hooks/errors';

interface FormInputs {
  name: string;
  CPF: number;
  birthDate: number;
  email: string;
}

interface PerfilProp {
  planDescription?: string;
}

const PerfilFree = ({
  planDescription = 'Assine a Caciq, incremente seu perfil, e tenha acesso ao que há de mais moderno e inteligente em termos de pesquisa científica',
}: PerfilProp) => {
  const [cnpj, setCNPJ] = useState(null);

  const { user, updateProfile } = useAuth();
  const { emitError } = useError();

  const [newPic, setNewPic] = useState(user.avatar);
  const { register, handleSubmit } = useForm<FormInputs>();

  const splitDate = user?.birthDate?.toString().split('T');

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      const fullData = {
        name: data.name,
        CPF: data.CPF,
        birthDate: data.birthDate,
        avatar: newPic || user.avatar,
        email: data.email,
      };

      const post = await api.put('/profile', fullData);

      updateProfile(fullData as any);
      window.location.reload();
    } catch (error: any) {
      emitError(error, 'Falha ao atualizar seu perfil');
    }
  };

  const [editPerfil, setEditPerfil] = useState(false);

  const handleCnpjFilter = e => {
    let filtered = e.target.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 20) return;
    filtered = filtered.replace(/\D/g, '');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    filtered = filtered.replace(/(-\d{2})\d+?$/, '$1');

    setCNPJ(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.MainContainer>
        <S.PerfilSection inputProps={editPerfil}>
          <div className="picture-container">
            {editPerfil ? (
              <UploadFile
                newFile={newPic || user.avatar}
                setNewFile={setNewPic}
              />
            ) : null}

            {newPic === null || newPic === '' || newPic === 'none' ? (
              <PerfilPhoto />
            ) : (
              <img src={newPic} alt="perfil picturer" />
            )}
          </div>

          <section className="perfil-container">
            <div className="user-information">
              <h2>{user.name}</h2>

              <div className="edit-column">
                <p>{fakeData.role}</p>

                <button
                  type="button"
                  onClick={() => setEditPerfil(!editPerfil)}
                >
                  Editar perfil <EditPen />
                </button>
              </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <ul className="user-informations-editable">
                <li>
                  <label htmlFor="name">Nome Completo</label>
                  <input
                    id="name"
                    type="text"
                    placeholder="Nome Completo"
                    {...register('name')}
                    defaultValue={user.name}
                  />
                </li>
                <div className="row-input">
                  <li>
                    <label htmlFor="cpf">CPF</label>
                    <input
                      type="text"
                      id="cpf"
                      value={cnpj}
                      placeholder="000.000.000-00"
                      {...register('CPF')}
                      defaultValue={user.CPF}
                      onChange={e => handleCnpjFilter(e)}
                    />
                  </li>

                  <li>
                    <label htmlFor="birthdate">Data de Nascimento</label>
                    <input
                      type="date"
                      id="birthdate"
                      placeholder="dd/mm/aaaa"
                      {...register('birthDate')}
                      defaultValue={splitDate?.[0]}
                    />
                  </li>
                </div>

                <li className="email-field">
                  {!user?.isEmailVerified ? (
                    <button type="button" className="confirm-email">
                      <IoMdAlert
                        className="alert-icon"
                        title="E-mail não confirmado, se ja foi confirmado espere uns minutos"
                      />
                    </button>
                  ) : null}
                  <label htmlFor="mail">E-mail</label>
                  <input
                    type="text"
                    id="mail"
                    placeholder="email@email.com.br"
                    {...register('email')}
                    defaultValue={user.email}
                  />
                </li>
              </ul>
              {editPerfil ? (
                <button type="submit">Salvar as alterações</button>
              ) : null}
            </form>
          </section>
        </S.PerfilSection>

        <SignatureSection planDescription={planDescription} />
      </S.MainContainer>
      <CtaBanner />
    </>
  );
};

const SignatureSection = ({ planDescription }) => {
  return (
    <S.SignaturePlan>
      <div className="signature-header">
        <h3>Planos de Assinatura</h3>
        <p>{planDescription}</p>
      </div>

      <div className="plans-column">
        <PlanCard />
      </div>
    </S.SignaturePlan>
  );
};

export default PerfilFree;
