import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';
import Logo from '../../assets/logo-caciq.svg';
import Linkedin from '../../assets/linkedin.svg';
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/instagram.svg';
import Twitter from '../../assets/twitter.svg';

const Footer: React.FC = () => {
  return (
    <S.FooterWrapper>
      <S.StyledFooter>
        <S.FooterContainer>
          <S.LogoContainer>
            <img src={Logo} width="264px" alt="" />
            <p className="logoText">
              Plataforma Digital
              <br /> de Logística Científica
            </p>
          </S.LogoContainer>
          <S.NavContainer>
            <ul>
              <S.NavTitle>Navegação</S.NavTitle>
              <S.LinkItem>
                <a href="https://caciq.com.br/">Sobre</a>
              </S.LinkItem>
              <S.LinkItem>
                <a href="https://caciq.com.br/metodologia-indices-caciq/">
                  Metodologia
                </a>
              </S.LinkItem>
              <S.LinkItem>
                <a href="https://caciq.com.br/contato/">Contato</a>
              </S.LinkItem>
            </ul>

            <ul>
              <S.NavTitle>Pesquisa</S.NavTitle>
              <S.LinkItem>
                <Link to="/">Textos</Link>
              </S.LinkItem>
              <S.LinkItem>
                <Link to="/autores">Autores</Link>
              </S.LinkItem>
              <S.LinkItem>
                <Link to="/revistas">Periódicos</Link>
              </S.LinkItem>
              <S.LinkItem>
                <Link to="/ppg">Pós-graduação</Link>
              </S.LinkItem>
              <S.LinkItem>
                <Link to="/indices">Índices</Link>
              </S.LinkItem>
            </ul>

            <ul>
              <S.NavTitle>Contato</S.NavTitle>
              <S.LinkItem>
                <a className="email" href="@">
                  contato@caciq.com.br
                </a>
              </S.LinkItem>
              {/* <S.SocialContainer>
                <a href="@">
                  <img src={Linkedin} alt="" />
                </a>
                <a href="@">
                  <img src={Facebook} alt="" />
                </a>
                <a href="@">
                  <img src={Instagram} alt="" />
                </a>
                <a href="@">
                  <img src={Twitter} alt="" />
                </a>
              </S.SocialContainer> */}
              <S.LinkTerms>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Privacidade_Finalizada.pdf"
                >
                  Política de Privacidade
                </a>
              </S.LinkTerms>
              <S.LinkTerms>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Termos_Uso_Finalizada.pdf"
                >
                  Proteção de Dados
                </a>
              </S.LinkTerms>
            </ul>
          </S.NavContainer>
        </S.FooterContainer>
      </S.StyledFooter>
      <S.Credits>
        <p>© 2020</p>
        <p>
          Desenvolvido com amor e café por <a href="@">.CodeTie</a>
        </p>
      </S.Credits>
    </S.FooterWrapper>
  );
};

export default Footer;
