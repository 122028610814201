import React, { useState, useEffect, useRef } from 'react';
import { Button } from './styles';

interface SelectorProp {
  items?: string[] | any;
  setNewValue: any;
  formName: string;
}

const Selector: React.FC<SelectorProp> = ({
  items = [],
  setNewValue,
  formName,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const buttonRef = useRef(null);

  const handleNewValue = () => {
    buttonRef.current.click();
    if (!isSelected) {
      setNewValue({
        label:
          items.title || items.name || !items._source?.university
            ? items?._source?.name
            : `${items?._source?.name} - ${items._source?.university}`,
        value: items?._id,
      });
    } else setNewValue('');
  };

  return (
    <Button selectProps={buttonRef?.current?.checked} onClick={handleNewValue}>
      <span />{' '}
      <p style={{ textAlign: 'left' }}>
        {items?._source?.university
          ? `${items._source.name} - ${items._source?.university}`
          : items.title || items.name || items?._source?.name}
      </p>
      <input type="radio" name="names" ref={buttonRef} />
    </Button>
  );
};

export default Selector;
