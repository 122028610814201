import styled, { css } from 'styled-components';
import { theme } from '../../../../styles/global';
import mq from '../../../../styles/mq';

export const Container = styled.div`
  width: 100%;
  min-height: 205px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  min-height: 205px;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;

  ${mq.custom(
    css`
      overflow-y: auto;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ul {
    display: flex;
    gap: 20px;
    position: relative;
    z-index: 2;

    padding: 17px 0;
    border-bottom: 1.5px solid #dadee5;
    transition: background-color 0.2s;

    &:hover::before {
      background: #f2f2f2;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50vw;
      right: -50vw;
      transition: background-color 0.2s;
    }

    li {
      width: calc(100% / 9);
      font: 13px Mulish, sans-serif;
      z-index: 2;
      font-weight: 600;
      color: ${theme.colors.purpleDefault};
    }
  }

  .content-section {
    ul {
      padding: 48px 0;
      li {
        ${theme.fonts.workSans.p}
        font-weight: 600;
        color: ${theme.colors.grey2};

        p {
          ${theme.fonts.workSans.p}
          font-weight: 600;
          color: ${theme.colors.blueDefault};
        }
      }
    }
  }
`;


export const MainContainer = styled.section`



.section-title {
  max-width: 530px;
  margin: 56px auto 32px auto;

  h3 {
    margin-bottom: 12px;
  }
  h3,
  h6 {
    text-align: center;
    color: ${theme.colors.grey2};

    a {
      color: ${theme.colors.purpleDefault};
    }
  }
}
`;