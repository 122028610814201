import React, { useEffect, useState } from 'react';
import CtaBanner from '../../components/CtaBanner';
import SearchBar from '../../components/SearchBar';
import YearFilter from '../../components/yearFilter';
import AdvancedFilters from '../../components/AdvancedFilters';
import { usePermission } from '../../hooks/permission';
import JournalsResultsTable from './components/JournalsResultsTable';
import Section from '../../components/Section';
import * as S from './styles';

const JournalsResultsScreen = () => {
  const { Permissions } = usePermission();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchRank, setSearchRank] = useState('');
  const [dateTerm, setDateTerm] = useState([]);
  const [sortSearch, setSortSearch] = useState({
    'indexes.IC-R-RAW': { order: 'desc' },
  });
  const permission = Permissions('Search_Journal');
  const [pageSize, setPageSize] = useState<any>(5);
  const [isRanking, setIsRanking] = useState(false);
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);

  useEffect(() => {
    if (searchTerm !== '' || searchRank !== '') {
      setOpenAdvancedFilter(true);
    }
  }, [searchTerm, searchRank]);

  useEffect(() => {
    setSearchRank('');
  }, [sortSearch]);
  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <Section />

          <S.FilterWrapper>
            <YearFilter setNewDate={setDateTerm} />
          </S.FilterWrapper>
          <SearchBar
            setSearchTerm={setSearchTerm}
            permission={permission}
            searchTerm={searchTerm}
            isRanking={isRanking}
            setPageSize={setPageSize}
            setIsRanking={setIsRanking}
            setSearchRank={setSearchRank}
            barType="journal"
          />
          <AdvancedFilters
            journal
            setSortSearch={setSortSearch}
            sortSearch={sortSearch}
            isOpen={openAdvancedFilter}
            isRanking={isRanking}
            setPageSize={setPageSize}
          />
        </S.IntroWrapper>
        <JournalsResultsTable
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm.toLocaleLowerCase()}
          dateSearch={dateTerm}
          sortSearch={sortSearch}
          isRanking={isRanking}
          pageSize={pageSize}
        />
      </S.Container>
      <CtaBanner isNews />
    </>
  );
};

export default JournalsResultsScreen;
