import styled, { css } from 'styled-components';

import mq from '../../styles/mq';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 70px;

  position: relative;

  ${mq.custom(
    css`
      padding: 0 16px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const IntroWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  ${mq.custom(
    css`
      text-align: center;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ${mq.tablet(css`
    margin: 0 20px;
  `)}

  h2 {
    color: ${({ theme }) => theme.colors.grey2};
    margin-bottom: 14px;
    letter-spacing: 2px;
    font-size: 2.5rem;
  }

  h5 {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.grey2};
    font-weight: normal;
    letter-spacing: 1px;
    margin-bottom: 20px;
    max-width: 656px;
    width: 100%;
    text-align: center;
  }

  h3 {
    margin-top: 36px;
    margin-bottom: 38px;
    font-weight: normal;
    font-size: 1.875rem;
    color: ${({ theme }) => theme.colors.grey2};
    letter-spacing: 1px;

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blueMidtone};
    }
  }

  .order-information {
    width: 100%;
    max-width: 1010px;
    margin-top: 20px;
    ${({ theme }) => theme.fonts.mulish.p};
    color: ${({ theme }) => theme.colors.grey2};
  }
`;

export const FilterWrapper = styled.div`
  margin-top: 28px;
  max-width: 1010px;
  width: 100%;

  label {
    margin-right: 8px;
    font-family: ${({ theme }) => theme.fonts.workSans.p};
    color: ${({ theme }) => theme.colors.grey2};
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
`;

export const ResultsWrapper = styled.div`
  ${mq.custom(
    css`
      overflow-x: auto;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 42px 0;

  .pagination-buttons {
    width: 180px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;

    a {
      padding: 10px;
      color: ${({ theme }) => theme.colors.grey3};
      cursor: pointer;
      font-size: 0.9rem;

      transition: color 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.purpleDefault};
      }
    }
  }

  .pagination-active a {
    color: ${({ theme }) => theme.colors.purpleDefault};
    font-weight: 600;
  }
`;

export const Block = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-image: linear-gradient(
    360deg,
    #ffffff 45.2%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: #fff;

  margin-top: -220px;

  z-index: 10;
`;

export const ReadMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background: #fff;

  margin-top: -100px;

  z-index: 10;
`;
