import styled, { css } from 'styled-components';

import mq from '../../../../styles/mq';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 40px;

  position: relative;
`;

export const IntroWrapper = styled.div`
  width: 100%;
  margin: 0 auto 36px auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mq.custom(
    css`
      text-align: center;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ${mq.custom(
    css`
      margin: 0 20px;
    `,
    { minWidth: 768, maxWidth: 1023 },
  )}


.title-header {
    margin: 36px 0 0 0;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  h3 {
    font-weight: normal;
    font-size: 1.875rem;
    color: ${({ theme }) => theme.colors.grey2};
    letter-spacing: 1px;

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blueMidtone};
    }
  }

  p {
    text-align: center;
    ${({ theme }) => theme.fonts.mulish.h6}
    color: ${({ theme }) => theme.colors.grey2};
  }
`;

export const ResultsWrapper = styled.div`
  ${mq.custom(
    css`
      overflow-x: auto;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 42px 0;

  .pagination-buttons {
    width: 180px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;

    a {
      padding: 10px;
      color: ${({ theme }) => theme.colors.grey3};
      cursor: pointer;
      font-size: 0.9rem;

      transition: color 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.purpleDefault};
      }
    }
  }

  .pagination-active a {
    color: ${({ theme }) => theme.colors.purpleDefault};
    font-weight: 600;
  }
`;

export const Block = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-image: linear-gradient(
    360deg,
    #ffffff 45.2%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: #fff;

  margin-top: -220px;

  z-index: 10;
`;

export const ReadMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  position: absolute;
  bottom: 0;

  background-image: linear-gradient(
    360deg,
    #fff 65%,
    rgba(255, 255, 255, 0) 100%
  );

  margin-top: -100px;

  z-index: 10;
`;
