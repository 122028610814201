/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../../components/Button';
import IndexesHeaders from '../../IndexesHeaders';
import ArticleIndexResultsCard from '../ArticleIndexResultsCard';
import FetchingAnimation from '../../../../../components/FetchingAnimation';
import ErrorAnimation from '../../../../../components/ErrorAnimation';
import LoadingAnimation from '../../../../../components/LoadingAnimaton';
import getClient from '../../../../../services/client/elasticsearch';
import useQueryUrl from '../../../../../hooks/queryUrl';
import { useRankingElastic } from '../../../../../hooks/rankSearch';
import * as S from './styles';

interface ArticlesIndexesTableProps {
  premium: boolean;
  registered: boolean;
  searchRank?: string;
  searchTerm?: string;
  dateSearch?: string[];
  sortSearch?: any;
  setSortSearch?: any;
  pageSize?: number;
  isRanking?: boolean;
}

const ArticlesIndexesTable: React.FC<ArticlesIndexesTableProps> = props => {
  const history = useHistory();
  const query = useQueryUrl();

  const [articleList, setArticleList] = useState([]);
  const [forcePage, setForcePage] = useState<number>(null);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [pageNumber, setPageNumber] = useState(0);

  const pageCount = Math.ceil(totalPages / props.pageSize);

  const defaultSearch = {
    index: 'articles',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      sort: props.sortSearch,
    },
  };
  const customSearch = {
    index: 'articles',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        bool:
          props.dateSearch && props.searchTerm
            ? {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match: {
                            title: props.searchTerm,
                          },
                        },
                        {
                          match: {
                            keywords: props.searchTerm,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: props.dateSearch?.map(year => ({
                        match: { publicationYear: year },
                      })),
                    },
                  },
                ],
              }
            : {
                should: [
                  {
                    bool: {
                      should: [
                        {
                          match: {
                            title: props.searchTerm,
                          },
                        },
                        {
                          match: {
                            keywords: props.searchTerm,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: props.dateSearch?.map(year => ({
                        match: { publicationYear: year },
                      })),
                    },
                  },
                ],
              },
      },
      sort: props.sortSearch,
    },
  };

  const exactMatchSearch = {
    index: 'articles',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        query_string: {
          fields: ['title'],
          query: props.searchTerm,
        },
      },
    },
  };

  const {
    data: articles,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    [
      'articles',
      pageNumber,
      props.searchTerm,
      props.dateSearch,
      props.sortSearch,
    ],
    async () => {
      const response = await getClient().search(
        (props.searchTerm !== '' || props.dateSearch.length > 0) &&
          !props.searchTerm.includes('"')
          ? customSearch
          : (props.searchTerm !== '' || props.dateSearch.length > 0) &&
            props.searchTerm.includes('"')
          ? exactMatchSearch
          : defaultSearch,
      );
      // eslint-disable-next-line no-shadow
      const count = await getClient().count({ index: 'articles' });

      const data = await response.hits.hits;
      const totalData: any = await response.hits.total;

      if (props.searchTerm !== '' || props.dateSearch.length > 0) {
        setTotalResults(totalData.value);
      } else {
        setTotalResults(count.count);
      }
      setArticleList(data);

      setTotalPages(totalData.value);
      return data;
    },
    {
      initialData: [],
    },
  );

  const { data: rank } = useRankingElastic('articles', props.searchRank);

  useMemo(() => {
    if (props.searchTerm) {
      query.set('termo', props.searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [props.searchTerm]);

  useEffect(() => {
    const actualRank = Object.keys(props.sortSearch);
    const cropRank = actualRank
      ?.filter(e => e !== 'total_refs_citation')
      ?.toString()
      ?.split('indexes.');
    const userSearch: any =
      rank.users[0]?._source?.indexes?.[`${cropRank[1]}-RANK`];

    const pageToGo = Math.ceil(userSearch / props.pageSize);
    if (props.isRanking && props.searchRank !== '' && !isNaN(pageToGo)) {
      if (pageToGo < 99999) {
        setPageNumber(pageToGo - 1);
        setForcePage(pageToGo - 1);
        history.push(`/indices?type=artigos&page=${pageToGo}`);
      } else {
        toast.warning('Resultado não encontrado');
      }
    } else return null;
  }, [rank]);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    setTimeout(() => {
      if (currentPage) {
        setPageNumber(currentPageNumber - 1);
        setForcePage(currentPageNumber - 1);
        query.set('page', currentPageNumber.toString());
        window.history.replaceState(
          {},
          '',
          `${location.pathname}?${query.toString()}`,
        );
      }
    }, 100);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const displayResults = articleList.map((article: any, index) => {
    return (
      <ArticleIndexResultsCard
        rank={index + 1 + pageNumber * props.pageSize}
        title={article?._source?.title || article?._source?.DOI}
        uniqId={article?._source?.uniqId}
        index={article?._source?.indexes}
        premium={props?.premium}
        registered={props?.registered}
        isRanking={props.sortSearch?.[Object.keys(props.sortSearch).toString()]}
      />
    );
  });

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            <h3>
              Foram encontrados <span>{totalResults.toLocaleString()}</span>{' '}
              resultados
            </h3>
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <IndexesHeaders
            type="article"
            isRanking={
              props.sortSearch?.[Object.keys(props.sortSearch).toString()]
            }
          />

          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            <ErrorAnimation />
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        {props.premium ? (
          <>
            <S.PaginationContainer>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                forcePage={forcePage}
                onPageChange={changePage}
                containerClassName="pagination-buttons"
                previousLinkClassName="pagination-previous-buttons"
                nextLinkClassName="pagination-next-buttons"
                disabledClassName="pagination-disabled"
                activeClassName="pagination-active"
                onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
              />
            </S.PaginationContainer>
          </>
        ) : (
          <>
            <S.Block>{}</S.Block>
            <S.ReadMore>
              <Button href="/usuario/planos">
                Cadastre-se e veja mais resultados
              </Button>
            </S.ReadMore>
          </>
        )}
      </S.Container>
    </>
  );
};

export default ArticlesIndexesTable;
