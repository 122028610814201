import styled, { css, keyframes } from 'styled-components';
import { theme } from '../../styles/global';
import mq from '../../styles/mq';

const selectOption = keyframes`
from {
  width: 0px;
  height: 0px;
  background:white;
}

to{

  width: 18px;
          height: 18px;
          background: ${theme.colors.purpleDefault};
}




`;

export const MainContainer = styled.section`
  width: 100%;
  min-height: 66vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;

  ${mq.custom(
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const Container = styled.div`
  width: 100%;
  max-width: 596px;

  #birthDate {
    color: #a9b3c4;

    &::-webkit-calendar-picker-indicator {
      filter: opacity(0);
    }
  }

  ${mq.custom(
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  .register-page-header {
    h2 {
      color: ${theme.colors.grey2};
      text-align: center;

      span {
        color: ${theme.colors.purpleDefault};
      }
    }

    p {
      text-align: center;
      margin: 32px 0 76px 0;
      color: ${theme.colors.grey2};
      ${theme.fonts.mulish.p};
    }
  }

  .search-button {
    width: 36px;
    height: 36px;
    background-color: ${theme.colors.purpleDefault};
    border: 0;
    border-radius: 10px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -50px;
    top: 34px;

    &:hover {
      background-color: ${theme.colors.purpleDark};
    }

    path {
      fill: white;
    }
  }

  .row-input {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 21px 0;

    li {
      flex-basis: 250px !important;
      margin: 0 !important;
    }
  }
  form {
    position: relative;
    margin-bottom: 92px;

    p {
      ${theme.fonts.mulish.h6};
      color: ${theme.colors.redDefault};
      position: relative;
    }
    .form-itens {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 22px;

      li {
        flex-basis: 596px;
        flex-grow: 1;
        input {
          width: 100%;
          height: 45px;
          margin-top: 12px;
          border: 1.5px solid #dadee5;
          border-radius: 10px;
          padding: 16px 4px 16px 12px;
          color: #636e6f;
          font-family: 'Work Sans', sans-serif;
          font-weight: normal;
          font-size: 16px;
          outline: none;

          &::placeholder {
            color: #a9b3c4;
          }

          &:focus {
            border: 1.5px solid #17a1a1;
          }
        }

        & + li {
          margin-top: 21px;
        }
      }
    }

    .terms-container {
      display: flex;
      align-items: center;

      input {
        width: 12px;
        height: 12px;
        margin: 0 10px 0 0;
      }

      a {
        color: ${theme.colors.purpleDefault};
        text-decoration: underline;
      }
    }

    label {
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #17a1a1;
    }

    .buttons-section {
      margin-top: 42px;
      display: flex;
      justify-content: center;
      padding: 0 46px;

      button {
        max-width: 102px;
        background: ${theme.colors.purpleDefault};
        border: 0;
        padding: 12px 18px;
        border-radius: 6px;
        ${theme.fonts.mulish.p};
        font-weight: 700;
        color: white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
        transition: 0.3s;

        &:hover {
          background: ${theme.colors.purpleMidtone};
          color: white;
        }

        &:disabled {
          background: rgba(0, 0, 0, 0.3);
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .button-container {
    max-width: 260px;
    opacity: 0.9;
    width: 100%;
    button {
      background: none;
      border: 0;
      position: relative;
      h3 {
        color: ${theme.colors.grey5};
      }

      &::before {
        content: '';
        width: 26px;
        height: 26px;
        position: absolute;
        left: -40px;
        top: 50%;
        transform: translateY(-50%);
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.grey6};
        border-radius: 50%;
      }
    }

    ul {
      margin: 30px 0;
      width: 100%;
      li {
        display: flex;

        align-items: center;
        width: 100%;
        gap: 12px;
        align-items: flex-start;

        & + li {
          margin-top: 10px;
        }

        span {
          max-width: 228px;
          ${theme.fonts.mulish.p};
          color: ${theme.colors.grey5};
        }

        svg {
          font-size: 20px;
          fill: ${theme.colors.grey5};
        }

        p {
          flex: 3;
        }
      }

      .unavaliable-feature {
        color: ${theme.colors.grey5};
        svg {
          fill: ${theme.colors.grey5};
        }
      }
    }
  }

  .button-active {
    opacity: 1;
    button {
      h3 {
        color: ${theme.colors.purpleDefault};
      }
      &::after {
        content: '';
        width: 18px;
        height: 18px;
        background: ${theme.colors.purpleDefault};
        position: absolute;
        left: -35px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        animation: 0.3s ${selectOption} ease;
      }
    }
    ul {
      width: 100%;
      li {
        span {
          color: ${theme.colors.grey2};
        }

        svg {
          fill: ${theme.colors.blueDefault};
        }
        p {
          flex: 3;
        }
      }
    }
  }
`;
