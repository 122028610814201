import styled, { css } from 'styled-components';

import mq from '../../styles/mq';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;

  ${mq.custom(
    css`
      padding: 0 16px;
      justify-content: center;
      align-items: center;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 843px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey2};

  p {
    margin-bottom: 12px;
    display: flex;
  }

  h2 {
    font-size: 30px;
    line-height: 120%;

    text-align: center;
    letter-spacing: 2px;
  }

  h3 {
    font-size: 1.43rem;
    font-weight: 900;
    letter-spacing: 1.65px;
    margin-bottom: 30px;

    span {
      font-weight: bold;
    }

    strong {
      font-family: ${({ theme }) => theme.fonts.workSans.p};
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blueDefault};
      letter-spacing: 0.825px;
    }
  }
`;

export const DetailsCardContent = styled.div`
  border-right: 1.5px solid #dadee5;
  padding: 38px;

  ${mq.tablet(css`
    padding: 0px;
    border-right: none;
    margin: auto;
    width: 100%;
  `)}

  ${mq.mobile(css`
    padding: 0px;
    border-right: none;
    margin: auto;
    width: 100%;
  `)}
`;

export const MoreDetailsContent = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    font-family: ${({ theme }) => theme.fonts.mulish.p};
    width: 224px;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 17px;
  }

  & > button {
    margin-bottom: 21px;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 843px;
  margin: 6px 0 28px 0;
  height: fit-content;

  a {
    &:not(:first-child) {
      margin-left: 22px;
    }
    margin-top: 22px;
  }

  .see-more-author-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DetailsWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;

  ${mq.custom(
    css`
      width: 100%;
      margin: 0 auto;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ${mq.tablet(css`
    width: 100%;
    padding: 0px;
    flex-direction: column;
    margin: auto;
  `)}

  ${mq.mobile(css`
    width: 100%;
    padding: 0px;
    flex-direction: column;
    margin: auto;
  `)}
`;

export const RelatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;
  margin-bottom: 52px;

  h3 {
    font: ${({ theme }) => theme.fonts.mulish.h4};
    font-weight: normal;
    margin-bottom: 28px;
    color: ${({ theme }) => theme.colors.grey2};
    font-size: 25px;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
