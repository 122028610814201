/* eslint-disable no-restricted-globals */
/* eslint-disable use-isnan */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import LoadingAnimation from '../../../../components/LoadingAnimaton';
import FetchingAnimation from '../../../../components/FetchingAnimation';
import Button from '../../../../components/Button';
import AuthorResultCard from '../AuthorResultsCard';
import ErrorAnimation from '../../../../components/ErrorAnimation';
import AuthorsResultsHeader from '../AuthorsResultsHeader';
import getClient from '../../../../services/client/elasticsearch';
import useQueryUrl from '../../../../hooks/queryUrl';
import { usePermission } from '../../../../hooks/permission';
import * as S from './styles';

interface AuthorsResultsTableProps {
  premium: boolean;
  registered: boolean;
  searchRank?: string;
  searchTerm?: string;
  setSearchTerm?: any;
  sortSearch?: any;
  pageSize?: number;
  isRanking?: boolean;
}

const AuthorsResultsTable: React.FC<AuthorsResultsTableProps> = props => {
  const history = useHistory();
  const query = useQueryUrl();
  const { Permissions } = usePermission();
  const [authorList, setAuthorList] = useState([]);
  const [forcePage, setForcePage] = useState<number>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const pageCount = Math.ceil(totalPages / props.pageSize);
  const citationsPermission = Permissions('Result_Citations');

  const defaultSearch = {
    index: 'authors',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      sort: [props.sortSearch],
    },
  };

  const customSearch = {
    index: 'authors',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      sort: [props.sortSearch],
      query: {
        match: {
          name: props.searchTerm,
        },
      },
    },
  };

  const exactMatchSearch = {
    index: 'authors',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        query_string: {
          fields: ['name'],
          query: props.searchTerm,
        },
      },
    },
  };

  const {
    data: authors,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['authors', pageNumber, props.pageSize, props.searchTerm, props.sortSearch],
    async () => {
      const response = await getClient().search(
        props.searchTerm !== '' && !props.searchTerm.includes('"')
          ? customSearch
          : props.searchTerm !== '' && props.searchTerm.includes('"')
          ? exactMatchSearch
          : defaultSearch,
      );
      // eslint-disable-next-line no-shadow
      const count = await getClient().count({ index: 'authors' });

      const data = await response.hits.hits;
      const totalData: any = await response.hits.total;
      if (props.searchTerm !== '') {
        setTotalResults(totalData.value);
      } else {
        setTotalResults(count.count);
      }
      setAuthorList(data);
      setTotalPages(totalData.value);
      return data;
    },
    {
      initialData: [],
    },
  );

  const currentSearch = query.get('termo') || '';
  useEffect(() => {
    if (currentSearch !== '') {
      props.setSearchTerm(currentSearch);
    }
  }, []);

  useMemo(() => {
    if (props.searchTerm) {
      query.set('termo', props.searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [props.searchTerm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    setTimeout(() => {
      if (currentPage) {
        setPageNumber(currentPageNumber - 1);
        setForcePage(currentPageNumber - 1);
        query.set('page', currentPageNumber.toString());
        window.history.replaceState(
          {},
          '',
          `${location.pathname}?${query.toString()}`,
        );
      }
    }, 100);
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const displayResults = authorList?.map((author: any, index) => {
    return (
      <AuthorResultCard
        rank={index + 1 + pageNumber * props.pageSize}
        name={author?._source?.name}
        publishedArticlesCount={author?._source?.publishedArticlesCount}
        ppgs={author?._source?.ppgsRel}
        // citedKeywords={
        //   author?._source?.indexes.length <= 0 && keyWordsPermission
        //     ? ['-']
        //     : author?._source?.indexes
        // }
        citations={citationsPermission && author?._source.total_refs_citation}
        indexes={author?._source?.indexes}
        premium={props.premium}
        setSearchTerm={props.setSearchTerm}
        uniqId={author?._source?.uniqId}
        registered={props.registered}
        isRanking={props.sortSearch?.[Object.keys(props.sortSearch).toString()]}
      />
    );
  });

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            {props.searchTerm !== '' ? (
              <h3>
                Foram encontrados <span>{totalResults.toLocaleString()}</span>{' '}
                resultados
              </h3>
            ) : (
              <h3>
                <span>{totalResults.toLocaleString()}</span> autores foram
                avaliados pela plataforma
              </h3>
            )}
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <AuthorsResultsHeader
            isRanking={
              props.sortSearch?.[Object.keys(props.sortSearch).toString()]
            }
          />
          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            <ErrorAnimation />
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        {props.premium ? (
          <>
            <S.PaginationContainer>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                forcePage={forcePage}
                onPageChange={changePage}
                containerClassName="pagination-buttons"
                previousLinkClassName="pagination-previous-buttons"
                nextLinkClassName="pagination-next-buttons"
                disabledClassName="pagination-disabled"
                activeClassName="pagination-active"
                onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
              />
            </S.PaginationContainer>
          </>
        ) : (
          <>
            <S.Block>{}</S.Block>
            <S.ReadMore>
              <Button>Cadastre-se e veja mais resultados</Button>
            </S.ReadMore>
          </>
        )}
      </S.Container>
    </>
  );
};

export default AuthorsResultsTable;
