import styled, { css } from 'styled-components';

import mq from '../../../../styles/mq';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;

  width: 1366px;
  max-width: 1366px;
  width: 100%;
  margin: auto;
  padding: 10px 49px;

  h3 {
    font-size: 13px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.purpleDefault};
    text-align: left;
  }

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const RankWrapper = styled.div`
  font-size: 14px;
  text-align: left;
  flex: 0.3;
`;
export const IssnWrapper = styled.div`
  max-width: 100px;
  align-items: start;

  flex: 3;

  p {
    color: ${({ theme }) => theme.colors.grey2};
  }

  ${mq.custom(
    css`
      max-width: 100px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ${mq.custom(
    css`
      max-width: 100px;
    `,
    { minWidth: 1023, maxWidth: 1150 },
  )}
`;

interface HeaderBtn {
  enableClick?: boolean;
}

export const HeaderBtn = styled.button<HeaderBtn>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: ${prop => (prop.enableClick ? 'pointer' : 'default')};

  margin-left: 10px;

  h3 {
    margin-right: 8px;
    flex-wrap: wrap;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  align-items: start;

  svg {
    transition: 0.3s;
  }

  &:not(:last-child) {
    margin-right: 14px;
  }

  flex: 1.5;
`;

export const IndexWrapper = styled.div`
  font-size: 14px;
  margin-right: 14px;
  flex: 1;

  p {
    font: ${({ theme }) => theme.fonts.mulish.p};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey2};
  }

  span {
    font-weight: bold;
  }

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: 'center';
  align-items: center;
  height: 100%;

  flex: 1;

  &:not(:first-child) {
    margin-top: 10px;
  }

  ${mq.custom(
    css`
      flex: 1;
    `,
    { minWidth: 769, maxWidth: 1360 },
  )}

  ${mq.custom(
    css`
      flex: 1;
    `,
    { minWidth: 0, maxWidth: 1024 },
  )}
`;

export const Divider = styled.div`
  margin: auto;
  border-bottom: 1.5px solid #dadee5;
  width: 95%;

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;
