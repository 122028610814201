/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import elasticsearch from 'elasticsearch';

function getClient() {
  const client = new elasticsearch.Client({
    // host: 'https://caciq-access:UUhPRya3Z6NL4E4$@search-caciq-gkxt76lmylfm4v7royes4pub2u.us-east-1.es.amazonaws.com',
    host: 'https://caciq:caciQ-2022_n0tisus@search-pcaciq-wxh6u2jlb4am4phmowpabdewla.us-east-1.es.amazonaws.com',
  });

  return client;
}

export default getClient;
