import React, { ReactNode, useState } from 'react';

import Button from '../../../../components/Button';
import { IJournal } from '../../../../models/journal';
import { usePermission } from '../../../../hooks/permission';
import * as S from './styles';

export interface JournalResultCardProps extends IJournal {
  publishedArticles?: number;
  premium?: boolean;
  registered?: boolean;
  rank?: number;
  isRanking?: boolean;
}

const JournalResultCard: React.FC<JournalResultCardProps> = props => {
  const { Permissions } = usePermission();
  const internalResultPermission = Permissions('Result_Internal_Result');

  const ICRPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');
  const HALLPermission = Permissions('Result_Scholar_H4_Index');
  const I10Permission = Permissions('Result_Scholar_Index');
  const QualisPermission = Permissions('Result_Qualis_Index');
  const SJRPermission = Permissions('Result_Scimago_Index');

  const ICR = props?.indexes?.['IC-R'];
  const ICH4 = props?.indexes?.['IC-H4-R'];
  const HALL = props?.indexes?.hAll;
  const H5 = props?.indexes?.h5;
  const SJR = props?.indexes?.sjr;
  const I10All = props?.indexes?.i10All;
  const I10 = props?.indexes?.i105y;
  const Qualis = props?.stratum;

  return (
    <>
      <S.Container>
        <S.Content>
          {props.isRanking && (
            <S.RankWrapper>
              <strong>{props.rank}°</strong>
            </S.RankWrapper>
          )}
          <S.IssnWrapper>
            <p>{props.issn}</p>
          </S.IssnWrapper>

          <S.TagWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <p className="title-content">{props.name}</p>
          </S.TagWrapper>

          <S.TagWrapper alignItems="flex-start" style={{ flex: '0.4' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <p>
                {props?.valuationArea?.length > 0 &&
                props?.valuationArea?.length < 2 ? (
                  props?.valuationArea?.join(', ')
                ) : props?.valuationArea?.length >= 2 ? (
                  `${props?.valuationArea?.slice(0, 2).join(', ')} ...`
                ) : (
                  <div
                    className="not-found"
                    title="Não encontrado nas maiores plataformas do Brasil"
                  />
                )}
              </p>
            </div>
          </S.TagWrapper>

          <S.TagWrapper style={{ flex: '0.8' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <p>
                {props?.publishedArticles ? (
                  props?.publishedArticles?.toLocaleString()
                ) : (
                  <div
                    className="not-found"
                    title="Não encontrado nas maiores plataformas do Brasil"
                  />
                )}
              </p>
            </div>
          </S.TagWrapper>

          <S.IndexWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '8px',
              flex: '1',
            }}
          >
            {ICR != null && ICRPermission ? (
              <p>
                Caciq-r{' '}
                <strong>
                  {Number(ICR) >= 1 ? Number(ICR) : Number(ICR).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}
            {ICH4 != null && ICH4Permission ? (
              <p>
                Caciq-h4R{' '}
                <strong>
                  {Number(ICH4) >= 1 ? Number(ICH4) : Number(ICH4).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}

            {HALL != null && HALLPermission ? (
              <p>
                Web-h4{' '}
                <strong>
                  {Number(HALL) >= 1 ? Number(HALL) : Number(HALL).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}

            {SJR != null && SJRPermission ? (
              <p>
                sjr{' '}
                <strong>
                  {Number(SJR) >= 1 ? Number(SJR) : Number(SJR).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}
            {Qualis != null && QualisPermission ? (
              <p>
                Qualis <strong>{props.stratum}</strong>
              </p>
            ) : (
              ''
            )}
          </S.IndexWrapper>
          {
            // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
          }
          <S.ButtonWrapper>
            <Button
              shadow
              href={`/revista/detalhe/${props.uniqId}`}
              disabled={!internalResultPermission}
            >
              Saiba mais
            </Button>
          </S.ButtonWrapper>
        </S.Content>
      </S.Container>
      <S.Divider />
    </>
  );
};

export default JournalResultCard;
