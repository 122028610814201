import React, { useState } from 'react';
import Button from '../../../../components/Button';
import { IPPG } from '../../../../models/ppg';
import { usePermission } from '../../../../hooks/permission';
import * as S from './styles';

export interface PPGResultsCardProps extends IPPG {
  test?: string;
  premium?: boolean;
  registered?: boolean;
  rank?: number;
  isRanking?: boolean;
}

const PPGResultsCard: React.FC<PPGResultsCardProps> = props => {
  const { Permissions } = usePermission();

  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

  function handleOpenModal() {
    setIsReadMoreOpen(true);
  }

  function handleCloseModal() {
    setIsReadMoreOpen(false);
  }
  const internalResultPermission = Permissions('Result_Internal_Result');

  const ICPPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');

  const ICP = props?.indexes?.['IC-P'];
  const ICH4 = props?.indexes?.['IC-H4-P'];

  return (
    <>
      <S.Container>
        <S.Content>
          {props.isRanking && (
            <S.RankWrapper>
              <strong>{props.rank}°</strong>
            </S.RankWrapper>
          )}
          <S.CourseWrapper>
            <p>{props.name}</p>
          </S.CourseWrapper>

          <S.CourseWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p>
              {props?.university ? (
                props.university
              ) : (
                <div
                  className="not-found"
                  title="Não encontrado nas maiores plataformas do Brasil"
                />
              )}
            </p>
          </S.CourseWrapper>

          <S.CiteWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <p>
                {props?.capesGrade ? (
                  props.capesGrade
                ) : (
                  <div
                    className="not-found"
                    title="Não encontrado nas maiores plataformas do Brasil"
                  />
                )}
              </p>
            </div>
          </S.CiteWrapper>

          <S.TagWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <p>
                {props?.valuationArea ? (
                  props?.valuationArea
                ) : (
                  <div
                    className="not-found"
                    title="Não encontrado nas maiores plataformas do Brasil"
                  />
                )}
              </p>
            </div>
          </S.TagWrapper>

          <S.IndexWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            {ICP != null && ICPPermission ? (
              <p>
                Caciq-p{' '}
                <strong>
                  {Number(ICP) >= 1 ? Number(ICP) : Number(ICP).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}
            {ICH4 != null && ICH4Permission ? (
              <p>
                Caciq-h4P{' '}
                <strong>
                  {Number(ICH4) >= 1 ? Number(ICH4) : Number(ICH4).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}
          </S.IndexWrapper>
          {
            // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
          }
          <S.ButtonWrapper>
            <Button
              shadow
              href={`/ppg/detalhe/${props.uniqId}`}
              disabled={!internalResultPermission}
            >
              Saiba mais
            </Button>
          </S.ButtonWrapper>
        </S.Content>
      </S.Container>
      <S.Divider />
    </>
  );
};

export default PPGResultsCard;
