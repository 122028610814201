import styled, { css } from 'styled-components';
import { theme } from '../../styles/global';

export const MainContainer = styled.section`
  padding-bottom: 49px;
  padding-top: 200px;
`;

export const PerfilSection = styled.section`
  width: 100%;
  max-width: 1206px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .perfil-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;

    .pending-plan {
      max-width: 400px;
      padding: 16px 20px;
      background: rgba(245, 213, 103, 0.4);
      border-radius: 20px;
      span {
        ${theme.fonts.mulish.p};
        color: ${theme.colors.grey2};
        margin-bottom: 1em;
        display: block;
      }

      p {
        ${theme.fonts.mulish.h6};
        margin-top: 1em;
        display: block;
      }
    }
  }
  .user-information {
    h2 {
      color: ${theme.colors.grey2};
    }
    span {
      ${theme.fonts.mulish.h6};

      color: ${theme.colors.grey2};
    }
  }
`;

export const AccountContainer = styled.section`
margin-top: 4em;
padding: 0 15px;


.plan-title{
  color: ${theme.colors.grey2};

}

.account-configuration{
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}


.plan-information{
  display: flex;
  align-items: flex-end;
  gap:30px;

  h6 {
    color: ${theme.colors.grey5};
    ${theme.fonts.mulish.h6};
    span {
      color: ${theme.colors.grey5};
      ${theme.fonts.mulish.h5};
      max-width: 150px;
      overflow-x: auto;
    }
  }
}

.subscription-cards {
  flex-basis: 300px;
  flex-grow: 1;
}

.subscription-details {
  ul {
    li {
      padding: 20px 0;
      & + li {
        border-top: 1px solid ${theme.colors.grey6};
      }




      .cancel-subscription {
        margin-top: 1em;
        border: 0;
        border-bottom: 1px solid;
        background: none;
        color: ${theme.colors.redMidtone};

        transition: 0.3s;

        &:hover {
          color: ${theme.colors.redDark};
        }
      }
      .procede-action{
        display: flex;
        gap:36px;
        margin-top: 10px;

        button {
          border: 0;
          /* border-bottom: 1px solid; */
          background: none;
          color: ${theme.colors.redMidtone};

          transition: 0.3s;

          &:hover {
            color: ${theme.colors.redDark};
          }
        }


      }
    }
  }


  .price-value{

    h1 {
      ${theme.fonts.mulish.h1};
      color: ${theme.colors.grey2};
    }
    span {
      ${theme.fonts.mulish.p};
      display: block;
      margin-bottom: 10px;
    }
  }


}
}

.subscription-actions {
  flex-basis: 100px;

  li {
    padding: 22px 0;

span{

  ${theme.fonts.mulish.p}
  color: ${theme.colors.grey3}
}

    & + li {
      border-top: 1px solid ${theme.colors.grey6};
    }
  }
  .cancel-subscription {
    margin-top: 3em;
    border: 0;
    border-bottom: 1px solid;
    background: none;
    color: ${theme.colors.redMidtone};

    transition: 0.3s;

    &:hover {
      color: ${theme.colors.redDark};
    }
  }

  `;
