/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Loading } from './styles';

export const LoadingAnimation = () => {
  return (
    <Loading>
      <span style={{ animationDelay: '0.1s' }} />
      <span style={{ animationDelay: '0.3s' }} />
      <span style={{ animationDelay: '0.6s' }} />
      <span style={{ animationDelay: '0.9s' }} />
    </Loading>
  );
};

export default LoadingAnimation;
