import React, { useEffect, useState } from 'react';
import CtaBanner from '../../components/CtaBanner';
import SearchBar from '../../components/SearchBar';
import { usePermission } from '../../hooks/permission';
import AdvancedFilters from '../../components/AdvancedFilters';
import PPGsResultsTable from './components/PPGsResultsTable';
import Section from '../../components/Section';
import * as S from './styles';

const PPGsResultsScreen: React.FC = () => {
  const { Permissions } = usePermission();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchRank, setSearchRank] = useState('');
  const [sortSearch, setSortSearch] = useState({
    'indexes.IC-P': { order: 'desc' },
  });

  const [pageSize, setPageSize] = useState<any>(5);
  const [isRanking, setIsRanking] = useState(false);
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const permission = Permissions('Search_PPG');

  useEffect(() => {
    if (searchTerm !== '' || searchRank !== '') {
      setOpenAdvancedFilter(true);
    }
  }, [searchTerm, searchRank]);

  useEffect(() => {
    setSearchRank('');
  }, [sortSearch]);
  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <Section />

          <SearchBar
            setSearchTerm={setSearchTerm}
            permission={permission}
            isRanking={isRanking}
            setPageSize={setPageSize}
            setIsRanking={setIsRanking}
            setSearchRank={setSearchRank}
            barType="ppg"
          />

          <AdvancedFilters
            ppgs
            setSortSearch={setSortSearch}
            sortSearch={sortSearch}
            isOpen={openAdvancedFilter}
            isRanking={isRanking}
            setPageSize={setPageSize}
          />
        </S.IntroWrapper>
        <PPGsResultsTable
          registered
          premium
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          sortSearch={sortSearch}
          setSortSearch={setSortSearch}
          isRanking={isRanking}
          pageSize={pageSize}
          searchRank={searchRank}
        />
      </S.Container>
      <CtaBanner isNews />
    </>
  );
};

export default PPGsResultsScreen;
