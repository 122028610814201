import styled, { css } from 'styled-components';
import { VerifyProfileProps } from '.';

import mq from '../../styles/mq';

export const UnverifyedContainer = styled.div<VerifyProfileProps>`
  width: ${props => (props.cardType === 'author' ? '294px' : '654px')};
  height: ${props => (props.cardType === 'author' ? '421px' : '251px')};

  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border: 1.5px solid #dadee5;

  padding: 25px;
  text-align: center;

  ${mq.tablet(css`
    margin-bottom: 20px;
  `)}

  p {
    font-family: ${({ theme }) => theme.fonts.mulish.p};
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.grey2};

    margin-bottom: 20px;

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.purpleDefault};
    }
  }

  a {
    margin: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
      object-fit: cover;
    }
  }
`;

export const VeriFyedAuthorContainer = styled.div<VerifyProfileProps>`
  width: ${props => (props.cardType === 'author' ? '294px' : '654px')};
  height: ${props => (props.cardType === 'author' ? '421px' : 'auto')};

  p {
    overflow-wrap: break-word;
    a {
      font-family: ${({ theme }) => theme.fonts.mulish.p};
      color: ${({ theme }) => theme.colors.grey2};
      &:hover {
        color: ${({ theme }) => theme.colors.purpleDefault};
      }
    }
  }

  ${mq.custom(
    css`
      width: 100%;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ${mq.tablet(css`
    margin-bottom: 20px;
  `)}

  h6 {
    margin-top: ${props => (props.cardType === 'author' ? '49px' : '28px')};
    color: ${({ theme }) => theme.colors.blueMidtone};
  }

  div {
    border-bottom: 1.5px solid #dadee5;
    padding: 13px 22px;
    color: ${({ theme }) => theme.colors.grey2};
  }

  section {
    margin: auto 0;
  }
`;

export const SocialContainer = styled.li<VerifyProfileProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.isPpgAuthor ? 'space-between' : 'flex-start'};
  align-items: center;
  margin: 0 auto;
  margin-top: 27px;

  div {
    width: 244px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
  }

  a {
    width: 40px;
    height: 40px;

    background: #fff;
    border: 1px solid ${({ theme }) => theme.colors.grey6};
    border-radius: 3px;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3.15574px 12.623px rgba(0, 0, 0, 0.1);
  }
`;
