/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/search-right.svg';
import { ReactComponent as CaciqLogo } from '../../assets/caciq-small-logo.svg';
import Button from '../Button';
import * as S from './styles';

interface PopUpProps {
  setPopUpState: () => void;
  isOpen: boolean;
  description?: string;
}

const WelcomePopUp = ({
  setPopUpState,
  isOpen,
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
}) => {
  const popUpRef = useRef(null);
  const handleClick = (e: MouseEvent) => {
    if ((popUpRef.current! as any).contains(e.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <S.MainContainer>
      <S.PopUp ref={popUpRef}>
        <div className="pop-up-body">
          <CaciqLogo />
          <h4>Bem-vindo a plataforma Caciq</h4>

          <p>{description}</p>
        </div>

        <div className="buttons-section-popup">
          <Button>Ir para a pesquisa</Button>

          <a href="https://google.com">Complete seu perfil</a>
        </div>
      </S.PopUp>
    </S.MainContainer>
  );
};

export default WelcomePopUp;
