import styled from 'styled-components';
import { theme } from '../../../../styles/global';

export const Container = styled.div`
  width: 100%;
  min-height: 205px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f2f2f2;
  }

  .author-data {
    .section-title {
      max-width: 530px;
      margin: 56px auto 32px auto;

      h3 {
        margin-bottom: 12px;
      }
      h3,
      h6 {
        text-align: center;
        color: ${theme.colors.grey2};

        a {
          color: ${theme.colors.purpleDefault};
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  min-height: 205px;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;

  .buttons-column {
    display: flex;
    gap: 12px;

    button {
      ${theme.fonts.mulish.h6}
    }
  }

  ul {
    display: flex;
    gap: 20px;
    padding-bottom: 17px;
    border-bottom: 1.5px solid #dadee5;

    li {
      width: calc(100% / 5);
      font: 13px Mulish, sans-serif;
      font-weight: 600;
      color: ${theme.colors.purpleDefault};
    }
  }

  .content-section {
    padding: 48px 0;
    ul {
      li {
        ${theme.fonts.workSans.p}
        font-weight: 600;
        color: ${theme.colors.grey2};

        p {
          ${theme.fonts.workSans.p}
          font-weight: 600;
          color: ${theme.colors.blueDefault};
        }
      }
    }
  }
`;
