/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { ReactComponent as EditPen } from '../../assets/pen-icon.svg';
import { ReactComponent as PerfilPhoto } from '../../assets/avatar.svg';
import Button from '../../components/Button';
import PeridicIndex from './components/PeridicIndex';
import { useAuth } from '../../hooks/auth';
import CtaBanner from '../../components/CtaBanner';
import SwitchComponent from '../../components/Switch';
import { useSocial } from '../../hooks/socials';
import getClient from '../../services/client/elasticsearch';
import UploadFile from '../../components/uploadPictureInput';
import { api } from '../../services/api';

import * as S from './styles';
import { useError } from '../../hooks/errors';

interface FormInputs {
  email: string;
  university: string;
  oficialSite: string;
  coordinator: string;
  avaliation: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  twitter: string;
}

const PPGProfile = () => {
  const [editPerfil, setEditPerfil] = useState(false);
  const { user, updateProfile } = useAuth();
  const [newPic, setNewPic] = useState(user.avatar);

  const social = user.socials;

  const { data: userElastic }: any = useQuery(
    ['user', user],
    async () => {
      const response = await getClient().search({
        index: 'ppgs',
        size: 5,
        body: {
          query: {
            match: { userId: user?.id },
          },
        },
      });

      return response.hits.hits;
    },
    {
      initialData: [],
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(user);
  console.log(userElastic);

  return (
    <S.MainContainer>
      <S.PerfilSection>
        <div className="picture-container">
          {editPerfil ? (
            <UploadFile newFile={newPic} setNewFile={setNewPic} />
          ) : null}

          {newPic === null || newPic === '' || newPic === 'none' ? (
            <PerfilPhoto />
          ) : (
            <img src={newPic} alt="perfil picturer" />
          )}
        </div>

        <section className="perfil-container">
          <div className="user-information">
            <h2>{user.name}</h2>

            <div className="edit-column">
              <p>Programa de Pós graduação</p>

              <button type="button" onClick={() => setEditPerfil(!editPerfil)}>
                Editar perfil <EditPen />
              </button>
            </div>
          </div>

          <FormSection
            inputProps={editPerfil}
            socialFields={social}
            publicId={userElastic[0]?._source?.uniqId}
            user={user}
            profilePicture={newPic || user.avatar}
            updateProfile={updateProfile}
          />
        </section>
      </S.PerfilSection>

      {userElastic[0]?._source?.authorsRel?.length > 0 && (
        <section className="author-data">
          <PeridicIndex
            user={user}
            userList={userElastic[0]?._source?.authorsRel?.map(ids => ids.id)}
          />
        </section>
      )}

      <CtaBanner />
    </S.MainContainer>
  );
};

const FormSection = ({
  inputProps,
  profilePicture,
  socialFields = [],
  updateProfile,
  user,
  publicId,
}) => {
  const { register, handleSubmit } = useForm<FormInputs>();
  const { emitError } = useError();

  const email: any = socialFields.filter(e => e.name === 'email');
  const universityName: any = socialFields.filter(
    e => e.name === 'universityName',
  );
  const universitySite: any = socialFields.filter(
    e => e.name === 'universitySite',
  );
  const coordinatorPPG: any = socialFields.filter(
    e => e.name === 'coordinatorPPG',
  );
  const valuationArea: any = socialFields.filter(
    e => e.name === 'valuationArea',
  );
  const linkedin: any = socialFields.filter(e => e.name === 'linkedin');

  const facebook: any = socialFields.filter(e => e.name === 'facebook');
  const instagram: any = socialFields.filter(e => e.name === 'instagram');
  const twitter: any = socialFields.filter(e => e.name === 'twitter');

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      const social = [];

      if (data.email)
        social.push({
          name: 'email',
          url: data.email.toString(),
          public: email[0]?.public || false,
        });
      if (data.university)
        social.push({
          name: 'universityName',
          url: data.university.toString(),
          public: universityName[0]?.public || false,
        });
      if (data.oficialSite)
        social.push({
          name: 'universitySite',
          url: data.oficialSite.toString(),
          public: universitySite[0]?.public || false,
        });
      if (data.coordinator)
        social.push({
          name: 'coordinatorPPG',
          url: data.coordinator.toString(),
          public: coordinatorPPG[0]?.public || false,
        });
      if (data.avaliation)
        social.push({
          name: 'valuationArea',
          url: data.avaliation.toString(),
          public: valuationArea[0]?.public || false,
        });
      if (data.linkedin)
        social.push({
          name: 'linkedin',
          url: data.linkedin.toString(),
          public: linkedin[0]?.public || false,
        });
      if (data.facebook)
        social.push({
          name: 'facebook',
          url: data.facebook.toString(),
          public: facebook[0]?.public || false,
        });
      if (data.instagram)
        social.push({
          name: 'instagram',
          url: data.instagram.toString(),
          public: instagram[0]?.public || false,
        });
      if (data.twitter)
        social.push({
          name: 'twitter',
          url: data.twitter.toString(),
          public: twitter[0]?.public || false,
        });

      const fullData = {
        CPF: '0000000000',
        birthDate: '0000',
        avatar: profilePicture || 'none',
        email: data.email,
      };

      const postProfile = await api.put('/profile', fullData);

      social.forEach(async item => {
        const post = await api.patch('/profile/social', item);
      });
      toast.success('Perfil Atualizado');

      updateProfile({ ...fullData, socials: social } as any);

      window.location.reload();
    } catch (error: any) {
      emitError(error, 'Erro ao atualizar perfil');
    }
  };

  const sortedFields = [
    email.length > 0
      ? { ...email[0] }
      : {
          name: 'email',
          url: user.email || '',
          public: false,
        },
    universityName.length > 0
      ? { ...universityName[0] }
      : {
          name: 'universityName',
          url: '',
          public: false,
        },
    universitySite.length > 0
      ? { ...universitySite[0] }
      : {
          name: 'universitySite',
          url: '',
          public: false,
        },
    coordinatorPPG.length > 0
      ? { ...coordinatorPPG[0] }
      : {
          name: 'coordinatorPPG',
          url: '',
          public: false,
        },
    valuationArea.length > 0
      ? { ...valuationArea[0] }
      : {
          name: 'valuationArea',
          url: '',
          public: false,
        },
    linkedin.length > 0
      ? { ...linkedin[0] }
      : {
          name: 'linkedin',
          url: '',
          public: false,
        },
    facebook.length > 0
      ? { ...facebook[0] }
      : {
          name: 'facebook',
          url: '',
          public: false,
        },
    instagram.length > 0
      ? { ...instagram[0] }
      : {
          name: 'instagram',
          url: '',
          public: false,
        },
    twitter.length > 0
      ? { ...twitter[0] }
      : {
          name: 'twitter',
          url: '',
          public: false,
        },
  ];

  const switchData = [...sortedFields];

  return (
    <S.UserDetails inputProps={inputProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="user-informations-editable">
          <li>
            <label htmlFor="email">E-mail</label>
            <input
              name="name"
              type="text"
              id="email"
              {...register('email')}
              defaultValue={user.email}
            />
          </li>
          <li>
            <label htmlFor="university">Nome da Universidade</label>
            <input
              name="name"
              type="text"
              id="university"
              {...register('university')}
              defaultValue={universityName[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="oficialSite">Site oficial</label>
            <input
              name="name"
              type="text"
              id="oficialSite"
              {...register('oficialSite')}
              defaultValue={universitySite[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="coordinator">Coordenador do Curso</label>
            <input
              name="name"
              type="text"
              id="coordinator"
              {...register('coordinator')}
              defaultValue={coordinatorPPG[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="avaliation">Área de avaliação</label>
            <input
              name="name"
              type="text"
              id="avaliation"
              {...register('avaliation')}
              defaultValue={valuationArea[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="linkedin">Linkedin</label>
            <input
              name="name"
              type="text"
              id="linkedin"
              {...register('linkedin')}
              defaultValue={linkedin[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="facebook">Facebook</label>
            <input
              name="name"
              type="text"
              id="facebook"
              {...register('facebook')}
              defaultValue={facebook[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="instagram">Instagram</label>
            <input
              name="name"
              type="text"
              id="instagram"
              {...register('instagram')}
              defaultValue={instagram[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="twitter">Twitter</label>
            <input
              name="name"
              type="text"
              id="twitter"
              {...register('twitter')}
              defaultValue={twitter[0]?.url}
            />
          </li>
        </ul>

        <div className="form-buttons">
          <Button typeOf="submit">Salvar as alterações</Button>
          {publicId && (
            <Button href={`/ppg/detalhe/${publicId}`} ghost>
              Visualizar perfil público
            </Button>
          )}
        </div>
      </form>
      <ul className="switchers-column">
        {switchData.map(item => (
          <li className="can-appear">
            <SwitchComponent
              name={item.name}
              url={item?.url}
              check={item?.public}
            />
          </li>
        ))}
      </ul>
    </S.UserDetails>
  );
};

export default PPGProfile;
