/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { User } from '../models/user';
import { api } from '../services/api';

interface AuthState {
  token: string;
  user: User;
}

export interface AuthContextData {
  signed: boolean;
  loading: boolean;
  user: User | null;
  signIn(email: string, password: string, remember: boolean): Promise<void>;
  orcidSignIn(orcidToken: string): Promise<void>;
  signOut(): void;
  updateProfile(user: Partial<User>): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider: React.FC = ({ children }: any) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('caciq/token');
    const user: User = JSON.parse(localStorage.getItem('caciq/user'));

    if (user && token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      return { token, user };
    }

    return {} as AuthState;
  });

  const [loading, setLoading] = useState(false);

  const orcidSignIn = async (orcidToken: string) => {
    try {
      setLoading(true);

      const response = await api.post(`/orcid/session?token=${orcidToken}`);

      const { user, token } = response.data as unknown as {
        user: User;
        token: string;
      };
      api.defaults.headers.Authorization = `Bearer ${token}`;
      localStorage.setItem('caciq/token', token);
      localStorage.setItem('caciq/user', JSON.stringify(user));

      setData({ token, user });

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error('Erro ao efetuar login');
    }
  };

  const signIn: any = async (
    email: string,
    password: string,
    remember: boolean,
  ) => {
    try {
      setLoading(true);

      const response = await api.post('/sessions', { email, password });
      setLoading(false);

      const { user, token } = response.data as unknown as {
        user: User;
        token: string;
      };

      api.defaults.headers.Authorization = `Bearer ${token}`;
      if (remember) {
        localStorage.setItem('caciq/token', token);
        localStorage.setItem('caciq/user', JSON.stringify(user));
      } else {
        localStorage.setItem('caciq/token', token);
        localStorage.setItem('caciq/user', JSON.stringify(user));
        setTimeout(() => {
          localStorage.removeItem('caciq/token');
          localStorage.removeItem('caciq/user');
        }, 1000 * 120);
      }

      setData({ token, user });
      return { token, user };
    } catch (error: any) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message || 'Não foi possível efetuar o login',
      );
    }
  };

  const signOut = () => {
    localStorage.removeItem('caciq/token');
    localStorage.removeItem('caciq/user');
    delete api.defaults.headers.Authorization;
    if (!data?.user || !data?.token) return;
    window.location.replace('/');
    toast.info('Logout efetuado com sucesso!');
    setData({} as AuthState);
  };

  useEffect(() => {
    if (!data?.user) return;
    localStorage.setItem('caciq/user', JSON.stringify(data.user));
  }, [data.user]);

  const updateProfile = (user: Partial<User>) => {
    const newUserData = { ...data.user, ...user };
    setData(oldData => ({ ...oldData, user: newUserData }));
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!data?.user,
        loading,
        user: data.user,
        orcidSignIn,
        signIn,
        signOut,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  return context;
}
