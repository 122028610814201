/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import * as S from './styles';

interface Headerprops {
  isRanking?: boolean;
}

const ArticleResultsHeader = ({ isRanking = false }: Headerprops) => {
  const HeaderTitles = {
    col1: 'Título',
    col2: 'Autores',
    // col3: 'Palavras-chave',
    col4: 'Citações',
    col6: 'Índices',
  };

  return (
    <>
      <S.Container>
        {isRanking && (
          <S.RankWrapper>
            <h3>Ranque</h3>
          </S.RankWrapper>
        )}
        <S.TitleWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col1}</h3>
          </S.HeaderBtn>
        </S.TitleWrapper>

        <S.TagWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col2}</h3>
          </S.HeaderBtn>
        </S.TagWrapper>

        {
          // Palavra-chave desativado a pedido do Dr. Emerson
          // definido na reunião presencial dia 17/10/2022
          //
          // <S.TagWrapper>
          //   <S.HeaderBtn type="button">
          //     <h3>{HeaderTitles.col3}</h3>
          //   </S.HeaderBtn>
          // </S.TagWrapper>
        }

        <S.TagWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col4}</h3>
          </S.HeaderBtn>
        </S.TagWrapper>

        <S.IndexWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col6}</h3>
          </S.HeaderBtn>
        </S.IndexWrapper>

        <S.ButtonWrapper />
      </S.Container>
      <S.Divider />
    </>
  );
};

export default ArticleResultsHeader;
