import styled from 'styled-components';

import BgImage from '../../assets/FundoExterno.png';

export const MainContainer = styled.section`
  width: 100%;
  min-height: 46vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 700px;

  h2 {
    ${({ theme }) => theme.fonts.mulish.h2};
    color: ${({ theme }) => theme.colors.grey2};

    margin-bottom: 42px;
    text-align: center;

    span {
      color: ${({ theme }) => theme.colors.purpleDefault};
    }
  }

  .popup-header {
    h2 {
      margin: 12px 0;
      text-align: center;
      color: ${({ theme }) => theme.colors.grey2};
    }
    p {
      ${({ theme }) => theme.fonts.mulish.p};
      margin-bottom: 20px;
      text-align: center;
      color: ${({ theme }) => theme.colors.grey4};
      span {
        color: ${({ theme }) => theme.colors.purpleDefault};
      }
    }
  }
  form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 20px;
    margin: 4em 0 12px 0;

    .input-container {
      flex: 1;
      input {
        width: 100%;
        height: 43px;
        margin-top: 12px;
        border: 1.5px solid #dadee5;
        border-radius: 10px;
        padding: 16px 4px 16px 12px;
        color: #636e6f;
        font-family: 'Work Sans', sans-serif;
        font-weight: normal;
        font-size: 16px;
        outline: none;

        &::placeholder {
          color: #a9b3c4;
        }

        &:focus {
          border: 1.5px solid #17a1a1;
        }
      }

      p {
        ${({ theme }) => theme.fonts.mulish.h6};
        color: ${({ theme }) => theme.colors.redDefault};
        position: relative;
      }
    }

    label {
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #17a1a1;
    }
  }

  .re-send-email {
    display: flex;
    justify-content: space-between;
    button {
      border: 0;
      background: none;
      color: ${({ theme }) => theme.colors.purpleDefault};
      border-bottom: 1px solid;
    }
  }
`;
