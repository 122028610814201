import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/global';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;
export const MainContainer = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;
export const PopUp = styled.div`
  width: 100%;
  max-width: 648px;
  min-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 6%;
  position: absolute;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  animation: 0.4s ${entranceAnim} ease;

  iframe {
    width: 100%;
    min-height: 600px;
    border: 0;
  }
`;
