/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import SelectInput from '../Select';
import * as S from './styles';

interface FilterProps {
  setNewDate?: any;
}

export const YearFilter = ({ setNewDate }: FilterProps) => {
  const [checkQuadriennium, setCheckQuadriennium] = useState(true);
  const [checkYear, setCheckYear] = useState(false);
  return (
    <S.Container>
      <div className="search-types">
        <input
          type="radio"
          id="quadriennium"
          name="yearFilter"
          checked={checkQuadriennium}
          onChange={() => {
            setCheckYear(false);
            setCheckQuadriennium(true);
          }}
        />
        <label htmlFor="quadriennium" className="radio" />
        <label htmlFor="quadriennium">Pesquisar por quadriênio</label>

        <SelectInput date customFunction={setNewDate} />
      </div>
      <div className="search-types">
        <input
          type="radio"
          id="year"
          name="yearFilter"
          checked={checkYear}
          onChange={() => {
            setCheckYear(true);
            setCheckQuadriennium(false);
          }}
        />
        <label htmlFor="year" className="radio" />

        <label htmlFor="year">Pesquisar por ano:</label>

        <SelectInput year customFunction={setNewDate} />
      </div>
    </S.Container>
  );
};

export default YearFilter;
