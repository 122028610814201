import styled, { css } from 'styled-components';
import { theme } from '../../styles/global';
import mq from '../../styles/mq';

export const MainContainer = styled.section`
  padding-bottom: 49px;
  padding-top: 200px;
`;

interface PerfilProps {
  inputProps: boolean;
}

export const PerfilSection = styled.section<PerfilProps>`
  width: 100%;
  max-width: 926px;
  margin: 0 auto;
  display: flex;

  #birthdate {
    color: #a9b3c4;

    &::-webkit-calendar-picker-indicator {
      filter: opacity(0);
    }
  }

  .picture-container {
    width: 266px;
    height: 266px;
    border-radius: 50%;
    border: solid white 16px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25);

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .perfil-container {
    flex: 1;
    padding-left: 15px;
  }

  .user-information {
    h2 {
      color: ${theme.colors.grey1};
    }

    p {
      color: ${theme.colors.grey2};
    }
  }

  .edit-column {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    button {
      background: none;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.grey2};

      svg {
        margin-left: 13px;
      }
    }

    &::before {
      content: '';
      height: 1.5px;
      background: ${theme.colors.purpleDefault};
      right: 0px;
      left: -30px;
      bottom: 0px;
      position: absolute;
      z-index: 1;
    }
  }

  .row-input {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 21px 0;

    li {
      flex-basis: 250px !important;
      margin: 0 !important;
    }
  }

  form {
    position: relative;
    margin-top: 28px;

    .email-field {
      position: relative;

      .confirm-email {
        border: 0;
        background: none;
      }

      .alert-icon {
        position: absolute;
        color: ${theme.colors.redLight};
        font-size: 20px;
        bottom: 6px;
        right: 20px;
      }
    }

    .user-informations-editable {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 22px;

      label {
        pointer-events: ${props => (!props.inputProps ? 'none' : '')};
      }

      li {
        flex-basis: 596px;
        flex-grow: 1;
        input {
          width: 100%;
          height: 45px;
          margin-top: 12px;
          border: 1.5px solid #dadee5;
          border-radius: 10px;
          padding: 16px 4px 16px 12px;
          color: #636e6f;
          font-family: 'Work Sans', sans-serif;
          font-weight: normal;
          font-size: 16px;
          outline: none;

          &::placeholder {
            color: #a9b3c4;
          }

          &:focus {
            border: 1.5px solid #17a1a1;
          }
        }

        & + li {
          margin-top: 21px;
        }
      }

      li {
        input {
          ${props => {
            if (!props.inputProps) {
              return css`
                background: none;
                border-color: theme.colors.grey6;
                border-radius: 0px;
                border-width: 0px 0px 1px 0px;
                border-style: solid;
                pointer-events: none;
              `;
            }
            return null;
          }}
        }
      }
    }

    label {
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #17a1a1;
    }

    button[type='submit'] {
      margin: 0 auto;
      display: block;
      background: ${theme.colors.purpleDefault};
      border: 0;
      padding: 12px 18px;
      border-radius: 6px;
      ${theme.fonts.mulish.p};
      font-weight: 700;
      color: white;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
      transition: 0.3s;

      &:hover {
        background: ${theme.colors.purpleMidtone};
        color: white;
      }
    }
  }
`;

export const SignaturePlan = styled.section`
  width: 100%;
  max-width: 926px;
  margin: 68px auto 0px auto;

  h3,
  p {
    text-align: center;
  }

  .signature-header {
    h3 {
      color: ${theme.colors.grey2};
    }
    p {
      max-width: 523px;
      margin: 12px auto 0 auto;

      color: ${theme.colors.grey3};
    }
  }

  .plans-column {
    margin-top: 54px;
    display: flex;
    gap: 32px;
    justify-content: center;
    align-items: flex-start;
  }
`;
