import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/global';

const selectOption = keyframes`
from {
  width: 0px;
  height: 0px;
  background:white;
}

to{

  width: 18px;
          height: 18px;
          background: ${theme.colors.purpleDefault};
}




`;
const animationEntrance = keyframes`
from {
opacity: 0;
}

to{

 opacity: 1;
}



`;

export const MainContainer = styled.section`
  width: 100%;
  min-height: 66vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;

  .add-code-btn {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const Container = styled.div`
  width: 100%;
  max-width: 596px;
  animation: 0.5s ease ${animationEntrance};

  form {
    vertical-align: top;
    margin-bottom: 78px;

    .card-li {
      .card-input {
        height: 45px;
        background-color: #fff;
        border: 1.5px solid #dadee5;
        border-radius: 10px;
        margin-top: 12px;
        padding: 0px 10px;

        &:focus {
          border: 1.5px solid #17a1a1;
        }
      }
    }

    #card_validity {
      color: #a9b3c4;

      &::-webkit-calendar-picker-indicator {
        filter: opacity(0);
      }
    }

    .form-buttons {
      display: flex;
      margin-top: 76px;
      justify-content: center;
    }

    .two-row-input {
      display: flex;
      align-items: flex-start;
      flex: 1;
      justify-content: space-between;
      gap: 22px;

      div {
        margin-top: 0px !important;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      li {
        flex-basis: 700px;
        flex-grow: 1;
        label {
          display: block;
          font-family: 'Mulish', sans-serif;
          font-size: 12px;
          line-height: 120%;
          letter-spacing: 1px;
          color: #17a1a1;
        }

        input {
          width: 100%;
          height: 45px;
          margin-top: 12px;
          border: 1.5px solid #dadee5;
          border-radius: 10px;
          padding: 16px 4px 16px 12px;
          color: #636e6f;
          font-family: 'Work Sans', sans-serif;
          font-weight: normal;
          font-size: 16px;
          outline: none;

          &::placeholder {
            color: #a9b3c4;
          }

          &:focus {
            border: 1.5px solid #17a1a1;
          }
        }
      }
    }
  }

  .form-header {
    h2 {
      color: ${theme.colors.grey2};
      text-align: center;

      span {
        color: ${theme.colors.purpleDefault};
      }
    }

    p {
      text-align: center;
      margin: 32px 0 76px 0;
      color: ${theme.colors.grey2};
      ${theme.fonts.mulish.p};
    }
  }

  .button-section {
    display: flex;
    justify-content: center;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;

  .button-container {
    opacity: 0.3;
    button {
      background: none;
      border: 0;
      position: relative;
      h3 {
        color: ${theme.colors.grey5};
      }

      &::before {
        content: '';
        width: 26px;
        height: 26px;
        position: absolute;
        left: -40px;
        top: 50%;
        transform: translateY(-50%);
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.grey6};
        border-radius: 50%;
      }
    }

    .price-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        color: ${theme.colors.grey5};

        span {
          ${theme.fonts.mulish.h4}
          color: ${theme.colors.grey5};
        }
      }

      p {
        ${theme.fonts.workSans.p};
        color: ${theme.colors.grey5};
      }
    }
  }

  .button-active-month {
    opacity: 1;
    button {
      h3 {
        color: ${theme.colors.blueMidtone};
      }
      &::after {
        content: '';
        width: 18px;
        height: 18px;
        background: ${theme.colors.blueMidtone};
        position: absolute;
        left: -35px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        animation: 0.3s ${selectOption} ease;
      }
    }
    .price-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        color: ${theme.colors.grey2};

        span {
          ${theme.fonts.mulish.h4}
          color: ${theme.colors.blueMidtone};
        }
      }

      p {
        ${theme.fonts.workSans.p};
        color: ${theme.colors.grey1};
      }
    }
  }
  .button-active-year {
    opacity: 1;
    button {
      h3 {
        color: ${theme.colors.purpleDefault};
      }
      &::after {
        content: '';
        width: 18px;
        height: 18px;
        background: ${theme.colors.purpleDefault};
        position: absolute;
        left: -35px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        animation: 0.3s ${selectOption} ease;
      }
    }
    .price-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        color: ${theme.colors.grey2};

        span {
          ${theme.fonts.mulish.h4}
          color: ${theme.colors.purpleDefault};
        }
      }

      p {
        ${theme.fonts.workSans.p};
        color: ${theme.colors.grey1};
        span {
          color: ${theme.colors.purpleDefault};
        }
      }
    }
  }
`;

export const ShortCodeContainer = styled.section`
  width: 100%;
  max-width: 596px;
  min-height: 100px;
  padding: 10 20px;
  animation: 0.5s ease ${animationEntrance};

  label {
    font-family: 'Mulish', sans-serif;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #17a1a1;
  }

  form {
    margin-top: 12px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  input {
    width: 100%;
    height: 42px;
    border: 1.5px solid #dadee5;
    border-radius: 10px;
    padding: 16px 4px 16px 12px;
    color: #636e6f;
    font-family: 'Work Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    outline: none;

    &::placeholder {
      color: #a9b3c4;
    }

    &:focus {
      border: 1.5px solid #17a1a1;
    }
  }
`;

export const GreetingsContainer = styled.section`
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      margin: 2em 0 20px 0;
      color: ${theme.colors.grey2};
    }

    p {
      color: ${theme.colors.grey2};
    }
  }

  .buttons-container {
    margin-top: 2em;
    justify-content: center;
    display: flex;
    gap: 30px;

    button {
      gap: 12px;

      svg {
        fill: white;
      }

      &:nth-child(1) {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`;
