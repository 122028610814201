import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const FooterWrapper = styled.div`
  width: 100%;
`;

export const StyledFooter = styled.footer`
  width: 100%;
  height: 316px;
  background: ${({ theme }) => theme.colors.grey8};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mq.custom(
    css`
      height: auto;
      justify-content: center;
      padding-bottom: 30px;
    `,
    { minWidth: 0, maxWidth: 1090 },
  )}
`;

export const FooterContainer = styled.div`
  width: 997px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mq.custom(
    css`
      justify-content: center;
      flex-direction: column;
      width: 100%;
    `,
    { minWidth: 0, maxWidth: 1090 },
  )}
`;

export const LogoContainer = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    ${({ theme }) => theme.fonts.mulish.p};
    color: ${({ theme }) => theme.colors.grey3};
  }

  ${mq.custom(
    css`
      margin: 30px 0;
    `,
    { minWidth: 0, maxWidth: 1090 },
  )}
`;

export const NavContainer = styled.div`
  width: 100%;
  margin-left: 119px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${mq.custom(
    css`
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      margin-left: 0;

      ul {
        margin-top: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    `,
    { minWidth: 0, maxWidth: 1090 },
  )}
`;

export const NavTitle = styled.h1`
  ${({ theme }) => theme.fonts.mulish.h5};

  color: ${({ theme }) => theme.colors.purpleDefault};
  margin-bottom: 23px;
`;

export const LinkItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  a {
    ${({ theme }) => theme.fonts.workSans.p};
    color: ${({ theme }) => theme.colors.grey3};
  }

  .email {
    ${({ theme }) => theme.fonts.workSans.p};
    font-weight: 600;
  }

  ${mq.custom(
    css`
      margin: 20px 0px;
    `,
    { minWidth: 0, maxWidth: 1090 },
  )}
`;

export const SocialContainer = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 154px;

  margin-bottom: 50px;

  a {
    width: 25px;
    height: 25px;

    background: #fff;
    border: 1px solid ${({ theme }) => theme.colors.grey6};
    border-radius: 3px;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3.15574px 12.623px rgba(0, 0, 0, 0.1);
  }
`;

export const LinkTerms = styled.li`
  font-size: 14px;
  margin-bottom: 10px;

  a {
    ${({ theme }) => theme.fonts.workSans.p};
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

export const Credits = styled.div`
  left: 0;
  height: 26px;
  width: 100%;
  background: red;
  bottom: 0;

  background: ${({ theme }) => theme.colors.purpleDark};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  ${({ theme }) => theme.fonts.workSans.p}
  font-size: 12px;

  p:not(:first-child) {
    margin-left: 30px;
    ${mq.custom(
      css`
        margin-left: 0;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}
  }

  a {
    color: ${({ theme }) => theme.colors.blueDefault};
  }

  ${mq.custom(
    css`
      flex-direction: column;
      height: auto;
      padding: 10px 0;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;
