import styled, { css } from 'styled-components';
import { theme } from '../../../../styles/global';
import mq from '../../../../styles/mq';

export const Analise = styled.div`
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  background: white;
  border-radius: 22px;
  box-shadow: 0px 5.63694px 22.5478px rgba(0, 0, 0, 0.1);
  border: 1.69108px solid #dadee5;
  padding: 30px 120px;

  h2 {
    color: ${theme.colors.grey2};
    color: ${theme.colors.purpleDefault};
    text-align: center;
  }

  button {
    margin: 27px auto 0 auto;
  }

  ${mq.custom(
    css`
      padding: 30px 16px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;
