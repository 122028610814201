/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/search-right.svg';
import Button from '../Button';
import Selector from './components/selector';
import * as S from './styles';
import LoadingAnimation from '../LoadingAnimaton';
import { useUsersElastic } from '../../hooks/useUsersElastic';
import { useUser } from '../../hooks/user';

interface PropsFilter {
  setPopUpState?: any;
  isOpen?: boolean;
  handleNewValue?: any;
  filterTable?: boolean;
  userType?: string;
}

const PopUpFindName = React.forwardRef(
  (
    {
      setPopUpState,
      userType = '',
      isOpen = false,
      filterTable = false,
      handleNewValue,
    }: PropsFilter,
    ref: any,
  ) => {
    const [userList, setUserList] = useState([]);
    const [formName, setFormName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const popUpRef = useRef(null);
    const inputRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchValue, setSearchValue] = useState('');

    const { data: users } = useUsersElastic(userType, searchTerm);

    useEffect(() => {
      if (!users?.users?.length) return setIsLoading(true);
      return setIsLoading(false);
    }, [users, isLoading]);

    useEffect(() => {
      console.log(users);
      setUserList(users?.users);
    }, [users]);

    useEffect(() => {
      setSearchValue(formName?.label || '');
    }, [formName]);

    function handleValue() {
      if (ref && !handleNewValue) {
        ref.value = formName;
      } else if (handleNewValue) {
        handleNewValue(formName);
        setPopUpState(false);
        setSearchTerm('');
      }
      return null;
    }

    const handleClick = (e: MouseEvent) => {
      if ((popUpRef.current! as any).contains(e.target)) {
        return;
      }
      setPopUpState(false);
      setSearchTerm('');
    };

    useEffect(() => {
      if (isOpen) {
        document.addEventListener('mousedown', handleClick);
      } else {
        document.removeEventListener('mousedown', handleClick);
      }

      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, [isOpen]);

    function handleSearch(data) {
      event.preventDefault();
      setSearchTerm(data.target[0].value);
    }

    return (
      <S.MainContainer>
        <S.PopUp ref={popUpRef} formProp={filterTable}>
          <form className="search-bar" onSubmit={handleSearch}>
            <input
              type="text"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            <button type="submit">
              <SearchIcon />
            </button>
          </form>

          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <div className="names-list">
              <ul>
                {userList.map((e, index) => (
                  <li style={{ animationDelay: `${0.5 + index / 8}s` }}>
                    <Selector
                      items={e}
                      setNewValue={setFormName}
                      formName={formName}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="buttons-section-popup">
            <Button onClick={handleValue}>
              {filterTable ? 'Filtrar' : 'Selecionar'}
            </Button>

            <Button onClick={() => setPopUpState(false)} ghost>
              Cancelar
            </Button>
          </div>
        </S.PopUp>
      </S.MainContainer>
    );
  },
);

export default PopUpFindName;
