import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/Button';
import ContentTag from '../../../../../components/ContentTag';
import ModalContent from '../../../../../components/ModalContent';
import { IJournal } from '../../../../../models/journal';
import { usePermission } from '../../../../../hooks/permission';
import * as S from './styles';

export interface JournalIndexResultsCardprops extends IJournal {
  premium?: boolean;
  registered?: boolean;
  rank?: number;
  isRanking?: boolean;
  currentSort?: string;
}

const JournalIndexResultsCard: React.FC<JournalIndexResultsCardprops> =
  props => {
    const history = useHistory();
    const { Permissions } = usePermission();

    const RedirectTo = () => {
      history.push('/usuario/planos');
    };
    const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

    function handleOpenModal() {
      setIsReadMoreOpen(true);
    }

    function handleCloseModal() {
      setIsReadMoreOpen(false);
    }

    const internalResultPermission = Permissions('Result_Internal_Result');

    const ICRPermission = Permissions('Result_Caciq_Index');
    const ICH4Permission = Permissions('Result_Caciq_H4_Index');
    const SJRPermission = Permissions('Result_Scimago_Index');
    const HALLPermission = Permissions('Result_Scholar_H4_Index');
    const QualisPermission = Permissions('Result_Qualis_Index');

    const ICR = props?.indexes?.['IC-R'];
    const ICH4 = props?.indexes?.['IC-H4-R'];
    const SJR = props?.indexes?.sjr;
    const HALL = props?.indexes?.hAll;
    const I10 = props?.indexes?.i10All;

    const ICRRank = props?.indexes?.['IC-R-RANK'];
    const ICH4rank = props?.indexes?.['IC-H4-R-RANK'];
    const SJRRank = props?.indexes?.['sjr-RANK'];
    const H5Rank = props?.indexes?.['h5-RANK'];
    const I10Rank = props?.indexes?.['i10All-RANK'];

    return (
      <>
        <S.Container>
          <S.Content>
            {props.isRanking && (
              <S.RankWrapper>
                <strong>{props.rank}°</strong>
              </S.RankWrapper>
            )}
            <S.IssnWrapper>
              <p>{props?.issn}</p>
            </S.IssnWrapper>

            <S.TitleWrapper>
              <p>{props?.name}</p>
            </S.TitleWrapper>

            {props.currentSort?.includes?.('IC-R') ||
            props.currentSort === '' ? (
              <>
                {ICR != null && ICRPermission ? (
                  <S.IndexWrapper>
                    <p>
                      <strong>
                        {Number(ICR) >= 1
                          ? Number(ICR)
                          : Number(ICR).toFixed(3)}
                      </strong>
                      {ICRRank ? (
                        <span>
                          ranque:{' '}
                          {props.currentSort.includes('iC-R')
                            ? props.rank
                            : ICRRank}
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </S.IndexWrapper>
                ) : (
                  <S.IndexWrapper>
                    <p>
                      <div
                        className="not-found"
                        title="Não encontrado nas maiores plataformas do Brasil"
                      />
                    </p>
                  </S.IndexWrapper>
                )}
              </>
            ) : null}

            {props.currentSort?.includes?.('IC-H4-R') ||
            props.currentSort === '' ? (
              <>
                {ICH4 != null && ICH4Permission ? (
                  <S.IndexWrapper>
                    <p>
                      <strong>
                        {Number(ICH4) >= 1
                          ? Number(ICH4)
                          : Number(ICH4).toFixed(3)}
                      </strong>
                      {ICH4rank ? (
                        <span>
                          ranque:{' '}
                          {props.currentSort.includes('IC-H4-R')
                            ? props.rank
                            : ICH4rank}
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </S.IndexWrapper>
                ) : (
                  <S.IndexWrapper>
                    <p>
                      <div
                        className="not-found"
                        title="Não encontrado nas maiores plataformas do Brasil"
                      />
                    </p>
                  </S.IndexWrapper>
                )}
              </>
            ) : null}

            {props.currentSort?.includes?.('sjr') ||
            props.currentSort === '' ? (
              <>
                {SJR != null && SJRPermission ? (
                  <S.IndexWrapper>
                    <p>
                      <strong>
                        {Number(SJR) >= 1
                          ? Number(SJR)
                          : Number(SJR).toFixed(3)}
                      </strong>
                      {SJRRank ? (
                        <span>
                          ranque:{' '}
                          {props.currentSort.includes('sjr')
                            ? props.rank
                            : SJRRank}
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </S.IndexWrapper>
                ) : (
                  <S.IndexWrapper>
                    <p>
                      <div
                        className="not-found"
                        title="Não encontrado nas maiores plataformas do Brasil"
                      />
                    </p>
                  </S.IndexWrapper>
                )}
              </>
            ) : null}

            {props.currentSort?.includes?.('stratum') ||
            props.currentSort === '' ? (
              <>
                {props.stratum != null && QualisPermission ? (
                  <S.IndexWrapper>
                    <p>
                      <strong>{props.stratum}</strong>
                    </p>
                  </S.IndexWrapper>
                ) : (
                  <S.IndexWrapper>
                    <p>
                      <div
                        className="not-found"
                        title="Não encontrado nas maiores plataformas do Brasil"
                      />
                    </p>
                  </S.IndexWrapper>
                )}
              </>
            ) : null}

            {props.currentSort?.includes?.('H5') || props.currentSort === '' ? (
              <>
                {HALL != null && HALLPermission ? (
                  <S.IndexWrapper>
                    <p>
                      <strong>
                        {Number(HALL) >= 1
                          ? Number(HALL)
                          : Number(HALL).toFixed(3)}
                      </strong>
                      {H5Rank ? (
                        <span>
                          ranque:{' '}
                          {props.currentSort.includes('H5')
                            ? props.rank
                            : H5Rank}
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </S.IndexWrapper>
                ) : (
                  <S.IndexWrapper>
                    <p>
                      <div
                        className="not-found"
                        title="Não encontrado nas maiores plataformas do Brasil"
                      />
                    </p>
                  </S.IndexWrapper>
                )}
              </>
            ) : null}

            <S.ButtonWrapper>
              {
                // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
              }
              {props?.premium && props?.registered ? (
                <>
                  <Button
                    shadow
                    href={`/revista/detalhe/${props?.uniqId}`}
                    disabled={!internalResultPermission}
                  >
                    Saiba mais
                  </Button>
                </>
              ) : props?.registered ? (
                /* Popup assinatura */
                <Button shadow onClick={handleOpenModal}>
                  Saiba mais
                </Button>
              ) : (
                /* Popup registro */
                <Button shadow onClick={handleOpenModal}>
                  Saiba mais
                </Button>
              )}
            </S.ButtonWrapper>
          </S.Content>
        </S.Container>
        <S.Divider />

        {props?.registered === false ? (
          <ModalContent
            modalType="alert"
            alertContent={{
              icon: 'alert',
              title:
                'Cadastre-se e ganhe 14 dias grátis para ver esse conteúdo.',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              buttonDefaultText: 'Cadastre-se',
              buttonSecondaryText: 'Voltar a navegação',
              onClickDefault: RedirectTo,

              onClickGhost: handleCloseModal,
            }}
            isOpen={isReadMoreOpen}
            onRequestClose={handleCloseModal}
          />
        ) : (
          <ModalContent
            modalType="alert"
            alertContent={{
              icon: 'alert',
              title: 'Ops! Você precisa ser assinante para ver este conteúdo.',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              buttonDefaultText: 'Ver planos de assinatura',
              buttonSecondaryText: 'Voltar a navegação',
              onClickDefault: RedirectTo,

              onClickGhost: handleCloseModal,
            }}
            isOpen={isReadMoreOpen}
            onRequestClose={handleCloseModal}
          />
        )}
      </>
    );
  };

export default JournalIndexResultsCard;
