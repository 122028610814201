/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import * as S from './styles';

interface AdvancedFilterProps {
  sortSearch: any;
  setSortSearch: any;
  isOpen?: boolean;
  article?: boolean;
  author?: boolean;
  journal?: boolean;
  ppgs?: boolean;
  isRanking?: boolean;
  setPageSize?: any;
  indexPage?: boolean;
}

const rankingSelector = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 250,
    label: '250',
  },
  {
    value: 500,
    label: '500',
  },
];

const authorOptions = [
  {
    value: {
      total_refs_citation: { order: 'desc' },
    },
    label: 'N° de citações',
  },
  {
    value: {
      'indexes.IC-A.raw': { order: 'desc' },
    },
    label: 'Caciq-a',
  },
  {
    value: {
      'indexes.IC-H4-A': { order: 'desc' },
    },
    label: 'Caciq-h4A',
  },
  {
    value: {
      'indexes.i10All': { order: 'desc' },
    },
    label: 'Web-h',
  },
  {
    value: {
      'indexes.hAll': { order: 'desc' },
    },
    label: 'Web-h4',
  },
];

const journalOptions = [
  {
    value: {
      'indexes.IC-R.raw': { order: 'desc' },
    },
    label: 'Caciq-r',
  },
  {
    value: {
      'indexes.IC-H4-R': { order: 'desc' },
    },
    label: 'Caciq-h4R',
  },
  // {
  //   value: {
  //     'indexes.hAll': { order: 'desc' },
  //   },
  //   label: 'Web-h4',
  // },
  // {
  //   value: {
  //     'indexes.h5': { order: 'desc' },
  //   },
  //   label: ' Web-h5',
  // },

  // {
  //   value: {
  //     'indexes.sjr': { order: 'desc' },
  //   },
  //   label: 'sjr',
  // },
];

const ppgOptions = [
  {
    value: {
      'indexes.IC-P': { order: 'desc' },
    },
    label: 'Caciq-p',
  },
  {
    value: {
      'indexes.IC-H4-P': { order: 'desc' },
    },
    label: 'Caciq-h4P',
  },
];

const articleFilters = [
  {
    value: {
      total_refs_citation: { order: 'desc' },
    },
    label: 'N° de citações',
  },
  {
    title: 'Priorizar maior índice Caciq - t',
    value: {
      'indexes.IC-T.raw': { order: 'desc' },
    },
    label: 'Caciq-t',
  },
];

export const AdvancedFilters = ({
  setSortSearch,
  sortSearch,
  isOpen = false,
  article,
  author,
  journal,
  ppgs,
  isRanking = false,
  setPageSize,
  indexPage = false,
}: AdvancedFilterProps) => {
  const [openFilter, setOpenFilter] = useState(isOpen);

  useEffect(() => {
    setOpenFilter(isOpen);
  }, [isOpen]);

  return (
    <S.Container>
      <S.FilterWrap isOpen={indexPage || openFilter}>
        <ul>
          {article && (
            <ArticleFilter
              sortSearch={sortSearch}
              setSortSearch={setSortSearch}
              indexPage={indexPage}
            />
          )}

          {author && (
            <AuthorFilter
              sortSearch={sortSearch}
              setSortSearch={setSortSearch}
              indexPage={indexPage}
            />
          )}

          {journal && (
            <JournalFilter
              sortSearch={sortSearch}
              setSortSearch={setSortSearch}
            />
          )}
          {ppgs && (
            <PPGFilter sortSearch={sortSearch} setSortSearch={setSortSearch} />
          )}
          {isRanking && <RankingSelector setPageSize={setPageSize} />}
        </ul>
      </S.FilterWrap>

      {!indexPage && (
        <div className="open-btn">
          <button type="button" onClick={() => setOpenFilter(!openFilter)}>
            {openFilter ? 'Ver Menos' : 'Filtros de Resultado'}
          </button>
        </div>
      )}
    </S.Container>
  );
};

const ArticleFilter = ({ sortSearch, setSortSearch, indexPage }) => {
  const [isChecked, setIsChecked] = useState(true);
  const [indexValue, setIndexValue] = useState<any>({
    'indexes.IC-T.raw': { order: 'desc' },
  });

  const mainIndex = {
    'indexes.IC-T.raw': { order: 'desc' },
  };

  useEffect(() => {
    setSortSearch(mainIndex);
  }, []);

  const [newDelete, setNewDelete] = useState('indexes.IC-T.raw');

  const handleChange = e => {
    if (isChecked) {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
      setSortSearch({ ...sortSearch, ...e.value });
    } else {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
    }
  };
  useEffect(() => {
    const deleteName = Object.keys(indexValue).toString();
    setNewDelete(deleteName);
  }, [indexValue]);

  useEffect(() => {
    setSortSearch(indexValue);
    setIndexValue(mainIndex);
  }, []);

  return (
    <>
      <li>
        <SwitchButton
          value={indexValue}
          setIsChecked={setIsChecked}
          check={isChecked}
          deleteProp={newDelete}
          sortValue={sortSearch}
          setSortValue={setSortSearch}
        />
        <span>Ranqueamento por</span>

        <Select
          options={indexPage ? articleFilters.slice(1, 5) : articleFilters}
          defaultValue={articleFilters[1]}
          onChange={e => handleChange(e)}
        />
      </li>
    </>
  );
};
const AuthorFilter = ({ sortSearch, setSortSearch, indexPage }) => {
  const mainIndex = {
    'indexes.IC-A.raw': { order: 'desc' },
  };

  const [indexValue, setIndexValue] = useState<any>({
    'indexes.IC-A.raw': { order: 'desc' },
  });
  const [newDelete, setNewDelete] = useState('indexes.IC-A.raw');

  const [isChecked, setIsChecked] = useState(true);

  const handleChange = e => {
    if (isChecked) {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
      setSortSearch({ ...sortSearch, ...e.value });
    } else {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
    }
  };

  useEffect(() => {
    const deleteName = Object.keys(indexValue).toString();
    setNewDelete(deleteName);
  }, [indexValue]);

  useEffect(() => {
    setSortSearch(indexValue);
    setIndexValue(mainIndex);
  }, []);

  return (
    <>
      <li>
        <SwitchButton
          value={indexValue}
          setIsChecked={setIsChecked}
          check={isChecked}
          deleteProp={newDelete}
          sortValue={sortSearch}
          setSortValue={setSortSearch}
        />
        <span>Ranqueamento por</span>

        <Select
          options={indexPage ? authorOptions.slice(1, 5) : authorOptions}
          defaultValue={authorOptions[1]}
          onChange={e => handleChange(e)}
        />
      </li>
    </>
  );
};

const PPGFilter = ({ sortSearch, setSortSearch }) => {
  const mainIndex = {
    'indexes.IC-P': { order: 'desc' },
  };

  const [indexValue, setIndexValue] = useState({
    'indexes.IC-P': { order: 'desc' },
  });

  const [newDelete, setNewDelete] = useState('indexes.IC-P');

  const [isChecked, setIsChecked] = useState(true);

  const handleChange = e => {
    if (isChecked) {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
      setSortSearch({ ...sortSearch, ...e.value });
    } else {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
    }
  };
  useEffect(() => {
    const deleteName = Object.keys(indexValue).toString();
    setNewDelete(deleteName);
  }, [indexValue]);

  useEffect(() => {
    setSortSearch(indexValue);
    setIndexValue(mainIndex);
  }, []);

  return (
    <>
      <li>
        <SwitchButton
          value={indexValue}
          setIsChecked={setIsChecked}
          check={isChecked}
          deleteProp={newDelete}
          sortValue={sortSearch}
          setSortValue={setSortSearch}
        />
        <span>Ranqueamento por</span>

        <Select
          options={ppgOptions}
          defaultValue={ppgOptions[0]}
          onChange={e => handleChange(e)}
        />
      </li>
    </>
  );
};

const JournalFilter = ({ sortSearch, setSortSearch }) => {
  const mainIndex = {
    'indexes.IC-R.raw': { order: 'desc' },
  };

  const [indexValue, setIndexValue] = useState({
    'indexes.IC-R.raw': { order: 'desc' },
  });

  const [newDelete, setNewDelete] = useState('indexes.IC-R.raw');

  const [isChecked, setIsChecked] = useState(true);

  const handleChange = e => {
    if (isChecked) {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
      setSortSearch({ ...sortSearch, ...e.value });
    } else {
      delete sortSearch?.[newDelete];
      setSortSearch({ ...sortSearch });
      setIndexValue(e.value);
    }
  };

  useEffect(() => {
    const deleteName = Object.keys(indexValue).toString();
    setNewDelete(deleteName);
  }, [indexValue]);

  useEffect(() => {
    setSortSearch(indexValue);
    setIndexValue(mainIndex);
  }, []);
  return (
    <>
      <li>
        <SwitchButton
          value={indexValue}
          setIsChecked={setIsChecked}
          check={isChecked}
          deleteProp={newDelete}
          sortValue={sortSearch}
          setSortValue={setSortSearch}
        />
        <span>Ranqueamento por</span>

        <Select
          options={journalOptions}
          defaultValue={journalOptions[0]}
          onChange={e => handleChange(e)}
        />
      </li>
    </>
  );
};

const RankingSelector = ({ setPageSize }) => {
  return (
    <>
      <li>
        <span>Top</span>
        <Select
          options={rankingSelector}
          defaultValue={rankingSelector[0]}
          onChange={e => setPageSize(e.value)}
        />
      </li>
    </>
  );
};

const SwitchButton = ({
  value,
  sortValue,
  setSortValue,
  deleteProp,
  check = false,
  setIsChecked,
}: any) => {
  const [checked, setChecked] = useState(check);

  useEffect(() => {
    if (check) setChecked(true);
    if (!check) setChecked(false);
  }, [check]);

  const setNewFilter = () => {
    setChecked(!checked);

    if (setIsChecked) setIsChecked(!check);

    if (!checked) {
      setSortValue({ ...sortValue, ...value });
    } else {
      delete sortValue?.[deleteProp];
      setSortValue({ ...sortValue });
    }
  };

  return (
    <Switch
      checked={checked}
      onChange={setNewFilter}
      checkedIcon={false}
      uncheckedIcon={false}
      onColor="#1AA"
      onHandleColor="#9FF"
      offColor="#ABC"
      offHandleColor="#DDE"
      activeBoxShadow="0 0 1px 1px #1AA"
    />
  );
};

export default AdvancedFilters;
