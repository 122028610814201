/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import LoadingAnimation from '../../../../components/LoadingAnimaton';
import JournalResultCard from '../JournalResultsCard';
import JournalsResultsHeader from '../JournalsResultsHeader';
import getClient from '../../../../services/client/elasticsearch';
import FetchingAnimation from '../../../../components/FetchingAnimation';
import ErrorAnimation from '../../../../components/ErrorAnimation';
import useQueryUrl from '../../../../hooks/queryUrl';
import { useRankingElastic } from '../../../../hooks/rankSearch';
import * as S from './styles';

interface tableProps {
  searchTerm?: string;
  dateSearch?: any;
  sortSearch?: any;
  pageSize?: number;
  isRanking?: boolean;
  setSearchTerm?: any;
}

const searchSource = [
  'name',
  'issn',
  'valuationArea',
  'indexes',
  'stratum',
  'uniqId',
  'articlesCount',
];

const JournalsResultsTable = ({
  searchTerm = '',
  setSearchTerm,
  dateSearch = [],
  sortSearch,
  pageSize,
  isRanking = false,
}: tableProps) => {
  const query = useQueryUrl();
  const [journalList, setJournalList] = useState([]);
  const [forcePage, setForcePage] = useState<number>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(totalPages / pageSize);

  const defaultSearch = {
    index: 'journals',
    from: pageNumber * pageSize,
    size: pageSize,
    _source: searchSource,
    body: {
      sort: [sortSearch],
    },
  };

  const customSearch = {
    index: 'journals',
    from: pageNumber * pageSize,
    size: pageSize,
    _source: searchSource,
    body: {
      query: {
        bool:
          dateSearch && searchTerm
            ? {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match: {
                            name: searchTerm,
                          },
                        },
                        {
                          match: {
                            issn: searchTerm,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: dateSearch?.map(year => ({
                        match: { 'articlesRel.publicationYear': year },
                      })),
                    },
                  },
                ],
              }
            : {
                should: [
                  {
                    bool: {
                      should: [
                        {
                          match: {
                            name: searchTerm,
                          },
                        },
                        {
                          match: {
                            issn: searchTerm,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: dateSearch?.map(year => ({
                        match: { 'articlesRel.publicationYear': year },
                      })),
                    },
                  },
                ],
              },
      },
      sort: [sortSearch],
    },
  };

  const exactMatchSearch = {
    index: 'journals',
    from: pageNumber * pageSize,
    size: pageSize,
    _source: searchSource,
    body: {
      query: {
        query_string: {
          fields: ['name'],
          query: searchTerm,
        },
      },
    },
  };
  const {
    data: journals,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(
    ['journals', pageNumber, searchTerm, dateSearch, sortSearch, pageSize],
    async () => {
      const response = await getClient().search(
        (searchTerm !== '' || dateSearch.length > 0) &&
          !searchTerm.includes('"')
          ? customSearch
          : (searchTerm !== '' || dateSearch.length > 0) &&
            searchTerm.includes('"')
          ? exactMatchSearch
          : defaultSearch,
      );
      const data = await response.hits.hits;
      setJournalList(data);
      const totalData: any = await response.hits.total;
      setTotalPages(totalData.value);
      return data;
    },
    {
      initialData: [],
    },
  );

  const currentSearch = query.get('termo') || '';
  useEffect(() => {
    if (currentSearch !== '') {
      setSearchTerm(currentSearch);
    }
  }, []);

  useMemo(() => {
    if (searchTerm) {
      query.set('termo', searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [searchTerm]);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    setTimeout(() => {
      if (currentPage) {
        setPageNumber(currentPageNumber - 1);
        setForcePage(currentPageNumber - 1);
        query.set('page', currentPageNumber.toString());
        window.history.replaceState(
          {},
          '',
          `${location.pathname}?${query.toString()}`,
        );
      }
    }, 100);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 100);
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const displayResults = journalList.map((journal: any, index) => {
    return (
      <JournalResultCard
        rank={index + 1 + pageNumber * pageSize}
        name={journal?._source?.name}
        issn={journal?._source?.issn}
        valuationArea={journal?._source?.valuationArea}
        publishedArticles={journal?._source?.articlesCount?.toLocaleString()}
        indexes={journal?._source?.indexes}
        uniqId={journal?._source?.uniqId}
        stratum={journal?._source?.stratum}
        isRanking={sortSearch?.[Object.keys(sortSearch).toString()]}
      />
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            {searchTerm !== '' ? (
              <h3>
                Foram encontrados <span>{totalPages.toLocaleString()}</span>{' '}
                resultados
              </h3>
            ) : (
              <h3>
                <span>{totalPages.toLocaleString()}</span> Periódicos foram
                avaliados pela plataforma
              </h3>
            )}
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <JournalsResultsHeader
            isRanking={sortSearch?.[Object.keys(sortSearch).toString()]}
          />
          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            <ErrorAnimation />
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        <S.PaginationContainer>
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            pageCount={pageCount}
            forcePage={forcePage}
            onPageChange={changePage}
            containerClassName="pagination-buttons"
            previousLinkClassName="pagination-previous-buttons"
            nextLinkClassName="pagination-next-buttons"
            disabledClassName="pagination-disabled"
            activeClassName="pagination-active"
            onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
          />
        </S.PaginationContainer>
      </S.Container>
    </>
  );
};

export default JournalsResultsTable;
