/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import getClient from '../services/client/elasticsearch';

export function useUsersVerified(search: string, searchTerm = '') {
  return useQuery(
    ['users', search, searchTerm],
    () => useUsersE(search, searchTerm),
    {
      initialData: { users: [], total: 0 },
    },
  );
}

async function useUsersE(search, searchTerm = '') {
  const defaultSearch = {
    index: search,
    size: 50,
    body: {
      query: {
        exists: {
          field: 'userId',
        },
      },
    },
  };
  const customSearch = {
    index: search,
    size: 50,
    body: {
      query: {
        bool: {
          must: [
            {
              exists: {
                field: 'userId',
              },
            },
            {
              match: {
                name: searchTerm,
              },
            },
          ],
        },
      },
    },
  };

  console.log(searchTerm);

  const response = await getClient().search(
    searchTerm !== '' ? customSearch : defaultSearch,
  );
  const data = await response.hits.hits;
  const totalData: any = await response.hits.total;
  return { users: data, total: totalData?.value };
}
