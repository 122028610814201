import styled, { css } from 'styled-components';

import mq from '../../styles/mq';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
`;

export const JournalWrapper = styled.div`
  max-width: 950px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey2};
  margin-bottom: 42px;

  ${mq.mobile(css`
    margin-bottom: 12px;
    flex-direction: column;
  `)}

  p {
    margin-bottom: 12px;
  }

  h2 {
    font-size: 40px;
    line-height: 120%;

    text-align: center;
    letter-spacing: 2px;
  }
`;

export const ImageWrapper = styled.div`
  margin-right: 70px;

  ${mq.mobile(css`
    margin-right: 0px;
  `)}

  img, .perfil-photo {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #bdbdbd;
    border-radius: 100%;
    border: 15px solid #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    width: 275px;
    height: 275px;
    object-fit: cover;

    ${mq.mobile(css`
      width: 190px;
      height: 190px;
      margin-bottom: 22px;
    `)}
  }
`;

export const JournalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.mobile(css`
    width: 100%;
    padding: 20px;
    align-items: center;
    text-align: center;
  `)}

  .NameWrapper {
    width: 100%;
    border-bottom: solid 1.5px ${({ theme }) => theme.colors.purpleDefault};
    text-align: left;

    h2 {
      line-height: 3.75rem;
      text-align: left;

      ${mq.mobile(css`
        text-align: center;
      `)}
    }
  }

  .IndexWrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 21px;
    flex-wrap: wrap;
    column-gap: 32px;

    h5 {
      font-size: 0.85rem;
      font-weight: 900;
      letter-spacing: 1.65px;
      width: 1005;
      margin-bottom: 30px;

      span {
        font-weight: bold;
      }

      strong {
        font-family: ${({ theme }) => theme.fonts.workSans.p};
        font-weight: bold;
        color: ${({ theme }) => theme.colors.blueDefault};
        letter-spacing: 0.825px;
      }
    }
  }

  .OccupationArea {
    h6 {
      font-size: 0.75rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.purpleDefault};
      margin-bottom: 9px;
    }
  }
`;

export const DetailsCardContent = styled.div`
  border-left: 1.5px solid #dadee5;
  padding: 0 0 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.custom(
    css`
      border-left: none;
      width: 100%;
      margin: auto;
    `,
    { minWidth: 0, maxWidth: 1024 },
  )}

  .smallCardContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    ${mq.custom(
      css`
        padding: 0px;
        border-right: none;
        margin: auto;
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
      `,
      { minWidth: 768, maxWidth: 1024 },
    )}

    ${mq.mobile(css`
      padding: 0px;
      border-right: none;
      margin: auto;
      flex-direction: column;
    `)}
  }
`;

export const MoreDetailsContent = styled.div`
  padding: 0 28px;
  display: flex;
  height: 100%;

  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > span {
    font-family: ${({ theme }) => theme.fonts.mulish.p};
    width: 224px;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 17px;
  }

  & > button {
    margin-bottom: 21px;
  }

  ${mq.custom(
    css`
      width: 100%;
      align-items: center;
    `,
    { minWidth: 0, maxWidth: 1024 },
  )}
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 843px;
  margin: 6px 0 28px 0;
  height: fit-content;

  a {
    &:not(:first-child) {
      margin-left: 22px;
    }
    margin-top: 22px;
  }
`;

export const DetailsWrapper = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1037px;

  ${mq.tablet(css`
    padding: 20px;
    flex-direction: column;
    width: 100%;
  `)}

  ${mq.mobile(
    css`
      padding: 0px;
      flex-direction: column;
      width: 100%;
      margin: 0 20px;
    `,
  )}
`;

export const RelatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;
  margin-bottom: 52px;

  h3 {
    font: ${({ theme }) => theme.fonts.mulish.h4};
    font-weight: normal;
    margin-bottom: 28px;
    color: ${({ theme }) => theme.colors.grey2};
    font-size: 25px;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  max-width: 1270px;
  margin: auto;
  padding: 0 20px;

  display: flex;

  flex-direction: row;
  justify-content: center;

  ${mq.custom(
    css`
      flex-wrap: wrap;
      div {
        margin-top: 10px;
      }
    `,
    { minWidth: 0, maxWidth: 1080 },
  )}
`;
