import styled from 'styled-components';

import BgImage from '../../assets/FundoExterno.png';

export const MainContainer = styled.section`
  width: 100%;
  min-height: 46vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 596px;

  h2 {
    ${({ theme }) => theme.fonts.mulish.h2};
    color: ${({ theme }) => theme.colors.grey2};

    margin-bottom: 42px;
    text-align: center;

    span {
      color: ${({ theme }) => theme.colors.purpleDefault};
    }
  }

  form {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 92px;

    div {
      input {
        width: 100%;
        height: 44px;
        margin-top: 12px;
        border: 1.5px solid #dadee5;
        border-radius: 10px;
        padding: 16px 4px 16px 12px;
        color: #636e6f;
        font-family: 'Work Sans', sans-serif;
        font-weight: normal;
        font-size: 16px;
        outline: none;

        &::placeholder {
          color: #a9b3c4;
        }

        &:focus {
          border: 1.5px solid #17a1a1;
        }
      }

      p {
        ${({ theme }) => theme.fonts.mulish.h6};
        color: ${({ theme }) => theme.colors.redDefault};
        position: relative;
      }
    }

    button {
      max-width: 200px;
      margin: 0 auto;
    }

    label {
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #17a1a1;
    }
  }
`;
