import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

interface NavProps {
  propHeight: number;
}

export const Container = styled.div<NavProps>`
  width: 100%;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: fixed;
  top: 0;
  z-index: 999;

  ${mq.custom(
    css`
      width: 100vw;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  &:hover {
    > nav {
      position: relative;
      height: unset;
      top: 0;
    }
  }

  .logo {
    padding: 20px 48px;

    ${mq.custom(
      css`
        padding: 20px 6px;
        width: 90px;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}
  }
`;

export const NavBar = styled.nav<NavProps>`
  width: 100%;
  max-width: 1270px;
  height: ${props => (props.propHeight !== 0 ? '0px' : 'unset')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: ${props => props.propHeight}px;
  transition: 0.3s;

  ${mq.custom(
    css`
      padding: 0px 20px;
      max-width: 100vw;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;

    li a {
      margin: 12px;
      font-family: 'Work Sans', sans-serif;
      color: #49565a;
      text-decoration: none;

      ${mq.custom(
        css`
          margin: 5px;
          font-size: 14px;
        `,
        { minWidth: 0, maxWidth: 1023 },
      )}
    }

    li:nth-child(2) a {
      font-weight: bold;
      color: #17a1a1;
    }

    li:last-child {
      margin-right: 32px;
    }
  }
`;
