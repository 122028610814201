import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import * as S from './styles';
import { useError } from '../../hooks/errors';

export interface SwitchProps {
  label?: string;
  name?: string;
  url?: string;
  check?: boolean;
}

const SwitchComponent: React.FC<SwitchProps> = ({
  label,
  name,
  url,
  check,
}) => {
  const { user, updateProfile } = useAuth();
  const [isChecked, setIsChecked] = useState(check);
  const { emitError } = useError();
  const DisplayStatus = () => {
    if (isChecked) return <span>Público</span>;
    return <span>Invísivel</span>;
  };

  useEffect(() => {
    if (check) setIsChecked(true);
  }, [check]);

  const handleChange = async () => {
    try {
      if (!url?.length) {
        toast.info('Campo Vazio');
      } else {
        const data = { name, url, public: !isChecked };
        const response = await api.patch('/profile/social', data);
        const socials = user.socials.filter(
          social => social.name !== response.data.name,
        );
        updateProfile({
          ...user,
          socials: [...socials, response.data],
        } as any);
        setIsChecked(!isChecked);
      }
    } catch (error: any) {
      emitError(error, 'Erro ao atualizar midia!');
    }
  };

  return (
    <>
      <S.SwitchContainer>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          checkedIcon={false}
          uncheckedIcon={false}
          height={16}
          width={32}
          handleDiameter={14}
          onColor="#1AA"
          onHandleColor="#9FF;"
          offColor="#ABC"
          offHandleColor="#DDE"
          activeBoxShadow="0 0 1px 1px #1AA"
        />
        {label ? <span>{label}</span> : <DisplayStatus />}
      </S.SwitchContainer>
    </>
  );
};

export default SwitchComponent;
