/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import getClient from '../services/client/elasticsearch';

export function useRankingElastic(search: string, searchTerm?: string) {
  return useQuery(
    ['users', search, searchTerm],
    () => useRanking(search, searchTerm),
    {
      initialData: { users: [] },
    },
  );
}

async function useRanking(search, searchTerm = '') {
  const defaultSearch = {
    index: search,
    from: 0,
    size: 5,
    body: {
      query: {
        query_string: {
          fields: ['title', 'name', 'university'],
          query: `"${searchTerm}"`,
        },
      },
    },
  };

  const response = await getClient().search(defaultSearch);
  const data: any = await response.hits.hits;
  return { users: data };
}
