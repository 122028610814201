/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import LoadingScreen from '../../components/LoadingScreen';
import FetchingAnimation from '../../components/FetchingAnimation';
import Button from '../../components/Button';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface FormInputs {
  token: string;
}

const schema = Yup.object().shape({
  token: Yup.string().required('O Token é obrigatório'),
});

function useQuery() {
  return new URLSearchParams(useLocation().search).get('token');
}

const ConfirmEmail = () => {
  const history = useHistory();
  const { user, updateProfile, signOut, signed } = useAuth();
  const [loading, setLoading] = useState(false);
  const [reseLoading, setReseLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>(null);
  const { emitError } = useError();

  const token = useQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const watchToken = watch('token');
  useEffect(() => setInputValue(watchToken), [watchToken]);

  useEffect(() => {
    if (!signed || !user?.isEmailVerified) return;
    // history.push('/usuario/pagamento');
    window.location.href = '/usuario/pagamento';
  }, [user, signed]);

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      setLoading(true);

      await api.get(`/signup/confirmEmail/${inputValue || token}`);
      updateProfile({ isEmailVerified: true });
      toast.success('E-mail confirmado');

      // history.push('/usuario/pagamento');
      window.location.href = '/usuario/pagamento';

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      emitError(error, 'Falha ao confirmar o e-mail');
    }
  };

  const reSend = async () => {
    try {
      setReseLoading(true);
      await api.get('/profile/reSendConfirmation');
      setReseLoading(false);
      toast.info('Código enviado! verifique seu e-mail.');
    } catch (error: any) {
      setReseLoading(false);
      emitError(error, 'Falha ao reenviar o código');
    }
  };

  return (
    <S.MainContainer>
      {loading && <LoadingScreen />}
      <S.Container>
        <div className="popup-header">
          <h2>Confirme seu E-mail para continuar</h2>

          {user?.email && (
            <p>
              Foi enviado um código de confirmação para o e-mail:{' '}
              <span>{user?.email}</span>
            </p>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <label htmlFor="email">Código de confirmação</label>
            <input
              name="token"
              id="token"
              value={inputValue || token}
              type="text"
              {...register('token')}
            />
            <p> {errors?.token?.message}</p>
          </div>

          <Button typeOf="submit"> Prosseguir</Button>
        </form>
        <div className="re-send-email">
          <button type="button" onClick={reSend}>
            Reenviar e-mail {reseLoading && <FetchingAnimation />}
          </button>
          <button type="button" onClick={signOut}>
            Sair
          </button>
        </div>
      </S.Container>
    </S.MainContainer>
  );
};

export default ConfirmEmail;
