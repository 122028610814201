/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import LoadingScreen from '../../components/LoadingScreen';
import Button from '../../components/Button';
import { api } from '../../services/api';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface FormInputs {
  email: string;
}

const schema = Yup.object().shape({
  email: Yup.string().required('O E-mail é obrigatório'),
});

const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      setLoading(true);
      const response = await api.post('/sessions/password/recover', data);
      setLoading(false);
      toast.success('E-mail enviado');
      history.push('/usuario/recuperar-senha');
    } catch (error: any) {
      emitError(error, 'Falha ao enviar e-mail');
    }
  };

  return (
    <S.MainContainer>
      {loading && <LoadingScreen />}
      <S.Container>
        <h2>
          <span>Recuperar </span>senha
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <label htmlFor="email">E-mail</label>
            <input name="email" id="name" type="email" {...register('email')} />
            <p> {errors.email?.message}</p>
          </div>

          <Button typeOf="submit"> Prosseguir</Button>
        </form>
      </S.Container>
    </S.MainContainer>
  );
};

export default ForgotPassword;
