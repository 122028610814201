/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useRef, useEffect, FormHTMLAttributes } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';
import CtaBanner from '../../components/CtaBanner';
import { ReactComponent as CaciqLogo } from '../../assets/caciq-small-logo.svg';
import { ReactComponent as RightArrow } from '../../assets/arrow-right.svg';
import Button from '../../components/Button';
import FetchingAnimation from '../../components/FetchingAnimation';
import LoadingScreen from '../../components/LoadingScreen';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import { useError } from '../../hooks/errors';
import * as S from './styles';
import { useSubscription } from '../../hooks/subscription';

interface FormInputs {
  card_holder: string;
  document: string;
  address: string;
}

const MonthlyRegistration = () => {
  const { user, signOut, signed, updateProfile } = useAuth();
  const [hasShortCode, setHasShortCode] = useState(false);
  const [greetings, setGreetings] = useState(false);
  const [isJournalOrPPG, setIsJournalOrPPG] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingPayment, setIsCheckingPayment] = useState(false);
  const history = useHistory();

  const formRef = useRef<HTMLFormElement>();

  const {
    PRICE_ID_PA_MONTHLY,
    PRICE_ID_PA_ANUAL,
    PRICE_ID_PP_ANUAL,
    getConfig,
  } = useSubscription();

  const [payIntent, setPayIntent] = useState<PaymentIntent | null>();
  const [priceId, setPriceId] = useState(
    isJournalOrPPG ? PRICE_ID_PP_ANUAL : PRICE_ID_PA_MONTHLY,
  );
  const { emitError } = useError();
  const { register, handleSubmit } = useForm<FormInputs>();

  const stripe = useStripe();
  const elements = useElements();

  function handleShortCode() {
    setHasShortCode(!hasShortCode);
  }
  function handleGreetings() {
    setGreetings(false);
  }

  async function getPaymenTStatus() {
    if (!signed) return;
    setIsCheckingPayment(true);

    let tries = 0;

    // eslint-disable-next-line consistent-return
    const checkStatus = async () => {
      try {
        if (tries >= 10) {
          setIsCheckingPayment(false);
          toast.info(
            'Não foi possível verificar o status do pagamento, realize um novo login mais tarde',
          );
          setIsCheckingPayment(false);
          signOut();
        }
        tries += 1;

        const response = (await api.get('/subscriptions/status')) as any;

        if (!response?.data?.subscription?.enabled) {
          setTimeout(() => {
            return checkStatus();
          }, 5000);
        } else if (response?.data?.subscription?.enabled) {
          updateProfile({ subscriptions: response?.data?.subscription });
          toast.success(
            'Seu pagamento foi realizado com sucesso! Redirecionando ...',
          );
          history.push('/');
          setIsCheckingPayment(false);
        }
      } catch (error: any) {
        return checkStatus();
      }
    };

    await checkStatus();
  }

  useEffect(() => {
    if (!user) return;
    setIsJournalOrPPG(
      user?.accountType === 'journal' || user?.accountType === 'ppg',
    );

    if (user?.subscriptions?.status === 'incomplete') {
      setPriceId(user?.subscriptions?.priceId);
    }
  }, [user, user?.accountType]);

  const onSubmit = async formData => {
    setIsLoading(true);
    // Verifica se tem o id do plano escolhido
    if (!priceId) {
      setIsLoading(false);
      emitError(null, 'Falha ao selecionar tipo de plano, tente novamente!');
    }

    try {
      const { data: createdSubscription } = await api.post('/subscriptions', {
        priceId,
        ...formData,
      });

      const { clientSecret } = createdSubscription as any;

      const cardElement = elements.getElement(CardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: formData.card_holder,
            },
          },
        },
      );

      if (error) {
        setIsLoading(false);
        emitError(null, error.message);
        return;
      }
      setPayIntent(paymentIntent as any);
    } catch (error: any) {
      setIsLoading(false);
      emitError(error, 'Erro ao criar assinatura!');
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (!payIntent) return;
    setIsLoading(false);

    if (payIntent?.status !== 'succeeded') return;
    setGreetings(true);
    getPaymenTStatus();
    toast.info('Estamos verificando o status da sua assinatura.');
  }, [payIntent]);

  return (
    <>
      <S.MainContainer>
        {!hasShortCode && !greetings ? (
          <>
            {isLoading && <LoadingScreen />}
            <S.Container>
              <div className="form-header">
                <h2>
                  Planos de <span>assinatura </span>
                </h2>
                <p>
                  Você pode assinar o plano anual com desconto ou, então,
                  assinar o plano mensal
                  <br /> (com fidelidade mínima de 12 meses).
                </p>
              </div>

              <S.ButtonSection>
                {user?.subscriptions?.status === 'incomplete' ? (
                  <PriceSelect
                    recurrence={user?.subscriptions?.recurrence as any}
                    priceId={user?.subscriptions?.priceId}
                    selected={priceId}
                    setPriceId={setPriceId}
                  />
                ) : (
                  <>
                    {!isJournalOrPPG && (
                      <PriceSelect
                        recurrence="monthly"
                        priceId={PRICE_ID_PA_MONTHLY}
                        selected={priceId}
                        setPriceId={setPriceId}
                      />
                    )}

                    <PriceSelect
                      recurrence="yearly"
                      priceId={
                        isJournalOrPPG ? PRICE_ID_PP_ANUAL : PRICE_ID_PA_ANUAL
                      }
                      selected={priceId}
                      setPriceId={setPriceId}
                    />
                  </>
                )}
              </S.ButtonSection>

              <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <ul className="user-informations-editable">
                  <li className="card-li">
                    <label htmlFor="card_number">Dados do Cartão</label>

                    <CardElement
                      options={{
                        classes: {
                          base: 'card-input',
                        },
                        hidePostalCode: true,
                        style: {
                          base: {
                            fontSize: '16px',
                            lineHeight: '42px',
                            fontFamily: 'Work Sans, serif',
                            textAlign: 'center',
                            color: '#636e6f',
                            fontWeight: 'lighter',
                            '::placeholder': {
                              color: '#a9b3c4',
                              fontWeight: 300,
                            },
                          },
                        },
                      }}
                    />
                  </li>

                  <li>
                    <label htmlFor="card_holder">Nome do titular</label>
                    <input
                      name="card_holder"
                      type="text"
                      id="card_holder"
                      placeholder="Nome do titular"
                      {...register('card_holder')}
                    />
                  </li>

                  <li>
                    <label htmlFor="cpf">CPF ou CNPJ do titular</label>
                    <input
                      name="document"
                      type="text"
                      id="document"
                      placeholder="CPF ou CNPJ"
                      {...register('document')}
                    />
                  </li>

                  <li>
                    <label htmlFor="address">Endereço</label>
                    <input
                      name="address"
                      type="text"
                      id="address"
                      placeholder="Endereço"
                      {...register('address')}
                    />
                  </li>
                </ul>
              </form>
            </S.Container>
          </>
        ) : greetings && !hasShortCode ? (
          <GreetingsTab isCheckingPayment={isCheckingPayment} user={user} />
        ) : (
          <ShortCode />
        )}

        {greetings ? (
          <div className="add-code-btn">
            <Button link size="small" onClick={() => handleGreetings()}>
              Voltar
            </Button>
          </div>
        ) : (
          <div
            className="add-code-btn"
            style={{ flexDirection: 'column', gap: '10px' }}
          >
            <Button typeOf="button" onClick={handleSubmit(d => onSubmit(d))}>
              Finalizar
            </Button>

            <Button ghost size="small" onClick={() => handleShortCode()}>
              {hasShortCode ? 'Voltar' : 'Tenho um código'}
            </Button>
          </div>
        )}
      </S.MainContainer>
      <CtaBanner />
    </>
  );
};

interface PriceSelectProps {
  recurrence: 'monthly' | 'yearly';
  priceId: string;
  selected: string;
  setPriceId: (id: string) => void;
}

const PriceSelect = ({
  recurrence,
  priceId,
  selected,
  setPriceId,
}: PriceSelectProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [price, setPrice] = useState<any>(null);
  const { emitError } = useError();

  const getPrice = async () => {
    try {
      const { data } = await api.get(`/subscriptions/prices/${priceId}`);
      setPrice(data);
      setIsLoading(false);
    } catch (error) {
      emitError(error, 'Erro ao buscar preço!');
    }
  };

  useEffect(() => {
    getPrice();
  }, []);

  return (
    <div
      className={
        selected === priceId
          ? `button-active-${
              recurrence === 'monthly' ? 'month' : 'year'
            } button-container`
          : 'button-container'
      }
    >
      <button type="button" onClick={() => setPriceId(price?.id)}>
        <h3>Plano {recurrence === 'monthly' ? 'Mensal' : 'Anual'} </h3>

        <div className="price-container">
          <h2>
            {isLoading ? (
              <FetchingAnimation />
            ) : (
              <>
                {price &&
                  (price?.unit_amount / 100)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                <span>/{recurrence === 'monthly' ? 'mês' : 'ano'}</span>
              </>
            )}
          </h2>
        </div>
      </button>
    </div>
  );
};

const ShortCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const inputRef = useRef(null);
  const { emitError } = useError();

  async function handleSubmit() {
    event.preventDefault();
    try {
      setIsLoading(true);

      const response = await api.get(
        `/subscriptions/join/${inputRef.current.value}`,
      );

      history.push('/');
      toast.success('Plano adicionado com sucesso!');
      setIsLoading(false);
    } catch (error: any) {
      emitError(error, 'Erro ao entrar na assinatura informada!');
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingScreen />}
      <S.ShortCodeContainer>
        <label htmlFor="shortcode"> Inserir código</label>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="0000" ref={inputRef} id="shortcode" />
          <Button typeOf="submit">Enviar</Button>
        </form>
      </S.ShortCodeContainer>
    </>
  );
};

const GreetingsTab = ({ user, isCheckingPayment }) => {
  const history = useHistory();

  function redirectHome() {
    history.push('/');
  }
  function redirectAccount() {
    history.push(`/perfil/${user.name}`);
  }

  return (
    <S.GreetingsContainer>
      <div className="header-container">
        <CaciqLogo />
        <h3>Seja Bem-vindo a Plataforma Caciq</h3>

        <p>
          Sua assinatura está sendo processada, aguarde confirmação via e-mail.
        </p>
        {isCheckingPayment && <FetchingAnimation />}
      </div>
      <div className="buttons-container">
        <Button onClick={() => redirectHome()}>
          <RightArrow /> Ir para Home
        </Button>
        <Button color="blueDefault" onClick={() => redirectAccount()}>
          Ir para Minha Conta <RightArrow />
        </Button>
      </div>
    </S.GreetingsContainer>
  );
};

export default MonthlyRegistration;
