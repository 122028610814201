/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import * as S from './styles';

interface Headerprops {
  isRanking?: boolean;
}

const AuthorsResultsHeader = ({ isRanking = false }: Headerprops) => {
  const HeaderTitles = {
    col1: 'Nome',
    col2: 'ARTIGOS PUBLICADOS',
    col3: 'Programa de Pós-graduação',
    col4: 'Palavras-chave',
    col5: 'Citações em outros artigos',
    col6: 'Índices',
  };
  return (
    <>
      <S.Container>
        {isRanking && (
          <S.RankWrapper>
            <h3>Ranque</h3>
          </S.RankWrapper>
        )}
        <S.TitleWrapper style={{ flex: '0.8' }}>
          <h3>{HeaderTitles.col1}</h3>
        </S.TitleWrapper>

        <S.TagWrapper style={{ flex: '0.5' }}>
          <h3>{HeaderTitles.col2}</h3>
        </S.TagWrapper>

        <S.TagWrapper>
          <h3>{HeaderTitles.col3}</h3>
        </S.TagWrapper>

        <S.CiteWrapper>
          <h3>{HeaderTitles.col5}</h3>
        </S.CiteWrapper>

        <S.IndexWrapper>
          <h3>{HeaderTitles.col6}</h3>
        </S.IndexWrapper>

        <S.ButtonWrapper />
      </S.Container>
      <S.Divider />
    </>
  );
};

export default AuthorsResultsHeader;
