import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 24%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0px;
  left: 0px;
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background: rgba(147, 45, 145, 0.8);
  }

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 0;
    position: absolute;
    left: 0;
    color: white;
    svg {
      width: 20px !important;
    }
  }

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }
`;
