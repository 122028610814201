import React from 'react';
import PerfilPhoto from '../../assets/avatar.svg';

export const fakeData = {
  name: 'Mourice Junior',
  role: 'Pesquisador Free',
  cpf: '0000000',
  bornDate: '1998-03-20',
  email: 'mourice@notis.com',
  photo: PerfilPhoto,
};

export default {};
