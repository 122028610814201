import React, { useRef, useEffect, useState } from 'react';
import Button from '../Button';
import alertIcon from '../../assets/alert.svg';
import logoPopup from '../../assets/logo-popup.svg';

import * as S from './styles';

export interface PopupProps {
  alert?: boolean;
  logo?: boolean;
  isOpen: boolean;
  principalButtonFunction: any;
  secondaryButtonFunction?: any;
  setIsOpen: any;
  title: string;
  text: string;
  buttonDefaultText: string;
  buttonSecondaryText: string;
}

const PopUp: React.FC<PopupProps> = ({
  alert,
  logo,
  title,
  text,
  principalButtonFunction,
  secondaryButtonFunction,
  isOpen,
  setIsOpen,
  buttonDefaultText,
  buttonSecondaryText,
  ...props
}) => {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    if ((popUpRef.current! as any).contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  const closePopUp = () => {
    setIsOpen(false);
  };
  return (
    <S.PopupBackground>
      <S.StyledPopUp {...props} ref={popUpRef}>
        {alert && <img src={alertIcon} alt="" style={{ marginBottom: 18 }} />}
        {logo && <img src={logoPopup} alt="" style={{ marginBottom: 18 }} />}
        <S.Title>{title}</S.Title>
        <S.Text>{text}</S.Text>
        <Button onClick={principalButtonFunction}>{buttonDefaultText}</Button>
        <S.LinkWrapper>
          <Button link onClick={secondaryButtonFunction || closePopUp}>
            {buttonSecondaryText}
          </Button>
        </S.LinkWrapper>
      </S.StyledPopUp>
    </S.PopupBackground>
  );
};

export default PopUp;
