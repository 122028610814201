/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as SearchIcon } from '../../../../assets/search-right.svg';
import Button from '../../../../components/Button';
import Selector from './components/selector';
import { useUsersVerified } from '../../../../hooks/useUsersVerified';
import LoadingAnimation from '../../../../components/LoadingAnimaton';
import { api } from '../../../../services/api';
import { useUsersElastic } from '../../../../hooks/useUsersElastic';
import * as S from './styles';

interface PropsFilter {
  setPopUpState?: any;
  isOpen?: boolean;
  handleNewValue?: any;
  filterTable?: boolean;
  userType?: string;
}

const PopUpFindName = React.forwardRef(
  (
    {
      setPopUpState,
      userType = '',
      isOpen = false,
      filterTable = false,
      handleNewValue,
    }: PropsFilter,
    ref: any,
  ) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [userList, setUserList] = useState([]);
    const [formName, setFormName] = useState<any>([]);
    const [searchData, setSearchData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const popUpRef = useRef(null);
    const inputRef = useRef(null);

    const { data: users } = useUsersVerified(userType, searchTerm);

    useEffect(() => {
      if (!users?.users?.length) return setIsLoading(true);
      return setIsLoading(false);
    }, [users, isLoading]);

    useEffect(() => {
      setUserList(users?.users);
    }, [users]);

    function handleValue() {
      try {
        const ids = formName.map(e => e.value);

        ids.forEach(async item => {
          await api.post(`/attachUser/${item}`);
        });

        toast.success('Professor Adicionado');
        window.location.reload();
      } catch (error: any) {
        toast.error(error.message);
      }
    }

    const handleClick = (e: MouseEvent) => {
      if ((popUpRef.current! as any).contains(e.target)) {
        return;
      }
      setPopUpState(false);
      setSearchData('');
    };

    useEffect(() => {
      if (isOpen) {
        document.addEventListener('mousedown', handleClick);
      } else {
        document.removeEventListener('mousedown', handleClick);
      }

      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, [isOpen]);

    function handleSearch() {
      event.preventDefault();
      setSearchTerm(inputRef.current.value);
    }

    console.log(formName);

    return (
      <S.MainContainer>
        <S.PopUp ref={popUpRef} formProp={filterTable}>
          <form className="search-bar" onSubmit={handleSearch}>
            <input type="text" ref={inputRef} value={formName?.label} />

            <button type="submit">
              <SearchIcon />
            </button>
          </form>

          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <div className="names-list">
              <ul>
                {userList.map((e, index) => (
                  <li style={{ animationDelay: `${0.5 + index / 8}s` }}>
                    <Selector
                      items={e}
                      setNewValue={setFormName}
                      formName={formName}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {formName.length > 0 && (
            <div className="show-authors">
              <h5>Autores selecionados</h5>
              <ul>
                {formName.map(e => (
                  <li>{e.label}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="buttons-section-popup">
            <Button onClick={handleValue}>
              {filterTable ? 'Filtrar' : 'Selecionar'}
            </Button>

            <Button onClick={() => setPopUpState(false)} ghost>
              Cancelar
            </Button>
          </div>
        </S.PopUp>
      </S.MainContainer>
    );
  },
);

export default PopUpFindName;
