import React, { useState, useEffect } from 'react';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import * as S from './styles';
import { ReactComponent as RightArrow } from '../../assets/arrow-right-purple.svg';
import { ReactComponent as ReturnArrow } from '../../assets/return-arrow.svg';
import Button from '../Button';
import { useAccount } from '../../hooks/accountInf';
import { useAuth } from '../../hooks/auth';

const InnerSection: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { accountInf, getAccount } = useAccount();

  useEffect(() => {
    if (!accountInf) getAccount();
  }, []);
  const planRecurrence = accountInf?.subscription?.enabled;

  const { signOut, user } = useAuth();

  const basicRole = user?.accountType;

  const navItensWPlan = [
    {
      id: 0,
      title: 'Perfil',
      href: `/perfil/usuario:${user?.name}`,
    },

    {
      id: 1,
      title: 'Conta',
      href: `/perfil/conta/usuario:${user?.name}`,
    },
    {
      id: 1,
      title: 'Alterar Senha',
      href: `/mudarsenha/usuario:${user?.name}`,
    },
  ];
  const navItensWoPLan = [
    {
      id: 0,
      title: 'Perfil',
      href: `/perfil/usuario:${user?.name}`,
    },

    {
      id: 1,
      title: 'Planos',
      href: `${
        basicRole === 'researcher' ? '/usuario/planos' : '/usuario/pagamento'
      }`,
    },
    {
      id: 1,
      title: 'Alterar Senha',
      href: `/mudarsenha/usuario:${user?.name}`,
    },
  ];

  return (
    <>
      <S.ContainerNav>
        <S.NavBar>
          <button
            type="button"
            className="go-back-btn"
            onClick={() => history.goBack()}
          >
            <ReturnArrow />
            <span>Voltar para a busca</span>
          </button>

          {location.pathname.includes('perfil') ? (
            <ul>
              {(planRecurrence ? navItensWPlan : navItensWoPLan).map(link => (
                <li>
                  <S.InnerLink key={link.id}>
                    <NavLink to={link.href} activeClassName="active-link">
                      {link.title}
                    </NavLink>
                  </S.InnerLink>
                </li>
              ))}
              <Button onClick={signOut} ghost size="small">
                Logout <RightArrow style={{ marginLeft: '10px' }} />
              </Button>
            </ul>
          ) : null}
        </S.NavBar>
      </S.ContainerNav>
    </>
  );
};

export default InnerSection;
