/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useQuery } from 'react-query';
import AnaliseIndex from '../../../../components/AnalisePerfil';
import Button from '../../../../components/Button';
import getClient from '../../../../services/client/elasticsearch';
import * as S from './styles';

interface PeriodicProps {
  periodic?: string[];
}

const Index = ({ periodic = [] }: PeriodicProps) => {
  return (
    <S.MainContainer>
      <div className="section-title">
        <h3>Índices de Ranqueamento</h3>
      </div>
      <S.Content>
        <ul>
          <li>ISSN</li>
          <li>NOME</li>
          <li>Caciq-r</li>
          <li>Caciq-h4R</li>
          <li>SJR</li>
          <li>Qualis</li>
          <li>Google-h4</li>
          <li>Google-h5</li>
          <li />
        </ul>

        <div className="content-section">
          {periodic.map((item: any) => (
            <ul>
              <li> {item?._source?.issn} </li>
              <li> {item?._source?.name} </li>
              <li>
                <p>
                  {item?._source?.indexes?.['IC-R'] &&
                    Number(item?._source?.indexes?.['IC-R'])}
                </p>
              </li>
              <li>
                <p>
                  {item?._source?.indexes?.['IC-H4-R'] &&
                    Number(item?._source?.indexes?.['IC-H4-R'])}
                </p>
              </li>
              <li>
                <p>
                  {item?._source?.indexes?.sjr &&
                    Number(item?._source?.indexes?.sjr)}
                </p>
              </li>
              <li>
                <p>{item?._source?.stratum}</p>
              </li>
              <li>
                <p>
                  {item?._source?.indexes.length > 0
                    ? item?._source?.indexes?.map(
                        e => e.name.toLowerCase() === 'h4' && Number(e.value),
                      )
                    : '-'}
                </p>

                <p>
                  {item?._source?.indexes?.h5 &&
                    Number(item?._source?.indexes?.h5)}
                </p>
              </li>
              <li>
                <p>
                  {item?._source?.indexes?.i10All &&
                    Number(item?._source?.indexes?.i10All)}
                </p>
              </li>
              <li>
                <Button href={`/revista/detalhe/${item?._source?.uniqId}`}>
                  Saiba Mais
                </Button>
              </li>
            </ul>
          ))}
        </div>
      </S.Content>
    </S.MainContainer>
  );
};

export default Index;
