/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState, useContext, createContext } from 'react';
import { useAuth } from './auth';
import { api } from '../services/api';

export interface PermissionContextData {
  Permissions: any;
}

export const PermissionContext = createContext<PermissionContextData>(
  {} as PermissionContextData,
);

export const PermissionProvider: React.FC = ({ children }: any) => {
  const { user, signed } = useAuth();
  const [publicPermission, setPublicPermission] = useState<any>(null);
  const userPermision = user?.plan?.permissions;

  async function getPermission() {
    try {
      const response = await api.get('/permissions');
      setPublicPermission(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    if (!signed && !publicPermission) getPermission();
    return null;
  }, [publicPermission]);

  const Permissions = permission => {
    if (!signed && publicPermission?.includes(permission)) return true;
    if (
      signed &&
      (userPermision?.includes(permission) ||
        user?.plan?.basicRole === 'CaciqAdmin')
    )
      return true;
    return false;
  };

  return (
    <PermissionContext.Provider value={{ Permissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

export function usePermission(): PermissionContextData {
  const context = useContext(PermissionContext);
  return context;
}

export default {};
