import styled, { css } from 'styled-components';

import mq from '../../styles/mq';

export const Container = styled.div`
  flex-basis: 400px;
  flex-grow: 1;
  padding: 24px 19.5px;

  background-color: ${({ theme }) => theme.colors.white};
  border: 1.5px solid ${({ theme }) => theme.colors.grey6};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    background-color: #f7f7f7;
  }
`;

export const Title = styled.h6`
  font-family: ${({ theme }) => theme.fonts.mulish.h6};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.blueMidtone};
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.workSans.p};
  font-weight: normal;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey2};
  margin-bottom: 8px;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .text-content {
    color: ${({ theme }) => theme.colors.grey2};
  }

  .property-container {
    margin-bottom: 10px;
    align-items: center;
    height: auto;
    display: flex;
    align-items: center;

    ${mq.custom(
      css`
        flex-wrap: wrap;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 14px;
`;
