/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import api from '../services/api';
import { useError } from './errors';

interface ISubConfig {
  publishableKey: string;
  prices: {
    id: string;
    object: string;
    active: boolean;
    billing_scheme: string;
    created: number;
    currency: string;
    livemode: boolean;
    lookup_key: unknown;
    metadata: any;
    nickname: unknown;
    product: {
      id: string;
      object: string;
      active: boolean;
      attributes: any[];
      created: Date;
      description: unknown;
      images: any[];
      livemode: boolean;
      metadata: any;
      name: string;
      package_dimensions: unknown;
      shippable: unknown;
      statement_descriptor: unknown;
      tax_code: unknown;
      type: string;
      unit_label: unknown;
      updated: Date;
      url: unknown;
    };
    recurring: {
      aggregate_usage: unknown;
      interval: string;
      interval_count: number;
      trial_period_days: unknown;
      usage_type: string;
    };
    tax_behavior: string;
    tiers_mode: unknown;
    transform_quantity: unknown;
    type: string;
    unit_amount: number;
    unit_amount_decimal: string;
  }[];
}

interface SubscriptionContextData {
  getConfig(): Promise<ISubConfig>;

  // PA = Pesquisador & Autor
  // PP = PPG & Periódico
  PRICE_ID_PA_MONTHLY: string;
  PRICE_ID_PA_ANUAL: string;
  PRICE_ID_PP_ANUAL: string;
}

const SubscriptionContext = createContext<SubscriptionContextData>(
  {} as SubscriptionContextData,
);

export const SubscriptionProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<ISubConfig>();
  const { emitError } = useError();
  const [stripePromise, setStripePromise] = useState<any>(null);

  // TODO alterar para o produto de produção da conta do Dr Emerson
  const PRICE_ID_PA_MONTHLY = 'price_1KP8QYLWStKfKpvSN3OeTH5q'; // 'price_1KLGBZLWStKfKpvSGOe8F56X';
  const PRICE_ID_PA_ANUAL = 'price_1KP8QYLWStKfKpvSDhVjRXOI'; // 'price_1KLJkeLWStKfKpvSy68QRz4E';
  const PRICE_ID_PP_ANUAL = 'price_1KP8ukLWStKfKpvSz5aruViI'; // 'price_1KLGF7LWStKfKpvS5vSf7udU';

  const getConfig = async (): Promise<ISubConfig> => {
    if (config) return config;

    try {
      const { data: response } = await api.get('/subscriptions/config');
      const { publishableKey } = response;

      setConfig(response);

      setStripePromise(loadStripe(publishableKey));
    } catch (error) {
      emitError(error, error.message);
    }
  };

  // const createSubscription = async () => {};

  // const confirmPayment = async () => {};

  // const updateSubscription = async () => {};

  // const cancelSubscription = async () => {};

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700;900&display=swap',
          },
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;500,900;1,700&display=swap',
          },
        ],
      }}
    >
      <SubscriptionContext.Provider
        value={{
          getConfig,
          PRICE_ID_PA_MONTHLY,
          PRICE_ID_PA_ANUAL,
          PRICE_ID_PP_ANUAL,
        }}
      >
        {children}
      </SubscriptionContext.Provider>
    </Elements>
  );
};

export function useSubscription(): SubscriptionContextData {
  const context = useContext(SubscriptionContext);
  return context;
}
