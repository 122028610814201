import styled from 'styled-components';
import { theme } from '../../styles/global';

export const Analise = styled.div`
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  background: white;
  border-radius: 22px;
  box-shadow: 0px 5.63694px 22.5478px rgba(0, 0, 0, 0.1);
  border: 1.69108px solid #dadee5;
  padding: 30px 120px;

  h4 {
    color: ${theme.colors.grey2};
    ${theme.fonts.mulish.h5}
    text-align: center;
    margin-bottom: 10px;

    span {
      color: ${theme.colors.purpleDefault};
      font-weight: 600;
    }
  }

  p {
    color: ${theme.colors.grey2};
    ${theme.fonts.mulish.h5}
    text-align: center;
  }
`;
