import styled from 'styled-components';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.section`
  width: 100%;
  max-width: 804px;
  transition: 0.3s;
  margin: 1em auto;

  .open-btn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      background: none;
      border: 0;
      color: ${({ theme }) => theme.colors.blueDefault};
      text-decoration: underline;
    }
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    li {
      flex-basis: 388px;
      display: flex;
      align-items: center;
      gap: 24px;

      span {
        ${({ theme }) => theme.fonts.workSans.p};
        color: ${({ theme }) => theme.colors.grey3};
      }
    }
  }

  .react-switch-bg {
    width: 44px !important;
    height: 16px !important;
  }

  .react-switch-handle {
    width: 14px !important;
    height: 14px !important;
  }
`;
export const FilterWrap = styled.section<ContainerProps>`
  width: 100%;
  max-width: 1200px;
  max-height: ${props => (props.isOpen ? '200px' : '0px')};
  overflow: ${props => (props.isOpen ? 'unset' : 'hidden')};
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s;

  .css-b62m3t-container {
    min-width: 117px;
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    border-radius: 10px;
    box-shadow: none !important;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`;

export const Alphabet = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
