import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const Container = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1010px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;

  align-items: flex-end;

  ${mq.custom(
    css`
      flex-direction: column;

      button {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  .search-bar-field {
    flex-basis: 200px;
    flex-grow: 1;

    ${mq.custom(
      css`
        flex-basis: 0;
        width: 100%;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}

    input {
      width: 100%;
      height: 45px;
      margin-top: 12px;
      border: 1.5px solid #dadee5;
      border-radius: 10px;
      padding: 16px 4px 16px 12px;
      color: #636e6f;
      font-family: 'Work Sans', sans-serif;
      font-weight: normal;
      font-size: 16px;
      outline: none;

      &::placeholder {
        color: #a9b3c4;
      }

      &:focus {
        border: 1.5px solid #17a1a1;
      }
    }

    label {
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #17a1a1;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 18px;
    border: 0;
    border-radius: 6px;
    font-size: 0.75em;
    color: white;
    font-weight: bold;
    transition: 0.3s;

    span {
      transition: 0.3s;
    }
    svg {
      margin-left: 10px;
    }
  }

  .search-btn {
    background: ${({ theme }: any) => theme.colors.blueDefault};
    &:hover {
      span {
        max-width: 100px;
        opacity: 1;
      }
      svg {
        margin-left: 10px;
      }
    }
  }
  .ranking-btn {
    background: ${({ theme }: any) => theme.colors.purpleMidtone};

    &:hover {
      span {
        max-width: 100px;
        opacity: 1;
      }
      svg {
        margin-left: 10px;
      }
    }
  }

  .close-btn {
    span {
      max-width: 0;
      opacity: 0;
    }

    svg {
      margin-left: 0;
    }
  }
`;

export const SelectWrapper = styled.div`
  margin-right: 12px;
`;

export const ButtonWrapper = styled.div`
  margin-left: 12px;
`;
