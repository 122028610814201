/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { User } from '../models/user';
import { api } from '../services/api';
import { useError } from './errors';

export interface UserContextData {
  user: User[] | null;
  getUser(): Promise<void>;
  isLoading: boolean;
}
export const UserContext = createContext<UserContextData>(
  {} as UserContextData,
);
export const UserProvider: React.FC = ({ children }: any) => {
  const [user, setUser] = useState<User[]>();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const getUser = async () => {
    try {
      setLoading(true);

      const response = await api.get('/users');
      setUser(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      emitError(error, 'Erro ao carregar usuários');
    }
  };

  return (
    <UserContext.Provider
      value={{
        isLoading: loading,
        user,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUser(): UserContextData {
  const context = useContext(UserContext);
  return context;
}
