import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const FeatureDetail = styled.p`
  font: ${({ theme }) => theme.fonts.mulish.p};
  font-size: 12px;
  margin-left: 18px;
  text-align: left !important;
  flex: 1;
`;
