import styled, { css } from 'styled-components';
import bgImg from '../../assets/banner-cta.png';
import newsBg from '../../assets/NewsSectionBg.png';
import mq from '../../styles/mq';

interface ContainerProps {
  backgroundProp: boolean;
}

export const CtaContainer = styled.div<ContainerProps>`
  height: 314px;
  background: url(${prop => (prop.backgroundProp ? bgImg : newsBg)}) no-repeat
    center;
  object-fit: cover;
  background-size: cover;
  margin-top: 45px;
  color: #fff;

  ${mq.custom(
    css`
      height: auto;
    `,
    { minWidth: 0, maxWidth: 1090 },
  )}
`;

export const CtaWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 880px;
  width: 100%;
  height: 100%;
  text-align: center;

  padding: 60px;

  h2 {
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 25px;
  }

  p {
    font-size: 0.75rem;
    max-width: 403px;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const StyledButton = styled.a<ContainerProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  padding: 12px 18px;
  border: 1px solid #ffffff;
  text-decoration: none;
  border-radius: 6px;
  background: none;
  color: #fff;
  font-size: 0.87rem;
  font-weight: bold;
  letter-spacing: 1px;

  &:not(:disabled):hover {
    background: #fff;
    transition: background 0.2s;
    color: ${prop =>
      prop.backgroundProp
        ? ({ theme }) => theme.colors.purpleDefault
        : ({ theme }) => theme.colors.blueDefault};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover svg {
    fill: #fff;
  }

  img {
    padding-left: 15px;
  }
`;
