import React, { useEffect } from 'react';

import ReactGA from 'react-ga';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { queryClient } from './services/queryClient';
import Routes from './Routes';
import { AuthProvider } from './hooks/auth';
import { ErrorProvider } from './hooks/errors';
import GlobalStyle, { theme } from './styles/global';
import { UserProvider } from './hooks/user';
import { SocialProvider } from './hooks/socials';
import { AccountProvider } from './hooks/accountInf';
import { SubscriptionProvider } from './hooks/subscription';
import { PermissionProvider } from './hooks/permission';
import 'react-toastify/dist/ReactToastify.css';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

export default function App() {
  // useGaTracker();

  useEffect(() => {
    ReactGA.initialize('UA-214540241-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        console.log('Reset is disabled');
      }}
    >
      <ToastContainer />
      <AuthProvider>
        <PermissionProvider>
          <ErrorProvider>
            <ThemeProvider theme={theme}>
              <SubscriptionProvider>
                <UserProvider>
                  <SocialProvider>
                    <AccountProvider>
                      <QueryClientProvider client={queryClient}>
                        <Routes />
                        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                      </QueryClientProvider>
                    </AccountProvider>
                  </SocialProvider>
                </UserProvider>
              </SubscriptionProvider>
            </ThemeProvider>
          </ErrorProvider>
        </PermissionProvider>
      </AuthProvider>
      <GlobalStyle />
    </ErrorBoundary>
  );
}
