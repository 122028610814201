import styled from 'styled-components';

import { ModalContentProps } from '.';

export const TitleContainer = styled.div<ModalContentProps>`
  display: flex;
  flex-direction: row;

  img {
    margin-right: 18px;
  }
`;

export const Title = styled.h5<ModalContentProps>`
  display: flex;
  flex: 1;
  justify-content: ${props => {
    if (props.total) {
      return 'flex-start';
    }
    if (props.big) {
      return 'center';
    }
    return 'flex-start';
  }};

  color: ${({ theme }) => theme.colors.purpleDefault};
  font-weight: normal;
  font-size: 20px;
`;

export const Total = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey3};
  span {
    font-weight: bold;
    font-size: 20px;

    letter-spacing: 0.5px;

    /* Caciq/ Blue/ Midtone */

    color: #17a1a1;
  }
`;

export const TagWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ContainerTag = styled.div`
  margin: 5px;
  display: flex;
`;

export const ModalContent = styled.div`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
`;

export const StyledPopUp = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const TitleAlert = styled.h5`
  font-size: 20px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const TextAlert = styled.p`
  font-size: 16px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const LinkWrapper = styled.div`
  margin-top: 18px;
`;
