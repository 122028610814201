import styled from 'styled-components';

export const CitationsInfo = styled.div`
  box-sizing: border-box;
  width: 294px;
  height: 151px;
  background-color: ${({ theme }) => theme.colors.purpleDefault};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 24px 40px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 21px;

  strong {
    font-size: 3rem;
    font-weight: 900;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.mulish.p};
    font-size: 1.25rem;
    line-height: 120%;
    letter-spacing: 1px;
  }
`;
