import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;

  span {
    font-family: ${({ theme }) => theme.fonts.mulish.p};
    font-size: 8px;
    margin-top: 3px;

    text-align: center;
  }
`;
