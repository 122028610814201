import React, { ReactNode, useState } from 'react';
import Modal from 'react-modal';
import closeImg from '../../../../assets/close.svg';

import PremiumIcon from '../../../../assets/premium.svg';

import Button from '../../../../components/Button';
import ContentTag from '../../../../components/ContentTag';
import ModalContent from '../../../../components/ModalContent';
import { IAuthor } from '../../../../models/author';
import { usePermission } from '../../../../hooks/permission';
import * as S from './styles';

export interface AuthorResultCardProps extends IAuthor {
  citedKeywords?: string[];
  citations: number;
  setSearchTerm?: any;
  premium?: boolean;
  registered?: boolean;
  rank?: number;
  isRanking?: boolean;
}

const AuthorResultCard: React.FC<AuthorResultCardProps> = props => {
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
  const { Permissions } = usePermission();

  function handleOpenModal() {
    setIsReadMoreOpen(true);
  }

  function handleCloseModal() {
    setIsReadMoreOpen(false);
  }

  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false);

  function handleOpenKeywordsModal() {
    setIsKeywordsModalOpen(true);
  }

  function handleCloseKeywordsModal() {
    setIsKeywordsModalOpen(false);
  }
  const internalResultPermission = Permissions('Result_Internal_Result');

  const ICAPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');
  const HALLPermission = Permissions('Result_Scholar_H4_Index');
  const I10Permission = Permissions('Result_Scholar_Index');

  const ICA = props?.indexes?.['IC-A'];

  const ICH4 = props?.indexes?.['IC-H4-A'];

  const HALL = props?.indexes?.hAll;

  const I10 = props?.indexes?.i10All;
  return (
    <>
      <S.Container>
        <S.Content>
          {props.isRanking && (
            <S.RankWrapper>
              <strong>{props.rank}°</strong>
            </S.RankWrapper>
          )}
          <S.TitleWrapper>
            <p>{props.name}</p>
          </S.TitleWrapper>

          <S.TagWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flex: '0.3',
            }}
          >
            {props?.publishedArticlesCount > 0 ? (
              props?.publishedArticlesCount
            ) : (
              <div
                className="not-found"
                title="Não encontrado nas maiores plataformas do Brasil"
              />
            )}
          </S.TagWrapper>

          {props.premium ? (
            <>
              <S.TagWrapper>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  {props?.ppgs?.length > 0 ? (
                    props.ppgs.slice(0, 3).map((ppg: any) => (
                      <ContentTag
                        hrefBlank={`/ppg/detalhe/${ppg.uniqId}`}
                        title={ppg.name}
                      >
                        {ppg.name}
                      </ContentTag>
                    ))
                  ) : (
                    <div
                      className="not-found"
                      title="Não encontrado nas maiores plataformas do Brasil"
                    />
                  )}

                  {props?.ppgs?.length > 3 && (
                    <Button link onClick={handleOpenKeywordsModal}>
                      {' '}
                      Ver mais
                    </Button>
                  )}
                  <ModalContent
                    modalType="tag"
                    isOpen={isKeywordsModalOpen}
                    onRequestClose={handleCloseKeywordsModal}
                    tags={props.ppgs}
                    title="Programa de Pós graduação"
                  />
                </div>
              </S.TagWrapper>

              <S.CiteWrapper>
                {props.citations ? (
                  <p>{props.citations?.toLocaleString()}</p>
                ) : (
                  <div
                    className="not-found"
                    title="Não encontrado nas maiores plataformas do Brasil"
                  />
                )}

                {/* <p>Artigos</p> */}
              </S.CiteWrapper>
            </>
          ) : (
            <S.PremiumWrapper>
              <Button size="small" color="blueMidtone" shadow>
                <img src={PremiumIcon} alt="" />
                <p>Seja Premium e tenha acesso completo</p>
              </Button>
            </S.PremiumWrapper>
          )}

          <S.IndexWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            {ICA != null && ICAPermission ? (
              <p>
                Caciq-a{' '}
                <strong>
                  {Number(ICA) >= 1 ? Number(ICA) : Number(ICA).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}
            {ICH4 != null && ICH4Permission ? (
              <p>
                Caciq-h4A{' '}
                <strong>
                  {Number(ICH4) >= 1 ? Number(ICH4) : Number(ICH4).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}

            {I10 != null && I10Permission ? (
              <p>
                Web-h{' '}
                <strong>
                  {Number(I10) >= 1 ? Number(I10) : Number(I10).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}
            {HALL != null && HALLPermission ? (
              <p>
                Web-h4{' '}
                <strong>
                  {Number(HALL) >= 1 ? Number(HALL) : Number(HALL).toFixed(3)}
                </strong>
              </p>
            ) : (
              ''
            )}
          </S.IndexWrapper>

          <S.ButtonWrapper>
            {
              // TODO: fazer prefetch no botão de Saiba Mais do usuário pagante
            }
            {props.uniqId ? (
              <Button
                shadow
                href={`/autor/detalhe/${props.uniqId}`}
                disabled={!internalResultPermission}
              >
                Saiba mais
              </Button>
            ) : (
              ''
            )}
          </S.ButtonWrapper>
        </S.Content>
      </S.Container>
      <S.Divider />

      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            top: '40px',
            height: 'fit-content',
            margin: 'auto',
            maxWidth: '430px',
            width: '100%',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={isReadMoreOpen}
        onRequestClose={handleCloseModal}
      >
        <button
          type="button"
          onClick={handleCloseModal}
          style={{
            top: 20,
            right: 20,
            position: 'absolute',
            background: 'none',
            border: 'none',
            zIndex: 999,
          }}
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>

        {/* {props.registered === false ? (
          <ModalContent
            title="Cadastre-se e ganhe 14 dias grátis para ver esse conteúdo."
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            buttonDefaultText="Cadastre-se"
            buttonSecondaryText="Voltar a navegação"
            onClickGhost={handleCloseModal}
          />
        ) : (
          <ModalContent
            alert
            title="Ops! Você precisa ser assinante para ver este conteúdo."
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            buttonDefaultText="Ver planos de assinatura"
            buttonSecondaryText="Voltar a navegação"
            onClickGhost={handleCloseModal}
          />
        )} */}
      </Modal>
    </>
  );
};

export default AuthorResultCard;
