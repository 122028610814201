/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import Button from '../../../../components/Button';
import LoadingAnimation from '../../../../components/LoadingAnimaton';
import ArticleResultsHeader from '../ArticleResultsHeader';
import ArticleResultCard from '../ArticleResultCard';
import * as S from './styles';
import getClient from '../../../../services/client/elasticsearch';
import ErrorAnimation from '../../../../components/ErrorAnimation';
import FetchingAnimation from '../../../../components/FetchingAnimation';
import { usePermission } from '../../../../hooks/permission';
import useQueryUrl from '../../../../hooks/queryUrl';

interface ArticlesResultsTableProps {
  premium: boolean;
  registered: boolean;
  searchTerm?: string;
  setSearchTerm?: any;
  dateSearch?: string[];
  sortSearch?: any;
  setSortSearch?: any;
  pageSize?: number;
  isRanking?: boolean;
}

const ArticlesResultsTable: React.FC<ArticlesResultsTableProps> = props => {
  const { Permissions } = usePermission();
  const query = useQueryUrl();
  const [articleList, setArticleList] = useState([]);
  const [forcePage, setForcePage] = useState<number>(null);
  const [pageNumber, setPageNumber] = useState(0);

  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageCount = Math.ceil(totalPages / props.pageSize);

  const keyWordsPermission = Permissions('Result_Keywords');
  const citationsPermission = Permissions('Result_Citations');

  // TODO: create useQuery hook

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentSearch = query.get('termo') || '';
  useEffect(() => {
    if (currentSearch !== '') {
      props.setSearchTerm(currentSearch);
    }
  }, []);

  useMemo(() => {
    if (props.searchTerm) {
      query.set('termo', props.searchTerm);
    } else query.delete('termo');

    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  }, [props.searchTerm]);

  useEffect(() => {
    const currentPage = query.get('page');
    const currentPageNumber = parseInt(currentPage);
    setTimeout(() => {
      if (currentPageNumber > 0) {
        setForcePage(currentPageNumber - 1);
        setPageNumber(currentPageNumber - 1);
        query.set('page', currentPageNumber.toString());
        window.history.replaceState(
          {},
          '',
          `${location.pathname}?${query.toString()}`,
        );
      }
    }, 100);
  }, []);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    query.set('page', (selected + 1).toString());
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${query.toString()}`,
    );
  };

  const defaultSearch = {
    index: 'articles',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      sort: [props.sortSearch],
    },
  };

  const customSearch = {
    index: 'articles',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        bool:
          props.searchTerm !== '' && props.dateSearch.length > 0
            ? {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match: {
                            title: props.searchTerm,
                          },
                        },
                        {
                          match: {
                            keywords: props.searchTerm,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: props.dateSearch?.map(year => ({
                        match: { publicationYear: year },
                      })),
                    },
                  },
                ],
              }
            : {
                should: [
                  {
                    match: {
                      title: props.searchTerm,
                    },
                  },

                  {
                    match: {
                      keywords: props.searchTerm,
                    },
                  },

                  {
                    bool: {
                      should:
                        props.dateSearch.length > 0
                          ? props.dateSearch?.map(year => ({
                              match: { publicationYear: year },
                            }))
                          : [{ match: { links: props.searchTerm } }],
                    },
                  },
                ],
              },
      },
      sort: [props.sortSearch],
    },
  };

  const exactMatchSearch = {
    index: 'articles',
    from: pageNumber * props.pageSize,
    size: props.pageSize,
    body: {
      query: {
        query_string: {
          fields: ['title'],
          query: props.searchTerm,
        },
      },
    },
  };

  const {
    data: articles,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    [
      'articles',
      pageNumber,
      props.pageSize,
      props.searchTerm,
      props.dateSearch,
      props.sortSearch,
    ],
    async () => {
      const response = await getClient().search(
        (props.searchTerm !== '' || props.dateSearch.length > 0) &&
          !props.searchTerm.includes('"')
          ? customSearch
          : (props.searchTerm !== '' || props.dateSearch.length > 0) &&
            props.searchTerm.includes('"')
          ? exactMatchSearch
          : defaultSearch,
      );
      const count = await getClient().count({ index: 'articles' });
      const data = await response.hits.hits;
      const totalData: any = await response.hits.total;
      setArticleList(data);
      setTotalPages(totalData.value);

      if (props.searchTerm !== '' || props.dateSearch.length > 0) {
        setTotalResults(totalData.value);
      } else {
        setTotalResults(count.count);
      }
      return data;
    },
    {
      initialData: [],
    },
  );

  const displayResults = articleList.map((article: any, index) => {
    return (
      <ArticleResultCard
        rank={index + 1 + pageNumber * props.pageSize}
        title={article._source.title || article._source.DOI}
        uniqId={article._source.uniqId}
        authors={article?._source?.authorsRel?.map(author => author)}
        keywords={
          keyWordsPermission
            ? article?._source?.keywords?.map(keyword => keyword)
            : []
        }
        citedArticles={citationsPermission && article?._source?.total_refs_citation}
        indexes={article?._source?.indexes?.['IC-T']}
        premium={props.premium}
        registered={props.registered}
        downloadableArticle={article?._source?.attached_file || null}
        publicationSite={article?._source?.DOI}
        setSearchTerm={props.setSearchTerm || null}
        isRanking={props.sortSearch?.[Object.keys(props.sortSearch).toString()]}
      />
    );
  });

  return (
    <>
      <S.Container>
        <S.IntroWrapper>
          <div className="title-header">
            {props.searchTerm !== '' || props.dateSearch.length > 0 ? (
              <h3>
                Foram encontrados <span>{totalResults.toLocaleString()}</span>{' '}
                resultados
              </h3>
            ) : (
              <h3>
                <span>{totalResults.toLocaleString()}</span> textos foram
                avaliados pela plataforma
              </h3>
            )}
            {!isLoading && isFetching && <FetchingAnimation />}
          </div>
        </S.IntroWrapper>

        <S.ResultsWrapper>
          <ArticleResultsHeader
            isRanking={
              props.sortSearch?.[Object.keys(props.sortSearch).toString()]
            }
          />
          {isLoading ? (
            <LoadingAnimation />
          ) : error ? (
            <ErrorAnimation />
          ) : (
            displayResults
          )}
        </S.ResultsWrapper>

        {props.premium ? (
          <>
            <S.PaginationContainer>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                forcePage={forcePage}
                onPageChange={changePage}
                containerClassName="pagination-buttons"
                previousLinkClassName="pagination-previous-buttons"
                nextLinkClassName="pagination-next-buttons"
                disabledClassName="pagination-disabled"
                activeClassName="pagination-active"
                onUpdate={window.scrollTo({ top: 450, behavior: 'smooth' })}
              />
            </S.PaginationContainer>
          </>
        ) : (
          <>
            <S.Block>{}</S.Block>
            <S.ReadMore>
              <Button href="/usuario/planos">
                Cadastre-se e veja mais resultados
              </Button>
            </S.ReadMore>
          </>
        )}
      </S.Container>
    </>
  );
};

export default ArticlesResultsTable;
