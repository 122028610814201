import React from 'react';
import * as S from './styles';
import { theme } from '../../../../styles/global';
import { ReactComponent as Check } from '../../../../assets/check-icon.svg';

export interface FeatureProps {
  active: boolean;
  featureDetail: string;
  journalOrPpg?: boolean;
}

const PlanFeatures: React.FC<FeatureProps> = ({
  active,
  featureDetail,
  journalOrPpg,
  ...props
}) => {
  return (
    <>
      <S.Container>
        {journalOrPpg ? (
          <Check
            width={22}
            height={23}
            stroke={!active ? theme.colors.purpleDefault : theme.colors.grey6}
          />
        ) : (
          <Check
            width={22}
            height={23}
            stroke={!active ? theme.colors.blueMidtone : theme.colors.grey6}
          />
        )}

        <S.FeatureDetail
          style={{
            color: !active ? theme.colors.grey2 : theme.colors.grey6,
          }}
        >
          {featureDetail}
        </S.FeatureDetail>
      </S.Container>
    </>
  );
};

export default PlanFeatures;
