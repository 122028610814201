/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import UploadFile from '../../components/uploadPictureInput';
import CtaBanner from '../../components/CtaBanner';
import FetchingAnimation from '../../components/FetchingAnimation';
import * as S from './styles';
import Payment from '../../components/paymentPopUp';
import { useAccount } from '../../hooks/accountInf';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import { useError } from '../../hooks/errors';

const AccountConfiguration = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { accountInf, getAccount } = useAccount();

  useEffect(() => {
    if (!accountInf) getAccount();
  }, []);

  const finishAccount = () => {
    history.push('/usuario/pagamento');
  };

  const planRecurrence = accountInf?.subscription?.recurrence;
  const accessType = accountInf?.subscription?.accessType;
  return (
    <>
      <S.MainContainer>
        <S.PerfilSection>
          <section className="perfil-container">
            <div className="user-information">
              <h2>{user.name}</h2>
              <span>
                Plano:
                {accessType === 'researcher'
                  ? ' Pesquisador '
                  : accessType === 'author'
                  ? ' Autor'
                  : accessType === 'ppg'
                  ? ' Programa de Pós Graduação'
                  : accessType === 'journal'
                  ? 'Periódico'
                  : ' Sem Plano'}
              </span>
            </div>
            {accountInf?.subscription?.status !== 'authorized' && (
              <div className="pending-plan">
                <span>
                  Seu Plano está <b>Pendente</b>, gostaria de terminar o
                  cadastro?
                </span>
                <Button size="small" ghost onClick={() => finishAccount()}>
                  Finalizar cadastro
                </Button>
                {/* <p>
                  Se seu plano já foi finalizado e está mensagem contínuar
                  aparecendo, ignorá-la.
                </p> */}
              </div>
            )}
          </section>
          {planRecurrence !== undefined && (
            <AccountInformation
              accountInf={accountInf?.subscription}
              planRecurrence={planRecurrence}
              accessType={accessType}
            />
          )}
        </S.PerfilSection>
      </S.MainContainer>
      <CtaBanner />
    </>
  );
};

const AccountInformation = ({ accountInf, planRecurrence, accessType }) => {
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [editPlan, setEditPlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const cancelPlan = async () => {
    try {
      setLoading(true);
      const response = await api.get('/subscriptions/cancel');

      setLoading(false);
      window.location.reload();
    } catch (error: any) {
      setLoading(false);
      emitError(error, 'Erro ao cancelar plano');
    }
  };

  const openEditPlan = () => {
    setEditPlan(!editPlan);
  };

  return (
    <>
      {editPlan && (
        <Payment
          isOpen={editPlan}
          setPopUpState={setEditPlan}
          url={accountInf?.formUrl}
        />
      )}
      <S.AccountContainer>
        <div className="plan-information">
          <h3 className="plan-title">Informações do Plano </h3>
          <h6>
            <span title="shortcode">{accountInf?.shortCode}</span>
          </h6>
        </div>
        <section className="account-configuration">
          <div className="subscription-details subscription-cards">
            <ul>
              <li className="price-value">
                <span>
                  Valor:
                  {planRecurrence === 'monthly'
                    ? ' Mensal'
                    : planRecurrence === 'yearly'
                    ? ' Anual'
                    : ''}
                </span>
                <h1>
                  {(accountInf?.price / 100)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </h1>
              </li>
              <li>
                <Button onClick={openEditPlan}>
                  Editar informações de pagamento
                </Button>
                <button
                  type="button"
                  className="cancel-subscription"
                  onClick={() => setCancelSubscription(!cancelSubscription)}
                >
                  {cancelSubscription
                    ? 'Tem certeza que deseja continuar com essa ação?'
                    : 'Cancelar assinatura'}
                </button>

                {cancelSubscription && (
                  <div className="procede-action">
                    <button type="button" onClick={() => cancelPlan()}>
                      Sim, quero cancelar
                    </button>
                    <button
                      type="button"
                      onClick={() => setCancelSubscription(false)}
                    >
                      Não, não quero cancelar
                    </button>
                  </div>
                )}
              </li>
            </ul>
          </div>

          <div className="subscription-actions subscription-cards">
            <ul>
              <li>
                <span>
                  Status:
                  <b
                    style={{
                      color:
                        accountInf?.status === 'authorized'
                          ? '#10b32b'
                          : '#d5d904',
                    }}
                  >
                    {accountInf?.status === 'authorized'
                      ? ' Autorizado'
                      : ' Pendente'}
                  </b>
                </span>
              </li>
              <li>
                <span>
                  Tipo de plano:
                  <b>
                    {accessType === 'researcher'
                      ? ' Pesquisador '
                      : accessType === 'author'
                      ? ' Autor'
                      : accessType === 'ppg'
                      ? ' PPG'
                      : accessType === 'journal'
                      ? 'Periódico'
                      : ' Sem Plano'}
                  </b>
                </span>
              </li>
              <li>
                <span>
                  Código: <b>{accountInf?.shortCode}</b>
                </span>
              </li>
            </ul>
          </div>
        </section>
      </S.AccountContainer>
    </>
  );
};

export default AccountConfiguration;
