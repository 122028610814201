import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 306px;
  min-height: 4px;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.grey6};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  flex-grow: 1;
  margin-right: 16px;

  &:last-child {
    margin-right: 0px;
  }

  h4 {
    ${({ theme }) => theme.fonts.mulish.h2};
    min-height: 64px;
    font-size: 25px;
    text-align: center;
  }

  h2 {
    color: ${({ theme }) => theme.colors.grey2};
    font-size: 40px;
    text-align: center;
    margin-bottom: 16px;

    span {
      color: ${({ theme }) => theme.colors.grey2};
    }
  }

  .features-container {
    width: 100%;
    max-width: 230px;
    margin: auto auto 0 auto;
  }
`;
