import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/auth';

const OrcidLogin = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const code = params.get('code');

  const { orcidSignIn } = useAuth();

  useEffect(() => {
    if (!code?.length) return;
    orcidSignIn(code);
  }, [code]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', padding: '100px 0' }}
    >
      <h3>Estamos autenticando seus dados...</h3>
    </div>
  );
};

export default OrcidLogin;
