import styled, { css } from 'styled-components';

interface FormProp {
  dateProp: boolean;
}

export const StyledForm = styled.div<FormProp>`
  min-width: ${prop => (prop.dateProp ? '120px' : '272px')};
  display: flex;
  flex-direction: ${prop => (prop.dateProp ? 'row' : 'column')};
  align-items: ${prop => (prop.dateProp ? 'center' : 'flex-start')};

  .css-b62m3t-container {
    min-width: ${prop => (prop.dateProp ? '120px' : '272px')};
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    border-radius: 10px;
    box-shadow: none !important;
    ${prop => {
      if (prop.dateProp) {
        return css`
          height: 20px;
          margin-top: 0px;
          background: none;
          border: none;
        `;
      }

      return css`
        height: 45px;
        margin-top: 12px;
        background: white;
        border: 1.5px solid #dadee5;
      `;
    }}
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  label {
    font-family: 'Mulish', sans-serif;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #17a1a1;
  }
`;
