/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useRef } from 'react';
import { PopUp, MainContainer } from './styles';

interface PaymentProps {
  isOpen: boolean;
  setPopUpState: any;
  getStatus?: () => void;
  url?: string;
  setGreettings?: any;
}

export const Payment = ({
  isOpen,
  setPopUpState,
  getStatus,
  url,
  setGreettings,
}: PaymentProps) => {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    if ((popUpRef.current! as any).contains(e.target)) {
      return;
    }
    setPopUpState(false);
    if (setGreettings) setGreettings(true);
    if (getStatus) getStatus();
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <MainContainer>
      <PopUp ref={popUpRef}>
        <iframe src={url} />
      </PopUp>
    </MainContainer>
  );
};

export default Payment;
