/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import * as S from './styles';

interface AnaliseIndex {
  message?: string;
}

const AnaliseIndex = ({
  message = 'Em breve você poderá divulgar nas redes sua produção científica a partir da Plataforma Caciq',
}: AnaliseIndex) => {
  return (
    <S.Analise>
      <h4>
        <span>Estamos verificando seu perfil.</span>
      </h4>
      <p>{message}</p>
    </S.Analise>
  );
};

export default AnaliseIndex;
