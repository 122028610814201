/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import * as S from './styles';

interface Headerprops {
  isRanking?: boolean;
}

const PPGsResultsHeader = ({ isRanking = false }: Headerprops) => {
  const HeaderTitles = {
    col1: 'Curso',
    col2: 'Universidade',
    col3: 'Nota Capes',
    col4: 'Áreas de Avaliação',
    col5: 'Índices',
  };

  return (
    <>
      <S.Container>
        {isRanking && (
          <S.RankWrapper>
            <h3>Ranque</h3>
          </S.RankWrapper>
        )}
        <S.CourseWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col1}</h3>
          </S.HeaderBtn>
        </S.CourseWrapper>

        <S.CourseWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col2}</h3>
          </S.HeaderBtn>
        </S.CourseWrapper>

        <S.CiteWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col3}</h3>
          </S.HeaderBtn>
        </S.CiteWrapper>

        <S.TagWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col4}</h3>
          </S.HeaderBtn>
        </S.TagWrapper>

        <S.IndexWrapper>
          <S.HeaderBtn type="button">
            <h3>{HeaderTitles.col5}</h3>
          </S.HeaderBtn>
        </S.IndexWrapper>

        <S.ButtonWrapper />
      </S.Container>
      <S.Divider />
    </>
  );
};

export default PPGsResultsHeader;
