import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const NavBar = styled.nav`
  width: 100%;
  max-width: 1184px;
  height: 78px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  padding: 0 60px;

  background: #fff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  ${mq.custom(
    css`
      width: 100%;
      height: auto;
      padding: 20px;
      flex-direction: column;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;

    ${mq.custom(
      css`
        display: flex;
        flex-direction: column;
        gap: 18px;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}

    li a {
      margin: 12px;
      font-family: 'Work Sans', sans-serif;
    }
    li:nth-last-child(2) {
      margin-right: 24px;
    }
    li:last-child {
      margin-right: 0px;
    }
  }

  .go-back-btn {
    display: flex;
    background: none;
    border: 0;
    justify-content: initial;
    align-items: center;
    padding: 10px;

    text-decoration: none;

    svg {
      width: 34px;
      padding-right: 12px;
    }

    span {
      font-family: 'Work Sans', sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.5px;

      color: #636e6f;

      &:hover {
        color: #6d216c;
      }
    }
  }
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContainerNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
`;

export const InnerLink = styled.div`
  a {
    font-family: 'Work Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.5px;

    text-decoration: none;
    color: #636e6f;

    &:hover,
    &:active {
      color: #17a1a1;
    }
  }

  .active-link {
    color: #17a1a1;
  }
`;
