/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { userFields } from '../../fakeData';
import getClient from '../../../../services/client/elasticsearch';
import { ReactComponent as PDFIcon } from '../../../../assets/Pdf-icon.svg';
import PopUpAddFile from '../../../../components/PopUpAddFile';
import { usePermission } from '../../../../hooks/permission';
import * as S from './styles';

interface AuthorProps {
  articles?: string[];
}

const AuthorArticles = ({ articles = [] }: AuthorProps) => {
  const { data: articlesList, isLoading } = useQuery(
    ['articles', articles],
    async () => {
      const response = await getClient().search({
        index: 'articles',
        size: 100,
        body: {
          query: {
            bool: {
              should: articles?.map(id => ({
                match: { _id: id },
              })),
            },
          },
        },
      });

      const data = await response.hits.hits;
      return data;
    },
    {
      initialData: [],
    },
  );

  const [fixIndex, setFixIndex] = useState(1);
  return (
    <>
      <S.Container>
        <section className="author-data">
          <div className="section-title">
            <h3>Meus Textos</h3>
            {/* <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              vulputate risus, sed a enim enim integer. Tempus tellus
              condimentum pellentesque ut vivamus odio tristique. Egestas id
              suspendisse sit felis eleifend diam semper.
              <a href="https://google.com">Saiba mais.</a>
            </h6> */}
          </div>
        </section>
        <S.Content fixIndex={fixIndex}>
          <ul>
            <li>ORIGEM</li>
            <li>PDF</li>
            <li>TÍTULO</li>
            <li>Caciq-t</li>
            <li />
          </ul>

          <div className="content-section">
            {articlesList.map((item: any, index) => (
              <ul>
                <li> Importado da web </li>
                <li>
                  {item?._source?.attached_file ? (
                    <a
                      href={item?._source?.attached_file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PDFIcon />
                    </a>
                  ) : (
                    '-'
                  )}
                </li>
                <li>{item?._source?.title}</li>
                <li>
                  <p>
                    {item?._source?.indexes?.['IC-T']
                      ? Number(item?._source?.indexes?.['IC-T'])
                      : '-'}
                  </p>
                </li>

                <li>
                  <div className="buttons-column">
                    <Link
                      to={`/textos/detalhe/${item?._source?.uniqId}`}
                      className="see-page-button"
                    >
                      Visualizar página
                    </Link>

                    <AddPDF
                      id={item?._source?.id}
                      setFixIndex={setFixIndex}
                      index={index}
                    />
                  </div>
                </li>
              </ul>
            ))}
          </div>
        </S.Content>
      </S.Container>
    </>
  );
};

const AddPDF = ({ id, setFixIndex, index }) => {
  const [addPdf, setAddPdf] = useState(false);
  const { Permissions } = usePermission();
  const addFilePermission = Permissions('Attach_Files');

  const addPdfOnClick = () => {
    if (addFilePermission) {
      setAddPdf(true);
      setFixIndex(index);
    } else return null;
  };

  return (
    <S.UploadPDF>
      {addPdf ? (
        <PopUpAddFile setPopUpState={setAddPdf} isOpen={addPdf} id={id} />
      ) : null}
      <button
        type="button"
        className={
          !addFilePermission
            ? 'add-pdf-button disabled-pdf-btn'
            : 'add-pdf-button'
        }
        onClick={addPdfOnClick}
      >
        Adicionar pdf
      </button>

      <input type="text" />
    </S.UploadPDF>
  );
};

export default AuthorArticles;
