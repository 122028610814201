import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentTag from '../ContentTag';
import ModalContent from '../ModalContent';
import * as S from './styles';
import Button from '../Button';

export interface ContentProps {
  type: 'article' | 'author' | 'journal' | 'ppg';
  item: string;
  properties: string;
  text: string;
  caciqIndex?: ReactNode;
  tags?: string[] | any;
  valuationAreas?: string[];
  university?: string;
  href?: string;
  modalHref?: string;
}

const RelatedContent: React.FC<ContentProps> = ({
  item,
  text,
  properties,
  caciqIndex,
  tags,
  type,
  valuationAreas,
  university,
  href,
  modalHref,
  ...props
}) => {
  const [isAuthorsModalOpen, setIsAuthorsModalOpen] = useState(false);

  const history = useHistory();

  function seeMore() {
    window.scrollTo(0, 0);
    history.push(href);
  }

  function handleOpenAuthorsModal() {
    setIsAuthorsModalOpen(true);
  }
  function handleCloseAuthorsModal() {
    setIsAuthorsModalOpen(false);
  }

  return (
    <>
      <ModalContent
        modalType="tag"
        isOpen={isAuthorsModalOpen}
        onRequestClose={handleCloseAuthorsModal}
        href={modalHref}
        tags={tags}
        title="Autores"
      />

      <S.Container {...props}>
        <S.Title>{item || 'Não cadastrado'}</S.Title>
        <S.Text>{text || 'Não cadastrado'}</S.Text>

        <S.Title>{properties}</S.Title>
        <S.ContainerRow>
          <div className="property-container">
            {tags ? (
              type === 'author' ? (
                tags?.map(
                  (tag, index) =>
                    index < 2 && (
                      <>
                        <div
                          style={{
                            marginRight: 8,
                            marginBottom: 10,
                          }}
                        >
                          <ContentTag href={tag?.href}>
                            {tag?.title || tag}
                          </ContentTag>
                        </div>
                      </>
                    ),
                )
              ) : type === 'article' ? (
                tags?.map(
                  (tag, index) =>
                    index < 2 && (
                      <>
                        <div
                          style={{
                            marginRight: 8,
                            marginBottom: 10,
                          }}
                        >
                          <ContentTag href={tag?.href}>
                            {tag?.title || tag}
                          </ContentTag>
                        </div>
                      </>
                    ),
                )
              ) : null
            ) : type === 'journal' ? (
              valuationAreas ? (
                valuationAreas?.map(area => (
                  <p className="text-content">{area} </p>
                ))
              ) : (
                <p style={{ color: '#A9B3C4' }}>-</p>
              )
            ) : type === 'ppg' ? (
              <p className="text-content">
                {university || <p style={{ color: '#A9B3C4' }}>-</p>}
              </p>
            ) : (
              <p style={{ color: '#A9B3C4' }}>-</p>
            )}

            {tags
              ? tags.length > 2 && (
                  <div style={{ marginRight: 8, marginBottom: 10 }}>
                    <ContentTag onClick={handleOpenAuthorsModal}>+</ContentTag>
                  </div>
                )
              : null}
          </div>
        </S.ContainerRow>

        <S.Title>Índices</S.Title>

        <S.ContainerRow>
          {caciqIndex || <p style={{ color: '#A9B3C4' }}>-</p>}
        </S.ContainerRow>

        <S.ButtonContainer>
          <Button color="blueMidtone" size="small" onClick={seeMore}>
            Saiba mais
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </>
  );
};

export default RelatedContent;
