/* eslint-disable consistent-return */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { ReactComponent as Search } from '../../assets/search-right.svg';

export interface ButtonProps {
  ghost?: boolean;
  size?: 'small' | 'big';
  shadow?: boolean;
  link?: boolean;
  href?: string;
  rightImage?: 'arrow' | 'search';
  customFunction?: any;
  typeOf?: string;
  search?: boolean;
  arrow?: boolean;
  disabled?: boolean;

  /* the color will be 'purple' if color isn't set */
  color?: 'blueMidtone' | 'blueDefault';
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  rightImage,
  href,
  disabled,
  onClick,
  ...props
}) => {
  const history = useHistory();
  const renderImage = useCallback(() => {
    let color = props.ghost ? '#932D91' : 'transparent';

    if (disabled) color = '#f0f';

    if (!rightImage) return null;

    if (rightImage === 'arrow') {
      return <ArrowRight style={{ marginLeft: 10 }} fill={color} />;
    }
    if (rightImage === 'search') {
      return <Search style={{ marginLeft: 10 }} fill={color} />;
    }

    return null;
  }, [rightImage, props.ghost, disabled]);

  const handleButton = () => {
    if (!href && onClick) return onClick();
    if (href) return history.push(href);
    return null;
  };

  return (
    <S.StyledButton
      {...props}
      disabled={disabled}
      onClick={() => handleButton()}
    >
      {children}
      {renderImage()}
    </S.StyledButton>
  );
};

export default Button;
