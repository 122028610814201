/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';
import { useQuery } from 'react-query';
import PlanFeatures from './components/PlanFeature';
import Button from '../Button';
import { theme } from '../../styles/global';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import * as S from './styles';

// eslint-disable-next-line react/require-default-props
const PlanCard = ({ isPlansScreen }: { isPlansScreen?: boolean }) => {
  const { signed, user } = useAuth();

  const { data } = useQuery(
    ['price'],

    async () => {
      const response = await api.get('/subscriptions/prices');

      return response.data;
    },

    {
      initialData: {
        pfPriceMonthly: 29,
        pfPriceYearly: 290,
        pjPriceMonthly: 29,
        pjPriceYearly: 290,
        pjPPGnJNPriceYearly: 1290,
      },
    },
  );

  const plans = [
    {
      title: 'Plano Gratuito',
      price: null,
      href: '/usuario/registrar',
      features: [
        {
          title: 'Pesquisar Textos (5 primeiros resultados)',
        },
        {
          title: 'Pesquisar índices (5 primeiros resultados)',
        },
        {
          title: 'Pesquisar Autores, Periódicos e Programas de Pós Graduação',
          disabled: true,
        },
        {
          title:
            'Visualizar detalhes sobre Textos, Autores, Periódicos e Programas de Pós Graduação',
          disabled: true,
        },
      ],
      show: true,
    },

    {
      title: 'Autor e Pesquisador',
      price:
        user?.accountType === 'journal' || user?.accountType === 'ppg'
          ? data?.pjPriceMonthly
          : data?.pfPriceMonthly,
      href: '/usuario/registro-premium/',
      journalOrPpg: false,
      monthly: true,
      features: [
        {
          title: 'Pesquisar textos (resultados ilimitados)',
        },
        {
          title: 'Pesquisar índices (resultados ilimitados)',
        },
        {
          title: 'Pesquisar Autores, Periódicos e Programas de Pós Graduação',
        },
        {
          title:
            'Visualizar detalhes sobre Textos, Autores, Periódicos e Programas de Pós Graduação',
        },
      ],
      show: true,
    },
    {
      title: 'Revistas e PPG ',
      price:
        user?.accountType === 'journal' || user?.accountType === 'ppg'
          ? data?.pjPriceYearly
          : data?.pjPPGnJNPriceYearly,
      href: '/usuario/registro-premium-2',
      monthly: false,
      annually: true,
      journalOrPpg: true,
      features: [
        {
          title: 'Pesquisar textos (resultados ilimitados)',
        },
        {
          title: 'Pesquisar índices (resultados ilimitados)',
        },
        {
          title: 'Pesquisar Autores, Periódicos e Programas de Pós Graduação',
        },
        {
          title:
            'Visualizar detalhes sobre Textos, Autores, Periódicos e Programas de Pós Graduação',
        },
      ],
      show: true,
    },
  ];

  const freeSubscriptionLogin = [
    {
      title: 'Autor e Pesquisador',
      price: data?.pjPriceMonthly,
      href: '/usuario/pagamento',
      journalOrPpg: false,
      monthly: true,
      features: [
        {
          title: 'Pesquisar textos (resultados ilimitados)',
        },
        {
          title: 'Pesquisar índices (resultados ilimitados)',
        },
        {
          title: 'Pesquisar Autores, Periódicos e Programas de Pós Graduação',
        },
        {
          title:
            'Visualizar detalhes sobre Textos, Autores, Periódicos e Programas de Pós Graduação',
        },
        {
          title: 'Vincular PDFs aos seus textos (para autores verificados)',
        },
        {
          title: 'Mantenha seus dados atualizados',
        },
      ],
      show: !!(user?.accountType !== 'journal' && user?.accountType !== 'ppg'),
    },
    {
      title: 'Revistas e PPG ',
      price: isPlansScreen ? data?.pjPPGnJNPriceYearly : data?.pfPriceYearly,
      href: '/usuario/pagamento',
      monthly: false,
      annually: true,
      journalOrPpg: true,
      features: [
        {
          title: 'Pesquisar textos (resultados ilimitados)',
        },
        {
          title: 'Pesquisar índices (resultados ilimitados)',
        },
        {
          title: 'Pesquisar Autores, Periódicos e Programas de Pós Graduação',
        },
        {
          title:
            'Visualizar detalhes sobre Textos, Autores, Periódicos e Programas de Pós Graduação',
        },
        {
          title: 'Vincule autores (apenas para Programas de Pós-graduação)',
        },
        {
          title: 'Mantenha seus dados atualizados',
        },
      ],
      show: !!(
        user?.accountType !== 'researcher' && user?.accountType !== 'author'
      ),
    },
  ];

  return (
    <>
      {(signed ? freeSubscriptionLogin : plans).map((itens: any, index) => (
        <>
          {itens?.show && (
            <S.Container key={index}>
              <div className="card-header">
                <h4
                  style={{
                    color: itens.journalOrPpg
                      ? theme.colors.purpleDefault
                      : theme.colors.blueMidtone,
                  }}
                >
                  {itens.title}
                </h4>
                {itens.price && (
                  <h2>
                    {itens.price && itens.monthly ? <h5>a partir de</h5> : null}
                    {itens.price?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                    {itens.monthly && <h5>por mês</h5>}

                    {itens.annually && <h5>por ano</h5>}
                  </h2>
                )}
              </div>
              <div className="features-container">
                {itens.features.map((features: any) => (
                  <div className="feature-itens">
                    <PlanFeatures
                      journalOrPpg={itens.journalOrPpg}
                      featureDetail={features.title}
                      active={features.disabled}
                    />
                  </div>
                ))}
              </div>

              <Button
                color={!itens.journalOrPpg ? 'blueDefault' : undefined}
                href={itens.href}
              >
                Assinar
              </Button>
            </S.Container>
          )}
        </>
      ))}
    </>
  );
};

export default PlanCard;
