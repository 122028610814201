/* eslint-disable import/extensions */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import * as S from './styles';
import Button from '../../../../components/Button';

interface ChangeProfile {
  message?: string;
}

const ChangeProfile = ({
  message = 'Quer mudar seu perfil para o de Autor?',
}: ChangeProfile) => {
  return (
    <S.Analise>
      <h2> {message} </h2>
      <Button href="/usuario/registro-premium/autor">Atualizar Perfil</Button>
    </S.Analise>
  );
};

export default ChangeProfile;
