import styled, { css } from 'styled-components';

import mq from '../../../../styles/mq';

export const Container = styled.div`
  width: 100%;
  height: 205px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f2f2f2;
  }

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 39px 49px;
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 217px;
  flex: 3;
  margin-right: 14px;

  ${mq.tablet(css`
    min-width: 277px;
  `)}

  p {
    color: ${({ theme }) => theme.colors.grey2};
    font-weight: 500;
    text-transform: uppercase;
  }

  ${mq.custom(
    css`
      max-width: 200px;
    `,
    { minWidth: 1023, maxWidth: 1150 },
  )}
`;

export const TagWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey2};

  &:not(:last-child) {
    margin-right: 14px;
  }

  flex: 1;
`;

export const CiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey2};
  }

  flex: 0.8;

  ${mq.custom(
    css`
      margin-right: 12px;
    `,
    { minWidth: 768, maxWidth: 1090 },
  )}
`;

export const RankWrapper = styled.div`
  margin-right: 14px;
  flex: 0.3;

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const IndexWrapper = styled.div`
  font-size: 14px;
  margin-right: 14px;

  flex: 0.8;

  p {
    font: ${({ theme }) => theme.fonts.mulish.p};
    font-weight: 900;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.grey2};
    margin-right: 10px;
  }

  span {
    font-weight: bold;
  }

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  height: 100%;

  flex: 1.3;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const PremiumWrapper = styled.div`
  /* width: 450px; */

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 14px;

  flex: 2.8;

  img {
    margin-right: 12px;
    padding: 0;
  }
`;

export const Divider = styled.div`
  margin: auto;
  border-bottom: 1.5px solid #dadee5;

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;
