/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
import Modal from 'react-modal';
import * as S from './styles';
import Button from '../Button';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import alertIcon from '../../assets/alert.svg';
import logoPopup from '../../assets/logo-popup.svg';
import articleIcon from '../../assets/article-icon.svg';
import ppgIcon from '../../assets/ppgIcon.svg';
import authorIcon from '../../assets/author-icon.svg';
import ContentTag from '../ContentTag';
import { DetailsCardProps } from '../DetailsCard';

export interface ModalContentProps extends Partial<DetailsCardProps> {
  isOpen: boolean;
  title?: string;
  onRequestClose: () => void;
  modalType?: 'tag' | 'alert';
  href?: string;
  hrefBlank?: string;
  tags?: string[] | any;
  handleCustomSearch?: any;
  alertContent?: {
    icon: 'alert' | 'logo';
    title: string;
    text: string;
    buttonDefaultText: string;
    buttonSecondaryText: string;
    onClickDefault?: () => void;
    onClickGhost?: () => void;
  };
}

const ModalContent: React.FC<ModalContentProps> = ({ tags = [], ...props }) => {
  return (
    <>
      <Modal
        style={{
          overlay: {
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            maxWidth: '800px',
            height: 'fit-content',
            maxHeight: props.modalType === 'tag' ? '80%' : 'fit-content',
            margin: 'auto',
            borderRadius: '20px',
            padding: props.modalType === 'tag' ? '40px' : '0px',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <S.ModalContent>
          <button
            type="button"
            onClick={props.onRequestClose}
            style={{
              top: 0,
              right: 0,
              position: 'absolute',
              background: 'none',
              border: 'none',
              zIndex: 9999,
            }}
          >
            <CloseImg />
          </button>
          {props.modalType === 'tag' ? (
            <>
              {props.type && (
                <S.TitleContainer {...props}>
                  <S.Title {...props}>
                    {props.type === 'citedKeywords' ? (
                      'Palavras-chave citadas'
                    ) : props.type === 'citedArticles' ? (
                      ' Textos Citados nas referências'
                    ) : props.type === 'verifiedArticles' ? (
                      <>
                        <img src={articleIcon} alt="" /> Textos Publicados
                      </>
                    ) : props.type === 'ppg' ? (
                      <>
                        <img src={ppgIcon} alt="" />
                        PPGs
                      </>
                    ) : props.type === 'citedAuthors' ? (
                      <>
                        <img src={authorIcon} alt="" />
                        Autores Citados
                      </>
                    ) : null}
                  </S.Title>

                  {props.total && (
                    <S.Total>
                      Total: <span>{props.total}</span>
                    </S.Total>
                  )}
                </S.TitleContainer>
              )}

              {props.title && (
                <S.TitleContainer {...props}>
                  <S.Title {...props}>{props.title}</S.Title>
                </S.TitleContainer>
              )}

              <S.TagWrapper>
                {tags?.map((tag: any, index: any) => (
                  <>
                    <S.ContainerTag>
                      <ContentTag
                        large
                        title={tag?.title || tag?.name || tag}
                        href={
                          props.href && `${props.href}/${tag.uniqId || tag}`
                        }
                        hrefBlank={
                          props.hrefBlank && `${props.hrefBlank}/${tag[1]}`
                        }
                        onClick={props.handleCustomSearch}
                      >
                        {tag?.title || tag?.name || tag}
                      </ContentTag>
                    </S.ContainerTag>
                  </>
                ))}
              </S.TagWrapper>
            </>
          ) : (
            <>
              <S.StyledPopUp {...props}>
                {props?.alertContent?.icon === 'alert' && (
                  <img src={alertIcon} alt="" style={{ marginBottom: 18 }} />
                )}
                {props?.alertContent?.icon === 'logo' && (
                  <img src={logoPopup} alt="" style={{ marginBottom: 18 }} />
                )}
                <S.TitleAlert>{props?.alertContent?.title}</S.TitleAlert>
                <S.TextAlert>{props?.alertContent?.text}</S.TextAlert>
                <Button onClick={props?.alertContent?.onClickDefault}>
                  {props?.alertContent?.buttonDefaultText}
                </Button>
                <S.LinkWrapper>
                  <Button link onClick={props?.alertContent?.onClickGhost}>
                    {props?.alertContent?.buttonSecondaryText}
                  </Button>
                </S.LinkWrapper>
              </S.StyledPopUp>
            </>
          )}
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default ModalContent;
