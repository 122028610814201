import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Img = styled.img`
  margin-right: 8px;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
`;

export const IndexName = styled.p`
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey2};
  align-self: center;
`;

export const IndexType = styled.span`
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const IndexValue = styled.h5`
  font: 16px Work Sans, sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.purpleDefault};
  margin-left: 5px;
`;
