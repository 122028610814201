/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import RelatedContent from '../../components/RelatedContent';
import CaciqIndex from '../../components/CaciqIndex';
import * as S from './styles';
import NumberOfCitations from '../../components/NumberOfCitations';
import { ReactComponent as AvatarImg } from '../../assets/avatar.svg';
import LoadingAnimation from '../../components/LoadingAnimaton';
import ErrorAnimation from '../../components/ErrorAnimation';
import FetchingAnimation from '../../components/FetchingAnimation';
import VerifyProfileCard from '../../components/VerifyProfileCard';
import { IPPG } from '../../models/ppg';
import { api } from '../../services/api';
import { usePermission } from '../../hooks/permission';
import getClient from '../../services/client/elasticsearch';

export interface PPGDetailsProps extends IPPG {
  avatar?: string;
  coordinator?: string;
  universitySite?: string;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  numberOfCitations?: number;
}

const PPGDetails: React.FC<PPGDetailsProps> = props => {
  const { Permissions } = usePermission();

  const [currentPPG, setCurrentPPG] = useState<any>([]);
  const [userPPG, setUserPPG] = useState<any>([]);
  const { details } = useParams() as any;

  const {
    data: ppg,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['ppgs', details],
    async () => {
      const response = await getClient().search({
        index: 'ppgs',
        size: 5,
        body: {
          query: {
            bool: {
              should: [
                {
                  match: {
                    name: details,
                  },
                },
                {
                  match: {
                    uniqId: details,
                  },
                },
              ],
            },
          },
        },
      });
      const data: any = await response.hits.hits;

      if (data[0]?._source?.userId) {
        const author = await api.post('/profile/public', {
          ids: [data[0]?._source?.userId],
        });

        setUserPPG(author?.data[0]);
      }

      return data;
    },
    {
      initialData: [],
    },
  );

  const universitySite: any = userPPG?.socials?.filter(
    e => e.name === 'universitySite' && e.public,
  );
  const coordinatorPPG: any = userPPG?.socials?.filter(
    e => e.name === 'coordinatorPPG' && e.public,
  );
  const lattes: any = userPPG?.socials?.filter(e => e.name === 'lattes');
  const personalWebsite: any = userPPG?.socials?.filter(
    e =>
      (e.name === 'personalWebsite' || e.name === 'personalSite') && e.public,
  );
  const instagram: any = userPPG?.socials?.filter(
    e => e.name === 'instagram' && e.public,
  );
  const facebook: any = userPPG?.socials?.filter(
    e => e.name === 'facebook' && e.public,
  );
  const twitter: any = userPPG?.socials?.filter(
    e => e.name === 'twitter' && e.public,
  );
  const linkedin: any = userPPG?.socials?.filter(
    e => e.name === 'linkedin' && e.public,
  );

  useEffect(() => {
    if (!ppg) return null;

    return setCurrentPPG(ppg[0]?._source);
  }, [ppg]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ICPPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');

  const ICP = currentPPG?.indexes?.['IC-P'];

  const H4P = currentPPG?.indexes?.['IC-H4-P'];

  return (
    <>
      <S.Container>
        {isLoading ? (
          <LoadingAnimation />
        ) : error ? (
          <ErrorAnimation />
        ) : (
          <>
            <S.AuthorWrapper>
              <S.ImageWrapper>
                {currentPPG?.avatar ? (
                  <img src={currentPPG?.avatar} alt="" />
                ) : (
                  <div className="perfil-photo">
                    <AvatarImg />
                  </div>
                )}
              </S.ImageWrapper>

              <S.AuthorInfoWrapper>
                <div className="NameWrapper">
                  <p>{currentPPG?.university}</p>
                  <h2>
                    {currentPPG?.name}{' '}
                    {!isLoading && isFetching && <FetchingAnimation />}
                  </h2>
                </div>
                <div className="IndexWrapper">
                  {ICP != null && ICPPermission ? (
                    <h5>
                      Caciq-p <strong>{Number(ICP)}</strong>
                    </h5>
                  ) : null}
                  {H4P != null && ICH4Permission ? (
                    <h5>
                      Caciq-h4P <strong>{Number(H4P)}</strong>
                    </h5>
                  ) : null}
                </div>

                <div className="OccupationArea">
                  <h6>Área de avaliação</h6>
                  <p>{currentPPG?.valuationArea}</p>
                </div>
              </S.AuthorInfoWrapper>
            </S.AuthorWrapper>

            <S.DetailsWrapper>
              <S.MoreDetailsContent>
                <NumberOfCitations
                  // eslint-disable-next-line no-octal
                  capesNumberInfo={currentPPG?.capesGrade || '-'}
                  textInfo="CAPES"
                />
              </S.MoreDetailsContent>

              <S.DetailsCardContent>
                <VerifyProfileCard
                  cardType="ppg"
                  coordinator={
                    coordinatorPPG?.length > 0 && coordinatorPPG[0]?.url
                  }
                  isVerifyed={userPPG?.userId || false}
                  universitySite={
                    universitySite?.length > 0 ? universitySite[0]?.url : null
                  }
                  lattes={lattes?.length > 0 ? lattes[0]?.url : null}
                  personalSite={
                    personalWebsite?.length > 0 ? personalWebsite[0]?.url : null
                  }
                  twitter={twitter?.length > 0 && twitter[0]?.url}
                  facebook={facebook?.length > 0 && facebook[0]?.url}
                  instagram={instagram?.length > 0 && instagram[0]?.url}
                  linkedin={linkedin?.length > 0 && linkedin[0]?.url}
                />
              </S.DetailsCardContent>
            </S.DetailsWrapper>
          </>
        )}
      </S.Container>

      <S.RelatedWrapper>
        {ppg.length > 1 && (
          <h3>Veja mais programas de pós graduação relacionados</h3>
        )}
        {isFetching ? (
          'Carregando artigos relacionados...'
        ) : error ? (
          'Erro ao carregar. Tente novamente'
        ) : (
          <S.ContainerCards>
            {ppg.slice(1, 4).map((item: any) => (
              <RelatedContent
                type="ppg"
                item="Curso"
                properties="Universidade"
                university={item?._source?.university}
                text={item._source?.name}
                href={`/ppg/detalhe/${item?._source?.uniqId}`}
                caciqIndex={
                  <CaciqIndex
                    type="ppg"
                    h4
                    name="IC-H4-P"
                    value={item?._source?.indexes?.['IC-H4-P']}
                  />
                }
              />
            ))}
          </S.ContainerCards>
        )}
      </S.RelatedWrapper>
    </>
  );
};

export default PPGDetails;
