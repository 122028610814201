import styled, { css } from 'styled-components';

import { DetailsCardProps } from '.';
import mq from '../../styles/mq';

export const Container = styled.div<DetailsCardProps>`
  width: ${props => (props.big ? '696px' : '342px')};
  min-height: 282px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1.5px solid ${({ theme }) => theme.colors.grey6};
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);

  padding: 24px;

  margin-bottom: 12px;

  /* Tablet */

  ${mq.tablet(css`
    width: 100%;
  `)}

  /* Mobile */

  ${mq.mobile(css`
    width: 100%;
    height: auto;
  `)}
`;

export const TitleContainer = styled.div<DetailsCardProps>`
  display: flex;
  flex-direction: row;

  img {
    margin-right: 18px;
  }
`;

export const ColumnWrapper = styled.div<DetailsCardProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 12px 0;
  overflow: hidden;
  gap: 10px;
`;

export const Title = styled.h5<DetailsCardProps>`
  display: flex;
  flex: 1;
  justify-content: ${props => {
    if (props.total) {
      return 'flex-start';
    }
    if (props.big) {
      return 'center';
    }
    return 'flex-start';
  }};

  color: ${({ theme }) => theme.colors.purpleDefault};
  font-weight: normal;
  font-size: 20px;
`;

export const Total = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey3} span {
    font-weight: bold;
    font-size: 20px;

    letter-spacing: 0.5px;

    /* Caciq/ Blue/ Midtone */

    color: #17a1a1;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerTag = styled.div`
  margin: 5px;
  display: flex;
  width: fit-content;
  height: fit-content;
`;

export const ModalContent = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
`;
