/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Modal from 'react-modal';
import React, { useState } from 'react';
import * as S from './styles';

import Button from '../Button';
import closeImg from '../../assets/close.svg';

import articleIcon from '../../assets/article-icon.svg';
import ppgIcon from '../../assets/ppgIcon.svg';
import authorIcon from '../../assets/author-icon.svg';
import ContentTag from '../ContentTag';
import ModalContent from '../ModalContent';

export interface DetailsCardProps {
  /* small default */
  big?: boolean;
  type:
    | 'citedKeywords'
    | 'citedArticles'
    | 'verifiedArticles'
    | 'ppg'
    | 'citedAuthors';
  total?: number;
  link?: string;
  href?: string;
  tags: string[] | any;
}

const DetailsCard = ({ ...props }: DetailsCardProps) => {
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

  function handleOpenModal() {
    setIsReadMoreOpen(true);
  }

  function handleCloseModal() {
    setIsReadMoreOpen(false);
  }

  return (
    <S.Container {...props}>
      <S.TitleContainer {...props}>
        <S.Title {...props}>
          {props.type === 'citedKeywords' ? (
            'Palavras-chave citadas'
          ) : props.type === 'citedArticles' ? (
            ' Textos citados nas referências'
          ) : props.type === 'verifiedArticles' ? (
            <>
              <img src={articleIcon} alt="" /> Artigos Publicados
            </>
          ) : props.type === 'ppg' ? (
            <>
              <img src={ppgIcon} alt="" />
              PPGs
            </>
          ) : props.type === 'citedAuthors' ? (
            <>
              <img src={authorIcon} alt="" />
              Autores Citados
            </>
          ) : null}
        </S.Title>

        {props.total && (
          <S.Total>
            Total: <span>{props.total}</span>
          </S.Total>
        )}
      </S.TitleContainer>

      {props.tags?.length ? (
        <S.ColumnWrapper {...props}>
          {props.big
            ? props.tags.map(
                (tag: any, index: any) =>
                  index < 8 && (
                    <ContentTag
                      large
                      title={tag?.title || tag}
                      hrefBlank={props.href && `${props.href}/${tag[1]}`}
                      href={props.link && `${props.link}/${tag.uniqId || tag}`}
                    >
                      {props.href ? tag[0] || tag[1] : tag?.title || tag}
                    </ContentTag>
                  ),
              )
            : props.tags.map(
                (tag: any, index: any) =>
                  index < 4 && (
                    <ContentTag
                      large
                      title={tag?.title || tag}
                      hrefBlank={props.href && `${props.href}/${tag[1]}`}
                      href={props.link && `${props.link}/${tag.uniqId || tag}`}
                    >
                      {tag?.title || tag}
                    </ContentTag>
                  ),
              )}
        </S.ColumnWrapper>
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ color: '#A9B3C4' }}>Sem dados para exibir</p>
        </div>
      )}

      <S.ButtonContainer>
        {props.tags?.length > 8 ? (
          <Button color="blueMidtone" link onClick={handleOpenModal}>
            + Ver mais
          </Button>
        ) : (
          ''
        )}
      </S.ButtonContainer>

      <ModalContent
        modalType="tag"
        isOpen={isReadMoreOpen}
        onRequestClose={handleCloseModal}
        hrefBlank={props.href}
        link={props.link}
        tags={props.tags}
        type={props.type}
      />
    </S.Container>
  );
};

export default DetailsCard;
