import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.purpleDefault};
  font-size: 23px;

  h3 {
    ${({ theme }) => theme.fonts.mulish.h5};
    color: ${({ theme }) => theme.colors.purpleDefault};
  }

  button {
    padding: 0;
    background: none;
    border: 0;
    ${({ theme }) => theme.fonts.mulish.h5};
    color: ${({ theme }) => theme.colors.purpleDefault};
    border-bottom: 1px solid;
  }
`;
