import styled, { css } from 'styled-components';

import mq from '../../../../../styles/mq';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 205px;
  width: 100%;

  transition: background-color 0.2s;

  &:hover {
    background-color: #f2f2f2;
  }

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const Content = styled.div`
  display: flex;
  max-width: 1366px;
  width: 100%;
  align-items: center;
  margin: 0 auto;

  height: 205px;
  padding: 39px 49px;
`;

export const RankWrapper = styled.div`
  margin-right: 14px;
  flex: 0.3;

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const IssnWrapper = styled.div`
  max-width: 100px;
  width: 100%;

  flex: 3;

  margin-right: 14px;

  ${mq.tablet(css`
    min-width: 100px;
  `)}

  p {
    color: ${({ theme }) => theme.colors.grey2};
    font-weight: 500;
  }

  ${mq.custom(
    css`
      max-width: 100px;
    `,
    { minWidth: 1023, maxWidth: 1150 },
  )}
`;

export const TitleWrapper = styled.div`
  max-width: 277px;
  padding-right: 50px;

  flex: 3;

  p {
    color: ${({ theme }) => theme.colors.grey2};
  }

  ${mq.custom(
    css`
      max-width: 277px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  ${mq.custom(
    css`
      max-width: 200px;
    `,
    { minWidth: 1023, maxWidth: 1150 },
  )}
`;

export const IndexWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: center;

  flex: 0.8;

  p {
    font: ${({ theme }) => theme.fonts.mulish.p};
    font-weight: 900;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.grey2};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  span {
    ${({ theme }) => theme.fonts.mulish.p};
    font-size: 13px !important;
  }

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  height: 100%;

  flex: 1.3;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const PremiumWrapper = styled.div`
  /* width: 450px; */

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 14px;

  flex: 2.8;

  img {
    margin-right: 12px;
    padding: 0;
  }
`;

export const Divider = styled.div`
  margin: auto;
  border-bottom: 1.5px solid #dadee5;

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;
