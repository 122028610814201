/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

import mq from '../../../../styles/mq';
import { InedxesHeadersProps } from '.';

interface HeaderBtn {
  enableClick?: boolean;
}

export const HeaderBtn = styled.button<HeaderBtn>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: ${prop => (prop.enableClick ? 'pointer' : 'default')};

  svg {
    transition: 0.3s;
  }

  h3 {
    margin-right: 8px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1366px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  margin: auto;
  padding: 10px 49px;

  h3 {
    font-size: 13px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.purpleDefault};
  }

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const Divider = styled.div`
  margin: auto;
  border-bottom: 1.5px solid #dadee5;
  width: 95%;

  ${mq.custom(
    css`
      width: 1366px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;

export const col00 = styled.div<InedxesHeadersProps>`
  font-size: 14px;
  margin-right: 14px;
  flex: 0.3;
`;

export const col01 = styled.div<InedxesHeadersProps>`
  ${props => {
    if (props.type === 'article') {
      return css`
        max-width: 600px;

        flex: 3;

        p {
          color: ${({ theme }) => theme.colors.grey2};
        }

        ${mq.custom(
          css`
            max-width: 500px;
          `,
          { minWidth: 0, maxWidth: 1023 },
        )}

        ${mq.custom(
          css`
            max-width: 600px;
          `,
          { minWidth: 1023, maxWidth: 1150 },
        )}
      `;
    }
    if (props.type === 'journal') {
      return css`
        max-width: 100px;
        align-items: start;

        flex: 3;

        p {
          color: ${({ theme }) => theme.colors.grey2};
        }

        ${mq.custom(
          css`
            max-width: 100px;
          `,
          { minWidth: 0, maxWidth: 1023 },
        )}

        ${mq.custom(
          css`
            max-width: 100px;
          `,
          { minWidth: 1023, maxWidth: 1150 },
        )}
      `;
    }
    if (props.type === 'author' || props.type === 'ppg') {
      return css`
        max-width: 277px;
        flex: 3;
        display: flex;

        p {
          color: ${({ theme }) => theme.colors.grey2};
        }

        ${mq.custom(
          css`
            max-width: 277px;
          `,
          { minWidth: 0, maxWidth: 1023 },
        )}

        ${mq.custom(
          css`
            max-width: 200px;
          `,
          { minWidth: 1023, maxWidth: 1150 },
        )}
      `;
    }
  }}
`;

export const col02 = styled.div<InedxesHeadersProps>`
  ${props => {
    if (props.type === 'article') {
      return css`
        display: flex;
        font-size: 14px;
        margin-right: 14px;

        flex: 0.8;
        text-align: center;
        align-items: center;
        justify-content: center;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
    if (props.type === 'author') {
      return css`
        font-size: 14px;
        display: flex;
        justify-content: center;

        flex: 0.8;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
          text-align: center;
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
    if (props.type === 'journal' || props.type === 'ppg') {
      return css`
        max-width: 277px;
        display: flex;
        align-items: center;

        flex: 3;

        p {
          color: ${({ theme }) => theme.colors.grey2};
        }

        ${mq.custom(
          css`
            max-width: 277px;
          `,
          { minWidth: 0, maxWidth: 1023 },
        )}

        ${mq.custom(
          css`
            max-width: 200px;
          `,
          { minWidth: 1023, maxWidth: 1150 },
        )}
      `;
    }
  }}
`;

export const col03 = styled.div<InedxesHeadersProps>`
  ${props => {
    if (props.type === 'article') {
      return css`
        display: flex;
        flex-direction: column;

        justify-content: 'center';
        align-items: center;
        height: 100%;

        flex: 1.3;

        &:not(:first-child) {
          margin-top: 10px;
        }

        ${mq.custom(
          css`
            flex: 1;
          `,
          { minWidth: 769, maxWidth: 1360 },
        )}

        ${mq.custom(
          css`
            flex: 1.3;
          `,
          { minWidth: 0, maxWidth: 1024 },
        )}
        visibility: hidden;
      `;
    }
    if (props.type === 'author') {
      return css`
        font-size: 14px;
        display: flex;
        justify-content: center;

        flex: 0.8;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
          text-align: center;
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
    if (props.type === 'journal' || props.type === 'ppg') {
      return css`
        font-size: 14px;
        display: flex;
        justify-content: center;

        flex: 0.8;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
          text-align: center;
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
  }}
`;

export const col04 = styled.div<InedxesHeadersProps>`
  ${props => {
    if (props.type === 'author') {
      return css`
        font-size: 14px;
        display: flex;
        justify-content: center;

        flex: 0.8;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
          text-align: center;
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
    if (props.type === 'journal' || props.type === 'ppg') {
      return css`
        text-align: left;
        font-size: 14px;
        display: flex;
        justify-content: center;

        flex: 0.8;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
          text-align: left;
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
  }}
`;

export const col05 = styled.div<InedxesHeadersProps>`
  ${props => {
    if (props.type === 'author') {
      return css`
        font-size: 14px;
        display: flex;
        justify-content: center;

        flex: 0.8;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
          text-align: center;
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
    if (props.type === 'journal') {
      return css`
        font-size: 14px;
        display: flex;
        justify-content: center;

        flex: 0.8;

        p {
          font: ${({ theme }) => theme.fonts.mulish.p};
          font-weight: 700;
          color: ${({ theme }) => theme.colors.grey2};
          text-align: center;
        }

        span {
          font-weight: bold;
        }

        strong {
          font: ${({ theme }) => theme.fonts.workSans.p};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blueDefault};
          font-size: 16px;
        }
      `;
    }
    if (props.type === 'ppg') {
      return css`
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        height: 100%;

        flex: 1.3;

        &:not(:first-child) {
          margin-top: 10px;
        }

        ${mq.custom(
          css`
            flex: 1.3;
          `,
          { minWidth: 769, maxWidth: 1360 },
        )}

        ${mq.custom(
          css`
            flex: 1.3;
          `,
          { minWidth: 0, maxWidth: 1024 },
        )}
        visibility: hidden;
      `;
    }
  }}
`;

export const col06 = styled.div<InedxesHeadersProps>`
  font-size: 14px;
  display: flex;
  justify-content: center;

  flex: 0.8;

  p {
    font: ${({ theme }) => theme.fonts.mulish.p};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey2};
    text-align: center;
  }

  span {
    font-weight: bold;
  }

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const col07 = styled.div<InedxesHeadersProps>`
  font-size: 14px;
  display: flex;
  justify-content: center;

  flex: 0.8;

  p {
    font: ${({ theme }) => theme.fonts.mulish.p};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey2};
    text-align: center;
  }

  span {
    font-weight: bold;
  }

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const col08 = styled.div<InedxesHeadersProps>`
  font-size: 14px;
  display: flex;
  justify-content: center;

  flex: 0.8;

  p {
    font: ${({ theme }) => theme.fonts.mulish.p};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey2};
    text-align: center;
  }

  span {
    font-weight: bold;
  }

  strong {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blueDefault};
    font-size: 16px;
  }
`;

export const col09 = styled.div<InedxesHeadersProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1.3;
`;
