/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { createNoSubstitutionTemplateLiteral } from 'typescript';
import { api } from '../../services/api';
import FetchingAnimation from '../FetchingAnimation';
import { ReactComponent as UploadIcon } from '../../assets/upload-icon.svg';
import { useError } from '../../hooks/errors';

import * as S from './styles';

const PopUpAddFile = React.forwardRef((props: any, ref: any) => {
  const [formName, setFormName] = useState(null);
  const [pdfName, setPdfName] = useState(null);
  const popUpRef = useRef(null);
  const [pdfResponse, setPdfResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  function handleValue() {
    toast.success('Pdf Adicionado');
    window.location.reload();
  }

  const handleClick = (e: MouseEvent) => {
    if ((popUpRef.current! as any).contains(e.target)) {
      return;
    }
    props.setPopUpState(false);
  };

  useEffect(() => {
    if (props.isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [props.isOpen]);

  async function handleFile(e: any) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      console.log(props.id);

      setLoading(true);
      const response = (await api.post(`/upload/attach/${props.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })) as any;
      setPdfResponse(response.data);
      console.log(response.data);
      setPdfName(response.data.file.linkUrl);
      setLoading(false);
      return response.data.linkUrl;
    } catch (error: any) {
      setLoading(false);
      setPdfResponse(error.message);
      emitError(error, 'Falha ao enviar arquivo');
    }
  }

  return (
    <S.MainContainer>
      <S.PopUp ref={popUpRef}>
        <div className="popUp-body">
          <h6>Adicionar PDF</h6>
          <h5>
            {props.description || 'Tem certeza que deseja adicionar um PDF?'}
          </h5>
        </div>

        <S.PDFSelector>
          <div className="input-field">
            <input type="file" id="pdf-file" onChange={e => handleFile(e)} />
            {pdfName === null ? (
              <label htmlFor="pdf-file"> Arquivo </label>
            ) : (
              <span>{pdfName}</span>
            )}
          </div>
          <label htmlFor="pdf-file" className="label-btn">
            Escolher Arquivo {loading ? <FetchingAnimation /> : <UploadIcon />}
          </label>
        </S.PDFSelector>

        <div className="buttons-section-popup">
          <button
            type="button"
            className={
              !pdfName
                ? 'send-information-button disabled-btn'
                : 'send-information-button'
            }
            onClick={handleValue}
          >
            Adicionar
          </button>

          <button
            type="button"
            className="cancel-button"
            onClick={() => props.setPopUpState(false)}
          >
            Cancelar
          </button>
        </div>
      </S.PopUp>
    </S.MainContainer>
  );
});

export default PopUpAddFile;
