import React from 'react';
import * as S from './styles';
import { ReactComponent as AvatarImg } from '../../../../assets/avatar.svg';
import Button from '../../../../components/Button';

export interface IAuthor {
  id: number;
  profileURL: string;
  avatar?: string;
  nameSttings: {
    name: string;
    isPublic: boolean;
  };
  cpf: string;
  birthDate: string;
  emailSettings: {
    email: string;
    isPublic: boolean;
  };
  citationNameSettings: {
    citationName: string;
    isPublic: boolean;
  };
  orcidSettings?: {
    orcid: string;
    isPublic: boolean;
  };
  lattesSettings?: {
    lates: string;
    isPublic: boolean;
  };
  linkedinSettings?: {
    linkedin: string;
    isPublic: boolean;
  };
  instagramSettings?: {
    instagram: string;
    isPublic: boolean;
  };
  twitterSettings?: {
    twitter: string;
    isPublic: boolean;
  };
  ppg?: string[];
}
interface VerifyAuthorProps {
  isAuthor: boolean;
  authors: IAuthor[];
}

const VerifyAuthor: React.FC<VerifyAuthorProps> = props => {
  return (
    <>
      <S.AuthorContent>
        {props.isAuthor ? (
          <>
            <p>Autores na plataforma</p>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {props.authors?.length > 0 ? (
                props.authors?.map(author => (
                  <>
                    <a href={author.profileURL}>
                      {author.avatar ? (
                        <img src={author.avatar} alt="Foto de perfil" />
                      ) : (
                        <div className="perfil-photo">
                          <AvatarImg />
                        </div>
                      )}
                    </a>
                  </>
                ))
              ) : (
                <p style={{ fontSize: '0.9rem' }}>
                  Ainda não há autores deste artigo na plataforma.
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            <p>
              Você é este autor?
              <br />
              <span>
                Ative sua conta <br />
              </span>
              e mantenha seu perfil atualizado
            </p>
            <Button
              size="small"
              color="blueMidtone"
              href="/usuario/registro-premium/autor"
            >
              Eu sou esse autor!
            </Button>
          </>
        )}
      </S.AuthorContent>
    </>
  );
};

export default VerifyAuthor;
