import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { api } from '../../services/api';
import { useError } from '../../hooks/errors';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search).get('code');
}

const OrcidLogin = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();
  const { emitError } = useError();

  const code = useQuery();

  const handleSync = async (token: string): Promise<void> => {
    try {
      const params = new URLSearchParams();
      params.append('token', token);

      await api.post(`/orcid/sync?token=${token}`);
      toast.success('ORCID sincronizado com sucesso!');
      setIsSuccess(true);
      history.push('/');
    } catch (error) {
      emitError(error, 'Erro ao sincronizar com o ORCID');
    }
  };

  useEffect(() => {
    if (!code) return;
    console.log({ code });
    handleSync(code);
  }, [code]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', padding: '100px 0' }}
    >
      {isSuccess ? (
        <h3>Dados sincronizados com sucesso!...</h3>
      ) : (
        <h3>Estamos sincronizando seus dados...</h3>
      )}
    </div>
  );
};

const OrcidWithErrorWrapper = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <OrcidLogin />
  </ErrorBoundary>
);

export default OrcidWithErrorWrapper;
