import React, { useState, useEffect } from 'react';
import * as S from './styles';

export interface InedxesHeadersProps {
  type?: 'article' | 'author' | 'journal' | 'ppg';
  isRanking?: boolean;
  currentSort?: string;
}

const ArticleHeader = {
  col00: 'Ranque',
  col01: 'Titulo',
  col02: 'Caciq-t',
};

const AuthorHeader = {
  col00: 'Ranque',
  col01: 'Nome',
  col02: 'Caciq-a',
  col03: 'Caciq-h4A',
  col04: 'Web-h4',
  col05: 'Web-h',
};

const JournalHeader = {
  col00: 'Ranque',
  col01: 'Issn',
  col02: 'Nome',
  col03: 'Caciq-R',
  col04: 'Caciq-h4R',
  col05: 'sjr',
  col06: 'Qualis',
  col07: 'Google-h5',
  col08: 'i10',
};

const PPGHeader = {
  col00: 'Ranque',
  col01: 'Curso',
  col02: 'Universidade',
  col03: 'Caciq-p',
  col04: 'h',
};
const IndexesHeaders: React.FC<InedxesHeadersProps> = props => {
  return (
    <>
      <S.Container>
        {props.isRanking && (
          <S.col00>
            {props.type === 'article' && <h3> {ArticleHeader.col00}</h3>}
            {props.type === 'author' && (
              <S.HeaderBtn type="button">
                <h3> {AuthorHeader.col00}</h3>
              </S.HeaderBtn>
            )}
            {props.type === 'journal' && (
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col00}</h3>
              </S.HeaderBtn>
            )}
            {props.type === 'ppg' && (
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col00}</h3>
              </S.HeaderBtn>
            )}
          </S.col00>
        )}
        <S.col01 {...props}>
          {props.type === 'article' && (
            <S.HeaderBtn type="button">
              <h3> {ArticleHeader.col01}</h3>
            </S.HeaderBtn>
          )}
          {props.type === 'author' && (
            <S.HeaderBtn type="button">
              <h3> {AuthorHeader.col01}</h3>
            </S.HeaderBtn>
          )}
          {props.type === 'journal' && (
            <S.HeaderBtn type="button">
              <h3> {JournalHeader.col01}</h3>
            </S.HeaderBtn>
          )}

          {props.type === 'ppg' && (
            <>
              <S.HeaderBtn type="button">
                <h3> {PPGHeader.col01}</h3>
              </S.HeaderBtn>
            </>
          )}
        </S.col01>

        {props.type === 'ppg' && (
          <S.col02 {...props}>
            <S.HeaderBtn type="button">
              <h3> {PPGHeader.col02}</h3>
            </S.HeaderBtn>
          </S.col02>
        )}

        {props.type === 'article' && (
          <S.col02 {...props}>
            <S.HeaderBtn>
              <h3>{ArticleHeader.col02}</h3>
            </S.HeaderBtn>
          </S.col02>
        )}

        {props.type === 'author' &&
          (props.currentSort?.includes?.('IC-A') ||
            props.currentSort === '') && (
            <S.col02 {...props}>
              <S.HeaderBtn type="button">
                <h3> {AuthorHeader.col02}</h3>
              </S.HeaderBtn>
            </S.col02>
          )}

        {props.type === 'journal' && (
          <S.col02 {...props}>
            <S.HeaderBtn type="button">
              <h3> {JournalHeader.col02}</h3>
            </S.HeaderBtn>
          </S.col02>
        )}

        {props.type === 'author' &&
          (props.currentSort?.includes?.('IC-H4-A') ||
            props.currentSort === '') && (
            <S.col03 {...props}>
              <S.HeaderBtn type="button">
                <h3> {AuthorHeader.col03}</h3>
              </S.HeaderBtn>
            </S.col03>
          )}

        {props.type === 'journal' &&
          (props.currentSort?.includes?.('IC-R') ||
            props.currentSort === '') && (
            <S.col03 {...props}>
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col03}</h3>
              </S.HeaderBtn>
            </S.col03>
          )}

        {props.type === 'ppg' &&
          (props.currentSort?.includes?.('IC-P') ||
            props.currentSort === '') && (
            <S.col03 {...props}>
              <S.HeaderBtn type="button">
                <h3> {PPGHeader.col03}</h3>
              </S.HeaderBtn>
            </S.col03>
          )}

        {props.type === 'author' &&
          (props.currentSort?.includes?.('hAll') ||
            props.currentSort === '') && (
            <S.col04 {...props}>
              <S.HeaderBtn type="button">
                <h3> {AuthorHeader.col04}</h3>
              </S.HeaderBtn>
            </S.col04>
          )}

        {props.type === 'journal' &&
          (props.currentSort?.includes?.('IC-H4-R') ||
            props.currentSort === '') && (
            <S.col04 {...props}>
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col04}</h3>
              </S.HeaderBtn>
            </S.col04>
          )}

        {props.type === 'ppg' &&
          (props.currentSort?.includes?.('IC-H4-P') ||
            props.currentSort === '') && (
            <S.col04 {...props}>
              <S.HeaderBtn type="button">
                <h3> {PPGHeader.col04}</h3>
              </S.HeaderBtn>{' '}
            </S.col04>
          )}

        {props.type === 'author' &&
          (props.currentSort?.includes?.('i10All') ||
            props.currentSort === '') && (
            <S.col05 {...props}>
              <S.HeaderBtn type="button">
                <h3> {AuthorHeader.col05}</h3>
              </S.HeaderBtn>
            </S.col05>
          )}

        {props.type === 'journal' &&
          (props.currentSort?.includes?.('sjr') ||
            props.currentSort === '') && (
            <S.col05 {...props}>
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col05}</h3>
              </S.HeaderBtn>
            </S.col05>
          )}

        {props.type === 'journal' &&
          (props.currentSort?.includes?.('stratum') ||
            props.currentSort === '') && (
            <S.col06 {...props}>
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col06}</h3>
              </S.HeaderBtn>
            </S.col06>
          )}

        {props.type === 'journal' &&
          (props.currentSort?.includes?.('H5') || props.currentSort === '') && (
            <S.col07 {...props}>
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col07}</h3>
              </S.HeaderBtn>
            </S.col07>
          )}

        {props.type === 'journal' &&
          (props.currentSort?.includes?.('i10All') ||
            props.currentSort === '') && (
            <S.col08 {...props}>
              <S.HeaderBtn type="button">
                <h3> {JournalHeader.col08}</h3>
              </S.HeaderBtn>
            </S.col08>
          )}
        <S.col09 />
      </S.Container>
      <S.Divider />
    </>
  );
};

export default IndexesHeaders;
