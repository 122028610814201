import styled, { css } from 'styled-components';
import mq from '../../../../styles/mq';

export const AuthorContent = styled.div`
  width: 294px;
  min-height: 263px;

  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border: 1.5px solid #dadee5;

  padding: 25px;
  text-align: center;

  ${mq.custom(
    css`
      margin-bottom: 20px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  p {
    font-family: ${({ theme }) => theme.fonts.mulish.p};
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.grey2};

    margin-bottom: 20px;

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.purpleDefault};
    }
  }

  a {
    margin: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 99px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
      object-fit: cover;
    }
    .perfil-photo {
      width: 60px;
      height: 60px;
      border-radius: 99px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }
`;
