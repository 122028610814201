/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useRef, useEffect } from 'react';
import SelectInput from '../Select';
import { ReactComponent as Search } from '../../assets/search-right.svg';
import { ReactComponent as Rank } from '../../assets/ranking-icon.svg';
import * as S from './styles';

export interface SearchbarProps {
  selection?: boolean;
  setSearchTerm?: any;
  searchTerm?: string;
  setSearchRank?: any;
  permission?: boolean;
  isRanking?: boolean;
  setIsRanking?: any;
  setPageSize?: any;
  barType?: 'article' | 'author' | 'journal' | 'ppg';
}

const articleSearchText = 'Título e Palavra-chave';
const authorSearchText = 'Nome';
const journalSearchText = 'ISSN e Título';
const ppgSearchText = 'Universidade';

const SearchBar = ({
  selection,
  setSearchTerm,
  permission = true,
  searchTerm,
  isRanking = false,
  setIsRanking,
  setPageSize,
  setSearchRank,
  barType,
}: SearchbarProps) => {
  const [handleInput, setHandleinput] = useState(searchTerm);
  const inputRef = useRef(null);
  const inputRankRef = useRef(null);

  const onSubmit = () => {
    event.preventDefault();
    if (isRanking) {
      setPageSize(5);
      setIsRanking(false);
    } else {
      setSearchTerm(inputRef.current.value);
    }
  };

  const onSubmitRanking = () => {
    event.preventDefault();
    setIsRanking(true);
    if (inputRankRef?.current?.value !== (null || '' || undefined)) {
      setSearchRank(inputRankRef?.current?.value);
    } else return null;
  };

  useEffect(() => {
    setHandleinput(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <S.Container onSubmit={isRanking ? onSubmitRanking : onSubmit}>
        <div className="search-bar-field">
          <label>
            {!isRanking
              ? barType === 'article'
                ? articleSearchText
                : barType === 'author'
                ? authorSearchText
                : barType === 'journal'
                ? journalSearchText
                : barType === 'ppg'
                ? ppgSearchText
                : 'Palavra-chave, Nome, Título ou ISSN'
              : 'Nome'}
          </label>
          {!isRanking ? (
            <input
              placeholder={
                barType === 'article'
                  ? articleSearchText
                  : barType === 'author'
                  ? authorSearchText
                  : barType === 'journal'
                  ? journalSearchText
                  : barType === 'ppg'
                  ? ppgSearchText
                  : 'Palavra-chave, Nome, Título ou ISSN'
              }
              value={handleInput}
              ref={inputRef}
              disabled={!permission}
              onChange={e => setHandleinput(e.target.value)}
            />
          ) : (
            <input placeholder="Nome" id="searchBar" ref={inputRankRef} />
          )}
        </div>

        <button
          className="search-btn"
          id={!isRanking ? null : 'rankingBtn'}
          type="submit"
          onClick={!isRanking ? onSubmit : onSubmitRanking}
        >
          <span>Pesquisar</span>
          <Search />
        </button>
      </S.Container>
    </>
  );
};

export default SearchBar;
