import styled from 'styled-components';
import { theme } from '../../../../styles/global';

interface ButtonProps {
  selectProps: boolean;
}

export const Button = styled.button.attrs({ type: 'button' })<ButtonProps>`
  width: 100%;
  background: none;
  border: 0;
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  transition: 0.3s;

  &:hover {
    background: rgba(200, 200, 200, 0.3);
  }

  span {
    width: 12px;
    height: 12px;
    border: 1.5px solid ${theme.colors.grey6};
    border-radius: 3px;
    margin-right: 10px;
    position: relative;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      opacity: ${prop => (prop.selectProps ? '1' : '0')};
      border-radius: 2px;
      background: ${theme.colors.purpleDefault};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
`;
