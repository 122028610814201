import styled, { css, keyframes } from 'styled-components';

const fetching = keyframes`

0% {

    transform:rotate(0deg);
    
}


50%{
    
  transform:rotate(180deg);

}


100%{

  transform:rotate(360deg);

}

`;

export const Container = styled.div`
  width: 20px;
  color: ${({ theme }) => theme.colors.purpleDefault};

  svg {
    width: 20px;
    animation: 0.7s infinite ${fetching};
  }
`;
