/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import RelatedContent from '../../components/RelatedContent';
import CaciqIndex from '../../components/CaciqIndex';
import * as S from './styles';
import DetailsCard from '../../components/DetailsCard';
import NumberOfCitations from '../../components/NumberOfCitations';
import { ReactComponent as AvatarImg } from '../../assets/avatar.svg';
import VerifyProfileCard from '../../components/VerifyProfileCard';
import FetchingAnimation from '../../components/FetchingAnimation';
import { IAuthor } from '../../models/author';
import ErrorAnimation from '../../components/ErrorAnimation';
import getClient from '../../services/client/elasticsearch';
import LoadingAnimation from '../../components/LoadingAnimaton';
import { usePermission } from '../../hooks/permission';
import { api } from '../../services/api';

export interface AuthorDetailsProps extends IAuthor {
  atuationArea?: string;
  verifyedArticles?: string[];
  avatar?: string;
  orcid?: string;
  lattes?: string;
  personalSite?: string;
  twitter?: string;
  instagram?: string;
  linkedin?: string;
  facebook?: string;
}

const AuthorDetails: React.FC = props => {
  const { Permissions } = usePermission();
  const [currentAuthor, setCurentAuthor] = useState<any>([]);
  const [relatedAuthors, setRelatedAuthors] = useState<any>([]);
  const [userAuthor, setUserAuthor] = useState<any>([]);
  const { details } = useParams() as any;

  const {
    data: author,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['authors', details],
    async () => {
      const response = await getClient().search({
        index: 'authors',
        size: 5,
        body: {
          query: {
            bool: {
              should: [
                {
                  match: {
                    name: details,
                  },
                },
                {
                  match: {
                    uniqId: details,
                  },
                },
              ],
            },
          },
        },
      });

      const data: any = await response.hits.hits;
      if (data[0]?._source?.valuationArea) {
        const related = await getClient().search({
          index: 'authors',
          size: 3,
          body: {
            query: {
              bool: {
                should: data[0]?._source?.valuationArea?.map(relation => ({
                  match: { valuationArea: relation },
                })),
              },
            },
          },
        });
        setRelatedAuthors(related.hits.hits);
      }

      if (data[0]?._source?.userId) {
        const caciqAuthor = await api.post('/profile/public', {
          ids: [data[0]?._source?.userId],
        });

        setUserAuthor(caciqAuthor?.data[0]);
      }

      return data;
    },
    {
      initialData: [],
    },
  );

  const orcid: any = userAuthor?.socials?.filter(
    e => e.name === 'orcid' && e.public,
  );
  const lattes: any = userAuthor?.socials?.filter(e => e.name === 'lattes');
  const personalWebsite: any = userAuthor?.socials?.filter(
    e =>
      (e.name === 'personalWebsite' || e.name === 'personalSite') && e.public,
  );
  const instagram: any = userAuthor?.socials?.filter(
    e => e.name === 'instagram' && e.public,
  );
  const facebook: any = userAuthor?.socials?.filter(
    e => e.name === 'facebook' && e.public,
  );
  const twitter: any = userAuthor?.socials?.filter(
    e => e.name === 'twitter' && e.public,
  );
  const linkedin: any = userAuthor?.socials?.filter(
    e => e.name === 'linkedin' && e.public,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!author) return null;

    return setCurentAuthor(author[0]?._source);
  }, [author]);

  const ICAPermission = Permissions('Result_Caciq_Index');
  const ICH4Permission = Permissions('Result_Caciq_H4_Index');
  const HALLPermission = Permissions('Result_Scholar_H4_Index');
  const I10Permission = Permissions('Result_Scholar_Index');

  const ICA = currentAuthor?.indexes?.['IC-A'];
  const ICH4 = currentAuthor?.indexes?.['IC-H4-A'];
  const HALL = currentAuthor?.indexes?.hAll;
  const H5 = currentAuthor?.indexes?.h5;
  const I10All = currentAuthor?.indexes?.i10All;
  const I10 = currentAuthor?.indexes?.i105y;

  return (
    <>
      <S.Container>
        {isLoading ? (
          <LoadingAnimation />
        ) : error ? (
          <ErrorAnimation />
        ) : (
          <>
            <S.AuthorWrapper>
              <S.ImageWrapper>
                {currentAuthor?.avatar ? (
                  <img src={currentAuthor.avatar} alt="Avatar" />
                ) : (
                  <div className="perfil-photo">
                    <AvatarImg />
                  </div>
                )}
              </S.ImageWrapper>

              <S.AuthorInfoWrapper>
                <div className="NameWrapper">
                  <h2>
                    {currentAuthor?.name}{' '}
                    {!isLoading && isFetching && <FetchingAnimation />}
                  </h2>
                </div>
                <div className="IndexWrapper">
                  {ICA && ICAPermission ? (
                    <h5>
                      Caciq-a <strong>{Number(ICA)}</strong>
                    </h5>
                  ) : null}
                  {ICH4 && ICH4Permission ? (
                    <h5>
                      Caciq-h4A <strong>{Number(ICH4)}</strong>
                    </h5>
                  ) : null}
                  {HALL && HALLPermission && (
                    <h5>
                      Web-h4 <strong>{Number(HALL)}</strong>
                    </h5>
                  )}
                  {/* {H5 && HALLPermission && (
                    <h5>
                      Google-h5 <strong>{Number(H5)}</strong>
                    </h5>
                  )} */}
                  {I10 && I10Permission && (
                    <h5>
                      Web-h <strong>{Number(I10)}</strong>
                    </h5>
                  )}
                  {/* {I10All && I10Permission && (
                    <h5>
                      i10All <strong>{Number(I10All)}</strong>
                    </h5>
                  )} */}
                </div>

                {
                  // OccupationArea desativado a pedido do Dr. Emerson
                  // definido na reunião presencial dia 17/10/2022
                  //
                  // <div className="OccupationArea">
                  //   <h6>Área de atuação</h6>
                  //   <p>
                  //     {currentAuthor?.valuationArea
                  //       ?.map(area => area)
                  //       .join(', ') || (
                  //       <p style={{ color: '#A9B3C4' }}>Sem dados para exibir</p>
                  //     )}
                  //   </p>
                  // </div>
                }
              </S.AuthorInfoWrapper>
            </S.AuthorWrapper>

            <S.DetailsWrapper>
              <S.MoreDetailsContent>
                {currentAuthor?.total_refs_citation && (
                  <NumberOfCitations
                    numberInfo={
                      currentAuthor?.total_refs_citation?.toLocaleString() || ''
                    }
                    textInfo="Citações em outros artigos"
                  />
                )}
                <VerifyProfileCard
                  cardType="author"
                  isVerifyed={currentAuthor?.userId || false}
                  orcid={orcid?.length > 0 ? orcid[0]?.url : null}
                  lattes={lattes?.length > 0 ? lattes[0]?.url : null}
                  personalSite={
                    personalWebsite?.length > 0 ? personalWebsite[0]?.url : null
                  }
                  twitter={twitter?.length > 0 && twitter[0]?.url}
                  facebook={facebook?.length > 0 && facebook[0]?.url}
                  instagram={instagram?.length > 0 && instagram[0]?.url}
                  linkedin={linkedin?.length > 0 && linkedin[0]?.url}
                />
              </S.MoreDetailsContent>

              <S.DetailsCardContent>
                <DetailsCard
                  type="verifiedArticles"
                  big
                  link="/textos/detalhe"
                  tags={currentAuthor?.articlesRel?.map(article => ({
                    title: article.title,
                    uniqId: article.uniqId,
                    href: `/textos/detalhe/${article.uniqId}`,
                  }))}
                />
                <div className="smallCardContainer">
                  <DetailsCard type="ppg" tags={currentAuthor?.ppgs} />
                  {/* <DetailsCard
                    type="citedAuthors"
                    tags={currentAuthor?.mostCitedAuthors}
                  /> */}
                </div>
              </S.DetailsCardContent>
            </S.DetailsWrapper>
          </>
        )}
      </S.Container>

      <S.RelatedWrapper>
        {relatedAuthors.length > 0 && <h3>Veja mais autores relacionados</h3>}
        {isLoading ? (
          <LoadingAnimation />
        ) : error ? (
          'Erro ao carregar. Tente novamente'
        ) : (
          <S.ContainerCards>
            {relatedAuthors?.map((item: any) => (
              <RelatedContent
                type="author"
                item="Nome"
                properties="Palavras-chave"
                href={`/autor/detalhe/${item?._source?.name}`}
                tags={item?._source?.keywords?.map(keyword => keyword)}
                text={item?._source?.name}
                caciqIndex={
                  <CaciqIndex
                    type="author"
                    h4
                    name="IC-A"
                    value={item?._source?.indexes?.['IC-H4-A']}
                  />
                }
              />
            ))}
          </S.ContainerCards>
        )}
      </S.RelatedWrapper>
    </>
  );
};

export default AuthorDetails;
