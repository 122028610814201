import styled from 'styled-components';

export const MainContainer = styled.section`
  width: 100%;
  min-height: 66vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 596px;

  #birthdate {
    color: #a9b3c4;

    &::-webkit-calendar-picker-indicator {
      filter: opacity(0);
    }
  }

  .header-section {
    h2 {
      ${({ theme }) => theme.fonts.mulish.h2};
      color: ${({ theme }) => theme.colors.grey2};
      text-align: center;

      span {
        color: ${({ theme }) => theme.colors.purpleDefault};
      }
    }

    p {
      text-align: center;
      margin: 32px 0 76px 0;
      ${({ theme }) => theme.fonts.mulish.p};
    }
  }

  form {
    margin-bottom: 92px;

    p {
      ${({ theme }) => theme.fonts.mulish.h6};
      color: ${({ theme }) => theme.colors.redDefault};
      position: relative;
    }
    .form-itens {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 22px;

      .row-input {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 21px 0;

        li {
          margin: 0;
          flex-basis: 250px;
        }
      }

      li {
        flex-basis: 596px;
        input {
          width: 100%;
          height: 45px;
          margin-top: 12px;
          border: 1.5px solid #dadee5;
          border-radius: 10px;
          padding: 16px 4px 16px 12px;
          color: #636e6f;
          font-family: 'Work Sans', sans-serif;
          font-weight: normal;
          font-size: 16px;
          outline: none;

          &::placeholder {
            color: #a9b3c4;
          }

          &:focus {
            border: 1.5px solid #17a1a1;
          }
        }

        & + li {
          margin-top: 21px;
        }
      }
    }

    .terms-container {
      display: flex;
      align-items: center;
      input {
        width: 12px;
        height: 12px;
        margin: 0 10px 0 0;
      }

      a {
        color: ${({ theme }) => theme.colors.purpleDefault};
        text-decoration: underline;
      }
    }

    label {
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #17a1a1;
    }

    .buttons-section {
      margin-top: 42px;
      display: flex;
      justify-content: center;
      padding: 0 46px;

      button {
        max-width: 102px;
        background: ${({ theme }) => theme.colors.purpleDefault};
        border: 0;
        padding: 12px 18px;
        border-radius: 6px;
        ${({ theme }) => theme.fonts.mulish.p};

        font-weight: 700;
        color: white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
        transition: 0.3s;

        &:hover {
          background: ${({ theme }) => theme.colors.purpleMidtone};
          color: white;
        }

        &:disabled {
          background: rgba(0, 0, 0, 0.3);
          cursor: not-allowed;
        }
      }
    }
  }
`;
