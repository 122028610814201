import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/global';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;
export const MainContainer = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;
export const PopUp = styled.div`
  width: 100%;
  max-width: 407px;
  min-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  position: absolute;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  overflow: hidden;
  animation: 0.4s ${entranceAnim} ease;

  .pop-up-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      width: 54px;
      height: min-content;
    }

    h4 {
      margin: 4px 0 18px 0;
    }

    h4,
    p {
      width: 100%;
      text-align: center;
    }
  }

  .buttons-section-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
  }
`;
