/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { Container } from './styles';

export const FetchingAnimation = () => {
  return (
    <Container>
      <ImSpinner8 />
    </Container>
  );
};

export default FetchingAnimation;
