import styled, { css } from 'styled-components';

import BgImage from '../../assets/FundoExterno.png';
import mq from '../../styles/mq';

export const MainContainer = styled.section`
  width: 100%;
  min-height: 66vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;

  ${mq.custom(
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1073px;

  h2 {
    ${({ theme }) => theme.fonts.mulish.h2};
    color: ${({ theme }) => theme.colors.grey2};

    margin-bottom: 42px;
    text-align: center;

    span {
      color: ${({ theme }) => theme.colors.purpleDefault};
    }
  }

  p {
    text-align: center;
    color: ${({ theme }) => theme.colors.grey2};
  }

  .plan-section {
    width: 100%;
    margin-top: 44px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;

    ${mq.custom(
      css`
        gap: 16px;
        flex-direction: column;
      `,
      { minWidth: 0, maxWidth: 1023 },
    )}
  }
`;
