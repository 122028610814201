/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import LoadingScreen from '../../components/LoadingScreen';
import Button from '../../components/Button';
import { api } from '../../services/api';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface FormInputs {
  password: string;
  token: boolean;
}

const schema = Yup.object().shape({
  password: Yup.string().required('A senha é obrigatória'),
  token: Yup.string().required('O token é obrigatório'),
});

const RecoverPassword = () => {
  const history = useHistory();
  const { emitError } = useError();

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      setLoading(true);
      const response = await api.post('/sessions/password/reset', data);
      setLoading(false);
      toast.success('Senha alterada');
      history.push('/login');
    } catch (error: any) {
      setLoading(false);
      emitError(error, 'Erro ao alterar senha!');
    }
  };

  return (
    <S.MainContainer>
      {loading && <LoadingScreen />}
      <S.Container>
        <h2>
          <span>Recuperar </span>senha
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <label htmlFor="email">Nova Senha</label>
            <input id="name" type="password" {...register('password')} />
            <p> {errors.password?.message}</p>
          </div>
          <div className="input-container">
            <label htmlFor="token">Token</label>
            <input id="token" {...register('token')} />
            <p> {errors.token?.message}</p>
          </div>

          <Button typeOf="submit"> Enviar</Button>
        </form>
      </S.Container>
    </S.MainContainer>
  );
};

export default RecoverPassword;
