/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { api } from '../../services/api';
import CtaBanner from '../../components/CtaBanner';
import { AuthContext } from '../../hooks/auth';
import LoadingScreen from '../../components/LoadingScreen';
import { validadeCpf } from '../../utils/cpferify';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface RegisterProp {
  description?: string;
}

interface FormInputs {
  name: string;
  CPF: number;
  birthDate: number;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
  terms: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string()
    .max(30, 'O Nome não pode exceder 30 caracteres')
    .required('O Nome é obrigatório'),

  CPF: Yup.string()
    .required('O CPF é obrigatório')
    .test('validade cpf', 'CPF inválido', validadeCpf),

  birthDate: Yup.string().required('A data de nascimento é obrigatória'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O E-mail é obrigatório'),
  emailConfirmation: Yup.mixed().oneOf(
    [Yup.ref('email')],
    'Os E-mails não coincidem',
  ),
  password: Yup.string()
    .min(6, 'Precisa ser ao menos 6 digitos')
    .required('A senha é obrigatória'),
  passwordConfirmation: Yup.mixed().oneOf(
    [Yup.ref('password')],
    'As senhas não coincidem',
  ),
  terms: Yup.boolean().oneOf(
    [true],
    'Aceite os termos de privacidade para concluir o cadastro',
  ),
});

const FreeRegister = ({
  description = 'Com o cadastro gratuito você já tem acesso a várias funcionalidades da plataforma como: pesquisar e visualizar resultados de artigos e índices.',
}: RegisterProp) => {
  const { signIn, loading } = useContext(AuthContext);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [cpf, setCPF] = useState(null);
  const [eula, setEula] = useState(false);
  const { emitError } = useError();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      delete data.emailConfirmation;
      delete data.passwordConfirmation;
      delete data.terms;

      const fullData = { ...data, accountType: 'researcher', socials: [] };
      setRegisterLoading(true);
      await api.post('/signup', fullData);
      setRegisterLoading(false);

      toast.success('Conta criada! Redirecionando ...');
      signIn(data.email, data.password, true);
    } catch (error: any) {
      setRegisterLoading(false);
      emitError(error, 'Falha ao criar conta!');
    }
  };

  const handleCpfFilter = e => {
    let filtered = e.target.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 20) return;
    filtered = filtered.replace(/\D/g, '');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    filtered = filtered.replace(/(-\d{2})\d+?$/, '$1');

    setCPF(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.MainContainer>
        {registerLoading ? <LoadingScreen /> : null}

        <S.Container>
          <div className="header-section">
            <h2>
              Cadastre-se de forma <span>gratuita </span>na Plataforma Caciq
            </h2>
            <p>{description}</p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="form-itens">
              <li>
                <label htmlFor="name">Nome Completo</label>
                <input
                  type="text"
                  id="name"
                  {...register('name')}
                  placeholder="Nome Completo"
                />
                <p> {errors.name?.message}</p>
              </li>

              <div className="row-input">
                <li>
                  <label htmlFor="cpf">CPF</label>
                  <input
                    type="text"
                    placeholder="000.000.000-00"
                    id="cep"
                    {...register('CPF')}
                    value={cpf}
                    onChange={e => handleCpfFilter(e)}
                  />
                  <p> {errors.CPF?.message}</p>
                </li>
                <li>
                  <label htmlFor="birthdate">Data de Nascimento</label>
                  <input
                    type="date"
                    placeholder="dd/mm/aaaa"
                    id="birthdate"
                    {...register('birthDate')}
                  />
                  <p> {errors.birthDate?.message}</p>
                </li>
              </div>

              <li>
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  id="email"
                  {...register('email')}
                  placeholder="email@email.com.br"
                />
                <p> {errors.email?.message}</p>
              </li>

              <li>
                <label htmlFor="emailConfirmation">Confirmar E-mail</label>
                <input
                  type="email"
                  id="emailConfirmation"
                  placeholder="email@email.com.br"
                  {...register('emailConfirmation')}
                />
                <p> {errors.emailConfirmation?.message}</p>
              </li>

              <li>
                <label htmlFor="password">Senha</label>
                <input
                  type="password"
                  id="password"
                  {...register('password')}
                />
                <p> {errors.password?.message}</p>
              </li>
              <li>
                <label htmlFor="passwordConfirmation">Confirmar Senha</label>
                <input
                  type="password"
                  id="passwordConfirmation"
                  {...register('passwordConfirmation')}
                />
                <p> {errors.passwordConfirmation?.message}</p>
              </li>
            </ul>

            <div className="terms-container">
              <input
                type="checkbox"
                id="terms"
                {...register('terms')}
                onChange={value => setEula(value.target.checked)}
              />
              <label htmlFor="terms">
                Li e concordo com a
                <a
                  href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Privacidade_Finalizada.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Política de privacidade
                </a>{' '}
                e com o{' '}
                <a
                  href="https://caciq.com.br/wp-content/uploads/2021/12/Caciq_Termos_Uso_Finalizada.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Termo de Proteção de Dados{' '}
                </a>
                <p>{errors.terms?.message}</p>
              </label>
            </div>

            <div className="buttons-section">
              <button
                type="submit"
                disabled={!eula}
                title={!eula && 'Você não aceitou os termos de privacidade'}
              >
                Finalizar
              </button>
            </div>
          </form>
        </S.Container>
      </S.MainContainer>
      <CtaBanner />
    </>
  );
};

export default FreeRegister;
