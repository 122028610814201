import React from 'react';

export const researcherList = [
  {
    title:
      'Pesquisar Autores, Periódicos, Programas de Pós Graduação e Índices',
  },
  {
    title:
      'Ver página de detalhes de Autores, Periódicos e Programas de Pós Graduação',
  },
  {
    title: 'Vincular PDF a artigos',
    hasRight: false,
  },
  {
    title: 'Vincular Programas de Pós Graduação ao Perfil',
    hasRight: false,
  },
];

export const authorList = [
  {
    title:
      'Pesquisar Autores, Periódicos, Programas de Pós Graduação e Índices',
  },
  {
    title:
      'Ver página de detalhes de Autores, Periódicos e Programas de Pós Graduação',
  },
  {
    title: 'Vincular PDF a artigos',
    hasRight: true,
  },
  {
    title: 'Vincular Programas de Pós Graduação ao Perfil',
    hasRight: true,
  },
];

export default {};
