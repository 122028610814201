/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
import React from 'react';
import { BsCamera } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import { useError } from '../../hooks/errors';

interface UploadFileProps {
  label?: string | any;
  name?: string;
  newFile: any;
  setNewFile: any;
}

export const UploadFile = ({
  label = <BsCamera />,
  newFile,
  name = 'avatar',
  setNewFile,
}: UploadFileProps) => {
  const { emitError } = useError();

  async function handleUpload(e) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = (await api.post('/upload/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })) as any;

      setNewFile(response.data.linkUrl);
      return response.data.linkUrl;
    } catch (error: any) {
      emitError(error, 'Erro ao fazer upload de imagem');
    }
  }

  return (
    <Container>
      <label htmlFor="file-input"> {label} </label>
      <input
        type="file"
        id="file-input"
        name={name}
        onInput={e => handleUpload(e)}
      />
    </Container>
  );
};

export default UploadFile;
