/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { ReactComponent as PerfilPhoto } from '../../assets/avatar.svg';
import { ReactComponent as EditPen } from '../../assets/pen-icon.svg';
import Button from '../../components/Button';
import Index from './components/PeridicIndex';
import PeridicIndex from '../PPGProfile/components/PeridicIndex';
import CtaBanner from '../../components/CtaBanner';
import { useAuth } from '../../hooks/auth';
import SwitchComponent from '../../components/Switch';
import { useSocial } from '../../hooks/socials';
import UploadFile from '../../components/uploadPictureInput';
import getClient from '../../services/client/elasticsearch';
import { api } from '../../services/api';

import * as S from './styles';
import { useError } from '../../hooks/errors';

interface FormInputs {
  email: string;
  editor: string;
  nameInCitations: string;
  personalSite: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  twitter: string;
}

const PeriodicProfile = () => {
  const [editPerfil, setEditPerfil] = useState(false);
  const { user, updateProfile } = useAuth();
  const { social, getSocial } = useSocial();
  const [newPic, setNewPic] = useState(user.avatar);

  useEffect(() => {
    if (!social) getSocial();
  }, [social]);

  const { data: userElastic }: any = useQuery(
    ['user', user],
    async () => {
      const response = await getClient().search({
        index: 'journals',
        size: 2,
        body: {
          query: {
            match: { _id: user?.authorId },
          },
        },
      });

      return response.hits.hits;
    },
    {
      initialData: [],
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.MainContainer>
      <S.PerfilSection>
        <div className="picture-container">
          {editPerfil ? (
            <UploadFile newFile={newPic} setNewFile={setNewPic} />
          ) : null}

          {newPic === null || newPic === '' || newPic === 'none' ? (
            <PerfilPhoto />
          ) : (
            <img src={newPic} alt="perfil picturer" />
          )}
        </div>

        <section className="perfil-container">
          <div className="user-information">
            <h2>{user.name}</h2>

            <div className="edit-column">
              <p>Revista</p>

              <button type="button" onClick={() => setEditPerfil(!editPerfil)}>
                Editar perfil <EditPen />
              </button>
            </div>
          </div>

          <FormSection
            inputProps={editPerfil}
            socialFields={social}
            updateProfile={updateProfile}
            user={user}
            publicId={userElastic[0]?._source?.uniqId}
            profilePicture={newPic || user.avatar}
          />
        </section>
      </S.PerfilSection>

      {userElastic[0]?._source?.indexes && <Index periodic={userElastic} />}
      {user?.isVerified && <PeridicIndex />}

      <CtaBanner
        title="Quer se manter atualizado?"
        bodyText="A Caciq traz pra você as principais notícias do mundo acadêmico, focando no ambiente de inovação, na análise de impacto e na avaliação da produção científica"
      />
    </S.MainContainer>
  );
};

const FormSection = ({
  inputProps,
  profilePicture,
  socialFields = [],
  user,
  updateProfile,
  publicId,
}) => {
  const { register, handleSubmit } = useForm<FormInputs>();
  const { emitError } = useError();

  const email: any = socialFields.filter(e => e.name === 'email');

  const editorResponsible: any = socialFields.filter(
    e => e.name === 'editorResponsible',
  );
  const nameInCitations: any = socialFields.filter(
    e => e.name === 'nameInCitations',
  );
  const periodicWebsite: any = socialFields.filter(
    e => e.name === 'periodicWebsite',
  );
  const linkedin: any = socialFields.filter(e => e.name === 'linkedin');

  const facebook: any = socialFields.filter(e => e.name === 'facebook');
  const instagram: any = socialFields.filter(e => e.name === 'instagram');
  const twitter: any = socialFields.filter(e => e.name === 'twitter');

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    try {
      const social = [];

      if (data.email)
        social.push({
          name: 'email',
          url: data.email.toString(),
          public: email[0]?.public || false,
        });
      if (data.editor)
        social.push({
          name: 'editorResponsible',
          url: data.editor.toString(),
          public: editorResponsible[0]?.public || false,
        });
      if (data.nameInCitations)
        social.push({
          name: 'nameInCitations',
          url: data.nameInCitations.toString(),
          public: nameInCitations[0]?.public || false,
        });
      if (data.personalSite)
        social.push({
          name: 'periodicWebsite',
          url: data.personalSite.toString(),
          public: periodicWebsite[0]?.public || false,
        });
      if (data.linkedin)
        social.push({
          name: 'linkedin',
          url: data.linkedin.toString(),
          public: linkedin[0]?.public || false,
        });
      if (data.facebook)
        social.push({
          name: 'facebook',
          url: data.facebook.toString(),
          public: facebook[0]?.public || false,
        });
      if (data.instagram)
        social.push({
          name: 'instagram',
          url: data.instagram.toString(),
          public: instagram[0]?.public || false,
        });
      if (data.twitter)
        social.push({
          name: 'twitter',
          url: data.twitter.toString(),
          public: twitter[0]?.public || false,
        });

      const fullData = {
        CPF: '0000000000',
        birthDate: '0000',
        avatar: profilePicture || 'none',
        email: data.email,
      };

      toast.info('Salvando informações');

      const postProfile = await api.put('/profile', fullData);
      social.forEach(async item => {
        const post = await api.patch('/profile/social', item);
      });
      toast.success('Perfil Atualizado');

      updateProfile({ ...fullData, socials: social } as any);

      window.location.reload();
    } catch (error: any) {
      emitError(error, 'Erro ao atualizar perfil');
    }
  };

  const sortedFields = [
    email.length > 0
      ? { ...email[0] }
      : {
          name: 'email',
          url: user.email || '',
          public: false,
        },
    editorResponsible.length > 0
      ? { ...editorResponsible[0] }
      : {
          name: 'editor',
          url: editorResponsible[0]?.url || '',
          public: false,
        },
    nameInCitations.length > 0
      ? { ...nameInCitations[0] }
      : {
          name: 'nameInCitations',
          url: nameInCitations[0]?.url || '',
          public: false,
        },
    periodicWebsite.length > 0
      ? { ...periodicWebsite[0] }
      : {
          name: 'periodicWebsite',
          url: periodicWebsite[0]?.url || '',
          public: false,
        },
    linkedin.length > 0
      ? { ...linkedin[0] }
      : {
          name: 'linkedin',
          url: linkedin[0]?.url || '',
          public: false,
        },
    facebook.length > 0
      ? { ...facebook[0] }
      : {
          name: 'facebook',
          url: facebook[0]?.url || '',
          public: false,
        },
    instagram.length > 0
      ? { ...instagram[0] }
      : {
          name: 'instagram',
          url: instagram[0]?.url || '',
          public: false,
        },
    twitter.length > 0
      ? { ...twitter[0] }
      : {
          name: 'twitter',
          url: twitter[0]?.url || '',
          public: false,
        },
  ];

  const switchData = [...sortedFields];

  return (
    <S.UserDetails inputProps={inputProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="user-informations-editable">
          <li>
            <label htmlFor="email">E-mail</label>
            <input
              name="name"
              type="text"
              id="email"
              {...register('email')}
              defaultValue={user.email}
            />
          </li>
          <li>
            <label htmlFor="editor">Editor responsável</label>
            <input
              name="name"
              type="text"
              id="editor"
              {...register('editor')}
              defaultValue={editorResponsible[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="nameInCitations">
              Nome em citação bibliográfica
            </label>
            <input
              name="name"
              type="text"
              id="nameInCitations"
              {...register('nameInCitations')}
              defaultValue={nameInCitations[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="personalSite">Site Pessoal</label>
            <input
              name="name"
              type="text"
              id="personalSite"
              {...register('personalSite')}
              defaultValue={periodicWebsite[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="linkedin">Linkedin</label>
            <input
              name="name"
              type="text"
              id="linkedin"
              {...register('linkedin')}
              defaultValue={linkedin[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="facebook">Facebook</label>
            <input
              name="name"
              type="text"
              id="facebook"
              {...register('facebook')}
              defaultValue={facebook[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="instagram">Instagram</label>
            <input
              name="name"
              type="text"
              id="instagram"
              {...register('instagram')}
              defaultValue={instagram[0]?.url}
            />
          </li>
          <li>
            <label htmlFor="twitter">Twitter</label>
            <input
              name="name"
              type="text"
              id="twitter"
              {...register('twitter')}
              defaultValue={twitter[0]?.url}
            />
          </li>
        </ul>

        <div className="form-buttons">
          <Button typeOf="submit">Salvar as alterações</Button>
          <Button href={`/revista/detalhe/${publicId}`} ghost>
            Visualizar perfil público
          </Button>
        </div>
      </form>
      <ul className="switchers-column">
        {switchData.map(item => (
          <li className="can-appear">
            <SwitchComponent
              name={item.name}
              url={item?.url}
              check={item?.public}
            />
          </li>
        ))}
      </ul>
    </S.UserDetails>
  );
};

export default PeriodicProfile;
