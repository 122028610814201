import React, { createContext, useContext, useState, useEffect } from 'react';
import { Plan } from '../models/user';
import { api } from '../services/api';

export interface AccountContextData {
  accountInf: Plan;
  getAccount(): Promise<void>;
  setAccountInf: any;
}

export const AccountContext = createContext<AccountContextData>(
  {} as AccountContextData,
);

export const AccountProvider: React.FC = ({ children }) => {
  const [accountInf, setAccountInf] = useState<Plan | undefined>();
  const [loading, setLoading] = useState(false);

  const getAccount = async () => {
    try {
      setLoading(true);
      const response = await api.get('/subscriptions/status');
      setAccountInf(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <AccountContext.Provider value={{ accountInf, getAccount, setAccountInf }}>
      {children}
    </AccountContext.Provider>
  );
};

export function useAccount(): AccountContextData {
  const context = useContext(AccountContext);
  return context;
}

export default {};
