import styled, { css } from 'styled-components';
import { theme } from '../../../../styles/global';
import mq from '../../../../styles/mq';

export const Container = styled.div`
  width: 100%;
  min-height: 205px;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 205px;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;

  ${mq.custom(
    css`
      overflow-y: auto;
    `,
    { minWidth: 0, maxWidth: 1023 },
  )}

  .table-header {
    max-width: 1366px;
    margin: 0 auto;

    display: flex;
    gap: 20px;
    padding: 17px 0;
    border-bottom: 1.5px solid #dadee5;
    transition: background-color 0.2s;

    &:hover {
    }

    .add-teacher {
      padding: 8px 12px;
      ${theme.fonts.mulish.h6};
      color: ${theme.colors.blueMidtone};
      border: solid 1px;
      border-radius: 6px;
      background: none;
      transition: 0.3s;
      &:hover {
        color: white;
        background: ${theme.colors.blueMidtone};
      }
    }
    li {
      width: calc(100% / 6);
      font: 13px Mulish, sans-serif;
      font-weight: 600;
      color: ${theme.colors.blueMidtone};
    }
  }

  .content-section {
    background-color: transparent;

    .main-list {
      max-width: 1366px;
      padding: 48px 0;
      margin: 0 auto;
      position: relative;
      display: flex;
      gap: 20px;
      padding: 17px 0;

      &:hover {
        &::after {
          background-color: #f2f2f2;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100vw;
        right: -100vw;
        background-color: transparent;
        transition: 0.3s;
        z-index: 0;
      }

      li {
        width: calc(100% / 6);
        display: flex;
        align-items: center;
        ${theme.fonts.workSans.p}
        font-weight: 600;
        color: ${theme.colors.grey2};
        z-index: 3;

        p {
          ${theme.fonts.workSans.p}
          font-weight: 600;
          color: ${theme.colors.blueDefault};
        }

        span {
          display: flex;

          p {
            margin-left: 10px;
          }
        }
      }

      .indexes {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        gap: 10px !important;

        li {
          display: flex;
          gap: 10px;
        }

        span {
          ${theme.fonts.mulish.h6}
          font-weight:600;
        }
        p {
          font-weight: 900 !important;
          ${theme.fonts.mulish.p}
        }
      }

      .keyword-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        align-items: center;

        .see-more-keyWord {
          ${theme.fonts.mulish.h6};
          color: ${theme.colors.purpleDefault};
          text-decoration: underline;
          margin-top: 8px;
          background: none;
          border: 0;
          font-weight: 600;
        }
      }
    }
  }
`;
