import React, { useEffect, useState } from 'react';

import LogoArticle from '../../assets/logo-article.svg';
import LogoAuthor from '../../assets/logo-author.svg';
import LogoJournal from '../../assets/logo-journal.svg';
import LogoPpg from '../../assets/logo-ppg.svg';

import * as S from './styles';

export interface IndexProps {
  /* 'article' is default */
  type?: 'article' | 'author' | 'journal' | 'ppg';
  h4?: boolean;
  value: number;
  name?: string;
}

const CaciqIndex: React.FC<IndexProps> = ({
  type,
  h4,
  value,
  name,
  ...props
}) => {
  const [getName, setGetName] = useState('');

  useEffect(() => {
    if (!name) return;
    setGetName(name);
  }, [name]);

  return (
    <>
      {getName.toLocaleLowerCase().includes('ic') ||
      getName.toLocaleLowerCase().includes('ic-t') ? (
        <S.Container {...props}>
          {type === 'author' ? (
            <S.ContainerRow>
              {/* <S.Img src={LogoAuthor} alt="" /> */}
              <S.IndexName>
                Caciq<S.IndexType>{h4 ? '-H4(A)' : '-A'}</S.IndexType>
              </S.IndexName>
              <S.IndexValue>{Number(value)}</S.IndexValue>
            </S.ContainerRow>
          ) : type === 'journal' ? (
            <S.ContainerRow>
              {/* <S.Img src={LogoJournal} alt="" /> */}
              <S.IndexName>
                Caciq<S.IndexType>{h4 ? '-H4(R)' : '-R'}</S.IndexType>
              </S.IndexName>
              <S.IndexValue>{Number(value)}</S.IndexValue>
            </S.ContainerRow>
          ) : type === 'ppg' ? (
            <S.ContainerRow>
              {/* <S.Img src={LogoPpg} alt="" /> */}
              <S.IndexName>
                Caciq<S.IndexType>{h4 ? '-H4(P)' : '-P'}</S.IndexType>
              </S.IndexName>
              <S.IndexValue>{Number(value)}</S.IndexValue>
            </S.ContainerRow>
          ) : (
            /* article */
            <S.ContainerRow>
              {/* <S.Img src={LogoArticle} alt="" /> */}
              <S.IndexName>
                Caciq<S.IndexType>-T</S.IndexType>
              </S.IndexName>
              <S.IndexValue>{Number(value)}</S.IndexValue>
            </S.ContainerRow>
          )}
        </S.Container>
      ) : null}
    </>
  );
};

export default CaciqIndex;
