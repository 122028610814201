/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../components/Button';
import LoadingScreen from '../../components/LoadingScreen';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import * as S from './styles';
import { useError } from '../../hooks/errors';

interface FormFields {
  currentPassword: string;
  newPassword: string;
  confirmationPass: string;
}

const changePassSchema = Yup.object().shape({
  newPassword: Yup.string().required('A senha é obrigatória'),
  confirmationPass: Yup.mixed().oneOf(
    [Yup.ref('newPassword')],
    'As senhas não coincidem',
  ),
});

const ChangePass = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(changePassSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit: SubmitHandler<FormFields> = async data => {
    try {
      delete data.confirmationPass;
      setLoading(true);
      const request = await api.post('/profile/changePass', data);
      toast.success('Senha alterada');
      setLoading(false);
      window.location.replace('/');
    } catch (err: any) {
      setLoading(false);
      emitError(err, 'Erro ao alterar senha');
    }
  };

  return (
    <S.MainContainer>
      {loading ? <LoadingScreen /> : null}
      <S.Container>
        <h2>
          <span>Alterar </span>Senha
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="currentPass">Senha atual</label>
            <input
              type="password"
              id="currentPass"
              {...register('currentPassword')}
            />
          </div>

          <div>
            <label htmlFor="newPass">Nova senha</label>
            <input type="password" id="newPass" {...register('newPassword')} />
            <p> {errors.newPassword?.message}</p>
          </div>

          <div>
            <label htmlFor="confirmationPass">Confirmar senha</label>
            <input
              type="password"
              id="confirmationPass"
              {...register('confirmationPass')}
            />
            <p> {errors.confirmationPass?.message}</p>
          </div>

          <div className="buttons-section">
            <Button typeOf="submit" size="big">
              Finalizar
            </Button>
          </div>
        </form>
      </S.Container>
    </S.MainContainer>
  );
};

export default ChangePass;
